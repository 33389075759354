@import './../_mixins/grid';
@import './../_variables/colors';
@import './../_variables/grid';
@import './../../../node_modules/lin3s-front-foundation/dist/scss/_mixins/aspect-ratio';

.landing-test-de-velocidad {

  .landing-title__title {
    padding-top: 32px;
    @include media (medium up) {
      padding-top: 96px;
    }
  }

  iframe {
    @include media (large up) {
      height: 650px;
    }
  }

  .section-description__description li ul {
    list-style: circle;
    margin-left: 40px;
  }

  .ofertas {
    @include media (large up) {
      padding-top: 16px;
    }
    .section-description {
      margin-bottom: -12px;
      @include media (large up) {
        margin-bottom: -28px;
      }
    }
  }

  .wifi-mesh-banner-repetidor {
    .button {
      margin-top: 24px;
    }
  }

}