$font-size-title: $font-size-16;
$line-height-title: $line-height-for-font-size-18;

$font-size-description: $font-size-16;
$line-height-description: $line-height-for-font-size-18;

:root {
  --card-offer-max-width: 300px;
  --c-cards-icon-with-description-alt-btn-nav-size: 48px;
}

.c-bigCompanies {
  padding-top: 48px;
  padding-bottom: 110px;

  &__card {
    position: relative;
    @include flex();
    @include flex-direction(column);
    align-items: flex-start;
    max-width: var(--card-offer-max-width);
    width: 100%;
    height: 100%;
    background-color: $color-white;
  }

  &__card-picture {
    width: 100%;

    > a {
      display: inline-block;
      width: 100%;

      img {
        object-fit: contain;
        width: inherit;
        margin-bottom: 20px;

        @media (hover: hover) {
          cursor: pointer;
        }
      }
    }

    &:hover {
      .c-bigCompanies__cardSeparator {
        width: 100%;
      }
    }
  }

  &__card-line-container {
    width: fit-content;
  }

  &__cardTitle {
    font-size: $font-size-title;
    line-height: $line-height-title;
    font-weight: bold;
    font-family: $font-family-secondary;
    color: $color-tundora;
  }

  &__cardText {
    font-size: $font-size-description;
    line-height: $line-height-description;
    font-family: $font-family-secondary;
    color: $color-tundora;
  }

  &__cardSeparator {
    border: none;
    margin: 16px 0;
    width: 32px;
    height: 1px;
    background-color: $color-pantone-red;
    transition: width $transition-duration-02s $transition-ease-in-out;
  }

  &--centered {
    .c-big-companies__card {
      .c-big-companies__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
    }
  }
}
