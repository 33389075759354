@import './../_variables/colors';
@import './../_theme/r-colors';

:root {
  --card-tarifa-min-width: 300px;
  --card-tarifa-max-width: 300px;

  @include media(medium up) {
    --card-tarifa-min-width: 350px;
    --card-tarifa-max-width: 400px;
  }
}

.c-card-tarifa {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  width: 100%;
  min-width: var(--card-tarifa-min-width);
  max-width: var(--card-tarifa-max-width);
  height: 100%;

  box-shadow: 4px 4px 20px rgba(38, 37, 37, 0.1);
  background-color: $color-grey-100;
  padding: 24px 16px;

  &--black {
    color: $color-white;
    background-color: $color-grey-800;

    .c-card-tarifa__content {
      padding-left: 6px;
    }
  }

  &--large {
    --card-tarifa-min-width: 290px;
    --card-tarifa-max-width: 290px;

    @include media(medium up) {
      --card-tarifa-min-width: 250px;
      --card-tarifa-max-width: 290px;
    }

    .c-card-tarifa__body {
      margin-top: 46px;
    }

    .c-card-tarifa__title {
      padding-left: 0;
      text-align: center;
      font-size: $font-size-36;
      line-height: 42px;
      color: $color-tundora;
    }

    .c-card-tarifa__content {
      justify-content: center;
    }

    .c-card-tarifa__icon {
      position: absolute;
      top: 0;
      padding: 16px 0 25px;

      & svg {
        height: 32px;
        width: 32px;

        & > * {
          stroke: $color-secondary;
        }
      }
    }

    .c-card-tarifa__description {
      color: $color-gray;
    }

    .c-card-tarifa__price {
      justify-content: center;
      margin-top: 16px;
    }

    .c-card-tarifa__integer, .c-card-tarifa__decimal {
      color: $color-secondary;
    }

    .c-card-tarifa__cta {
      margin-top: 5px;
    }
  }

  &--bono {
    @extend .c-card-tarifa--large;

    .c-card-tarifa__body {
      margin-top: 0;
    }

    .c-card-tarifa__content {
      display: none;
    }

    .c-card-tarifa__price {
      align-items: end;
      margin-bottom: 15px;
      margin-top: 35px;
    }

    .c-card-tarifa__offer {
      margin-right: 30px;
    }

    .c-card-tarifa__offer, .c-card-tarifa__real-price {
      display: flex;
      align-items: start;
      flex-direction: column;
    }

    .c-card-tarifa__offer .c-card-tarifa__text {
      font-size: $font-size-14;
      line-height: 16px;
      color: $color-gray;
    }

    .c-card-tarifa__real-price .c-card-tarifa__text {
      font-size: $font-size-12;
      line-height: 12px;
      color: $color-gray;
    }

    .c-card-tarifa__real-price .c-card-tarifa__integer,
    .c-card-tarifa__real-price .c-card-tarifa__decimal {
      font-size: $font-size-14;
      line-height: 24px;
    }

    .c-card-tarifa__offer__content, .c-card-tarifa__real-price__content {
      display: flex;
      align-items: center;
      margin-right: auto;
    }

    .c-card-tarifa__integer {
      line-height: 48px;
    }
  }

  &__ribbon {
    background-color: #14CCAF;
    font-size: 14px;
    line-height: 24px;
    width: 100%;
    padding: 0 var(--card--oferta-side-padding);
    color: $color-white;
    font-family: $font-family-secondary;
    font-weight: bold;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-top: -16px;

    .icon {
      height: auto;
      width: auto;
      margin-right: 8px;

      & > * {
        stroke: $color-white;
        fill: $color-white;
      }
    }
  }

  &__title {
    padding-left: 6px;

    font-family: $font-family-primary;
    font-weight: $font-weight-primary-bold;
    font-size: $font-size-21;
    line-height: 27px;
    position: relative;

    > span {
      font-size: $font-size-24;
    }

    &--crossed-out {
      &::after {
        content: " ";
        top: 50%;
        width: 120px;
        position: absolute;
        right: 50%;
        border: 1px solid #000;
        transform: translate(50%) rotate(10deg);
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    font-family: $font-family-secondary;
    font-size: $font-size-14;
    line-height: 14px;
  }

  &__icon {
    display: flex;

    padding: 0 14px 0 6px;

    > svg {
      flex-shrink: 0;
    }
  }

  &__description {
    flex-shrink: 1;
  }

  &__price {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    margin-top: 32px;
  }

  &__destacado {
    background-color: $color-azure-radiance;
    line-height: 32px;
    padding: 0 var(--card--oferta-side-padding) 0 30px;
    color: $color-white;
    margin: 12px -16px 12px -24px;
    right: 0;
    box-shadow: 4px 4px 20px rgba(38, 37, 37, 0.1);
    position: relative;
    font-size: 14px;
    font-family: $font-family-secondary;
    font-weight: $font-weight-secondary-regular;

    &:before {
      content: ' ';
      width: 8px;
      height: 32px;
      background-color: #0067BB;
      position: absolute;
      left: 0;
      z-index: -1;
      transform: skewY(45deg);
      transform-origin: 0 0;
    }

    &--small {
      width: max-content;
    }
  }

  &__oldprice {
    margin-top: 14px;
    width: 100%;
    text-align: center;

    &__text {
      font-size: 14px;
      line-height: 16px;
    }

    &__price {
      font-family: $font-family-primary;
      font-size: 24px;
      line-height: 27px;
      font-weight: bold;
      text-decoration-line: line-through;

      .price-text {
        font-family: $font-family-secondary;
        font-size: 14px;
        line-height: 16px;
        font-weight: normal;
      }
    }
  }

  &__integer {
    font-family: $font-family-primary;
    font-weight: $font-weight-primary-bold;
    font-size: $font-size-48;
    line-height: 58px;
  }

  &__price-decimal {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    height: 100%;

    font-family: $font-family-secondary;
  }

  &__decimal {
    font-size: $font-size-24;
    font-weight: $font-weight-secondary-bold;
    line-height: 24px;
  }

  &__frecuency {
    font-size: $font-size-14;
    line-height: 14px;
    color: $color-grey-600;
  }

  &__promo_frecuency {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
    text-align: center;
  }

  &__cta {
    margin-top: 18px;

    & a {
      width: 100%;
      padding-bottom: 12px;
      padding-top: 12px;
    }
  }

  &__links {
    div div div div a {
      border-radius: 100px;
    }
  }

  .webcareslot1 a {
    border-radius: $button-border-radius;
  }

  &__condiciones {
    min-height: 30px;
    width: 100%;
    margin-top: 8px;

    & a {
      font-family: $font-family-secondary;
      font-weight: normal;
      font-size: $font-size-14;
      line-height: $line-height-for-font-size-18;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: $color-international-orange;
    }
  }

}
