.c-testimoniales {
  position: relative;
  padding: 40px 0 24px 0;
  background: var(--color-white-v21);

  @media screen and (min-width: 768px){
    padding: 64px 0 34px 0;
  }

  &__content {
    @include flexbox();
    @include flex-direction(row);
  }

  &__list {
    position: relative;
    margin-right: 0;

    @media screen and (min-width: 768px){
      margin-right: 16px;
    }
  }

  &__item {
    margin-bottom: 16px;
    cursor: pointer;
    background: var(--color-white-v21);
    padding: 24px;
    height: 100%;
    @include flexbox();
    @include flex-direction(column);

    &__info {
      @include flexbox();
      @include align-items(center);
    }

    &__name {
      font-family: var(--font-family-primary);
      font-weight: bold;
      font-size: 21px;
      line-height: 29px;
      color: var(--color-text-v21);
    }

    &__via {
      font-family: var(--font-family-secondary);
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: var(--color-text-v21);
      margin-left: 12px;
    }

    &__name-info {
      font-family: var(--font-family-secondary);
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.4);
    }

    &__avatar {
      margin-right: 16px;
      width: 48px;
      height: 48px;
      border-radius: 50%;

      & .icon {
        --width: 48px;
        --height: 48px;
      }

      & img,
      & svg {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    &__more-info {
      margin-top: 8px;

      &__title {
        font-family: var(--font-family-primary);
        font-size: 18px;
        line-height: 24px;
        color: var(--color-text-v21);
        margin-bottom: 12px;
      }

      &__copy {
        font-family: var(--font-family-secondary);
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: var(--color-text-v21);

        & strong {
          color: var(--color-primary-v21);
        }
      }
    }
  }

  & .c-carousel__nav-controls {
    @include flexbox();
  }
}
