@import './../_variables/_colors';

$login-title-font-size: $font-size-18;
$login-title-line-height: $line-height-for-font-size-18;

$sign-up-title-font-size: $font-size-14;
$sign-up-title-line-height: $line-height-for-font-size-13;

.cybersecurity {
  &.user-login-form {
    min-width: 0;

    @media screen and (min-width: 375px) {
      min-width: 240px;
    }

    &__title {
      font-size: $login-title-font-size;
      line-height: $login-title-line-height;
    }
    .contact-form__submit {
      display: flex;
      justify-content: center;
    }
  }
}
