$height-coverage-banner: 48px;

:root {
  --height-coverage-banner: #{$height-coverage-banner};
}

.c-coverage-banner {
  background-color: $color-grey-800;
  width: 100%;
  height: $height-coverage-banner;
  font-size: $font-size-16;
  line-height: 24px;
  font-family: $font-family-secondary;

  @include media(medium down) {
    font-size: $font-size-14;
    line-height: 16px;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 1440px;
    color: $color-white;
    height: $height-coverage-banner;

    &__button {
      margin-left: 15px;
      font-size: $font-size-16;
      line-height: 24px;

      @include media(medium down) {
        & > * {
          font-size: $font-size-14;
        }
      }

      @include media(mobilexlarge down) {
        display: none;
      }

      &--mobile {
        display: none;

        @include media(mobilexlarge down) {
          display: inline;
        }
      }
    }

    &__pipe {
      margin: 0 22px;
      height: 24px;
      border: 1px solid $color-white;

      @include media(mobilexlarge down) {
        display: none;
      }
    }

    &__link.link.link--white-secondary {
      font-size: $font-size-16;
      line-height: 24px;
      font-weight: $font-weight-secondary-semi-bold;

      @include media(medium down) {
        font-size: $font-size-14;
      }

      @include media(mobilexlarge down) {
        display: none;
      }
    }
  }
}
