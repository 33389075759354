.c-ventajas-tarifas {
  position: relative;
  margin-bottom: 28px;

  @include media(large up) {
    &--leave-space-right {
      width: calc(100% - var(--configurador-width) - var(--configurador-spacing));

      & .c-ventajas-tarifas__ventaja {
        flex: 1 1 calc(50% - 24px);
      }
    }
  }

  &__container {
    position: relative;

    @include media(large down) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  & .section-description__title {
    position: relative;
    font-family: $font-family-primary;
    font-weight: $font-weight-primary-bold;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.5px;
    color: $color-black;
    margin-bottom: 40px !important;

    @include media(medium down) {
      font-size: 20px;
      line-height: 26px;
      text-align: center;
    }
  }

  &__list {
    @include flex();
    justify-content: space-between;
    flex-wrap: wrap;

    &--4-columns { 

      .c-ventajas-tarifas__ventaja {
        flex: 1 1 100%;

        @include media(medium up) {
          flex: 1 1 calc(50% - 24px);
        }

        @include media(large up) {
          flex: 1 1 calc(25% - 24px);
        }
      }
    }
  }

  &__ventaja {
    @include flex();
    margin-bottom: 24px;
    margin-right: 0;
    flex: 1 1 100%;

    @include media(medium up) {
      margin-bottom: 32px;
      margin-right: 24px;
      flex: 1 1 calc(33% - 24px);
    }

    // &:last-child {
    //   margin-right: 0;
    // }

    &__icon {
      @include flex();
      margin-right: 16px;
      display: flex;
      align-items: baseline;
      margin-bottom: 8px;
      
      &--ventajas-tv {
	  	flex: 0 0 26px;
      }
    }

    &__block {
      width: 100%;
      max-width: 100%;

      @include media(medium up) {
        max-width: 250px;
      }
    }

    &__title {
      font-weight: $font-weight-primary-bold;
      font-family: $font-family-primary;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 32px;
      letter-spacing: -0.5px;
    }

    &__text {
      font-family: $font-family-secondary;
      font-size: 14px;
      line-height: 16px;
    }
  }

}
