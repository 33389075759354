.landing-cambio-red-movil {
  background-color: #F9F9F9;
  .landing-cambio-red-movil__content__step-slider{
    &.step-left-align{
      text-align: left;
    }
  }
  & .main {
    margin-top: 0;
  }

  &__logo {
    height: 60px;
    width: 100%;
    background: $color-primary;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      height: 34px;
    }

    @include media(medium up) {
      height: 72px;
    }
  }

  &__overlay {
    height: 255px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);

    @include media(medium up) {
      height: 100%;
    }

    @include media(xlarge up) {
      display: none;
    }
  }

  &__bg {
    height: 255px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    @include media(medium up) {
      height: 100%;
    }

    & img {
      height: 100%;
      object-fit: cover;
      object-position: top right;
      width: 100%;
    }
  }

  &__content-header {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  &__content-titles {
    height: 255px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 16px;

    @include media(medium up) {
      height: auto;
      padding: 0 32px;
    }
  }

  &__header {
    position: relative;
    width: 100%;
    height: 478px;

    &__title {
      font-family: $font-family-primary;
      font-weight: 500;
      font-size: 28px;
      line-height: 42px;
      color: $color-white;
      text-transform: uppercase;

      @include media(medium up) {
        font-size: 50px;
        line-height: 75px;
      }
    }

    &__subtitle {
      font-family: $font-family-primary;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: $color-white;
      margin-bottom: 16px;

      @include media(medium up) {
        font-size: 28px;
        line-height: 42px;
      }
    }

    &__copy {
      font-family: $font-family-secondary;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--color-black-v21);
      margin-bottom: 32px;
      text-align: center;
      padding: 16px 16px 0;

      @include media(medium up) {
        padding: 0;
        height: auto;
        text-align: left;
        color: $color-white;
      }
    }

    &__ctas {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include media(medium up) {
        flex-direction: row;
      }

      & .button {
        display: flex;
        margin-right: 0;
        margin-bottom: 16px;
        background: #00A3E0;
        border-radius: 8px;

        @include media(medium up) {
          margin-right: 24px;
          margin-bottom: 0;
        }
      }

      & .icon {
        margin-right: 10px;
      }

      & .icon svg > * {
        fill: $color-white;
      }
    }

    &__bottom {
      width: 100%;
      background-color: $color-white;

      @include media(medium up) {
        background-color: transparent;
      }

      & .u-wrapper {
        padding: 0;

        @include media(medium up) {
          padding: 0 32px
        }
      }
    }
  }


  &__content {
    width: 100%;
    padding: 32px;

    @include media(medium up) {
      padding: 64px 32px;
    }

    &__content-block {
      position: relative;
      background: $color-white;
      border-radius: 8px;
      max-width: 1036px;
      width: 100%;
      margin: 46px auto 80px;
      padding: 64px 16px;

      @include media(medium up) {
        padding: 64px 32px;
      }

      @include media(large up) {
        padding: 64px 100px;
      }
    }

    &__title {
      text-align: center;
      font-family: $font-family-primary;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      padding: 0 16px;

      @include media(medium up) {
        padding: 0;
        font-size: 28px;
        line-height: 42px;
      }
    }

    &__title-info {
      text-align: center;
      font-family: $font-family-primary;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 32px;
      text-transform: uppercase;

      @include media(medium up) {
        font-size: 21px;
        line-height: 31px;
      }
    }

    &__bubble {
      width: 72px;
      height: 72px;
      border-radius: 50%;
      background-color: $color-white;
      box-shadow: 4px 4px 20px rgba(38, 37, 37, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 0;
      position: absolute;
      left: 0;
      right: 0;
      top: -36px;
    }

    &__bubble-number {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: $color-white;
      box-shadow: 4px 4px 20px rgba(38, 37, 37, 0.1);
      font-family: $font-family-secondary;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: $color-primary;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0;
      margin-bottom: 16px;

      @include media(medium up) {
        margin-right: 16px;
        margin-bottom: 0;
      }
    }

    &__step {
      position: relative;
      margin-bottom: 72px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__step-text {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 40px;

      @include media(medium up) {
        align-items: center;
        flex-direction: row;
      }
    }

    &__step-image {
      text-align: center;

      & img {
        width: 180px;

        @include media(medium up) {
          width: 240px;
        }
      }
    }

    &__step-slider {
      text-align: center;
      margin: 0 -16px 0 -16px;

      .c-carousel .swiper-container {
        padding: 0;
      }

      .c-carousel__nav-controls {
        display: none;
      }

      .swiper-slide {
        position: relative;
      }

      .swiper-slide-next .landing-cambio-red-movil__content__slider__card-overlay {
        right: -1px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.9) 3.72%, #FFFFFF 28.25%);
      }

      .swiper-slide-prev .landing-cambio-red-movil__content__slider__card-overlay {
        left: -1px;
        background: linear-gradient(270deg, rgba(255, 255, 255, 0.9) 3.72%, #FFFFFF 28.25%);
      }

      .swiper-slide-active .landing-cambio-red-movil__content__slider__card-overlay {
        opacity: 0;
        @include transition();
      }

      .swiper-slide-active .landing-cambio-red-movil__content__slider__info {
        opacity: 1;
        @include transition();
      }
    }

    &__step-note {
      text-align: left;
      font-family: $font-family-secondary;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 32px;
    }

    &__slider {
      &__card-overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 1;
        @include transition();
      }

      &__card {
        position: relative;
        padding-bottom: 24px;
        display: flex;
        flex-direction: column;

        & img {
          max-width: 240px;
          width: auto;
        }
      }

      &__info {
        margin-top: 16px;
        padding: 16px;
        background: var(--color-white);
        box-shadow: 4px 4px 20px rgba(38, 37, 37, 0.1);
        border-radius: 8px;
        min-height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        @include transition();
        max-width: 240px;

        & p {
          font-family: $font-family-secondary;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          text-align: center;
        }
      }

      &__footer {
        margin-top: 0;
      }

      &__counter {
        display: flex;
        align-items: center;
        justify-content: center;

        &__index {
          font-size: 14px;
          line-height: 16px;
        }

        &__total {
          font-family: $font-family-secondary;
          color: #b2b2b2;
          font-size: 14px;
          line-height: 16px;
        }

        &__nav {
          margin-left: 46px;

          .button {
            box-sizing: border-box;
            flex: 0 0 56px;
            cursor: pointer;
            border-radius: 50%;
            height: 56px;
            width: 56px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-secondary;
            background: transparent;
            max-width: 56px;
            min-width: auto;
            margin: 0 8px;

            .icon {
              stroke-width: calc(8 / 3);
              display: inline-flex;
              width: 12.5px;
              margin: 0;
            }

            &[aria-disabled="true"] {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }

  &__cta-call {
    display: flex;
    margin: 0 auto;
    min-width: 130px;
    width: 130px;
    background: #00A3E0;
    border-radius: 8px;
    color: $color-white;
    padding: 11px 20px 9px;

    .icon svg > * {
      stroke: $color-white;
    }
  }
}



