@import '../_variables/colors';

$data-center-description-font-size: $font-size-16;
$data-center-description-line-height: $line-height-for-font-size-18;

$data-center-cards-font-size: $font-size-18;
$data-center-cards-line-height: $line-height-for-font-size-18;

$section-header-font-size: $font-size-24;
$section-header-line-height: $line-height-for-font-size-26;

.soluciones_cloud {
  .section-header::after,
  .card-icon-with-description__title::after {
    background-color: $color-pantone-red;
  }

  .section-description {
    &--centered {
      margin-bottom: 0;
    }

    &__title {
      font-size: $section-header-font-size !important;
      line-height: $section-header-line-height;
    }
    &__description {
      margin-top: 26px;

      font-size: $data-center-description-font-size;
      line-height: $data-center-description-line-height;
    }
  }

  &_housing {
    .housing-cards {
      padding-top: 0 !important;
    }

    .horizontal-scroller {
      &__item {
        @media screen and (min-width: 48em) {
          transform: none;
          flex: 0 0 25% !important;
        }
      }
    }

    &_cta {
      display: flex;
      justify-content: center;
    }
  }

  &__datacenter {
    display: block;
    margin-top: 48px;

    &__container {
      @media (min-width: 64em) {
        display: flex;
        width: 100%;

        > :first-child {
          flex: 0 0 28%;
        }

        > :not(:first-child) {
          width: 64%;
          margin-left: 8%;
        }
      }
    }

    &__cards {
      /* margin-top: 48px;
      @media only screen and (min-width: 64em) {
        margin-top: 0;
      } */
      .c-porQueR {
        padding-bottom: 0;
        padding-top: 0;
        .c-carousel {
          padding-top: 0;
          .swiper-slide {
            width: max-content !important;
          }
          .swiper-container {
            padding-left: 0;
            padding-top: 0;
          }
        }

        .c-porQueR__card {
          max-width: 255px;
          width: 100%;
        }

        .c-porQueR__content {
          padding: 48px 16px;
        }

        .c-porQueR__cardTitle {
          font-family: $font-family-primary;
          font-size: $data-center-cards-font-size;
          line-height: $data-center-cards-line-height;
          font-weight: 700;
          color: $color-tundora;
        }

        .c-porQueR__cardText {
          color: $color-tundora;
        }
      }
    }

    .u-wrapper {
      .c-carousel {
        .swiper-slide {
          width: max-content;
        }
        &__nav-controls {
          display: flex !important;
        }
      }
    }

    .soluciones_cloud_housing_datacenter-button {
      margin-top: 36px;

      @media screen and (min-width: 60em) {
        margin-top: 16px;
      }
    }
  }
}
