@import './../_variables/colors';

$links-group-title-text-color: $color-primary;
$links-group-title-font-family: $font-family-primary;
$links-group-title-font-size: $font-size-14;
$links-group-title-font-weight: $font-weight-primary-semi-bold;
$links-group-link-text-color: $color-mine-shaft;
$links-group-link-text-color-hover: $color-primary;
$links-group-link-font-family: $font-family-secondary;
$links-group-link-font-size: $font-size-14;
$links-group-link-font-weight: $font-weight-secondary-regular;
$links-group-link-line-height: $line-height-for-font-size-14;

.links-group {
  .link {
    color: $links-group-link-text-color;
    font-family: $links-group-link-font-family;
    font-size: $links-group-link-font-size;
    font-weight: $links-group-link-font-weight;
    line-height: $links-group-link-line-height;
    padding: 12px 0;

    &:hover {
      color: $links-group-link-text-color-hover;
    }
  }
}

.links-group__title {
  color: $links-group-title-text-color;
  font-family: $links-group-title-font-family;
  font-size: $links-group-title-font-size;
  font-weight: $links-group-title-font-weight;
  margin-bottom: 8px;
  text-transform: uppercase;
}
