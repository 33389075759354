:root {
  --component-banner-image-label-color-bg: #fff;
  --component-banner-image-label-color: var(--color-tertiary-v21);
  --component-banner-image-color: #fff;

  [data-theme="dark"] {
    --component-banner-image-label-color-bg: #fff;
    --component-banner-image-label-color: var(--color-tertiary-v21);
    --component-banner-image-color: var(--color-text-v21);
  }

  [data-theme="netflix"] {
    --component-banner-image-label-color-bg: var(--color-chart-11);
    --component-banner-image-label-color: #fff;
    --component-banner-image-color: #fff;
  }

}

.c-banner-image {
  min-height: 250px;
  margin-top: calc(var(--s-v21) * 5);
  margin-bottom: 60px;
  border-radius: 16px;
  overflow: hidden;

  @include media (medium up) {
    margin-top: 57px;
    margin-bottom: 57px;
  }

  &__container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-end;
    height: 100%;

    @include media (medium up) {
      align-items: flex-start;
    }
  }

  &__content {
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    padding: 41px 20px 31px 20px;
    margin-bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    width: 100%;

    @include media (medium up) {
      max-width: 560px;
      padding: 51px 33px 41px 105px;
      background: none;
    }

    & .label--banner {
      background-color: var(--component-banner-image-label-color-bg);
      color:  var(--component-banner-image-label-color);
      padding: 6px 8px;
      border-radius: 4px;
      margin-bottom: 16px;
      font-family: var(--font-family-primary);
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      width: -moz-fit-content; // Firefox
      width: fit-content; // Chrome and other browsers
    }
  }

  &__wrapper-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    position: relative;
    font-family: var(--font-family-primary);
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: var(--component-banner-image-color);

    &--desktop {
      display: none;

      @include media (medium up) {
        display: block;
      }
    }

    &--mobile {
      @include media (medium up) {
        display: none;
      }
    }
  }

  &__description {
    position: relative;
    font-family: var(--font-family-secondary);
    font-size: 16px;
    line-height: 22px;
    color: var(--component-banner-image-color);
    margin-top: 16px;

    &--desktop {
      display: none;

      @include media (medium up) {
        display: block;
      }
    }

    &--mobile {
      @include media (medium up) {
        display: none;
      }
    }
  }

  &__actions {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 26px;
    width: 100%;

    @include media (medium up) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__add-offer {
    width: 100%;
    position: relative;
    padding-right: 37px;
    margin-bottom: 20px;

    @include media (medium up) {
      margin-bottom: 0;
    }
  }

  &__see-more {
    position: relative;
    color: var(--color-white-v21);
    font-family: var(--font-family-secondary);
    font-size: 16px;
    line-height: 24px;

    & .icon svg > * {
      stroke: var(--color-white-v21);
    }
  }

  &__logo {
    display: none;
    position: relative;
    width: 330px;
    margin: 80px 100px 80px 0;

    @include media (medium up) {
      display: initial;
    }
  }
  
  &--netflix {
    & .c-banner-image__label {
      background: var(--color-chart-11);
      color: var(--color-white-v21);
    }

    & .c-banner-image__title {
      color: var(--color-white-v21);
    }
  }

  &--internet-segunda-vivienda {

    .c-banner-image__title {
      color: var(--color-white-v21);
    }

    p {
      color: var(--color-white-v21);
    }

    .c-banner-image__content {
      max-width: 650px;
    }

  }

  &--fibra-max {
    position: relative;

    .c-banner-image__wrapper-content {
      @media (max-width: 900px){
        padding-top: 100px;
      }
    }

    .c-banner-image__content {
      background: none !important;
    }

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.4);
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }

    &:after {
      content: "";
      width: 400px;
      height: 200px;
      position: absolute;
      top: 55px;
      right: 105px;
      display: block;
      background-image: url('#{$environment-local-url}/images/apps/liga-ea-sports.svg');
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 2;

      @media (max-width: 900px){
        width: 200px;
        height: 100px;
        top: 35px;
        right: 50%;
        transform: translateX(50%)
      }

      @media (min-width: 901px) and (max-width: 1160px){
        width: 240px;
        height: 120px;
        top: 170px;
        right: 45px;
      }

      @media (min-width: 1161px) and (max-width: 1300px){
        width: 300px;
        height: 140px;
        top: 85px;
      }
    }
  }
}
