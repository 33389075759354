.c-banner-full-image {
  
  &.c-banner-full-image--isSticky {
    display: none;

    @include media(medium up) {
      display: block;
    }
  }

  &__sticky {
    position: fixed;
    bottom: 0;
    width: 100vw;
    display: flex;
    align-items: center;
    height: 56px;

    @include media(medium up) {
      display: none;
    }

    .c-banner-full-image__logo {
      width: 40px;
      height: 40px;
      padding: 2px;
      margin:  8px 15px;
      border: 1px solid #ECECEC;
    }

    &__text {
      display: inline-flex;
      background-color: #F6F6F6;
      flex-grow: 1;
      height: 100%;

      h3 {
        display: inline-flex;
        color:#000;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        height: 100%;
        margin-bottom: 0;
        align-items: center;
      }
    }

    &__button {
      display: inline-block;
      background-color: #fff;
      height: 100%;
      width: 56px;

      a {
        display: inline-flex;
        background-color: #000;
        width: 56px;
        height: 56px;
        justify-content: center;
        align-items: center;

        svg {
          width: 15px;
        }

        .icon__glyph--secondary,
        .icon__glyph--primary {
          stroke: #fff;
        }
      }
    }
  }

  .c-contacto__cta {
    position: relative;
    display: inline-block;
    border-color: #fff;

    path {
      fill: #fff;
    }

  }

  &__container {
    position: relative;
  }

  &__top {
    height: 360px;
    padding-left: 15px;
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    @include media(medium up) {
      padding-left: 95px;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }

  &__logo {
    height: 80px;
    width: 80px;
    background-color: #fff;
    position: relative;
    padding: 8px;
    vertical-align: sub;
    margin-right: 20px;
    display: inline-block;
  }

  &__upperContent {
    display: inline-block;
  }

  &__title {
    position: relative;
    color: #fff;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.5px;
    font-weight: bold;
    font-family: $font-family-primary;
    max-width: 228px;

    @include media(medium up) {
      max-width: 350px;
    }

    &--logo {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 8px;
    }
  }

  &__description {
    position: relative;
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    font-family: $font-family-secondary;
    margin-top: 8px;
    max-width: 228px;   

    @include media(medium up) {
      max-width: 350px;
    }
  }
  
  &__button {
	  position: relative;
  }
}