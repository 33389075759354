:root {
  --card-offer-max-width: 300px;
  --card-offer-min-height: 480px;
  --card-offer-image-height: 134px;
  --card-offer-ribbon-height: 32px;
  --card-offer-ribbon-darken-color: #BC031A;
  --c-cards-icon-with-description-alt-btn-nav-size: 48px;

  @include media(medium down) {
    --card-offer-min-height: 80px;
  }
}

.section--ofertas {
  @include media(xlarge down) {
    max-width: 100%;
  }
}

.c-ofertas {
  padding-top: 48px;
  //padding-bottom: 110px;
  width: 100%;
  margin: 0 auto;
  max-width: 1320px;
  @include media(xlarge down) {
    margin: auto;
    max-width: 1280px;
  }
  &--non-padding-bottom{
    padding-bottom: 0;
  }

  &--no-title {
    .c-ofertas__carousel {
      margin-top: 0;
    }
  }

  // Mostramos lineas adicionales solo cuando no es visible el configurador
  .c-ofertas__configurador + .c-ofertas__lineasAdicionales {
    display: none;
  }

  @include media(medium down) {
    .c-ofertas__lineasAdicionales {
      display: inherit !important;
      max-width: 500px;
    }
  }

  @include media(xxlarge up) {
    .c-ofertas__lineasAdicionales {
      display: inherit !important;
      max-width: 500px;
    }
  }
  // FIN Mostramos lineas adicionales solo cuando no es visible el configurador

  &--no-padding-bottom {
      padding-bottom: 0;
   }

   &--min-padding-bottom {
     padding-bottom: 30px;

     @include media(medium up) {
       padding-bottom: 0;
     }
   }

  .section-description__title  {
    padding: 0 16px;
  }
  .swiper-slide {
    @include media(medium down) {
      width: calc(100% - 20px) !important;
      max-width: 300px;
    }
  }

  &__slideConfigurador {
    @include media(xlarge down) {
      display: none;
    }

    @include media(medium down) {
      display: block;
    }
  }

  &__slideMargin {
    margin-top: 24px;
  }

  &--altBackground {
    background-color: $alt-background;
  }

  @include media(medium down) {
    padding-top: 29px;
  }

  .section-description__title {
    font-size: 21px !important;
  }

  .section-description__subtitle {
    font-size: 18px !important;
    font-family: $font-family-primary;
    line-height: 24px;
  }

  .section-description--centered {
    text-align: center;
  }

  &__configurador {
    background-color: #333;
    margin: 24px;
    margin-right: calc(24px + 32px);
    display: flex;
    margin-top: 0;

    @include media(large down) {
      margin-right: 24px;
    }

    @include media(medium down) {
      display: none;
    }

    @include media(xxlarge up) {
      display: none;
    }
  }

  &__configuradorBody {
    display: flex;
    padding: 24px 16px;
    justify-content: center;
    align-items: center;
    width: 100%;

    .c-ofertas__lineasAdicionales {
      color: $color-white-smoke;
    }
  }

  &__configuradorTitle {
    font-size: 18px;
    line-height: 24px;
    font-family: $font-family-primary;
    font-weight: $font-weight-primary-bold;
    color: $color-white;
    margin-bottom: 11px;
  }

  &__configuradorText {
    font-size: 14px;
    line-height: 17px;
    font-family: $font-family-secondary;
    font-weight: $font-weight-secondary-regular;
    color: $color-white;
  }

  &__button--configurador {
    margin: 0 96px;
    padding: 12px 40px;

    @include media(large down) {
      margin: 0 30px;
      padding: 10px 30px;
    }
  }

  &__lineasAdicionales {
    font-size: 16px;
    line-height: 24px;
    font-family: $font-family-secondary;
    font-weight: $font-weight-secondary-bold;
    color: $color-gray;
    max-width: 330px;
  }

  &__destacado-bottom {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    line-height: 24px;
    font-family: $font-family-secondary;
    font-weight: $font-weight-secondary-bold;
    color: $color-gray;
    text-align: center;

    &:first-of-type {
      padding-bottom: 15px;
      border-bottom: 1px solid #e6e6e6;
    }

    &:last-of-type {
      padding-top: 8px;
      font-size: 12px;
    }

    img {
      height: 12px;
      width: auto;
    }
  }

  .swiper-container {
    padding: 24px;
    padding-bottom: 35px;
    margin-bottom: -10px;
    padding-top: 0;
    overflow: hidden;

    @include media(medium down) {
      overflow: visible;
    }
  }

  &__destacado {
    padding: 12px 34px;
    color: $color-white;
    background-color: $color-azure-radiance;
    width: auto;
    margin: 15px auto 15px auto;
    display: flex;
    align-items: center;

    @include media(medium) {
      text-align: center;
      width: max-content;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      height: 32px;
      width: 32px;
      min-width: 32px;
      margin-left: -22px;
      margin-right: 8px;
      background: rgba($color-white, 0.2);

      & > svg {
        height: 15px;
        & > * {
          stroke: $color-white;
        }
      }
    }
  }
}
