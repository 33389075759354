@import './../../_mixins/grid';
@import './../../_variables/colors';
@import './../../_variables/grid';
@import './../../_variables/transition';
@import './../../_variables/z-index';

$interstitial-popup-transition-duration: .3s;
$interstitial-popup-overlay-background-color: rgba($color-mine-shaft, .6);
$interstitial-popup-content-background-color: $color-white;
$interstitial-popup-content-shadow-color: rgba($color-black, .5);
$interstitial-popup-overlay-transition-out: opacity $interstitial-popup-transition-duration ease-in-out $interstitial-popup-transition-duration;
$interstitial-popup-overlay-transition-in: opacity $interstitial-popup-transition-duration ease-in-out;
$interstitial-popup-content-transition-out: opacity $interstitial-popup-transition-duration ease-in-out, transform $interstitial-popup-transition-duration ease-in-out;
$interstitial-popup-content-transition-in: opacity $interstitial-popup-transition-duration ease-in-out $interstitial-popup-transition-duration, transform $interstitial-popup-transition-duration ease-in-out $interstitial-popup-transition-duration;
$interstitial-popup-header-background-color: $color-black;
$interstitial-popup-header-text-color: $color-white;
$interstitial-popup-header-font-family: $font-family-secondary;
$interstitial-popup-header-font-size-mobile: $font-size-18;
$interstitial-popup-header-font-size: $font-size-24;
$interstitial-popup-header-font-weight: $font-weight-secondary-bold;
$interstitial-popup-header-line-height-mobile: $line-height-for-font-size-18;
$interstitial-popup-header-line-height: $line-height-for-font-size-24;
$interstitial-popup-title-text-color: $color-mine-shaft;
$interstitial-popup-title-font-family: $font-family-primary;
$interstitial-popup-title-font-size: $font-size-24;
$interstitial-popup-title-font-weight: $font-weight-primary-bold;
$interstitial-popup-title-line-height: $line-height-for-font-size-24;
$interstitial-popup-sub-title-text-color: $color-tundora;
$interstitial-popup-sub-title-font-family: $font-family-secondary;
$interstitial-popup-sub-title-font-size: $font-size-16;
$interstitial-popup-sub-title-font-weight:  $font-weight-secondary-regular;
$interstitial-popup-sub-title-line-height: $line-height-for-font-size-16;

.interstitial-popup {
  bottom: 0;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: fixed;
  width: 100%;
  z-index: $z-index-interstitial-popup;
}

.interstitial-popup--opened {
  pointer-events: auto;

  .interstitial-popup__overlay {
    opacity: 1;
    transition: $interstitial-popup-overlay-transition-in;
  }

  .interstitial-popup__content {
    opacity: 1;
    transform: scale(1);
    transition: $interstitial-popup-content-transition-in;
  }
}

.interstitial-popup__overlay {
  background-color: $interstitial-popup-overlay-background-color;
  bottom: 0;
  cursor: url('#{$environment-local-url}/images/close-cursor.png'), pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  overflow-y: auto;
  position: absolute;
  transition: $interstitial-popup-overlay-transition-out;
  width: 100%;
  z-index: 0;
}

.interstitial-popup__contents {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 800px;
  padding: 100px 30px 30px;
}

.interstitial-popup__content-layer {
  align-items: flex-end;
  display: inline-flex;
  flex-direction: column;
}

.interstitial-popup__header,
.interstitial-popup__body,
.interstitial-popup__image {
  padding: 15px 30px;
}

.interstitial-popup__image {
  padding-top: 0;
  text-align: center;
}

.interstitial-popup__content {
  background-color: $interstitial-popup-content-background-color;
  box-shadow: 0 5px 45px -10px $interstitial-popup-content-shadow-color;
  cursor: default;
  max-height: 100%;
  max-width: 100%;
  opacity: 0;
  transform: scale(1.1);
  transition: $interstitial-popup-content-transition-out;
}

.interstitial-popup__header {
  background-color: $interstitial-popup-header-background-color;
  color: $interstitial-popup-header-text-color;
  font-family: $interstitial-popup-header-font-family;
  font-size: $interstitial-popup-header-font-size-mobile;
  font-weight: $interstitial-popup-header-font-weight;
  line-height: $interstitial-popup-header-line-height-mobile;
}

.interstitial-popup__title,
.interstitial-popup__sub-title {
  margin-bottom: 15px;
  text-align: center;
}

.interstitial-popup__title {
  color: $interstitial-popup-title-text-color;
  font-family: $interstitial-popup-title-font-family;
  font-size: $interstitial-popup-title-font-size;
  font-weight: $interstitial-popup-title-font-weight;
  line-height: $interstitial-popup-title-line-height;
}

.interstitial-popup__sub-title {
  color: $interstitial-popup-sub-title-text-color;
  font-family: $interstitial-popup-sub-title-font-family;
  font-size: $interstitial-popup-sub-title-font-size;
  font-weight: $interstitial-popup-sub-title-font-weight;
  line-height: $interstitial-popup-sub-title-line-height;
}

@include media(large up) {
  .interstitial-popup__contents {
    margin-top: 90px;
  }

  .interstitial-popup__header,
  .interstitial-popup__body,
  .interstitial-popup__image {
    padding: 20px 45px;
  }

  .interstitial-popup__header {
    font-size: $interstitial-popup-header-font-size;
    line-height: $interstitial-popup-header-line-height;
  }
}

@include media(xlarge up) {
  .interstitial-popup__contents {
    margin-top: 120px;
  }

  .interstitial-popup__header,
  .interstitial-popup__body,
  .interstitial-popup__image {
    padding: 20px 60px;
  }
}

.image-modal {
  .interstitial-popup__image {
    padding: 0;
  }

  .interstitial-popup__content {
    background-color: transparent;
    box-shadow: none;
  }
}
