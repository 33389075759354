.section-tarifas {
  padding: 83px 0 45px 0;
}

.c-nuevasTarifasMoviles {
  padding: 48px 0;

  &--altBackground {
    background-color: $alt-background;
  }

  .section-description {
    margin-bottom: 14px;
    padding: 0 16px;
  }

  .section-description__title {
    font-size: 21px !important;
  }

  .section-description--centered {
    text-align: center;
  }

  .c-carousel {
    padding: 0;
    margin-top: 32px;
    margin-bottom: 24px;

    .c-carousel__wrapper {
      .swiper-container {
        overflow: visible;

        .swiper-wrapper {
          @include media(medium up) {
            right: 11%;
          }

          .swiper-slide {
            width: 290px !important;
            @include media(medium up) {
              width: 381px !important;
            }
          }
        }
      }
    }
  }

  &__card {
    box-shadow: 4px 4px 20px rgba(38, 37, 37, 0.1);
    background-color: $color-white;
    padding: 24px;
    width: 296px;

    transition: box-shadow 0.2s ease;

    @include media(medium up) {
      width: 396px;
    }

    &:hover {
      box-shadow: 0px 5px 15px rgba(38, 37, 37, 30%);
    }
  }

  &__monthOffer {
    font-size: 14px;
    line-height: 23px;
    padding-left: 22px;
    color: var(--color-white);
    text-transform: uppercase;
    position: absolute;
    top: -24px;
    left: 0;
    width: 296px;
    height: 24px;
    background-color: #52CCAF;
    font-weight: bold;

    @include media(767px) {
      width: 296px;
    }
    @include media(medium up) {
      width: 396px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

  }

  &__gb {
    font-size: $font-size-21;
    font-family: $font-family-primary;
    line-height: $line-height-for-font-size-20;
    font-weight: bold;
    margin-bottom: 16px;
    color: var(--color-text);
  }

  &__tag {
    background-color: $color-grey-200;
    text-transform: uppercase;
    font-size: $font-size-12;
    line-height: $line-height-for-font-size-12;
    font-family: $font-family-secondary;
    font-weight: 500;
    color: $color-grey-800;
    padding: 5px;
  }

  &__item {
    display: flex;
    align-items: center;
    font-family: $font-family-secondary;
    font-size: $font-size-16;
    line-height: $line-height-for-font-size-16;
    margin-bottom: 48px;
    color: var(--color-text);
    flex-direction: column;

    &__inner {
      width: 100%;
      display: flex;
      margin: 0 0 15px 0;
    }

    &__icon {
      height: 24px;
    }

    &__text {
      margin-left: 8px;
      display: flex;
      flex-direction: column;
      @include media(medium up) {
        flex-direction: row;
      }
    }
  }

  &__pricePerMonth {
    display: flex;
    align-items: flex-start;
    font-family: $font-family-primary;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    height: 46px;

    margin-bottom: 17px;
    justify-content: space-between;

    &__container {
      display: flex;
    }

  }

  &__priceInteger {
    font-size: 48px;
    line-height: 48px;
    font-family: $font-family-primary;
    font-weight: 700;
  }

  &__priceDecimal {
    font-size: 24px;
    line-height: 18px;
    font-family: $font-family-secondary;
    font-weight: 700;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    span {
      font-size: 14px;
      line-height: normal;
      color: $color-grey-600;
      margin-left: 4px;
      margin-top: 4px;
    }
  }

  &__priceLater {
    margin-bottom: 16px;
    margin-left: 4px;
    font-size: 14px;

    & .price_later_normal {
      font-weight: normal;
    }

    & .price_later_bold {
      font-weight: bold;
    }
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    min-height: 48px;
    background-color: $color-primary;
    color: $color-white;
    border: none;
    border-radius: 24px;

    font-family: $font-family-secondary;
    font-size: $font-size-16;
    line-height: $line-height-for-font-size-16;
    font-weight: 700;

    &:hover {
      cursor: pointer;
    }

    &__over {
      position: absolute;
      left: 0;
      top: 0;
      width: 296px;
      height: 195px;
      @include media(767px) {
        width: 296px;
      }
      @include media(medium up) {
        width: 396px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__bannerDiscount {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    font-size: 14px;
    line-height: 16px;

    &__icon {
      margin-right: 8px;
    }

    &__text {
      color: var(--color-secondary);;
      font-weight: bold;
    }
  }


  &__footer {
    text-align: center;
    font-family: $font-family-secondary;
    font-size: $font-size-16;
    line-height: $line-height-for-font-size-16;
    font-weight: bold;
    color: $color-grey-600;
    padding: 0 16px;
  }
}
