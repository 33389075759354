.section-por-que-empresas{
  margin-bottom:calc(var(--s-v21)*7);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include media (large up){
    align-items: center;
  }
  @include media (medium up){
    margin-bottom:calc(var(--s-v21)*11) !important;
  }
  .section-description__title{
    margin-bottom:calc(var(--s-v21)*4) !important;
    @include media (medium up){
      text-align: center;
      font-size: 29px !important;
      line-height: 35px !important;
      font-weight: bold !important;
    }
    @include media (large up){
      margin-bottom:calc(var(--s-v21)*5) !important;
    }
  }
}

.por-que-empresas-wrapper{
  display: flex;
  flex-direction: column;
  @include media (large up){
    flex-direction: row;
  }
  .por-que-empresas-element-container{
    display: flex;
    align-items: flex-start;
    @include media (large up){
      max-width: 300px;
      flex-direction: column;
      align-items: center;
    }
    &:not(:last-child){
      margin-bottom: calc(var(--s-v21)*4);
      @include media (large up){
        margin-right: calc(var(--s-v21)*4);
        margin-bottom: 0;
      }
    }
    .element-img{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: calc(var(--s-v21)*3);
      @include media (large up){
        margin-bottom: calc(var(--s-v21)*2);
        margin-right: 0;
      }
      img{
        height: 64px;
        width: 64px;
        max-width: fit-content;
        @include media (medium up){
          height: 80px;
          width: 80px;
        }
      }
    }
    .element-text-container{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: start;
      @include media (large up){
        align-items: center;
        text-align: center;
      }
      .element-text-container-title{
        font-family: $font-family-primary;
        font-weight: bold;
        font-size: 21px;
        line-height: 29px;
        margin-bottom:var(--s-v21);
        @include media (medium up){
          font-size: 21px;
          line-height: 29px;
          margin-bottom:12px;
        }
      }
      .element-text-container-description{
        margin-top:0;
        font-size: 16px;
        line-height: 24px;
        @include media (medium up){
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}
