#modal-browsers {

    .modal__content {
      padding: 0;
    }

    .modal__close-button {
      font-size: 0;
      position: relative;
      top: 50px;

      .icon__glyph {
        stroke: #000;
      }
    }

  }

  .c-browsers {
    padding: 26px 0 0;
    position: relative;

    @include media(medium up) {
      padding: 46px 0 0;
    }

    &__top {
      padding-right: 16px;
      padding-left: 16px;
    }

    &__title {
      color: $color-font-primary;
      font-size: $font-size-em-24;
      font-weight: bold;
      font-family: $font-family-primary;
      letter-spacing: -0.5px;
      line-height: $line-height-for-font-size-30;
    }

    &__subtitle {
      color: $color-font-primary;
      font-size: $font-size-em-18;
      font-weight: normal;
      font-family: $font-family-primary;
      letter-spacing: -0.5px;
      line-height: $line-height-for-font-size-24;
    }

    &__copy {
      color: $color-font-primary;
      font-size: $font-size-em-16;
      font-weight: normal;
      font-family: $font-family-secondary;
      line-height: $line-height-for-font-size-24;
    }

    &__browsers {
      margin-top: 16px;
      background: $color-grey-300;
      padding-bottom: 0;

      &__content {
        padding: 20px 16px;
        @include flex();
        flex-wrap: wrap;
      }

      &__item {
        margin: 0 12px 16px;
        min-width: 115px;
        @include flex();
        @include flex-direction(column);
        align-items: center;

        &__image {
          margin-bottom: 8px;
          height: 48px;
        }

        &__title {
          color: $color-font-primary;
          font-size: $font-size-em-16;
          font-weight: bold;
          font-family: $font-family-secondary;
          line-height: $line-height-for-font-size-24;
        }

        &__link {
          color: $color-primary;
          font-size: $font-size-em-14;
          font-weight: normal;
          font-family: $font-family-secondary;
          line-height: $line-height-for-font-size-24;
        }
      }
    }

    &__modal-content {
      padding: 0;

      &__close-button {
        transform: translate(-25%, 150%);
        z-index: 10;

        .icon__glyph {
          stroke: $color-font-primary;
        }
      }
    }
  }
