.section-centralita-virtual-slider{
  padding-top:calc(var(--s-v21) * 5);
  margin-top:calc(var(--s-v21) * 5);
  margin-bottom:calc(var(--s-v21) * 5);
  background-color: var(--color-grey-200);
  padding-bottom:calc(var(--s-v21) * 7);
  @include media(medium up){
    margin-top:calc(var(--s-v21) * 12);
    margin-bottom:calc(var(--s-v21) * 12);
    padding-top:calc(var(--s-v21) * 7);
  }
  .section-por-que-empresas{
    margin-top:calc(var(--s-v21) * 5);
    @include media(medium up){
      margin-top:calc(var(--s-v21) * 12);
    }
  }
  .centralita-virtual-slider-wrapper{
    .centralita-virtual-slider-title{
      color: var(--color-text-v21);
      font-family: $font-family-primary;
      display:none;
      @include media(medium up){
        display: block;
        text-align: center;
        font-weight: 600;
        font-size: 37px;
        line-height: 55px;
      }
    }
    .centralita-virtual-slider-description{
      display:none;
      @include media(medium up){
        font-family: $font-family-secondary;
        display: block;
        text-align: center;
        font-weight: 300;
        font-size: 16px;
        line-height: 22px;
      }
    }
    .centralita-virtual-slider-mobile-title{
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      @include media(medium up){
        display: none;
      }
    }
    .centralita-virtual-slider-swiper-container{
      margin-top: calc(var(--s-v21)*3);
      @include media(medium up){
        margin-top: calc(var(--s-v21)*4);
      }
      p{
        margin-top: 0;
        margin-bottom: 0;
      }
      .centralita-virtual-slider-swiper-wrapper{
        // display: flex;
        justify-content: flex-start;
        @media screen and (min-width: 1160px) {
          justify-content: center;
         }
        .centralita-virtual-slider-card{
          display: flex;
          flex-direction: column;
          color: var(--color-text-v21);
          width: 290px;
          min-height: 496px;
          background-color: #fff;
          box-shadow: 4px 4px 20px #E5E5E5;
          border-radius: 16px;
          padding: calc(var(--s-v21)*2);
          &:not(:last-child){
            margin-right: calc(var(--s-v21)*4);
          }
          p{
            margin-top: 0;
            margin-bottom: 0;
          }
          .card-inner-title{
            font-weight: bold;
            font-size: 20px;
            line-height: 28px;
            margin-bottom: calc(var(--s-v21)*2);
          }
          .card-inner-image{
            align-self: center;
            width: 200px;
            img{
              width: 100%;
            }
          }
          .card-inner-list-title{
            font-weight: 900;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: var(--s-v21);
          }
          .card-inner-list{
            margin-bottom: calc(var(--s-v21)*2);
            .inner-list-block{
              display: flex;
              align-items: center;
              margin-bottom: 8px;
              .icon{
                margin-right: var(--s-v21);
                width: fit-content !important;
                img{
                  width: 16px;
                  height: 16px;
                }
              }
              .text{
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color:#999;
              }
            }
          }
          .card-free{
            font-weight: 700;
            font-size: 26px;
            line-height: 32px;
            // margin-top: 6px;
            margin-bottom: 43px;
          }
          .card-price-container{
            margin-bottom: calc(var(--s-v21)*2);
            .card-price{
              .price-int{
                font-family: var(--font-family-primary);
                font-weight: 700;
                font-size: 32px;
                line-height: 48px;
              }
              .price-decimal{
                margin-left: -4px;
                font-family: var(--font-family-primary);
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
              }
              .price-month{
                font-size: 16px;
                line-height: 24px;
                color:#999;
              }
            }
            .card-price-tax{
              margin-top: -4px;
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              color:#999;
            }
          }
          .card-button{
            background: #EF3340;
            border-radius: 8px;
            width: 100%;
            justify-self: flex-end;
          }
        }
      }
    }
  }
}
