.stores-icon-container {

    @media only screen and (max-width: 326px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    a {
        display: inline-flex;
        width: 140px;
        height: 40px;
    }
    a:first-of-type {
        margin-right: 15px;

    @media only screen and (max-width: 326px) {
        margin-right: 0;
        margin-bottom: 15px;
    }

    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}