@import "./../../app/_variables/colors";

.c-seguros-movil {
  .section-description__title,
  .section-description__subtitle,
  .section-description__description {
    font-family: $font-family-secondary;
  }

  .section-description__title {
    font-size: 18px !important;
    line-height: 24px;

    @include media(medium up) {
      font-size: 24px !important;
      line-height: 30px;
    }
  }

  .section-description__subtitle,
  .section-description__description {
    font-size: 16px !important;
    font-weight: normal;
    line-height: 24px;
    color: $color-black;
  }

  .section-description__subtitle {
    margin-top: 16px;
  }

  .section-description__description {
    margin-top: 20px;
  }

  width: 100%;
  padding: 36px 0 64px 0;
  text-align: center;

  @include media(medium up) {
    padding: 64px 0;
  }

  & .swiper-wrapper {
    align-items: flex-end;
  }
}

.c-seguros-icons {
  position: relative;
  margin: 64px auto 0;

  &__icons {
    & .cards-icon-with-description--home {
      padding: 0;
    }

    & .cards-icon-with-description--home > .cards-icon-with-description__container {
      flex-wrap: wrap;
      justify-content: center;
    }

    & .c-card-home {
      justify-content: flex-start;

      @include media(medium up) {
        justify-content: center;
      }
    }

    & .c-card-home__wrapper {
      align-items: center;
    }

    & .c-card-home__title {
      text-align: left;
      font-family: $font-family-secondary;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;

      @include media(medium up) {
        text-align: center;
      }
    }

    &  .c-card-home__icon svg {
      height: 48px;

      @include media(medium up) {
        height: 72px;
      }
    }

  }
}
