.form-title{
  font-family: $font-family-primary;;
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 35px;
  margin-bottom: 16px;
}
.form-description{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 16px;
}
.concurso-cosmo{
  &__banner-two-columns {
    padding-top: 60px;
    padding-bottom: 80px;
    h2.c-banner-two-columns__title{
      display: none;
    }
    .c-banner-two-columns__text{
      padding-left: 40px;
    }
  }

  padding: 12px 48px;

  &-hero{
    text-align: center;
  }
  &-form-section{
    margin-top: 40px;
    margin-bottom: 80px;
    padding-left: 32px;
    padding-right: 32px;
  }
  &-form{
    max-width: 820px;
    margin: 0 auto;

    &__submit-message {
      margin-top: 1em;
    }
    .button.button--primary{
      background-color: #EF3340;
      border-radius: 8px;
      width: 48%;
    }
    @media (max-width: 768px) {
      .button.button--primary{
        background-color: #EF3340;
        border-radius: 8px;
        width: 100%;
      }
    }
  }
  &-form__form-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  &-form__form-col{
    flex-basis: 48%;
    max-width: 48%;
  }
  &-form__form-col-2{
    flex-basis: 100%;
    max-width: 100%;
  }
  &-video-wm {
    display: block;
    width: 100%;

    @include media(large up)  {
      width: 960px;
      margin: 0 auto;
    }
  }
}
.legal-text {
  margin-top: 1rem;

  a {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--color-primary);
  }
}
.form-group-input{
  margin-bottom: 24px;
  &__label,.form-label{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    margin-bottom: 1rem;
    padding: 0
  }
  input,textarea{
    border: 1px solid #d1d1d1;
    border-radius: 8px;
    box-shadow: none;
    box-sizing: border-box;
    display: block;
    font-family: "Lato", sans-serif;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
    padding: 13px 16px;
    transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
    width: 100%;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    &:focus{
      border-color: $color-primary;
      outline: none;
    }
  }
  .validation-error{
    white-space: nowrap;
    margin-bottom: 1rem;
    color: red;
    font-size: 14px;
    font-weight: 400;
  }

}

.c-concurso-cosmo-video {
  display: flex;
  flex-direction: column;
  padding: 22px 12px;
  background-color: #f6f6f6;
}
.section-description__subtitle{
  font-size: 18px !important;
  font-family: "R Gerstner", sans-serif;
  line-height: 24px;
}
@media print, screen and (min-width: 48em) {
  .c-concurso-cosmo-video {
    padding: 60px 0;
  }
}
.c-concurso-cosmo-video > .section-description {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 0 auto;
  width: auto;
  padding-bottom: 30px;
}
@media print, screen and (min-width: 48em) {
  .c-concurso-cosmo-video > .section-description {
    align-items: center;
  }
}
.c-concurso-cosmo-video > .video-wm {
  margin: 0 auto;
  width: 100%;
}
@media print, screen and (min-width: 48em) {
  .c-concurso-cosmo-video > .video-wm {
    width: 660px;
  }
}
  @media (max-width: 768px) {
    .c-concurso-cosmo-video .section-description{
      text-align: center;
    }
    .c-concurso-cosmo-video .section-description__description{
      width: 100%;
    }
    .c-concurso-cosmo-video .section-description__subtitle{
      text-align: center;
    }
  }

