.c-historias {
  position: relative;
  padding: 20px 20px 60px;
  background: $color-white;

  @include media(medium up) {
    padding: 72px 20px 140px;
  }

  &__content {
    @include flex();
    @include flex-direction(column);
    margin-top: 60px;

    @include media(medium up) {
      @include flex-direction(row);
    }
  }

  &__progress {
    background: #F2F2F2;
    border-radius: 93px;
    width: 100%;
    height: 4px;
    margin-right: 20px;
    flex-shrink: 0;
    order: 2;

    @include media(medium up) {
      margin-right: 20px;
      width: 4px;
      height: auto;
      order: 2;
    }

    @include media(large up) {
      margin-right: 48px;
    }

    &__line {
      display: block;
      background: $color-red-crimson;
      border-radius: 93px;
      height: 4px;
      width: 0;

      @include media(large up) {
        height: 0;
        width: 4px;
      }
    }
  }

  &__list {
    margin-right: 0;
    order: 1;

    @include media(medium up) {
      margin-right: 20px;
    }

    @include media(large up) {
      margin-right: 56px;
    }
  }

  &__item {
    background: transparent;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-bottom: 16px;
    padding: 20px 20px 0;
    cursor: pointer;
    @include transition();

    @include media(medium up) {
      min-width: 380px;
    }

    @include media(large up) {
      min-width: 486px;
    }

    &.active {
      background: $color-white;
      box-shadow: 0 21px 32px rgba(185, 185, 185, 0.45);
      border-radius: 4px;
      padding: 20px;
      @include transition();

      & .c-historias__item__avatar {
        width: 58px;
        height: 58px;
        border: 1px solid $color-red-crimson;
        padding: 4px;
        border-radius: 50%;
        @include transition(0.6, ease-in-out);
      }

      & .c-historias__item__more-info {
        opacity: 1;
        visibility: visible;
        height: auto;
        @include transition(0.8, ease-in-out);
      }
    }

    &__info {
      @include flex();
      align-items: center;
    }

    &__name {
      font-family: $font-family-secondary;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      color: $color-black;
      margin-bottom: 4px;
    }

    &__name-info {
      font-family: $font-family-secondary;
      font-size: 16px;
      line-height: 24px;
      color: rgba(0,0,0, 0.4);
    }

    &__avatar {
      width: 44px;
      height: 44px;
      margin-right: 20px;
      border-radius: 50%;
      @include transition(0.6, ease-in-out);

      & img,
      & svg {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    &__more-info {
      margin-top: 24px;
      opacity: 0;
      visibility: hidden;
      height: 0;
      @include transition(0.8, ease-in-out);

      &__title {
        font-family: $font-family-primary;
        font-size: 18px;
        line-height: 24px;
        color: $color-primary;
        margin-bottom: 12px;
      }

      &__copy {
        font-family: $font-family-secondary;
        font-size: 16px;
        line-height: 24px;
        color: $color-primary;
        width: 100%;
        max-width: 418px;
      }
    }
  }

  &__content-image {
    position: relative;
    width: 100%;
    max-width: 610px;
    height: auto;
    display: none;
    order: 3;

    @include media(medium up) {
      display: block;
    }
  }

  &__image {
    @include absolute-center();
    @include object-fit();
    opacity: 0;
    visibility: hidden;
    @include transition();

    &.active {
      opacity: 1;
      visibility: visible;
      @include transition();
    }
  }
}
