@font-face {
  font-family: 'R Gerstner';
  src: url('#{$environment-local-url}/fonts/RGerstner-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'R Gerstner';
  src: url('#{$environment-local-url}/fonts/RGerstner-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}
