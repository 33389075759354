.c-work-with-us {
    padding-top: 48px;
    padding-bottom: 110px;

    &__section {
        padding-top: 48px;
        padding-bottom: 110px;
        padding-left: 24px;
        padding-right: 24px;
    }

    em {
        color: $color-primary;
        font-style: normal;
    }

    a {
        color: $color-primary;
        text-decoration: underline;

        &:hover {
            color: $color-secondary;
        }
    }
    h3 {
        line-height: $line-height-for-font-size-22;
        font-size: $font-size-18;
        font-family: $font-family-secondary;
        font-weight: bolder;
        margin-top: 60px;
        margin-bottom: 30px;
    }

    &__list {
        width: 100%;
        display: flex;
        flex-direction: column;
        line-height: $line-height-for-font-size-20;
        justify-content: center;
        font-size: $font-size-16;

        li {
            margin-bottom: 10px;
        }

        .li--em {
            font-weight: bold;
        }

        &--center {
            align-items: center;
        }

        &--em-first::first-letter {
            color: $color-primary;
            font-size: $font-size-16;
            font-weight: bold;
        }

        &--nested {
            margin-left: 30px;
        }
    }

    &--altBackground {
        background-color: $alt-background;
    }

    @include media(medium down) {
        padding-top: 29px;
    }

    .section-description {
        margin-bottom: 14px;
    }

    .section-description__title {
        font-size: 21px !important;
    }
    .section-description--centered {
        text-align: center;
    }

    &__content {
        padding: 48px 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    &__card {
        position: relative;
        @include flex();
        @include flex-direction(column);
        align-items: flex-start;
        max-width: var(--card-offer-max-width);
        width: 100%;
        height: 100%;
        box-shadow: 4px 4px 20px rgba(38, 37, 37, 0.1);
        background-color: $color-white;
    }

    &__cardIcon {
        margin-bottom: 16px;
        height: 64px;

        .icon__glyph {
        stroke: $color-primary;
        }
    }

    &__card-picture {
        width: 100%;

        > img {
        object-fit: contain;
        width: inherit;
        }
    }

    &__cardTitle {
        font-size: 18px;
        line-height: 24px;
        font-weight: bold;
        font-family: $font-family-primary;
    }

    &__cardText {
        font-size: 16px;
        line-height: 24px;
        font-family: $font-family-secondary;
    }

    &__cardSeparator {
        border: none;
        margin: 16px 0;
        width: 32px;
        height: 4px;
        background-color: $color-primary;
    }
}
