.c-banner-seguros {
  box-sizing: border-box;
  height: 100%;
  min-height: 200px;
  padding: 0;
  position: relative;
  width: 100%;
  margin: 38px auto;
  overflow: hidden;

  &--movil {
    background-color: #FADC8F;
  }

  &--movil-plus {
    background-color: #ADF2E7;
  }

  &--rotura-pantalla {
    background-color: #E9CAF8;
  }

  &__overlay {
    position: absolute;
    z-index: 1;
    background: #FFFFFF;
    opacity: 0.4;
    border-radius: 50%;
    left: -22%;
    top: -220px;
    width: 150%;
    height: 360px;

    @include media(medium up) {
      width: 100%;
      height: 330%;
      left: 0;
      top: -100%;
    }

    @include media(large up) {
      width: 110%;
      height: 330%;
      left: -5%;
      top: -100%;
    }
  }

  &__wrapper {
    margin: 0 16px;

    @include media(xxlarge up) {
      margin: 0;
    }
  }

  &__container {
    z-index: 2;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    padding: 20px;

    @include media(medium up) {
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
    }
  }

  &__container-text {
    position: relative;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    order: 1;
    margin-top: 20px;
    padding-bottom: 84px;

    @include media(medium up) {
      padding-bottom: 0;
      margin-top: 0;
      align-items: center;
      order: unset;
      max-width: 60%;
    }

    &--with-offer {
      @include media(medium up) {
        max-width: 54%;
      }

      @include media(large up) {
        max-width: 60%;
      }
    }
  }

  &__container-image {
    order: 3;
    flex: 1;
    display: inline-block;
    text-align: center;

    @include media(medium up) {
      flex: unset;
      order: unset;
    }
  }

  &__container-offer {
    order: 2;
    flex: 1;
    display: inline-block;

    @include media(medium up) {
      flex: unset;
      order: unset;
    }
  }

  &__pretitle {
    font-family: $font-family-primary;
    font-size: 14px;
    line-height: 16px;
    color: $color-black;
    margin-bottom: 8px;
    z-index: 2;
    position: relative;
    text-align: center;

    @include media(large up) {
      margin-bottom: 10px;
      font-size: 24px;
      line-height: 30px;
    }
  }

  &__title {
    font-family: $font-family-primary;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: $color-black;
    margin-bottom: 4px;
    z-index: 2;
    position: relative;
    text-align: center;

    @include media(large up) {
      font-size: 36px;
      line-height: 42px;
      margin-bottom: 24px;
    }
  }

  &__subtitle {
    font-family: $font-family-primary;
    font-size: 18px;
    line-height: 24px;
    color: $color-black;
    z-index: 2;
    position: relative;
    text-align: center;

    & span {
      text-decoration: underline;
      font-weight: 700;
    }
  }

  &__offer {
    width: 140px;
    margin: 0 auto;

    &__title {
      font-family: $font-family-primary;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      color: $color-font-primary;
    }

    &__price {
      font-family: $font-family-primary;
      font-weight: 600;
      font-size: 48px;
      line-height: 58px;
      color: $color-font-primary;
    }

    &__euros {
      font-family: $font-family-primary;
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      color: $color-font-primary;
    }

    &__months {
      font-family: $font-family-primary;
      font-size: 14px;
      line-height: 14px;
      color: #7F7F7F;
    }

    &__content-price {
      display: flex;
      align-items: center;
    }

    &__content-extra {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__ribbon {
      font-size: 14px;
      line-height: 24px;
      color: #008dff;
      font-family: $font-family-secondary;
      font-weight: 700;
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }
  }

  &__image {
    height: 127px;

    @include media(medium up) {
      height: 158px;
    }
  }

  &__cta {
    display: block;
  }
}
