@import "./../_variables/_colors";

.desktop-wrapper > .kit-digital-form {
    padding: 25px;
}

.kit-digital {

    .select2-container--default .select2-selection--multiple .select2-selection__choice {
        margin-top: 5px !important;
        padding: 0px !important;
    }

  .kit-digital-form {
    background-color: $color-white;

    .form-group-input__label {
      margin-bottom: 8px;
    }
    .form-group-input.soy-cliente{
      display: flex;
      align-items: center;
      .form-group-input-radioButton-container{
        display: flex;
        // padding: 0 0 9px 20px;
      }
    }
    .form-label {
      font-family: $font-family-secondary;
      font-size: $font-size-16;
      line-height: $line-height-for-font-size-18;
      font-weight: 700;
      white-space: nowrap;
    }

    .form-group-input.soy-cliente{
      display: flex;
      align-items: center;
      .form-group-input-radioButton-container{
        display: flex;
        padding: 0 0 9px 20px;
      }
    }

    .form-group-input {
      margin-bottom: 24px;
      input{
        appearance: auto!important;
      }
    }

    .form-group-input-radioButton-container {
      display: flex;
      padding: 15px 0;
      .form-group-input-radioButton-inner {
        &:last-child {
          margin-left: calc(var(--s) * 4);
        }
      }
    }
    .form-title {
      flex-basis: 100%;
      font-family: $font-family-primary;
      font-size: $font-size-18;
      line-height: $line-height-for-font-size-18;
      font-weight: 700;
      margin-bottom: 8px;
    }

    .form-description {
      font-family: $font-family-secondary;
      font-size: $font-size-16;
      line-height: $line-height-for-font-size-18;
      margin-bottom: 16px;
    }

    .kit-digital-form__form-row {
      @include media(medium up) {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }

      .kit-digital-legal-privacy {
        margin-bottom: 24px;
        .form-checkbox {

          font-family: $font-family-secondary;
          font-size: $font-size-14;
          line-height: $line-height-for-font-size-16;
          font-weight: 400;
        }

        a {
          text-decoration: underline;
          font-weight: 400;
        }
      }
      &.actions-wrapper {
        width: 100%;

        > div {
          flex-basis: 100%;
          max-width: 100%;

          .button {
            height: 48px;
            width: 100%;
          }
        }
      }
    }

    .kit-digital-form__form-col {
      @include media(medium up) {
        flex-basis: 48%;
        max-width: 48%;
      }
    }

    @include media(medium up) {
      &:hover {
        .legal-text {
          .ellipsis {
            text-overflow: unset;
            white-space: normal;
          }
        }
      }
    }

    .legal-text {
      font-family: $font-family-secondary;
      font-size: $font-size-12;
      line-height: $line-height-for-font-size-12;
      font-weight: 400;
      color: $color-grey-600;

      margin-top: 16px;

      @include media(medium up) {
        margin-top: 9px;
      }

      a {
        text-decoration: underline;
      }

      .kit-digital-form__form-col {
        @include media(medium up) {
          flex-basis: 100%;
          max-width: 100%;
        }
      }

      .ellipsis {
        @include media(large up) {
          display: block;
          width: 420px;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .contact-form__terms-not-accepted {
      margin-bottom: 0;
      margin-top: 9px;
      padding: 0 10px;
      margin-left: 15px;
    }

    .contact-form__content {
      @include media(medium up) {
        padding-top: 0;
      }

      .contact-form__content-title {
        flex-basis: 100%;
        font-family: $font-family-primary;
        font-size: $font-size-18;
        line-height: $line-height-for-font-size-18;
        font-weight: 700;
        color: $color-primary;

        margin-bottom: 8px;
      }

      .contact-form__content-description {
        font-family: $font-family-secondary;
        font-size: $font-size-16;
        line-height: $line-height-for-font-size-16;
        color: $color-primary;

        margin-bottom: 16px;

        @include media(xlarge up) {
          margin-bottom: 24px;
        }
      }
    }

    .contact-form__item {
      &.button {
        flex-basis: 100%;
        max-width: 100%;
        padding: 0;
      }

      &.help-selector {
        flex-grow: 1;

        max-width: 100%;
        padding-right: 0;
      }
    }

    .contact-form__submit {
      .button {
        width: 100%;
      }
    }

    .kit-digital-form__submit-message {
      margin-top: 15px;
    }

    .kit-digital-form__query-form-col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .kit-digital-form__submit {
        width: 100%;

        .submit-loader {
          width: fit-content;
          margin: 0 auto;
        }
      }
      .kit-digital-form__submit-message {
        margin-top: 15px;
      }
    }
  }
}

/* Multi-select styles */
.combo {
  display: block;
  margin-bottom: 1.5em;
  //max-width: 400px;
  position: relative;
}

.combo::after {
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
  border-right: 2px solid rgba(0, 0, 0, 0.5);
  content: "";
  display: block;
  height: 12px;
  pointer-events: none;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translate(0, -65%) rotate(45deg);
  width: 12px;
}

.input-wrapper {
  border-radius: 4px;
}

.combo-input {
  background-color: $color-white;
  border: 1px solid #d1d1d1;
  border-radius: 0;
  display: block;
  font-size: $font-size-16;
  color: #444;
  min-height: calc(1.4em + 26px);
  padding: 16px;
  text-align: left;
  width: 100%;
  border-radius: 5px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

select.combo-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.open .combo-input {
  border-radius: 0;
}

.combo-input:focus {
  border-color: $color-primary;
  outline: 5px solid transparent;
}

.combo-label {
  display: block;
  font-size: $font-size-16;
  font-weight: 700;
  margin-bottom: 8px;
}

.combo-menu {
  background-color: $color-white;
  border: 1px solid #d1d1d1;
  border-radius: 0;
  display: none;
  max-height: 300px;
  overflow-y: scroll;
  left: 0;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 10;
}

.open .combo-menu {
  display: block;
}

.combo-option {
  padding: 16px;
  line-height: $line-height-for-font-size-16;
}

.combo-option.option-current,
.combo-option:hover {
  background-color: $color-grey-300;
  cursor: pointer;
}

.combo-option.option-selected {
  padding-right: 30px;
  position: relative;
}

.combo-option.option-selected::after {
  border-bottom: 1.5px solid #00d41f;
  border-right: 1.5px solid #00d41f;
  content: "";
  height: 16px;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0, -50%) rotate(45deg);
  width: 8px;
}

/* multiselect list of selected options */
.selected-options {
  list-style-type: none;
  margin: 0;
  max-width: 400px;
  padding: 0;
}

.selected-options li {
  display: inline-block;
  margin-bottom: 5px;
}

.remove-option {
  background-color: $color-primary;
  border: 1px solid $color-primary;
  border-radius: 3px;
  color: #fff;
  font-size: 0.75em;
  font-weight: bold;
  margin-bottom: 6px;
  margin-right: 6px;
  padding: 0.25em 1.75em 0.25em 0.25em;
  position: relative;
}

.remove-option:focus {
  border-color: #ff580033;
  box-shadow: 0;
  outline: 3px solid transparent;
}

.remove-option::before,
.remove-option::after {
  border-right: 2px solid #fff;
  content: "";
  height: 1em;
  right: 0.75em;
  position: absolute;
  top: 50%;
  width: 0;
}

.remove-option::before {
  transform: translate(0, -50%) rotate(45deg);
}

.remove-option::after {
  transform: translate(0, -50%) rotate(-45deg);
}

.multiselect-inline {
  align-items: center;
  background-color: $color-white;
  border: 1px solid #d1d1d1;
  border-radius: 0;
  display: flex;
  flex-wrap: wrap;
  min-height: calc(1.4em + 26px);
  padding: 12px 16px 14px;
}

.multiselect-inline .selected-options {
  flex: 0 1 auto;
}

.multiselect-inline .selected-options li {
  margin-bottom: 0;
}

.multiselect-inline .combo-input {
  border: none;
  flex: 1 1 35%;
  min-height: calc(1.4em - 2px);
  padding: 0;
}

.multiselect-inline .combo-input:focus {
  box-shadow: none;
  outline: none;
}

.multiselect-inline:focus-within {
  //box-shadow: 0 0 3px 2px $color-primary;
  //outline: 5px solid transparent;
  border-color: $color-primary;
}
