.c-banner-landing {
  position: relative;
  margin-bottom: 32px;
  margin-top: 32px;
  background: $color-primary;
  padding: 24px 12px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  min-height: 80px;
  width: 100%;

  @include media(large up) {
    flex-direction: row;
    padding: 8px 28px;
  }

  &__content-title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
  }

  &__title {
    color: $color-white;
    font-weight: bold;
    font-family: $font-family-primary;
    font-size: 18px;
    line-height: 24px;
    margin-right: 16px;
    margin-bottom: 16px;

    @include media(large up) {
      margin-bottom: 0;
    }
  }

  &__description {
    color: $color-white;
    font-size: 16px;
    line-height: 24px;
    font-family: $font-family-secondary;
    margin-bottom: 16px;

    @include media(large up) {
      margin-bottom: 0;
    }
  }

  &__cta {

    & a {
      color: $color-black;
      height: 48px;
      min-width: 200px;
      padding: 0 20px;

      & .button__content {
        line-height: 48px;
      }
    }
  }
}
