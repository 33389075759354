.section-soporte-experto{
  margin-bottom:calc(8px*5);
  @include media (medium up){
    margin-bottom:calc(8px*8);
  }
}
.soporte-experto-wrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--color-dark);
  @include media (large up){
    flex-direction: row;
  }
  .soporte-experto-left-block{
    position: relative;
    @include media (large up){
      max-width: 50%;
      min-width: 50%;
    }
    picture{
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .left-block-play-button{
      position: absolute;
      top: 50%;
      left: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translate(-50%, -50%);
    }
  }
  .soporte-experto-right-block{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include media (large up){
      max-width: 50%;
      padding-top:104px;
      padding-bottom:104px;
      padding-left: 20px;
    }
    @media screen and (min-width: 1440px){
      padding-left:135px;
    }
    .right-block-text-container{
      color: var(--color-white);
      margin-top:60px;
      margin-bottom:60px;
      @include media (large up){
        margin-top:0;
        margin-bottom:0px;
      }
      .right-block-title{
          font-family: $font-family-primary;
          font-size: 29px;
          line-height: 35px;
          font-weight: bold;
          margin-bottom: 8px;
      }
      .right-block-description{
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
      }
      .right-block-list{
        padding-left: 15px;
        list-style-type: disc;
        .right-block-list-item{
          font-size: 16px;
          line-height: 24px;
          &:not(:last-child){
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}
.soporte-cybersecurity-video{
  @include media (medium up){
    min-width: 700px;
  }
}
