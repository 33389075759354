
@import './../_mixins/base-html-elements';
@import '../_definitions/animations';
@import '../_variables/colors';
@import '../_variables/transition';

$tv-channels-filter-active-bgcolor: $color-primary;
$tv-channels-filter-active-bordercolor: $color-primary;
$tv-channels-filter-active-color: $color-white;
$tv-channels-filter-font-family: $font-family-secondary;
$tv-channels-filter-title-font-family: $font-family-primary;

.c-tv-channels-menu {
	.section-description__title {
		text-align: center;
		font-family: $font-family-primary;
		font-style: normal;
		font-weight: bold;
		font-size: 21px;
		line-height: 27px;
		text-align: center;
		letter-spacing: -0.5px;
		margin-bottom: 32px !important;
	}
}

.tv-channels-filter {
	width: 100%;
	display: flex;
	align-items: flex-end;

	> * {
		width: 50%;
	}

	> *:nth-child(1){
		order: 1;
		width: 100%;
	}

	> *:nth-child(2){
		order: 2;
		width: 100%;
		padding: 0 15px;
	}

	> *:nth-child(3){
		order: 3;
	}

	> *:nth-child(4){
		order: 4;
	}
}

.form-input--tv-channels-filter {
	border-radius: 0;
	font-weight: normal;
}

.form-input--tv-channels-filter-buscador {
	background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjI1IDE1LjI1TDExIDExIiBzdHJva2U9IiNFMTBBMEEiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz4KPHBhdGggZD0iTTYuNzUgMTIuNzVDMTAuMDYzNyAxMi43NSAxMi43NSAxMC4wNjM3IDEyLjc1IDYuNzVDMTIuNzUgMy40MzYyOSAxMC4wNjM3IDAuNzUgNi43NSAwLjc1QzMuNDM2MjkgMC43NSAwLjc1IDMuNDM2MjkgMC43NSA2Ljc1QzAuNzUgMTAuMDYzNyAzLjQzNjI5IDEyLjc1IDYuNzUgMTIuNzVaIiBzdHJva2U9IiNFMTBBMEEiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz4KPC9zdmc+");
	background-repeat: no-repeat;
	background-position: calc(100% - 10px) center;
}

.tv-channels-filter__by {
	margin-bottom: 20px;

	.button {
		width: 100%;
		border-radius: 50px;
		display: none;
	}
}

.tv-channels-filter__label {
	font-weight: 700;
	margin-bottom: 5px;
	display: block;
}

.tv-channels-filter__label--hiddenxs {
	display: none;
}

.tv-channels-filter__select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'><path d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z'></path><path d='M0 0h24v24H0V0z' fill='none'></path></svg>");
	background-repeat: no-repeat;
	background-position: calc(100% - 15px) center;

	option {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		line-height: 48px;
		padding: 0 16px;
	}
}

@include media(large up) {
	.tv-channels-filter {
		> * {
			width: auto;

			&:first-child {
				width: auto;
			}
		}

		> *:nth-child(1){
			order: 1;
			width: auto;
			flex: 0 0 auto;
		}

		> *:nth-child(2){
			order: 5;
			width: 100%;
			padding: 0 15px;
		}

		> *:nth-child(3){
			order: 2;
			flex: 0 0 auto;
		}

		> *:nth-child(4){
			order: 3;
			flex: 0 0 auto;
		}

		> *:nth-child(5){
			order: 4;
			flex: 0 0 auto;
			margin-left: auto;
		}
	}

	.tv-channels-filter__by {
		.button {
			width: auto;
			display: block;
		}
	}

	.tv-channels-filter__label--hiddenxs {
		display: block;
	}
}

.tv-channels-filter-menu {
	list-style-type: none;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin: auto;
	font-family: $tv-channels-filter-font-family;
	margin-bottom: 30px;
	position: relative;

	 @include media(medium up) {}

	&__item {
		flex: 0 0 50%;
		text-align: center;
		font-size: .875rem;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		margin: 0 5px;
		max-width: 350px;
			@include media(medium up) {
				margin: 0 15px;
			}

		&__inner {
			padding: 20px 25px 11px 25px;
			border: 1px solid #BFBFBF;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin-bottom: 8px;
			min-height: 180px;
			transition: all ease-in .2s;
			@include media(medium up) {
				min-height: auto;
			}

			&--hovered {
				background-color: $tv-channels-filter-active-bgcolor;
				border: 1px solid $tv-channels-filter-active-bordercolor;
				color: $tv-channels-filter-active-color;
			}
			&--unhovered {
				background-color: transparent !important;
				border: 1px solid #BFBFBF !important;
				color: #000 !important;
			}

		}

		&__title {
			font-family: $tv-channels-filter-title-font-family;
			font-weight: bold;
			font-size: 20px;
			letter-spacing: -0.5px;
			line-height: 27px;
			margin-bottom: 5px;
		}

		&__description {
			line-height: 20px;
			span {
				text-decoration: underline;
			}
		}

		&--active  {

			.tv-channels-filter-menu__item__inner {
				background-color: $tv-channels-filter-active-bgcolor;
				border: 1px solid $tv-channels-filter-active-bordercolor;
				color: $tv-channels-filter-active-color;
			}
		}
	}

	&__view {
		color: $color-secondary;
	}

}