.c-retrofit {
  position: relative;

  &__hero {
    @include flex();
    @include flex-direction(column);
    box-sizing: border-box;
    min-height: 480px;
    padding: 16px;
    position: relative;
    width: 100%;
    background: $color-red-crimson;

    @include media(medium up) {
      padding: 16px 48px;
    }

    &--big {
      min-height: 556px;
    }

    &__overlay {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
      //background: rgba(255, 88, 0, 0.3);
      background: linear-gradient(89.82deg, rgba(0, 0, 0, 0.77) 3.83%, rgba(0, 0, 0, 0) 94.15%);
      display: none;
    }

    &__bg-image {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;

      & img {
        height: 100%;
        object-fit: cover;
        object-position: top;
        width: 100%;
      }
    }

    &__container {
      @include flex();
      justify-content: flex-end;
      align-items: flex-start;
      height: 100%;
      padding: 0 0 20px;
      position: relative;
      z-index: 1;
      flex: 1;
      flex-direction: column;

      @include media(medium up) {
        flex-direction: row;
        align-items: center;
        padding: 0;
        justify-content: space-between;
      }
    }

    &__column-text {
      width: 100%;
      max-width: 100%;
      margin-right: 20px;
      @include flex();
      @include flex-direction(column);
      align-items: flex-start;

      @include media(medium up) {
        max-width: 350px;
      }

      @include media(xlarge up) {
        max-width: 520px;
      }
    }

    &__title {
      font-family: $font-family-primary;
      font-weight: bold;
      font-size: 36px;
      line-height: 42px;
      color: $color-warning;
      margin-bottom: 38px;
      @include flex();
      align-items: baseline;
      filter: drop-shadow(4px 2px 1px rgba(0, 0, 0, 0.4));

      & span {
        margin-right: 16px;
      }

      & span:empty {
        display: none;
      }
    }

    &__subtitle {
      font-family: $font-family-primary;
      font-weight: $font-weight-primary-regular;
      font-size: 24px;
      line-height: 30px;
      color: $color-white;
      margin-bottom: 32px;
    }

    &__list {
      margin-bottom: 0;

      &__item {
        font-family: $font-family-primary;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 16px;
        color: $color-white;
        @include flex();
        align-items: center;
        filter: drop-shadow(4px 2px 1px rgba(0, 0, 0, 0.4));

        &::before {
          content: '';
          width: 8px;
          height: 8px;
          background: $color-white;
          border-radius: 50%;
          margin-right: 8px;
          flex-shrink: 0;
        }
      }
    }

    &__link {
      font-family: $font-family-primary;
      font-weight: 300;
      font-size: 14px;
      line-height: 30px;
      color: $color-white;
      margin-top: 16px;
    }

    &__column-image {
      width: 100%;
      max-width: 750px;
      height: 100%;
      align-items: flex-end;
      position: relative;

      & svg {
        max-width: 100%;
      }

      &__image {
        max-width: 230px;

        @include media(medium up) {
          max-width: 550px;
          width: 100%;
        }
      }

      @include media(medium up) {
        @include flex();
      }
    }

    &__column-box {
      background: rgba(255, 255, 255, 0.8);
      box-shadow: 4px 4px 20px rgba(38, 37, 37, 0.1);
      width: 100%;
      max-width: 610px;
      height: 100%;
      min-height: 180px;
      padding: 24px 32px;
      @include flex();
      @include flex-direction(column);
      justify-content: space-between;

      &__name {
        font-family: $font-family-primary;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: $color-black;
        margin-bottom: 8px;
        text-transform: capitalize;
      }

      &__title {
        font-family: $font-family-primary;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        color: $color-black;

        & .alt-color {
          color: $color-primary;
        }
      }

      &__message {
        font-family: $font-family-primary;
        font-size: 24px;
        line-height: 30px;
        color: $color-black;
      }

      &__line-logged-title {
        font-family: $font-family-secondary;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 16px;
      }

      &__line-logged {
        @include flex();
        align-items: center;
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 16px;

        & svg {
          height: 16px;
          margin-right: 8px;
          width: auto;
          flex-shrink: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      &__content {
        margin-top: 8px;
        margin-bottom: 8px;
      }

      &__content-houses {
        display: flex;
        flex-wrap: wrap;
        margin-top: 16px;
        margin-bottom: 24px;

        & .c-retrofit__hero__column-box__content-user {
          margin: 0 0 14px;
          width: 100%;

          @include media(xlarge up) {
            margin: 0 8px 14px;
            width: calc(50% - 16px);
          }
        }

        &[data-items='1'] {
          & .c-retrofit__hero__column-box__content-user {
            width: 100%;
          }
        }
      }

      &__content-user {
        margin-top: 16px;
        margin-bottom: 24px;
        background: $color-white;
        box-shadow: 4px 4px 20px rgba(38, 37, 37, 0.1);
        padding: 16px;
      }

      &__content-consulta {
        margin-top: 16px;
        margin-bottom: 48px;
      }

      &__content-unavailable {
        @include flex();
        @include flex-direction(column);
        align-items: center;
        justify-content: center;
        margin-top: 32px;

        &__icon {
          margin-bottom: 4px;
        }

        &__text {
          font-family: $font-family-primary;
          font-weight: 300;
          font-size: 14px;
          line-height: 24px;
          margin-bottom: 16px;
          text-align: center;
        }

        &__stores {
          & > a {
            @include media(medium up) {
              margin-left: 11px;

              &:first-child {
                margin-left: 0;
              }
            }

            &:first-child {
              margin-top: 0;
            }

            img {
              width: 120px;
              max-width: 120px;

              @include media(medium up) {
                width: max-content;
                max-width: max-content;
              }
            }
          }
        }
      }

      &__content-customer-offer {
        @include flex();
        @include flex-direction(column);
        align-items: center;
        justify-content: center;
        margin-top: 32px;
      }

      &__content-confirmation {
        @include flex();
        @include flex-direction(column);
        align-items: center;
        justify-content: center;
        margin-top: 32px;

        &__icon {
          margin-bottom: 4px;
        }

        &__text {
          font-family: $font-family-primary;
          font-weight: 300;
          font-size: 14px;
          line-height: 24px;
          margin-bottom: 16px;
          text-align: center;
        }

        &__stores {
          & > a {
            @include media(medium up) {
              margin-left: 11px;

              &:first-child {
                margin-left: 0;
              }
            }

            &:first-child {
              margin-top: 0;
            }

            img {
              width: max-content;
              max-width: max-content;
            }
          }
        }
      }

      &__content-notlogged {
        @include flex();
        align-items: center;
        margin-bottom: 24px;
        margin-top: 32px;
        flex-direction: column;

        @include media(large up) {
          flex-direction: row;
        }

        & .button {
          margin-right: 24px;
          margin-bottom: 24px;

          @include media(large up) {
            margin-bottom: 0;
          }
        }
      }

      &__bottom {
        @include flex();
        @include flex-direction(column);
        align-items: center;

        &__separator {
          width: 100%;
          height: 1px;
          max-width: 300px;
          margin: 16px auto;
          background: $color-silver;
        }
      }

      &__cta {
        margin-bottom: 24px;
      }

      &__link_contact {
        font-family: $font-family-secondary;
        font-weight: 300;
        font-size: 16px;
        line-height: 14px;
        color: $color-black;

        &__link {
          font-weight: bold;
          color: $color-primary;
        }
      }

      &__link_app {
        font-family: $font-family-secondary;
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
        color: $color-primary;
        @include flex();
        align-items: center;

        & svg {
          flex-shrink: 0;
          height: 15px;
          margin-right: 8px;
          width: auto;
        }
      }

      &__form-consulta {
        @include flex();
        align-items: center;
        flex-direction: column;

        @include media(large up) {
          align-items: flex-end;
          flex-direction: row;
        }

        &__item {
          width: 100%;
          max-width: 260px;

          & label {
            display: block;
            margin-bottom: 8px;
          }
        }

        & .button {
          margin-left: 0;
          margin-top: 16px;
          margin-bottom: 0;
          margin-right: 0;

          @include media(large up) {
            margin-left: 16px;
            margin-right: 16px;
            margin-top: 0;
          }

          @include media(xlarge up) {
            margin-left: 24px;
          }

          & .button__content {
            height: 100%;
          }
        }
      }

      &__form-consulta-login {
        margin-top: 24px;
        @include flex();
        align-items: flex-start;
        flex-wrap: wrap;

        &__item {
          width: 100%;
          max-width: 260px;
          margin-bottom: 24px;

          & label {
            display: block;
            margin-bottom: 8px;
          }
        }

        &__item:first-child {
          margin-right: 24px;
        }

        & button {
          width: 100%;
          max-width: 260px;
        }
      }
    }
  }

  &__countdown {
    position: relative;
    margin-bottom: 40px;
    background-color: $color-grey-800;
    min-width: 160px;
    padding: 12px;

    &__content {
      @include flex();
    }

    &__item {
      background: $color-grey-C4;
      margin-right: 12px;
      min-width: 40px;
      height: 34px;
      @include flex();
      justify-content: center;
      align-items: center;
      color: $color-white;
      font-size: 18px;
      line-height: 18px;
      padding: 0 4px;
      font-weight: bold;

      & > span {
        color: inherit;
        font-size: inherit;
        line-height: inherit;
        font-weight: bold;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__menu {
    position: relative;
    height: 70px;
    border-bottom: 1px solid $color-grey-C4;
    background-color: $color-white;

    &__container {
      height: 100%;
      @include flex();
      @include flex-align(left);
      align-items: center;
      overflow-x: auto;

      @include media(medium up) {
        justify-content: space-between;
      }
    }

    &__item {
      position: relative;
      padding: 0 16px;
      font-family: $font-family-secondary;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: $color-primary;
      cursor: pointer;
      height: 100%;
      @include flex();
      align-items: center;
      white-space: nowrap;

      &:after {
        background-color: $color-primary;
        bottom: 0;
        content: '';
        height: 2px;
        position: absolute;
        left: 0;
        width: 0;
        transition: width 0.4s $transition-ease-in-out;
      }

      &:hover {
        opacity: 1;

        &:after {
          transition: width $transition-duration-02s $transition-ease-in-out;
          width: 100%;
        }
      }

    }
  }

  &__comparativa {
    padding: 0 20px;
    margin-bottom: 80px;

    &__content {
      margin-top: 40px;
    }
  }

  &__mapa {
    padding: 0 20px;
    margin-bottom: 100px;

    &__content {
      margin-top: 40px;
    }
  }

  &__sticky {
    background-color: #F2F2F2;
    position: fixed;
    bottom: -100%;
    left: 0;
    z-index: 1201;
    width: 100%;
    transition: all 0.6s;

    @include media(medium up) {
      z-index: 28;
    }

    &.visible {
      bottom: 0;
      transition: all 0.6s;
    }

    &__content {
      max-width: 824px;
      margin: auto;
      height: 72px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px;

      @include media(medium up) {
        height: 64px;
      }
    }

    &__title {
      color: $color-black;
      font-size: 14px;
      line-height: 24px;
      font-family: $font-family-primary;
      font-weight: 300;
      text-align: center;

      @include media(medium up) {
        text-align: left;
        font-size: 18px;
        line-height: 24px;
        font-weight: $font-weight-primary-bold;
      }
    }

    &__ctas {
      .button {
        padding: 8px 10px;
        min-width: 130px;
        min-height: 40px;
        line-height: 40px;
        margin-left: 16px;
        background-color: $color-red-crimson;
      }

      .button__content {
        line-height: 20px;

        @include media(medium up){
          padding: 0 10px;
          line-height: 40px;
        }
      }
    }
  }

  & .section--retrofit-faq {
    background-color: $color-white;
    padding-bottom: 40px;
  }

  & .section--retrofit-faq .c-faq {
    max-width: 1250px;
  }

  & .section--retrofit-faq .c-faq .accordion-item--faq {
    box-shadow: none;
    border: 1px solid #F2F2F2;
    border-radius: 4px;
  }

  &__contacto .c-contact-client {
    background-color: $color-white;
  }

  &__contacto .c-contact-client__content {
    max-width: 1250px;
  }

  &__contacto .c-contact-client__content__faq {
    max-width: 870px;
  }


  &__contacto .section--faq-no-cliente {
    background-color: $color-white !important;
  }

  &__app .c-app {
    background-color: transparent;
    background: linear-gradient(269.9deg, #F5F5F5 0.07%, rgba(242, 242, 242, 0) 99.91%);
    padding-top: 28px;
    padding-bottom: 28px;
  }

  &__app .c-app:after {
    display: none;
  }

  &__app .c-app .list-item--highlight {
    background-color: transparent;

  }

  &__app .section-description__title,
  &__app .section-description__description,
  &__app .list-item--highlight,
  &__app .list-item--white {
    color: $color-primary;
  }

  &__app .list-item--highlight .icon__glyph {
    fill: $color-primary;
    stroke: $color-primary;
  }

  &__app .list-item--white .icon__glyph {
    fill: $color-red-crimson;
    stroke: $color-red-crimson;
  }

  &__app .c-app__content.u-wrapper {
    max-width: 830px;
  }

  & .c-footer {
    padding: 0 24px 80px;
  }

  &__button {
    height: 42px;
    max-width: 226px;
    width: 100%;
    margin-right: 24px;
    min-height: 42px;
    padding: 0 12px;

    @include media(large up) {
      height: 52px;
      min-height: 48px;
      padding: 12px 30px;
    }
  }


  &__carrusel {
    padding-top: 32px;
    padding-bottom: 0;

    @include media(medium up) {
      padding-top: 64px;
      padding-bottom: 22px;
    }

    &__info-text {
      font-family: $font-family-secondary;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      width: 100%;
      max-width: 1036px;
      margin: 0 auto;

      & span {
        color: $color-primary;
        font-weight: bold;
      }
    }

    & .c-carousel {
      padding-top: 0;
    }

    & .section-description {
      padding-left: 16px;
      padding-right: 16px;
    }

    & .section-description__title {
      font-family: $font-family-primary;
      font-weight: 600;
      font-size: 21px !important;
      line-height: 24px;

      @include media(medium up) {
        font-size: 24px !important;
        line-height: 30px;
      }
    }

    & .section-description__description {
      font-family: $font-family-secondary;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
    }

    &--que-es {
      .swiper-wrapper {
        @include media(medium up) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: 1fr;
          grid-column-gap: 30px;
          grid-row-gap: 30px;
          max-width: 1036px;
          margin: 0 auto;
        }
      }
    }

    &--como-consigo {
      padding-left: 16px;
      padding-right: 16px;

      & .section-description--centered {
        margin-bottom: 0;

        @include media(medium up) {
          margin-bottom: 32px;
        }
      }

      & .swiper-container {
        max-width: 1036px;
      }

      & .swiper-wrapper {
        @include media(medium up) {
          justify-content: center;
          max-width: 1036px;
          margin: 0 auto;
        }
      }
    }

    &__card {
      position: relative;
      @include flex();
      @include flex-direction(column);
      align-items: flex-start;
      max-width: 312px;
      width: 100%;
      height: 100%;
      box-shadow: 4px 4px 20px rgba(38, 37, 37, 0.1);
      background-color: $color-white;
      padding: 16px;

      @include media(medium up) {
        max-width: 503px;
      }

      &__content {
        @include flex();
        flex-direction: row;
      }

      &__cardIcon {
        margin-right: 24px;
        height: 56px;
        flex-shrink: 0;
        width: 56px;
        background: rgba($color-primary, 0.1);
        @include flex();
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        & svg {
          height: 32px;
        }
      }

      &__card-picture {
        width: 100%;

        > img {
          object-fit: contain;
          width: inherit;
        }
      }

      &__cardTitle {
        font-size: 18px;
        line-height: 24px;
        font-weight: bold;
        font-family: $font-family-primary;
      }

      &__cardText {
        font-size: 16px;
        line-height: 24px;
        font-family: $font-family-secondary;
        margin-bottom: 24px;
      }

      &__cardSeparator {
        border: none;
        margin: 8px 0;
        width: 32px;
        height: 4px;
        background-color: $color-primary;
      }
    }

    &__card-number {
      position: relative;
      @include flex();
      @include flex-direction(column);
      align-items: flex-start;
      max-width: 230px;
      min-width: 230px;
      width: 100%;
      height: 100%;
      background-color: $color-white;
      padding: 60px 0 16px;

      @include media(medium up) {
        max-width: 319px;
      }

      @include media(large up) {
        max-width: 503px;
      }

      &.active {
        & .c-retrofit__carrusel__card-number__cardNumber {
          color: rgba(255, 88, 0, 0.05);
        }

        & .c-retrofit__carrusel__card-number__progress__line {
          background-color: $color-secondary;
          width: 80%;
          @include transition();
        }

        & .c-retrofit__carrusel__card-number__cardTitle {
          font-weight: 600;
          color: #333;

          & span {
            font-size: 36px;
            line-height: 42px;
          }
        }

        & .c-retrofit__carrusel__card-number__progress__ball {
          height: 12px;
          width: 12px;
          border-radius: 50%;
          top: -5px;

          &::before {
            opacity: 1;
            visibility: visible;
            height: 24px;
            width: 24px;
            top: -6px;
            left: -6px;
            border-radius: 50%;
            background: rgba($color-primary, 0.25);
            @include transition();
            flex-shrink: 0;
          }
        }
      }

      &__cardInfo {
        width: 100%;
      }

      &__cardNumber {
        position: absolute;
        top: 0;
        left: -24px;
        font-family: $font-family-primary;
        font-weight: bold;
        font-size: 132px;
        line-height: 140px;
        color: rgba(#F2F2F2, 0.4);
      }

      &__content {
        position: relative;
        @include flex();
        flex-direction: row;
        z-index: 1;
        width: 100%;
      }

      &__cardTitle {
        font-size: 21px;
        line-height: 27px;
        font-weight: 300;
        font-family: $font-family-primary;
        padding-left: 0;
        padding-right: 16px;
        color: #7F7F7F;
        height: 70px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;

        & span {
          display: block;
          font-size: 21px;
          line-height: 27px;
        }
      }

      &__cardText {
        font-size: 14px;
        line-height: 16px;
        font-family: $font-family-secondary;
        padding-left: 0;
        padding-right: 16px;
        width: 100%;
        max-width: 290px;
      }

      &__progress {
        background: #F2F2F2;
        width: 100%;
        height: 2px;
        margin: 24px 0 40px;
        flex-shrink: 0;
        position: relative;

        &__ball {
          display: block;
          background: $color-primary;
          height: 8px;
          width: 8px;
          border-radius: 50%;
          position: absolute;
          top: -3px;

          &::before {
            content: '';
            position: absolute;
            height: 0;
            width: 0;
            top: 0;
            left: 0;
            border-radius: 50%;
            background: rgba($color-primary, 0);
            opacity: 0;
            visibility: hidden;
            flex-shrink: 0;
            @include transition();
          }
        }

        &__line {
          display: block;
          background: $color-primary;
          height: 2px;
          width: 0;
          @include transition();
        }
      }
    }

    & .c-carousel__btn-nav {
      display: none !important;
    }
  }

  &__stars {
    &__hero {
      position: absolute;
      bottom: -30px;
      right: -40px;
      z-index: 2;
      -moz-transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      -o-transform: scaleX(-1);
      transform: scaleX(-1);
      -ms-filter: fliph; /*IE*/
      filter: fliph; /*IE*/

      @include media(medium up) {
        bottom: 0;
        right: 0;
        width: 200px;
      }
    }

    &__hero2 {
      position: absolute;
      bottom: -30px;
      right: -40px;
      z-index: 2;
      -moz-transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      -o-transform: scaleX(-1);
      transform: scaleX(-1);
      -ms-filter: fliph; /*IE*/
      filter: fliph; /*IE*/
      display: none;

      @include media(medium up) {
        display: block;
        top: 0;
        left: 0;
      }
    }

    &__carrusel {
      position: absolute;
      bottom: -60px;
      left: -20px;
      z-index: 2;
      display: none;

      @include media(medium up) {
        left: -20px;
        display: block;
      }

      @include media(large up) {
        left: -20px;
      }

      @include media(xlarge up) {
        left: -30px;
      }

      @include media(xxlarge up) {
        left: -60px;
      }
    }

    &__banner {
      position: absolute;
      top: -60px;
      left: 0;
      z-index: 2;
      display: none;

      @include media(medium up) {
        display: block;
      }
    }
  }

  &__wifi {
    &__banner {
      position: absolute;
      top: -30px;
      right: 24px;
      z-index: 2;

      @include media(large up) {
        top: 0;
      }

      & svg {
        width: 42px;

        @include media(medium up) {
          width: 76px;
        }

        @include media(large up) {
          width: 128px;
        }
      }
    }
  }

  &__logo {
    &__banner {
      position: absolute;
      bottom: 0;
      z-index: 2;
      left: 0;
      width: 100px;

      @include media(medium up) {
        width: 200px;
        bottom: -60px;
        left: 0;
      }

      @include media(large up) {
        width: 240px;
        bottom: 0;
        left: -90px;
      }

      @include media(xlarge up) {
        width: 300px;
        bottom: 0;
        left: -140px;
      }
    }
  }

  &__ok {
    &__historias {
      position: absolute;
      bottom: -60px;
      right: -24px;
      z-index: 2;

      & svg {
        width: 80px;

        @include media(xlarge up) {
          width: 118px;
        }
      }
    }
  }


  & .main {
    margin-top: calc(var(--mundo-r-header-clean-height)) !important;

    @include media(medium up) {
      margin-top: calc(var(--mundo-r-header-clean-height) + var(--mundo-r-secondary-menu-height)) !important;
    }
  }

  &__forms {
    & input.error {
      margin-top: 0;
      margin-bottom: 0;
      border: 1px solid red;
    }

    & label.error {
      position: absolute;
      margin-top: 8px;
      margin-bottom: 0;
      color: $color-primary;
      font-size: 14px;
      font-weight: 500;
      font-family: $font-family-secondary;
    }
  }
}
