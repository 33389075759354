.c-banner-interior-wrapper {
	padding-top: 100px;
}

.c-banner-interior {
  position: relative;
  margin-bottom: 28px;

  &__container {
    position: relative;
  }

  &__top {
    position: relative;
    @include flex();
    align-items: center;
    height: 200px;
    padding-left: 32px;
    margin-bottom: 32px;

    > div > .c-banner-interior__subtitle {
      display: none;
      font-weight: 400;

      @include media(medium up) {
        display: inline-block;
      }
    }

    @include media(medium down) {
      height: 138px;
      padding-left: 16px;
      margin-bottom: 24px;
    }

    &--specialHeight {

      @include media(medium up) {
        height: 312px;
        padding-left: 32px;
      }
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    & img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    position: relative;
    z-index: 1;
    font-family: $font-family-primary;
    font-weight: $font-weight-primary-bold;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -0.5px;
    color: $color-white;

    @include media(medium up) {
      font-size: 36px;
      line-height: 42px;
    }

    &--desktop {
      display: none;
      @include media(medium up) {
        display: block;
      }
    }

    &--mobile {
      display: block;
      @include media(medium up) {
        display: none;
      }
    }
  }

  &__subtitle {
    position: relative;
    z-index: 1;
    font-family: $font-family-secondary;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;

    @include media(medium up) {
      font-family: $font-family-secondary;
      font-size: 16px;
      margin-top: 24px;
      line-height: 24px;
      color: $color-white;
    }
  }

  &__bottom {
    position: relative;

    > p.c-banner-interior__subtitle {
      @include media(medium up) {
        display: none;
      }
    }

    @include media(large down) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  
  &__description-line1 {
    position: relative;
	font-family: $font-family-primary;
	font-weight: $font-weight-primary-bold;
	font-size: 24px;
	line-height: 30px;
	color: $color-black;
	margin-bottom: 18px;
  }
  &__description-line2 {
	margin-bottom: 40px;
  }
  
  &__description {
    width: 100%;

    & p {
      font-family: $font-family-secondary;
      font-size: 18px;
      line-height: 24px;

      @include media(medium down) {
        font-size: 16px;
      }
    }
  }

  &__cta {
    display: block;
    position: relative;
    font-family: $font-family-secondary;
    font-size: 16px;
    line-height: 24px;
    margin-top: 12px;
    margin-bottom: 12px;

    section {
      margin: 0;

      .legal-text-modal__content {
        padding: 0;
        justify-content: start;

        a {
          color: $color-primary;
          font-size: 18px;
        }
      }
    }
  }

  &--color-dark {
    .c-banner-interior__title,
    .c-banner-interior__subtitle,
    .c-banner-interior__description {
      color: $color-primary;
    }
  }
}
