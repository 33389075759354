.two-blocks-slider-wrapper{
  display:flex;
  flex-direction: column;
  @media screen and (min-width: 1440px){
    flex-direction: row;
    justify-content: space-between;
  }
  .two-blocks-slider-left-block{
    margin-bottom: calc(var(--s-v21)*4);
    margin-right: 20px;
    @media screen and (min-width: 1440px){
      margin-bottom: 0;
      max-width: 400px;
    }
    .left-block-title{
      font-weight:bold;
      font-family: $font-family-primary;
      font-size: 21px;
      line-height: 29px;
      margin-bottom:calc(var(--s-v21)*3);
      @include media (medium up){
      }
    }
    .left-block-description{
      font-size: 16px;
      @include media (medium up){

      }
    }
  }
  .two-blocks-slider-right-block{
     color:#444444;
    .right-block-slide-card{
      max-width: 190px;
      display: flex;
      flex-direction: column;
      position: relative;
      &:not(:last-child){
        @include media (medium up){
          margin-right: calc(var(--s-v21)*8);
        }
      }
      &:hover{
        .slide-card-separator{
          width: 100%;
        }
      }
      .slide-card-all-clickable{
        position: absolute;
        height: 100%;
        width: 100%;
      }
      .slide-card-image{
        margin-bottom:calc(var(--s-v21)*3);
        max-width: 190px;
        img{
          width: 100%;
        }
      }
      .slide-card-title{
        font-family: $font-family-primary;
        font-size:18px;
        font-weight: 600;
        @include media (medium up){
          font-size: 16px;
        }
      }
      .slide-card-description{
        font-size: 16px;
        line-height: 24px;
      }
      .slide-card-separator{
        margin:16px 0;
        height: 1px;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 1px solid #ef3340;
        width: 32px;
        align-self: flex-start;
        transition: width .5s;
      }
    }
  }
}
