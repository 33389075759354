.c-card-seguro-movil {
  max-width: 300px;
  box-shadow: 4px 4px 20px rgba(38, 37, 37, 0.1);

  @include media(medium up) {
    max-width: 397px;
  }

  &--green {
    & .c-card-seguro-movil__header {
      background-color: #ADF2E7;
    }
  }

  &--purple {
    & .c-card-seguro-movil__header {
      background-color: #E9CAF8;
    }
  }

  &__header-container {
    display: flex;
    flex-direction: column;
  }

  &__destacado {
    order: 3;
    padding: 4px 8px;
    font-family: var(--font-family-secondary);
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: start;
    color: var(--color-white);
    background-color: #14CCAF;

    @include media(medium up) {
      order: 1;
      padding: 4px 6px;
    }
  }

  &__header,
  &__body,
  &__ctas {
    padding: 0 20px;
  }

  &__header {
    display: flex;
    order: 1;
    flex-direction: column;
    align-items: center;
    height: 250px;
    padding-top: 24px;
    background-color: #FADC8F;

    > img {
      width: 182px;
      height: 180px;
    }

    @include media(medium up) {
      order: 2;
      padding-top: 24px;
      padding-bottom: 20px;
    }
  }

  &__header-text {
    width: 100%;
    font-family: var(--font-family-secondary);
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #7B5900;
    text-align: start;
  }

  &__promotion-flag,
  &__promotion-flag-desktop {
    display: flex;
    align-items: center;
    height: 24px;
    padding: 4px 8px;
    font-family: var(--font-family-secondary);
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    background-color: $color-azure-radiance;

    @include media(medium up) {
      line-height: 24px;
    }
  }

  &__promotion-flag {
    order: 2;

    @include media(medium up) {
      display: none;
    }
  }

  &__promotion-flag-desktop {
    display: none;

    @include media(medium up) {
      display: flex;
      width: max-content;
      width: -moz-max-content;
      margin: 15px 20px 0;
    }
  }

  &__body {
    margin-top: 28px;
  }

  &__title,
  &__subtitle,
  &__desde {
    text-align: start;
  }

  &__title {
    font-family: var(--font-family-primary);
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
  }

  &__subtitle {
    margin-top: 8px;
    font-family: var(--font-family-primary);
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
  }

  &__prices {
    margin-top: 60px;

    @include media(medium up) {
      margin-top: 16px;
    }
  }

  &__desde {
    font-family: var(--font-family-primary);
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
  }

  &__price {
    display: flex;
  }

  &__price-amount {
    font-family: var(--font-family-primary);
    font-weight: bold;
    font-size: 48px;
    line-height: 58px;
  }

  &__price-params {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__currency {
    font-family: var(--font-family-secondary);
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
  }

  &__period {
    font-family: var(--font-family-secondary);
    font-size: 14px;
    line-height: 14px;
    color: $color-grey-600;
  }

  &__ctas {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 16px;
  }

  &__contratar,
  &__contratar-externo,
  &__mas-info {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family-secondary);
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }

  &__contratar {
    display: none;
    height: 56px;

    @include media(medium up) {
      display: flex;
    }
  }

  &__contratar-externo {
    display: flex;
    height: 56px;

    @include media(medium up) {
      display: none;
    }
  }

  &__mas-info {
    width: 100%;
    margin-top: 8px;
    border: 1px solid var(--color-primary);
    background-color: transparent;
    border-color: var(--color-primary);
    color: var(--color-primary);

    &:hover {
      color: var(--color-white);
    }
  }
}
