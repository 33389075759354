.c-tv-channels {
  .section-description__title {
    font-family: $font-family-primary;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 27px;
    text-align: center;
    letter-spacing: -0.5px;
    margin-bottom: 32px !important;
  }

  &__box-wrapper {
    width: 100%;
    margin: 0 auto 20px;
  }

  &__box {
    width: 100%;
    max-width: 338px;
    margin: 0 auto;
    background: $color-white;
    box-shadow: 4px 4px 20px rgba(38, 37, 37, 0.1);
    display: flex;
    flex-direction: column;

    &__top {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      width: 100%;
      padding: 22px 16px;

      &__channel {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
      }

      &__moreinfo {
        display: flex;
        border-top: 1px solid $color-grey-500;
        margin-top: 14px;
        padding-top: 16px;

        & div:first-child {
          margin-right: 8px;
        }
      }
    }

    &__bottom {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      width: 100%;
      padding: 22px 16px;
      background: $color-primary;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: $color-white;
    }
  }

  &__title {
    font-family: $font-family-primary;
    font-weight: bold;
    font-size: 21px;
    line-height: 27px;
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    & svg {
      margin-right: 8px;
    }
  }

  &__copy {
    font-family: $font-family-secondary;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 8px;
    margin-top: 4px;

    &--light {
      font-weight: 400;
    }
  }

  &__cta {
    font-family: $font-family-secondary;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    &--primary {
      color: $color-secondary;
    }

    &--gray {
      color: $color-grey-400;

      & svg > * {
        stroke: $color-grey-400;
      }
    }

    & svg {
      margin-left: 8px;
      width: 10px;
    }
  }

  &__price {
    font-family: $font-family-primary;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;

    &--primary {
      color: $color-secondary;
    }
  }

  &__month {
    font-family: $font-family-secondary;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: $color-grey-400;
  }

  &__price-text {
    font-family: $font-family-secondary;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    margin-top: 4px;
    color: $color-grey-600;
  }

  &__agile {
    width: 100%;
    max-width: 704px;
    margin-left: auto !important;
    margin-right: auto !important;
    padding-top: 20px;
  }
}
