.list_with_card_price {
  margin-bottom: 48px;
  margin-top: 48px;

  > div {
    @include media(medium up) {
      width: 50%;
    }

    &.full {
      width: 100%;
      margin-bottom: 30px;
      @include media(medium up) {
        width: 100%;
      }
    }

    &.full-column {
      display: flex;
      flex-direction: column;
    }

    &:last-child {
      @include media(medium up) {
        display: flex;
        justify-content: center;
        align-items: flex-start;
      }
    }

    .card {
      margin-top: 30px;
      @include media(medium up) {
        margin-left: 30px;
        margin-top: 0;
        flex: 0 0 60%;
      }
    }
    .links-with-icon__list {
      svg {
        polyline {
          stroke: #ef3340;
        }
      }
    }
  }

  .links-with-icon__link-group {
    flex: 100%;
    max-width: none;
  }
}
