:root {
  --c-cards-icon-with-description-alt-btn-nav-size: 48px;
}

.c-offers {
  padding: 32px 16px 48px !important;
  position: relative;

  @include media(medium up) {
    background-color: transparent;
    padding: 60px 32px !important;
  }

  &--centered {
    & .section-description__title {
      text-align: center !important;

      @include media(medium up) {
        text-align: center !important;
      }

      @include media(medium down) {
        display: inherit !important;
      }
    }
  }

  &__container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1110px !important;
    z-index: 1;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;

    > * {
      margin: 0 auto;
    }

    @include media(medium) {
      grid-template-columns: 1fr 1fr;
    }

    @include media(large) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include media(xlarge) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  &__carousel {
    padding-bottom: 0;
    position: relative;

    @include media(medium up) {
      padding: 40px 20px 50px;
      margin: -20px -20px;
    }

    @include media(medium down) {
      overflow: visible;
    }

    .swiper-wrapper {
      @include media(medium down) {
        transform: none;
        @include flex-direction(column);
      }
    }

    .swiper-slide {
      opacity: 1;
      transition: $transition-opacity;
      width: 80%;

      @include media(medium up) {
        width: 40%;
      }

      @include media(medium down) {
        width: 100%;
        opacity: 1 !important;
      }

      &:not(.swiper-slide-visible) {
        opacity: 0;
        transition: $transition-opacity;
      }
    }

    &__wrapper {
      margin-left: -16px;
      margin-right: -32px;
      position: relative;

      @include media(medium up) {
        margin-left: initial;
        margin-right: initial;
      }

      @include media(medium down) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    &__btn-nav {
      align-items: center;
      background-color: $color-white;
      border-radius: 50%;
      box-shadow: 4px 4px 20px rgba(51, 51, 51, .1);
      cursor: pointer;
      display: none;
      justify-content: center;
      height: var(--c-cards-icon-with-description-alt-btn-nav-size);
      position: absolute;
      right: calc(-0.5 * var(--c-cards-icon-with-description-alt-btn-nav-size));
      top: 150px;
      transition: $transition-background-color;
      width: var(--c-cards-icon-with-description-alt-btn-nav-size);
      z-index: 10;

      &.show {
        @include media(xlarge up) {
          display: flex;
        }
      }

      &.prev {
        left: calc(-0.8 * var(--c-cards-icon-with-description-alt-btn-nav-size));
        right: auto;
        top: 130px;
        transform: rotate(180deg);
      }

      &:hover {
        background-color: darken($color-white, 1%);
        transition: $transition-background-color;
      }
    }
  }

  .swiper-pagination {
    display: none;
  }

  .section-description__title {
    text-align: center;

    @include media(medium up) {
      text-align: left;
    }

    @include media(medium down) {
      display: none;
    }
  }

  &__destacado-bottom {
    font-size: 16px;
    line-height: 24px;
    font-family: $font-family-secondary;
    font-weight: $font-weight-secondary-bold;
    color: $color-gray;
    text-align: center;
    margin-top: 24px;
  }
}
