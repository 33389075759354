:root {
  --hambuger-size: 24px;
}

.c-hamburger {
  width: var(--hambuger-size);
  height: var(--hambuger-size);
  cursor: pointer;
  right: 24px;
  top: calc((0.5 * var(--mundo-r-header-height)) - (0.5 * var(--hambuger-size)));
  z-index: 101;

  display: flex;
  flex-direction: column;
  justify-content: center;

  &__container {
    display: block;
    z-index: 1;

    @include media(large up) {
      display: none;
    }
  }

  span {
    background-color: $color-white;
    width: 100%;
    height: 1px;
    margin-bottom: 6px;
    transition: $transition-transform;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &.open {
    span {
      &:nth-child(1) {
        transform: rotate(45deg) translate(3px, 3px);
        transition: $transition-transform;
      }

      &:nth-child(2) {
        display: none;
      }

      &:nth-child(3) {
        transform: rotate(-45deg) translate(3px, -3px);
        transition: $transition-transform;
      }
    }
  }
}
