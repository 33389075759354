:root {
  --card-smartphone-max-width: 300px;
}

.c-card-smartphone {
  width: 100%;
  max-width: var(--card-smartphone-max-width);
  height: 100%;
  box-shadow: 4px 4px 20px rgba(38, 37, 37, 0.1);
  padding: 16px 0 15px 0;
  position: relative;

  &--featured {
    .c-card-smartphone__featured {
      position: absolute;
      top: 0;

      width: 100%;

      border-top: $color-primary solid 3px;
    }
  }

  &--full-image {
    .c-card-smartphone__content__image {
      max-width: 100%;

      padding-left: 0;
      padding-right: 0;
    }
  }

  &__container {
    margin: 0 auto;
  }

  &__content {
    &__top {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &__features {
      width: 75px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      min-height: 224px;

      &--icon {
        display: flex;
        align-items: center;
        flex-direction: column;

        & img {
          padding-bottom: 8px;
        }

        & span {
          font-family: $font-family-secondary;
          font-weight: normal;
          font-size: $font-size-12;
          line-height: $line-height-for-font-size-13;
          color: $color-gray;
        }
      }
    }

    &__image {
      padding: 10px;
      width: 100%;
      max-width: 225px;
      display: flex;
      align-items: center;
      justify-content: center;

      & img {
        max-height: 260px;
      }
    }

    &__info {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 16px 16px 0 16px;
    }

    &__price {
      display: flex;
      align-items: center;
      justify-content: center;

      &__financing {
        font-family: $font-family-primary;
        font-weight: bold;
        font-size: $font-size-14;
        line-height: $line-height-for-font-size-13;
        text-align: center;
        color: $color-mine-shaft;
      }

      &__price {
        font-family: $font-family-primary;

        font-weight: bold;
        font-size: $font-size-48;
        line-height: $line-height-for-font-size-48;
        color: $color-pantone-red;
      }

      &__currency {
        font-family: $font-family-primary;
        font-weight: bold;
        font-size: 1.1rem;
        line-height: 1;
        color: $color-pantone-red;
      }

      &__text {
        font-family: $font-family-primary;
        font-weight: 300;
        font-size: $font-size-16;
        line-height: $line-height-for-font-size-20;
        color: $color-silver;
        margin-left: 6px;
      }

      &__block__top {
        padding-top: 5px;
      }
    }
  }

  &__brand {
    font-family: $font-family-primary;
    font-weight: 300;
    font-size: $font-size-18;
    line-height: $line-height-for-font-size-20;
    text-align: center;
    color: $color-gray;
    padding: 0 16px 0 16px;
  }

  &__name {
    font-family: $font-family-primary;
    font-weight: bold;
    font-size: $font-size-24;
    line-height: $line-height-for-font-size-26;
    text-align: center;
    color: $color-mine-shaft;
    text-transform: uppercase;
    white-space: nowrap;
    padding: 0 16px 0 16px;
  }

  &__initial_payment {
    font-family: $font-family-primary;
    font-weight: 500;
    font-size: $font-size-14;
    line-height: $line-height-for-font-size-13;
    text-align: center;
    color: $color-mine-shaft;
    margin-bottom: 3px;
    margin-top: 5px;
  }

  &__single_payment {
    font-family: $font-family-primary;
    font-weight: 500;
    font-size: $font-size-12;
    line-height: $line-height-for-font-size-13;
    text-align: center;
    color: $color-mine-shaft;
    margin-top: 7px;
  }

  &__cta {
    & a {
      min-width: 268px;
      padding-bottom: 12px;
      // padding-top: 12px;
      margin-top: 12px;
    }
  }

  &__link {
    & a {
      font-family: $font-family-secondary;
      font-weight: bold;
      font-size: $font-size-16;
      line-height: $line-height-for-font-size-20;
      display: flex;
      align-items: center;
      text-align: center;
      color: $color-pantone-red;
      min-width: 268px;
      min-height: 48px;
      padding-bottom: 12px;
      padding-top: 12px;
      display: block;
      margin-top: 8px;
    }
  }

  & .equipment-preview__separator {
    width: 100%;
    background-color: $color-grey-200;
  }

  &__promotion-flag {
    background: $color-azure-radiance;
    position: absolute;
    width: 172px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 45%;
    right: 0;
    z-index: 1;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    font-family: $font-family-primary;
    font-weight: bold;
    font-size: $font-size-16;
    line-height: $line-height-for-font-size-13;
    color: #FFFFFF;
    text-transform: uppercase;
  }
}
