:root {
  --table-border-color: #{$color-grey-200};
  --table-striped-color: #{$color-grey-200};
  --table-font-family-head: #{$font-family-primary};
}

.o-table {
  width: 100%;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;

  tr {
    border-bottom: 1px solid var(--table-border-color);
  }

  th {
    font-family: var(--table-font-family-head);
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
  }

  td,
  th {
    padding: 28px 14px;
    display: table-cell;
    text-align: left;
    vertical-align: middle;
  }

  tr td:first-child {
    font-weight: bold;
  }

  &--fixed {
    table-layout: fixed;
  }

  &--striped {
    tr {
      border-bottom: none;
    }

    > tbody {
      > tr:nth-child(odd) {
        background-color: var(--table-striped-color);
      }

      > tr > td {
        border-radius: 0;
      }
    }
  }

  &--highlight {
    & > tbody > tr {
      transition: background-color 0.25s ease;

      &:hover {
        background-color: var(--table-striped-color);
      }
    }
  }

  &--centered {
    & {
      thead tr th,
      tbody tr td {
        text-align: center;
      }
    }
  }

  @include media(medium down) {
    &--responsive {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      display: block;
      position: relative;

      td:empty::before {
        content: '\00a0';
      }

      th,
      td {
        margin: 0;
        vertical-align: top;
      }

      thead {
        border: 0;
        border-right: 1px solid var(--table-border-color);
        display: block;
        float: left;

        tr {
          display: block;
          padding: 0 10px 0 0;

          th::before {
            content: "\00a0";
          }
        }
      }

      tbody {
        display: block;
        width: auto;
        position: relative;
        overflow-x: auto;
        white-space: nowrap;

        tr {
          display: inline-block;
          vertical-align: top;
        }
      }

      th {
        display: block;
        text-align: left;
      }

      td {
        display: block;
        min-height: 1.25em;
        text-align: left;
      }

      tr {
        border-bottom: none;
        padding: 0 10px;
      }
    }
  }

  @include media(mobilelarge down) {
    &--responsive-mobile {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      display: inline-block;
      position: relative;

      td:empty::before {
        content: '\00a0';
      }

      th,
      td {
        margin: 0;
        vertical-align: top;
        border-left: 0;
        border-right: 0;
        border-bottom: 1px solid #ccc;
        height: 90px;

        &:last-child {
          border-right: 0;
        }
      }

      thead {
        border: 0;
        border-right: 1px solid var(--table-border-color);
        display: block;
        float: left;

        tr {
          display: block;
          padding: 0 10px 0 0;
          min-height: 4em;

          th::before {
            content: "\00a0";
          }
        }

        tr:first-of-type {
          white-space: pre-line;
          max-width: 180px;
          padding-left: 0;
          padding-right: 0;
        }

        th {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      tbody {
        display: block;
        width: auto;
        position: relative;
        overflow-x: auto;
        white-space: nowrap;

        tr {
          display: inline-block;
          vertical-align: top;
        }
      }

      th {
        display: block;
        text-align: left;
      }

      td {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 4em;
        text-align: left;
      }

      tr {
        border-bottom: none;
        padding: 0;
      }

      td {
        padding: 10px 8px;
      }
    }
  }
}
