.section-solutions-services{
  margin-top:calc(var(--s-v21)*5);
  margin-bottom:calc(var(--s-v21)*5);
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  @include media (medium up){
    margin-top:calc(var(--s-v21)*8);
    margin-bottom:calc(var(--s-v21)*8);
  }
}
