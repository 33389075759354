.c-hero-landing-cards-v2 {
  box-sizing: border-box;
  height: 100%;
  min-height: 350px;
  padding: 0;
  position: relative;
  width: 100%;

  @include media(medium up) {
    min-height: 700px;
  }

  &--landingsofertas {
    @include media(medium up) {
      min-height: 520px;
    }

    & .c-hero-landing-cards-v2__bg-image img {
      object-position: center;
    }
  }

  &__overlay {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    background: rgba(68, 68, 68, 0.4);
  }

  &__bg-image {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    & img {
      height: 100%;
      object-fit: cover;
      object-position: top;
      width: 100%;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 50px 12px 0;
    position: relative;
    z-index: 1;

    @include media(medium up) {
      padding: 60px 20px 40px;
    }

    @include media(xlarge up) {
      padding: 60px 0 40px;
    }
  }

  &__title {
    font-family: $font-family-primary;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.5px;
    color: $color-white;
    margin-bottom: 36px;
    display: none;

    @include media(medium up) {
      display: block;
      font-size: 36px;
      line-height: 42px;
      margin-bottom: 40px;
    }
  }

  &__subtitle {
    font-family: $font-family-primary;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.5px;
    color: $color-white;
    margin-bottom: 36px;
    display: none;

    @include media(medium up) {
      display: block;
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 40px;
      margin-top: -36px;
    }
  }

  &__content {
    display: flex;
    align-items: flex-start;
  }

  &__content-ofertas {
    position: relative;
    margin-right: 0;
    width: 100%;

    @include media(medium up) {
      margin-right: 32px;
      width: calc(100% - 287px);
    }

    &--movil {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;

      @include media(medium up) {
        flex-direction: row;
      }

      @include media(xlarge up) {
        flex-wrap: nowrap;
      }

      .c-card-new-offer {
        min-height: auto;
        margin-bottom: 12px;

        @include media(medium up) {
          margin-right: 12px;
        }
      }

      .c-card-new-offer__icons {
        min-height: auto;
      }

      .c-card-new-offer__header {
        display: none;

        @include media(medium up) {
          display: flex;
        }
      }

      .c-card-new-offer__info__middle {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
        justify-content: space-between;

        @include media(medium up) {
          display: flex;
          flex-direction: column;
        }
      }

      .c-card-new-offer__offer-price__price-text {
        display: none;

        @include media(medium up) {
          display: block;
        }
      }

      .c-card-new-offer__offer-price__block {
        margin-left: 0;
        @include media(medium up) {
          margin-left: 10px;
        }
      }

      .c-card-new-offer__offer-price__block__top {
        display: flex;
        flex-direction: column;

        @include media(medium up) {
          flex-direction: row;
        }

      }

      .c-card-new-offer__offer-price__time {
        font-size: 11px;
        line-height: normal;

        @include media(medium up) {
          font-size: 18px;
          line-height: 24px;
        }
      }

      .c-card-new-offer__offer-price__currency {
        font-size: 18px;
        line-height: 18px;

        @include media(medium up) {
          font-size: 24px;
          line-height: 24px;
        }

      }
      .c-card-new-offer__icons__copy {
        line-height: 16px;

        @include media(medium up) {
          line-height: 24px;
        }
      }

      .c-card-new-offer__offer-price__price {
        font-size: 40px;

        @include media(medium up) {
          font-size: 54px;
        }
      }
      &+.c-hero-landing__configurador-cmn .c-hero-landing__configurador-cmn__top,
      &+.c-hero-landing__configurador-cmn .c-hero-landing__configurador-cmn__bottom  {
        display: none;
      }
      &+.c-hero-landing__configurador-cmn .c-hero-landing__configurador-cmn__middle {
        padding: 0;
      }
    }

    &--tv {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;

      @include media(medium up) {
        flex-direction: row;
      }

      @include media(xlarge up) {
        flex-wrap: nowrap;
      }

      .c-card-new-offer {
        min-height: auto;
        margin-bottom: 12px;

        @include media(medium up) {
          margin-right: 12px;
        }
      }
    }
  }

  &__ofertas {
    background: rgba(255, 255, 255, 0.7);
    padding: 0 0 10px;

    @include media(medium up) {
      padding: 0;
    }

    & .cards-icon-with-description--landings {
      padding: 20px 8px 8px;

      @include media(medium up) {
        padding: 0 16px 40px;
      }
    }

    &__content-title {
      padding-left: 24px;
      margin-bottom: 8px;
      padding-top: 0;

      @include media(medium up) {
        margin-bottom: 8px;
      }
    }

    &__title-destacado {
      position: relative;
      background: $color-chart-8;
      color: $color-white;
      font-family: $font-family-primary;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.5px;
      padding: 10px 24px;
      width: fit-content;
      margin-top: 0;
      margin-left: -8px;
      margin-bottom: 0;

      @include media(medium up) {
        font-size: 21px;
        line-height: 27px;
        margin-bottom: 32px;
      }

      @include media(large up) {
        margin-bottom: 32px;
      }

      &::before {
        content: ' ';
        width: 8px;
        height: 100%;
        background-color: $color-chart-8-dark;
        position: absolute;
        left: 0;
        z-index: -1;
        transform: skewY(45deg);
        transform-origin: 0 0;
      }
    }

    &__title {
      font-family: $font-family-primary;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.5px;
      color: $color-black;
      margin-bottom: 10px;

      @include media(medium up) {
        font-size: 21px;
        line-height: 27px;
        margin-bottom: 10px;
      }
    }

    &__subtitle {
      font-family: $font-family-secondary;
      font-size: 14px;
      line-height: 21px;
      color: $color-black;

      @include media(medium up) {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }

  &__legal {
    font-family: $font-family-secondary;
    font-size: 12px;
    line-height: 14px;
    color: $color-white;
    margin-top: 48px;
    margin-bottom: 24px;

    @include media(medium up) {
      margin-bottom: 0;
    }
  }

  &__content-legal-link {
    text-align: right;
    padding: 0 20px;

    & a {
      display: inline-block;
      color: $color-grey-600;
    }
  }

  &__promo-bottom {
    font-family: $font-family-secondary;
    background: $color-chart-8;
    color: $color-white;
    font-weight: bold;
    font-size: 16px;
    line-height: 12px;
    padding: 8px 12px;
    margin-top: 24px;
    margin-bottom: 24px;
    width: fit-content;
    display: none;

    @include media(medium up) {
      display: block;
    }
  }

  &__promo-bottom-mobile {
    font-family: $font-family-secondary;
    background: $color-chart-8;
    color: $color-white;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
    padding: 8px 12px;
    margin: 18px 8px 0;
    width: calc(100% - 16px);
    display: block;

    @include media(medium up) {
      display: none;
    }
  }
}
