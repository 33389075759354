.c-mundo-r-header--light {
    @include media(large up) {
      margin-top: 70px;
    }

    .c-mundo-r-header__logo img {
      height: 35px !important;
      width: auto !important;

      @include media(large up) {
        height: 56px !important;
      }
      
    }

    .c-mundo-r-header__logo-link {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .c-browsers {
    margin-top: calc(var(--mundo-r-header-height) + var(--mundo-r-secondary-menu-height));;
    padding: 0 0 0;
    position: relative;

    @include media(medium up) {
      padding: 46px 0 0;
    }

    @include media(large up) {
      padding: 70px 0 0;
    }

    &__top {
      padding-right: 16px;
      padding-left: 16px;

      @include media(medium up) {
        padding-right: 32px;
        padding-left: 32px;
      }
    }

    &__title {
      margin-bottom: 7px;
      color: $color-font-primary;
      font-size: $font-size-em-36;
      font-weight: bold;
      font-family: $font-family-primary;
      letter-spacing: -0.5px;
      line-height: $line-height-for-font-size-42;

      @include media(medium up) {
        margin-bottom: 10px;
      }
    }

    &__subtitle {
      margin-bottom: 15px;
      color: $color-font-primary;
      font-size: 1em;
      font-weight: bold;
      font-family: $font-family-primary;
      letter-spacing: -0.5px;
      line-height: 27px;

      @include media(medium up) {
        margin-bottom: 0;
        font-size: $font-size-em-24;
        line-height: $line-height-for-font-size-30;
      }
    }

    &__copy {
      color: $color-secondary;
      font-size: $font-size-em-16;
      font-weight: bold;
      font-family: $font-family-secondary;
      line-height: 24px;

      @include media(medium up) {
        font-size: $font-size-em-18;
      }
    }

    &__icon {
      width: 97px;
      height: auto;
      margin-right: 29px;
      display: none;

      @include media(medium up) {
        display: block;
      }
    }

    &__flex-container {
      @include media(medium up) {
        display: flex;
        align-items: center;
        margin-bottom: 42px;
      }
    }

    &__browsers {
      margin-top: 9px;
      padding-bottom: 0;
      background: none;

      @include media(medium up) {
        display: block;
        max-width: 1100px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 28px;
      }

      &__content {
        padding: 0 16px;
        @include flex();
        flex-wrap: wrap;
        flex-direction: column;

        @include media(medium up) {
          flex-direction: row;
        }
      }

      &__item {
        margin: 0 0 16px;
        padding: 12px 12px 12px 26px;
        min-width: 183px;
        @include flex();
        @include flex-direction(row);
        align-items: center;
        background: #F2F2F2;

        @include media(medium up) {
          margin-right: 20px;
          padding: 14px 35px 14px 35px;
          @include flex-direction(column);
          text-align: center;
        }

        @include media(large up) {
          margin-right: 30px;
        }

        &__image {
          margin-right: 36px;
          height: 55px;
          width: auto;

          @include media(medium up) {
            margin-right: auto;
            margin-left: auto;
          }
        }

        &__title {
          color: $color-font-primary;
          font-size: $font-size-em-16;
          font-weight: bold;
          font-family: $font-family-secondary;
          line-height: $line-height-for-font-size-24;
        }

        &__link {
          color: $color-secondary;
          font-size: $font-size-em-14;
          font-weight: bold;
          font-family: $font-family-secondary;
          line-height: $line-height-for-font-size-16;
        }
      }
    }

    &__modal-content {
      padding: 0;

      &__close-button {
        transform: translate(-25%, 150%);
        z-index: 10;

        .icon__glyph {
          stroke: $color-font-primary;
        }
      }
    }
  }

  .u-wrapper--narrow {
    @include media(xlarge up) {
      max-width: 1100px !important;
    }
  }
