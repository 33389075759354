.c-hero-landing {
  &__legal {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  &__configurador {
    width: 100%;
    margin-top: 24px;

    @include media(medium up) {
      margin-top: 0;
      width: 317px;
    }

    &__container {
      padding: 16px 10px;
      background: $color-white;
      box-shadow: 0px 10px 30px rgba(38, 38, 38, 0.15);
    }

    &__title {
      font-family: $font-family-primary;
      font-weight: bold;
      font-size: 21px;
      line-height: 27px;
    }

    &__destacado {
      background-color: $color-lilac;
      line-height: 26px;
      padding: 0 14px 0 32px;
      color: #fff;
      margin: 10px 0 10px -18px;
      right: 0;
      position: relative;
      font-size: 14px;
      font-family: $font-family-secondary;
      font-weight: bold;
      height: 26px;
      width: max-content;
      text-transform: uppercase;

      &::before {
        content: ' ';
        width: 8px;
        height: 32px;
        background-color: $color-lilac;
        position: absolute;
        left: 0;
        z-index: -1;
        transform: skewY(45deg);
        transform-origin: 0 0;
      }
    }

    &__price {
      font-family: $font-family-primary;
      font-weight: bold;
      font-size: 36px;
      line-height: 42px;
      margin-bottom: 8px;
    }

    &__price-later {
      font-family: $font-family-secondary;
      font-size: 14px;
      line-height: 25px;
      margin-bottom: 18px;

      & span {
        margin-left: 8px;
        font-family: $font-family-primary;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
      }
    }

    &__ctas {
      position: relative;
      width: 100%;
      max-width: 320px;

      & > * {
        width: 100%;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 22px;
        }
      }
    }


    &__legal {
      font-family: $font-family-secondary;
      font-size: 14px;
      line-height: 16px;
      color: $color-primary;
    }
  }

  &__configurador-cmn {
    width: 255px;
    background: $color-white;
    padding: 0;
    display: none;

    @include media(medium up) {
      display: block;
      position: absolute;
      right: 16px;
      z-index: 1;
    }

    &--cmn-mobile {
      display: block;
      width: calc(100% - 16px);
      margin: 0 8px;

      & .c-hero-landing__configurador-cmn__top {
        padding: 16px 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include media(mobilelarge up) {
          padding: 16px;
        }
      }

      & .button.primary {
        max-width: 110px;
        min-width: 110px;
        margin-right: 12px;

        @include media(medium up) {
          max-width: 140px;
          min-width: 140px;
          margin-right: 24px;
        }
      }

      @include media(medium up) {
        display: none;
      }

      & .c-descriptivoBody__ctas {
        padding: 0;
        margin-top: 0;

        & a {
          padding: 0 20px;
        }

        & a.cart {
          line-height: 42px;
        }
      }
    }

    &__title {
      font-family: $font-family-primary;
      font-weight: bold;
      font-size: 21px;
      line-height: 27px;
    }

    &__destacado {
      background-color: $color-lilac;
      line-height: 26px;
      padding: 0 14px 0 32px;
      color: #fff;
      margin: 10px 0 10px -18px;
      right: 0;
      position: relative;
      font-size: 14px;
      font-family: $font-family-secondary;
      font-weight: bold;
      height: 26px;
      width: max-content;
      text-transform: uppercase;

      &::before {
        content: ' ';
        width: 8px;
        height: 32px;
        background-color: $color-lilac;
        position: absolute;
        left: 0;
        z-index: -1;
        transform: skewY(45deg);
        transform-origin: 0 0;
      }
    }

    &__top {
      padding: 16px 0 0;
      border-bottom: 1px solid #ECECEC;
      text-align: center;
    }

    &__middle {
      padding: 0;
      border-bottom: 1px solid #ECECEC;

      & .c-descriptivoBody__ctas {
        padding: 20px;
        margin-top: 0;
      }
    }

    &__bottom {
      padding: 16px;
    }

    &__months {
      font-family: $font-family-primary;
      font-weight: $font-weight-primary-bold;
      color: $color-primary;
    }

    &__offer-price {
      @include flex();
      font-family: $font-family-primary;
      justify-content: center;
      width: auto;
      padding: 0 16px;

      &__price {
        font-weight: $font-weight-primary-bold;
        font-size: 32px;
        line-height: 32px;
        color: $color-chart-7;

        @include media(medium up) {
          font-size: 54px;
          line-height: 54px;
        }
      }

      &__block {
        @include flex();
        @include flex-direction(column);
        justify-content: center;

        @include media(medium down) {
          margin-left: 4px;
          margin-top: 6px;
          justify-content: flex-start;
          flex-shrink: 0;
        }

        &__top {
          @include flex();
          @include flex-align(center);
          margin-bottom: 2px;

          @include media(medium down) {
            justify-content: flex-start;
            align-content: center;
          }
        }
      }

      &__currency {
        font-weight: $font-weight-primary-bold;
        font-size: 24px;
        line-height: 24px;
        color: $color-chart-7;

        @include media(medium down) {
          font-size: 14px;
          line-height: 14px;
        }
      }

      &__time {
        margin-left: 4px;
        font-size: 18px;
        line-height: 24px;
        color: $color-grey-600;

        @include media(medium down) {
          font-size: 12px;
          line-height: 14px;
        }
      }

      &__price-text {
        font-family: $font-family-secondary;
        font-weight: $font-weight-primary-bold;
        font-size: 10px;
        line-height: 12px;
        color: $color-grey-600;

        @include media(medium down) {
          font-size: 10px;
          line-height: 12px;
        }
      }
    }

    &__price {
      font-family: $font-family-primary;
      font-weight: bold;
      font-size: 36px;
      line-height: 42px;
      margin-bottom: 8px;
    }

    &__price-later {
      font-family: $font-family-secondary;
      font-size: 14px;
      line-height: 25px;
      margin-bottom: 18px;

      & span {
        margin-left: 8px;
        font-family: $font-family-primary;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
      }
    }

    &__ctas {
      position: relative;

      & > * {
        width: 100%;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 22px;
        }
      }
    }

    &__contrata {
      font-family: $font-family-secondary;
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;
      color: $color-primary;
      display: flex;
      align-items: center;

      & svg {
        width: 21px;
        margin-left: 4px;
        fill: $color-primary;
      }
    }

    &__legal {
      font-family: $font-family-secondary;
      font-size: 14px;
      line-height: 16px;
      color: $color-primary;
      display: block;
    }

    &__text-legal {
      font-family: $font-family-secondary;
      font-size: 11px;
      line-height: 12px;
      font-weight: bold;
      color: $color-font-primary;
      padding: 0 16px;
      margin-bottom: 0;

      @include media(medium up) {
        margin-bottom: 12px;
      }
    }

    &__banner-destacado {
      font-family: $font-family-secondary;
      background: $color-lilac;
      color: $color-white;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      padding: 4px;
      text-align: center;
    }
  }

  &__cmn {
    & krt-cmn-form .krt-button {
      --width: 100% !important;
    }

    & .cmn-form-claim {
      font-weight: bold !important;
      margin-bottom: 0 !important;
    }

    & .cmn-footer {
      text-align: center !important;
    }
  }
}

.landings-ofertas .main {
  margin-top: calc(var(--mundo-r-header-light-height)) !important;
}

.landings-cobertura .fiber-coverage {
  margin-top: 0 !important;
  padding-top: 54px;
}

.landings-cobertura .fiber-coverage__header {
  margin-bottom: 0 !important;
}
