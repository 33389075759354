@import './../_mixins/base-html-elements';
@import './../_mixins/grid';
@import './../_variables/dimensions';


.kit-digital-popup-modal {
  margin: 0 !important;
  max-width: 700px;
  width: 100%;
  padding: 0;

  @include media(medium up){
    margin: 0 25px;
  }
}

.kit-digital-popup {
  padding: 0;
  width: 100%;
  max-width: 700px;

  @include media(medium up){
    padding: 40px;
  }

  header {
    margin-bottom: 16px;
    h2 {
      font-size: $font-size-24;
      line-height: $line-height-for-font-size-24;
      font-family: $font-family-primary;
      font-weight: 700;
      max-width: 95%;
    }

    p {
      margin-top: 8px;
    }

  }
  .details {
    border: 1px solid $color-grey-400;
    border-radius: 10px;
    margin-top: 8px;
    .summary {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;

      @include media(medium up){
        padding: 24px;
      }

      .summary-wrapper {
          h3 {
            font-size: $font-size-21;
            line-height: $line-height-for-font-size-21;
            font-family: $font-family-primary;
            font-weight: 500;
            color: $color-primary;
          }
      }

      svg {
        flex-shrink: 0;
        &.flip {
          transform: scale(1, -1);
        }
        path {
          stroke: $color-secondary;
        }
      }
    }
    .content {
      height: 0;
      padding: 0;
      overflow: hidden;

      padding-left: 16px;
      padding-right: 16px;

      @include media(medium up){
        padding-left: 24px;
        padding-right: 24px;
      }

      &.show {
        height: auto;
        padding-top: 16px;
        padding-bottom: 16px;
        border-top: 1px solid $color-grey-400;

        @include media(medium up){
          padding-top: 24px;
          padding-bottom: 24px;
        }

      }

      h4 {
        font-size: $font-size-24;
        line-height: $line-height-for-font-size-24;
        font-family: $font-family-primary;
        font-weight: 700;
      }


      h5 {
        font-size: $font-size-18;
        line-height: $line-height-for-font-size-18;
        font-family: $font-family-primary;
      }

      ul {
        margin-top: 16px;
        margin-bottom: 24px;
        li {
          margin-top: 16px;
          display: flex;
          align-items: center;

          svg {
            margin-right: 16px;
            flex-shrink: 0;
          }
        }
      }

      .fine-print {
        font-size: $font-size-12;
      }
    }
  }


}
