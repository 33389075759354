.c-page {
  position: relative;
  width: 100%;
  background: var(--color-white);

  &__bg {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    & img {
      height: 100%;
      object-fit: cover;
      object-position: top;
      width: 100%;
    }
  }

  &__content-header {
    position: relative;
    width: 100%;
    min-height: 300px;
    height: auto;
    /*margin-bottom: 36px;*/


    &--configuration {
      .c-page__bg {
        height: 260px;

        @include media(medium up) {
          height: 340px;
        }
      }
    }

     /*~ *:first-of-type {
       margin-top: 0;
       padding-top: 0;
     }*/
  }

  &__content-header-text {
    z-index: 0;
    position: relative;
    max-width: 1420px;
    margin: 0 auto;
    padding-top: 40px;
    width: 100%;

    &.z-index {
      z-index: 2;
    }
  }

  &__content-header-configuration {
    max-width: 1030px;
    margin: 32px auto 0;
    width: 100%;

    @include media(medium up) {
      margin: 80px auto 0;
    }
  }

  &__title {
    font-family: var(--font-family-primary);
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: var(--color-white);
    margin-bottom: 16px;

    @include media(medium up) {
      margin-bottom: 24px;
      font-size: 50px;
      line-height: 50px;
    }
  }

  &__subtitle {
    font-family: var(--font-family-secondary);
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: var(--color-white);
    margin-bottom: 24px;

    @include media(medium up) {
      font-size: 20px;
      line-height: 22px;
    }
  }

  &__tarifas-table {
    padding: 32px 0 60px;
    background-color: var(--color-white);
  }

  &__simple-text {
    padding-top: 20px;
    padding-bottom: 48px;

    & h2 {
      margin-top: 20px;
      margin-bottom: 12px;
    }

    & p {
      margin-bottom: 12px;
    }
  }

  /*&--smartphones {
    padding-top: 20px;
  }*/
}
