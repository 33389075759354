:root {
  --mundo-r-header-clean-height: 72px;
  --mundo-r-header-clean-mobile-height: 126px;
  --mundo-r-header-clean-submenu-height: 50px;
  --mundo-r-zindex-normal: 100;
  --mundo-r-zindex-submenu-trick: 99;
  --mundo-r-zindex-submenu: 98;
  --mundo-r-secondary-menu-height: 32px;

  @include media(medium up) {
    --mundo-r-header-clean-height: 84px;
  }
}

.c-mundo-r-header-clean {
  background-color: $color-primary;
  height: var(--mundo-r-header-clean-height);
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: var(--mundo-r-zindex-normal);

  @include media(medium up) {
    height: calc(var(--mundo-r-header-clean-height) + var(--mundo-r-secondary-menu-height));
  }

  &.menu-open {
    position: fixed;
  }

  &:after {
    content: ' ';
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-color: $color-primary;
  }

  @include media(medium up) {
    //top: var(--mundo-r-preheader-height);
    top: 0;
  }

  &__menuCliente {
    background-color: #F7F7F7;
    position: relative;
    z-index: 999999;
    padding: 0 16px;
    width: 100%;

    @include media(medium up) {
      display: none;
    }
  }

  &__menuClienteColumn {
    flex: 0;

    .c-mundo-r-header-clean__menuClienteLink {
      margin-top: 6px;
      display: block;
    }
  }

  &__menuClienteItem {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
    color: #333333;
    border-bottom: 1px solid #BFBFBF;
    padding: 16px 0;

    &:last-of-type {
      border-bottom: none;
    }
  }

  &__menuClienteTitle {
    font-family: $font-family-secondary;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
    color: #333333;
  }

  &__menuClienteLink {
    font-family: $font-family-secondary;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
    color: $color-primary;
  }

  &__container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1250px;
    padding: 0 16px;

    @include media(medium up) {
      height: calc(100% - var(--mundo-r-secondary-menu-height));
      justify-content: space-between;
      padding: 0 0 0 16px;
    }

    @include media(xxlarge up) {
      padding: 0;
    }
  }

  &__container-right {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__logo-link {
    display: block;
    flex-shrink: 0;
    z-index: var(--mundo-r-zindex-normal);
    margin-bottom: 0;
  }

  &__logo {
    height: 36px;
    margin-left: 0;
    flex-shrink: 0;

    img {
      width: 60px;
      height: 60px;

      @include media(360px up) {
        width: 120px;
        height: 50px;
      }

      @include media(medium up) {
        width: auto;
        height: 42px;
      }
    }
  }

  &__call-us {
    background-color: $color-grey-800;
    color: $white;
    display: none;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 24px 10px;
    transition: $transition-background-color;
    white-space: normal;
    z-index: var(--mundo-r-zindex-normal);
    max-width: 200px;
    min-width: 200px;

    @include media(medium up) {
      display: flex;
    }

    &:hover {
      background-color: $color-chart-2;
      transition: $transition-background-color;
    }

    &--sticky {
      position: fixed;
      bottom: -50%;
      height: auto;
      width: 100%;
      z-index: 999999;
      max-width: 100%;
      padding: 14px 25px;
      justify-content: flex-start;
      transition: all .3s;

      &.visible {
        display: flex;
        bottom: 0;
        @include media(medium up) {
          display: none !important;
        }
      }

      strong {
        display: initial !important;
      }

      .c-mundo-r-header-clean__call-us__icon {
        width: 29px;
        margin-right: 17px;
      }

      .c-mundo-r-header-clean__call-us__content {
        font-size: 18px;
      }
    }

    &--sticky--cliente {
      align-items: center;

      .c-mundo-r-header-clean__call-us__content {
        font-size: 16px;
        max-width: unset;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }


    &__icon {
      margin-right: 8px;
      margin-top: 8px;
      width: 20px;
      flex-shrink: 0;

      > svg * {
        stroke: $color-white;
      }
    }

    &__content {
      font-family: $font-family-primary;
      font-size: $font-size-14;
      line-height: 24px;
      width: 140px;

      .phone {
        display: block;
      }
    }
  }

  &__your-area {
    position: relative;
    align-items: center;
    display: none;
    justify-content: center;
    flex-direction: row;
    height: 100%;
    padding: 0 15px;
    transition: $transition-background-color;
    z-index: var(--mundo-r-zindex-normal);

    @include media(medium up) {
      display: flex;
      flex-direction: column;
    }

    @include media(xlarge up) {
      padding: 20px 16px;
    }

    &:hover {
      background-color: $color-chart-2;
      transition: $transition-background-color;
    }

    &__icon {
      width: 33px;
    }

    &__content {
      color: $color-white;
      font-family: $font-family-primary;
      font-size: $font-size-14;
      font-weight: bold;
      letter-spacing: -0.5px;
      line-height: $line-height-for-font-size-14;
      margin-top: 6px;
      white-space: nowrap;
    }
  }

  &__your-area-mobile {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    color: $color-white;
    padding: 1px 8px;
    z-index: 1;

    &__icon {
      width: 26px;

      @include media(medium up) {
        width: 20px;
      }
    }

    &__content {
      font-size: 10px;
      padding: 0 0 0 5px;
    }
  }

  .header-desktop__nav-menu-layer--show {
    opacity: 1;
    pointer-events: initial;
  }

  .header-desktop__nav-menu-layer {
    top: var(--mundo-r-header-clean-height) !important;
    left: 0;
    right: auto;
    width: 300px;
  }

  &__language-selector {
    border-left: 1px solid $color-grey-500;
    display: none;
    position: relative;
    justify-content: flex-end;
    flex-direction: column;
    height: 100%;
    z-index: var(--mundo-r-zindex-normal);
    font-family: $font-family-secondary;
    font-size: $font-size-14;
    line-height: $line-height-for-font-size-14;
    padding: 16px;
    transition: $transition-background-color;
    cursor: pointer;

    .icon {
      width: 12px;
    }

    &:hover {
      background-color: darken($color-white, 5%);
      transition: $transition-background-color;
    }

    @include media(medium up) {
      display: flex;

      &.show {
        img {
          transform: rotate(180deg);
        }
      }
    }

    &__current {
      white-space: nowrap;
    }
  }

  &__phone-mobile {
    background: $color-white;
    height: 32px;
    padding: 5px 8px;
    z-index: 1;

    &__number {
      font-family: $font-family-secondary;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: $color-primary;
    }

    @include media(medium up) {
      display: none;
    }
  }

  &__container-langs-mobile {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    margin-left: 4px;

    & .links .link {
      margin: 0;
    }

    @include media(medium up) {
      display: none;
    }
  }

  &--scrolling {
    position: fixed;
    top: calc(-1 * var(--mundo-r-header-clean-height));
    left: 0;
    right: 0;
    z-index: 999;
  }

  // Cuando se está haciendo scroll down se esconde
  &--nav-down {
    transform: translateY(-100%);
    transition: $transition-transform;
  }

  // Cuando se está haciendo scroll up se muestra
  &--nav-up {
    top: 0;
    transform: translateY(0);
    transition: $transition-transform;
  }

  & .header-desktop__secondary {
    display: none;

    @include media(medium up) {
      display: flex !important;
    }
  }

  & .header-desktop__secondary .section__column-content {
    max-width: 1224px;
    margin-left: auto;
    margin-right: auto;
  }

  & .header-desktop__secondary-content-menu {
    margin-left: 4px;

    @include media(medium up) {
      margin-left: 30px;
    }
  }
}
