.soluciones-avanzadas-form{
    display: flex;

    >div{
        @include media(large up){
            width: 35%;
        }
    }
    >form{
        @include media(large up){
            width: 65%;
        }

        .contact-form__content{
            padding-top: 0;
        }
    }
}

.c-porQueR{
    +.soluciones-avanzadas-form{
        margin-top: -40px;
    }
}


.landing-cartera-internet.solucion-pymes {
    .links-with-icon {
        margin: 0;
        @include media(medium up) {
            margin: 0;
        }
    }
}

#solucion-pymes {
    .comparison-table-column.comparison-table-column--featured.comparison-table-column--huge {
        .promotion-duration {
            font-weight: 600;
            font-size: 1.2em;
            margin-bottom: 10px;
        }
    }
}

.subvenciones-tecnologicas{
    #programa-subvenciones-tecnologicas{
        margin-top: 60px;
        margin-bottom: 60px;
    }
}

.new {
    color: #87DBDF;
    font-size: 0.9rem;
    font-weight: 600;
}
.body--landing{
    #hostingR{
        .horizontal-scroller:first-child{
            padding-top: 0;
        }
        .horizontal-scroller__item{
            @include media(mobilexlarge down) {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }
}
