/*=============================================
=            Color Variables                  =
=============================================*/

$color-primary: #444444;
$color-primary-rgb: 68, 68, 68;
$color-primary-contrast: #ffffff;
$color-primary-contrast-rgb: 255, 255, 255;

$color-secondary: #ef3340;
$color-secondary-rgb: 239, 51, 64;
$color-secondary-contrast: #ffffff;
$color-secondary-contrast-rgb: 255, 255, 255;

$color-tertiary: #ef3340;
$color-tertiary-rgb: 239, 51, 64;
$color-tertiary-contrast: #ffffff;
$color-tertiary-contrast-rgb: 255, 255, 255;

$color-success: #00d41f;
$color-success-rgb: 0, 212, 31;
$color-success-contrast: #ffffff;
$color-success-contrast-rgb: 255, 255, 255;

$color-warning: #eec303;
$color-warning-rgb: 238, 195, 3;
$color-warning-contrast: #ffffff;
$color-warning-contrast-rgb: 255, 255, 255;

$color-danger: #ff0000;
$color-danger-rgb: 255, 0, 0;
$color-danger-contrast: #ffffff;
$color-danger-contrast-rgb: 255, 255, 255;

$color-font-primary: #1D1F2C;
$color-text: $color-primary;
$color-text-inverse: $color-primary-contrast;
$color-white: #ffffff;
$color-inverse: #ffffff;

$color-grey-100: #F9F9F9;
$color-grey-200: #F2F2F2;
$color-gray-250: #ECECEC;
$color-grey-300: #F6F6F6;
$color-grey-400: #BFBFBF;
$color-grey-500: #ECECEC;
$color-grey-600: #7F7F7F;
$color-grey-800: #333333;
$color-grey-rgb-200: 242, 242, 242;
$color-grey-C4: #C4C4C4;

$box-shadow-default: 0px 2px 10px $color-grey-400;

$color-highlight: $color-secondary;
$color-dark: $color-primary;

$color-body-text: $color-primary;

$color-lilac: #987AD9;
$color-lilac-dark: #50348C;
$color-dark-grayish-navy: #1D1F2C;
$color-cta-primary: #E10A0A;
$color-red-crimson: #E1251B;
$color-gray-mine-shaft: #222222;

/* Chart colors */
$color-chart-0: $color-secondary;
$color-chart-1: #88DBDF;
$color-chart-2: #D45D00;
$color-chart-3: #003DA5;
$color-chart-4: #F2A900;
$color-chart-5: #C6A1CF;
$color-chart-6: #960051;
$color-chart-7: #EF3340;
$color-chart-8: #00A3E0;
$color-chart-8-dark: #006185;
$color-chart-9: #526F32;
$color-chart-10: #84BD00;

$color-font-primary: #1D1F2C;

$color-secondary-variant-1: #00A3E0;
$color-secondary-variant-1-rgb: 0, 163, 224;
$color-secondary-variant-1-contrast: #ffffff;
$color-secondary-variant-1-contrast-rgb: 255, 255, 255;

$color-secondary-variant-2: #960051;
$color-secondary-variant-2-rgb: 150, 0, 80;
$color-secondary-variant-2-contrast: #ffffff;
$color-secondary-variant-2-contrast-rgb: 255, 255, 255;
