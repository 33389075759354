:root {
 --card-new-offer-max-width: 500px;
 --card-new-offer-min-width: 0;
 --card-new-offer-min-height: 250px;
 --card-new-offer-image-height: 130px;
 --card-new-offer-ribbon-height: 32px;
 --card-new-offer-ribbon-darken-color: #{$color-chart-8-dark};

 @include media(large) {
  --card-new-offer-max-width: 262px;
  --card-new-offer-min-width: 255px;
  --card-new-offer-min-height: 480px;
  --card-new-offer-image-height: 200px;
 }
}

.c-card-new-offer {
 position: relative;
 @include flex();
 @include flex-direction(column);
 max-width: var(--card-new-offer-max-width);
 min-height: var(--card-new-offer-min-height);
 width: 100%;
 min-width: var(--card-new-offer-min-width);
 box-shadow: 4px 4px 20px rgba(38, 37, 37, 0.1);
 background-color: $color-white;
 transition: background-color 0.2s ease, box-shadow 0.2s ease;

 &:hover {
  box-shadow: 0 10px 30px rgba(38,38,38,.30);
 }

 &__header {
  height: 48px;
  background-color: $color-chart-7;
  color: $color-white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .c-card-new-offer__title {
   margin-bottom: 0;
  }
 }

 &__content {
  @include flex();
  @include flex-direction(column);
  flex-grow: 1;
 }

 &__image {
  height: var(--card-new-offer-image-height);
  overflow: hidden;

  & img {
   object-fit: cover;
   object-position: center;
   width: 100%;
   height: 100%;
  }
 }

 &__ribbon {
  position: relative;
  top: auto;
  left: -8px;

  display: flex;
  align-items: center;

  width: calc(100% + 8px);
  min-height: var(--card-new-offer-ribbon-height);

  padding: 4px 16px 4px 23px;
  margin-left: -18px;

  background: $color-chart-8;

  &:before {
   position: absolute;
   z-index: -1;
   left: 0;
   top: 100%;
   content: "";

   @include triangle(10px 8px, var(--card-new-offer-ribbon-darken-color), inset-down);
  }

  > span {
   font-family: $font-family-secondary;
   font-weight: $font-weight-primary-bold;
   font-size: $font-size-14;
   line-height: 16px;
   color: $color-white;
   max-height: 32px; // line-height * 2
   text-overflow: ellipsis;
   overflow: hidden;
   white-space: normal;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
  }
 }

 &__title {
  margin-bottom: 8px;
  font-weight: $font-weight-primary-bold;
  font-size: $font-size-16;
  line-height: 24px;
  font-family: $font-family-primary;
  letter-spacing: -0.5px;
 }

 &__subtitle {
  margin-top: 16px;

  @include media(medium down) {
   margin-top: 0;
  }
 }

 &__info {
  padding: 16px 15px;
  position: relative;
  @include flex();
  @include flex-direction(column);
  flex-grow: 1;

  @include media(medium down) {
   width: 100%;
   padding: 8px 8px 8px 16px;
  }
 }

 &__permanency {
  font-family: $font-family-primary;
  font-weight: $font-weight-primary-bold;
  color: $color-primary;
 }

 &__price-later {
  font-family: $font-family-secondary;
  font-size: 11px;
  line-height: 12px;
  font-weight: bold;
  color: $color-font-primary;
  margin-bottom: 12px;
 }

 &__offer-price {
  @include flex();
  font-family: $font-family-primary;

  &__price {
   font-weight: $font-weight-primary-bold;
   font-size: 54px;
   line-height: 54px;
   color: $color-cta-primary;
  }

  &__block {
   margin-left: 10px;
   @include flex();
   @include flex-direction(column);
   justify-content: center;

   &__top {
    @include flex();
    @include flex-align(center);
    margin-bottom: 2px;
   }
  }

  &__currency {
   font-weight: $font-weight-primary-bold;
   font-size: 24px;
   line-height: 24px;
   color: $color-cta-primary;
  }

  &__time {
   margin-left: 4px;
   font-size: 18px;
   line-height: 24px;
   color: $color-grey-400;
  }

  &__price-text {
   font-family: $font-family-secondary;
   font-weight: $font-weight-primary-bold;
   font-size: 10px;
   line-height: 12px;
   opacity: 0.25;
  }
 }

 &__icons {
  padding: 12px 0 0;
  min-height: 120px;
  @include flex();
  flex-direction: column;

  &__block {
   @include flex();
   align-items: center;
   margin-bottom: 18px;

   &:last-child {
    margin-bottom: 0;
   }
  }

  &__icon {
   position: relative;
   flex-shrink: 0;
   margin-right: 11px;
   width: 32px;

   & svg {
    width: 32px;
    height: 32px;
   }
  }

  &__copy {
   line-height: 18px;
   font-size: 16px;

   & span {
    font-weight: bold;
    color: $color-chart-8;
   }
  }
 }

 &__footer {
  display: flex;
  justify-content: center;
  flex-direction: column;

  padding: 16px 18px;
 }

 &__cta {
  width: 100%;
 }

 &__link {
  color: $color-cta-primary;
  text-align: center;
  font-size: $font-size-16 !important;
  font-weight: bold !important;
  padding-top: 16px;
 }

 &--black {
  background: $color-dark-grayish-navy;
 }

 &--red {
  background: $color-chart-7;

  & .button.c-card-new-offer__cta {
   background-color: $color-white;
   color: $color-chart-7;
  }
 }

 &--black,
 &--red {
  & .c-card-new-offer__footer {
   display: flex;
   justify-content: center;
  }

  & .c-card-new-offer__title {
   color: $color-white;
  }

  & .c-card-new-offer__subtitle {
   color: $color-white;
  }

  & .c-card-new-offer__cta {
   margin-top: auto;
  }
 }
}
