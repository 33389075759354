@import '../_variables/colors';

$wifi-empresas-cards-font-size: $font-size-21;
$wifi-empresas-cards-line-height: $line-height-for-font-size-23;

$section-header-font-size: $font-size-24;
$section-header-line-height: $line-height-for-font-size-26;

$card-price-font-size: 84px;
$card-price-height: 72px;

$card-price-euro-font-size: $font-size-42;
$card-price-euro-line-height: $line-height-for-font-size-46;

$card-price-monthly-font-size: $font-size-24;
$card-price-monthly-height: 13px;

$card-price-price-taxes-font-size: $font-size-18;
$card-price-price-taxes-line-height: $line-height-for-font-size-20;

$card-price-feature-font-size: $font-size-14;
$card-price-feature-line-height: $line-height-for-font-size-13;

.soluciones_wifi {
  .section-description {
    &__title {
      font-size: $section-header-font-size !important;
      line-height: $section-header-line-height;
    }
  }

  &__wifipro {
    .link-with-icon {
      .icon__glyph {
        stroke: $color-tundora;
      }
      &:hover {
        .icon__glyph {
          stroke: $color-pantone-red;
        }
      }
    }

    .section-header {
      width: 100%;
    }
    .section-header::after {
      background-color: $color-pantone-red;
    }

    &__list {
      @media screen and (min-width: 64em) {
        flex: 0 0 60% !important;
      }
      .section-description {
        &--centered {
          margin-bottom: 0;
        }

        &__title {
          font-size: $section-header-font-size !important;
          line-height: $section-header-line-height;
        }

        &__description {
          margin-top: 26px;
        }
      }

      .list-with-icon {
        margin-top: 24px;
      }
    }

    &__card {
      display: flex;
      justify-content: flex-end !important;
      flex: 100%;
      @media screen and (min-width: 64em) {
        flex: 0 0 40% !important;
      }
      .card {
        display: 'flex';
        flex: 0 0 100%;
        @media screen and (min-width: 64em) {
          flex: 0 0 38%;
        }

        &-product {
          &__price {
            .price-monthly-fee {
              .price-with-subject__integer-amount {
                font-size: $card-price-font-size;
                height: $card-price-height;
              }

              .price-with-subject {
                &__right {
                  justify-content: space-around;

                  .price-with-subject {
                    &__decimal-amount {
                      font-size: $card-price-euro-font-size;
                      line-height: $card-price-euro-line-height;
                    }

                    &__subject {
                      font-size: $card-price-monthly-font-size;
                      height: 13px;
                    }
                  }
                }
              }

              .price-taxes {
                font-size: $card-price-price-taxes-font-size;
                line-height: $card-price-price-taxes-line-height;
                color: $color-tundora;
              }
            }
          }
        }
      }
    }
  }

  &__wifiempresa {
    display: block;
    margin-top: 48px;

    &__container {
      @media (min-width: 64em) {
        display: flex;
        width: 100%;

        > :first-child {
          flex: 0 0 28%;
        }

        > :not(:first-child) {
          width: 64%;
          margin-left: 8%;
        }
      }

      .section-description {
        &--centered {
          margin-bottom: 0;
        }

        &__title {
          font-size: $section-header-font-size !important;
          line-height: $section-header-line-height;
        }

        &__description {
          margin-top: 26px;
          margin-bottom: 40px;
        }
      }
    }

    &__cards {
      .c-porQueR {
        padding-bottom: 0;
        padding-top: 0;

        .section-description {
          &--centered {
            margin-bottom: 0;
          }
          /* &__title {
            font-size: $wifi-empresas-title-font-size;
            line-height: $wifi-empresas-title-line-height;
          } */
          &__description {
            margin-top: 26px;
          }
        }

        .c-carousel {
          padding-top: 0;

          .swiper-slide {
            width: max-content !important;
          }
          .swiper-container {
            padding-left: 0;
            padding-top: 0;
          }
        }

        .c-porQueR__card {
          max-width: 255px;
          width: 100%;
        }

        .c-porQueR__content {
          padding: 48px 16px;
        }

        .c-porQueR__cardTitle {
          font-family: $font-family-primary;
          font-size: $wifi-empresas-cards-font-size;
          line-height: $wifi-empresas-cards-line-height;
          font-weight: 700;
          color: $color-tundora;
        }

        .c-porQueR__cardText {
          color: $color-tundora;
        }
      }
    }

    .u-wrapper {
      .c-carousel {
        .swiper-slide {
          width: max-content;
        }
        &__nav-controls {
          display: flex !important;
        }
      }
    }

    .soluciones_wifi-button {
      margin-top: 36px;

      @media screen and (min-width: 60em) {
        margin-top: 16px;
      }
    }
  }
}
