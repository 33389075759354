:root {
  --card-wifi-mesh-max-width: 262px;
  --card-wifi-mesh-min-height: 480px;
  --card-wifi-mesh-image-height: 180px;
  --card-wifi-mesh-ribbon-height: 32px;
  --card-wifi-mesh-ribbon-darken-color: #BC031A;

  @include media(medium down) {
     --card-wifi-mesh-min-height: 80px;
  }
 }

 .c-card-wifi-mesh {
  position: relative;
  @include flex();
  @include flex-direction(column);
  max-width: var(--card-wifi-mesh-max-width);
  min-height: var(--card-wifi-mesh-min-height);
  width: 100%;
  box-shadow: 4px 4px 20px rgba(38, 37, 37, 0.1);
  background-color: $color-white;
  height: 522px;

  @include media(medium down) {
   margin: 0 auto 8px;
   @include flex();
   /*@include flex-direction(row);*/
   max-width: 100%;
   min-height: var(--card-wifi-mesh-min-height);
  }

  &__featured {
   font-family: $font-family-secondary;
   font-style: normal;
   font-weight: bold;
   font-size: 14px;
   line-height: 21px;
   text-align: center;
    color: #fff;
    background-color: #79DB96;
  }

  &__content {
   @include flex();
   @include flex-direction(column);
   flex-grow: 1;
   /*position: absolute;
   bottom: 0;*/
  }

  &__image {
    // margin-top: 10px;
   //height: var(--card-wifi-mesh-image-height);
   overflow: hidden;
   padding: 0;

   @include media(medium down) {
    /*flex-shrink: 0;
    width: 14%;
    height: auto;*/
   }

   & img {
    object-fit: cover;
    object-position: center;
    width: 95%;
    margin: 10px;
    // height: 100%;
   }
  }

  &__ribbon {
   position: absolute;
   top: calc(var(--card-wifi-mesh-image-height) - var(--card-wifi-mesh-ribbon-height));
   left: -8px;

   display: none;
   align-items: center;

   width: calc(100% + 8px);
   min-height: var(--card-wifi-mesh-ribbon-height);

   padding: 4px 15px 4px 23px;

   font-family: $font-family-primary;
   font-weight: $font-weight-primary-bold;
   font-size: $font-size-16;
   line-height: $line-height-for-font-size-16;

   color: $color-white;
   background: $color-primary;

   @include media(medium up) {
    display: flex;
   }

   &:before {
    position: absolute;
    z-index: -1;
    left: 0;
    top: -8px;
    content: "";

    @include triangle(8px, var(--card-wifi-mesh-ribbon-darken-color), down-right);
   }

   > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
   }

   @include media(medium down) {
    display: none;
   }
  }

  &__title {
   margin-bottom: 0;
   font-weight: $font-weight-primary-bold;
   font-size: $font-size-16;
   line-height: 24px;
   font-family: $font-family-primary;
   letter-spacing: -0.5px;

   @include media(medium down) {
    font-size: 14px;
    line-height: 20px;
   }

   &--black {
   font-size: 18px;
   line-height: 24px;
   }
  }

  &__subtitle {
   margin-top: 0;
   color: #7F7F7F;
   line-height: 21px;
   font-size: 14px;
   @include media(medium down) {
    margin-top: 0;
   }

   &--black {
   font-size: 16px;
   line-height: 24px;
   }
  }

  &__highlight {
   font-size: 12px;
   color: #00A3E0;
   text-transform: uppercase;
   margin-top: 20px;
   @include media(medium down) {
   }
  }

  &__months {
   font-size: 12px;
   line-height: 14px;
   color: #1D1F2C;
   @include media(medium down) {
   }
  }

  &__sino {
   font-size: 12px;
   line-height: 14px;
   color: #BFBFBF;
   text-transform: uppercase;
   @include media(medium down) {
   }
  }

  &__opt-title {
  font-family: $font-family-secondary;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  color: #1D1F2C;
   @include media(medium down) {
   }
 }
  &__opt-text {
  font-family: $font-family-secondary;
  font-size: 12px;
  line-height: 14px;
  color: #1D1F2C;
   @include media(medium down) {
   }
 }

  &__info {
   padding: 8px 18px;
   position: relative;
   @include flex();
   @include flex-direction(column);
   flex-grow: 1;

   @include media(medium down) {
    width: 100%;
    padding: 8px 8px 8px 16px;
   }

   &__middle {
    @include media(medium down) {
     @include flex();
     @include flex-direction(column);
    //  justify-content: space-between;
    }
   }
  }

  &__inner:not(.c-card-wifi-mesh__inner--black) {
    border-bottom: 1px solid #F2F2F2;
    padding: 0 0 15px 0;
    margin: 0 0 15px 0;
  }

  &__offer-price {
   @include flex();
   font-family: $font-family-primary;

   @include media(medium down) {
    // justify-content: flex-end;
    width: 90px;
   }

   &__price {
    font-weight: $font-weight-primary-bold;
    font-size: 54px;
    line-height: 54px;

    @include media(medium down) {
     font-size: 32px;
     line-height: 32px;
    }
   }

   &__block {
    margin-left: 10px;
    @include flex();
    @include flex-direction(column);
    justify-content: center;

    @include media(medium down) {
     margin-left: 4px;
     justify-content: flex-start;
     flex-shrink: 0;
    }

    &__top {
     @include flex();
     @include flex-align(center);
     margin-bottom: 2px;

     @include media(medium down) {
      justify-content: flex-start;
      align-content: center;
     }
    }
   }

   &__currency {
    font-weight: $font-weight-primary-bold;
    font-size: 24px;
    line-height: 24px;

    @include media(medium down) {
     font-size: 14px;
     line-height: 14px;
    }
   }

   &__time {
    margin-left: 4px;
    font-size: 18px;
    line-height: 24px;
    color: $color-grey-400;

    @include media(medium down) {
     font-size: 12px;
     line-height: 14px;
    }
   }

   &__price-text {
    font-family: $font-family-secondary;
    font-weight: $font-weight-primary-bold;
    font-size: 10px;
    line-height: 12px;
    opacity: 0.25;

    @include media(medium down) {
     font-size: 10px;
     line-height: 12px;
    }
   }
  }

  &__icons {
   padding: 12px 0 28px;
   min-height: 120px;
   @include flex();
   flex-direction: column;

   @include media(medium down) {
    width: 80%;
    padding-bottom: 0;
    min-height: auto;
    justify-content: flex-start;
    margin-right: 12px;
   }

   &__block {
    @include flex();
    align-items: center;
    margin-bottom: 18px;

    &:last-child {
     margin-bottom: 0;
    }

    @include media(medium down) {
     margin-bottom: 12px;
    }
   }

   &__icon {
    position: relative;
    flex-shrink: 0;
    margin-right: 11px;
    width: 32px;

    @include media(medium down) {
     margin-right: 4px;
     height: 24px;
    }
   }

   &__copy {
    line-height: 24px;
    font-size: 16px;

    @include media(medium down) {
     line-height: 16px;
     font-size: 12px;
    }
   }
  }

  &__footer {
   display: flex;
   justify-content: center;
    padding: 16px 18px;
  }

  &--black {
   background: $color-black;

   & .c-card-wifi-mesh__footer {
    display: flex;
    justify-content: center;

    @include media(medium down) {
     justify-content: flex-start;
     padding: 0 8px 8px 16px;
    }
   }

   & .c-card-wifi-mesh__title {
    color: $color-white;

    @include media(medium down) {
     margin-top: 0;
     font-size: 14px;
    }
   }

   & .c-card-wifi-mesh__subtitle {
    color: $color-white;

    @include media(medium down) {
     font-size: 12px;
    }
   }

   & .c-card-wifi-mesh__cta {
    margin-top: auto;

    @include media(medium down) {
     display: block;
     color: $color-primary;
     margin-top: 8px;
     background: transparent;
     border: 0;
     min-height: auto;
     min-width: auto;
     padding: 0;

     & .button__content {
      justify-content: flex-start;
      font-size: 12px;
      text-align: left;
     }
    }
   }
  }
 }
