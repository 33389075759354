@import './../_mixins/base-html-elements';
@import './../_mixins/grid';
@import './../_variables/colors';
@import './../../../node_modules/lin3s-front-foundation/dist/scss/_mixins/aspect-ratio';
$font-size-header-intro: $font-size-14;
$line-height-header-intro: $line-height-for-font-size-14;

$font-size-header-title: $font-size-24;
$line-height-header-title: $line-height-for-font-size-26;

$font-size-description: $font-size-16;
$line-height-description: $line-height-for-font-size-18;

$card-title-font-size: $font-size-18;
$card-title-line-height: $line-height-for-font-size-18;

.list_with_big_companies_cards {
  .horizontal-scroller:first-child {
    padding-top: 0 !important;
  }

  .section__column {
    margin-top: 30px;
    margin-bottom: 30px;
    &-content {
      @media screen and (min-width: 64em) {
        display: flex;
      }

      .big_companies_header {
        @media screen and (min-width: 48em) {
          flex: 0 0 100%;
        }

        @media screen and (min-width: 64em) {
          flex: 0 0 33%;
        }

        .section-header {
          font-size: $font-size-header-intro;
          line-height: $line-height-header-intro;
        }

        .section-header::after {
          background-color: $color-pantone-red;
        }

        .section-description__title {
          font-size: $font-size-header-title !important;
          line-height: $line-height-header-title;
        }

        .section-description__description {
          font-size: $font-size-description;
          line-height: $line-height-description;
          margin-top: 24px;
        }
      }
    }

    .swiper-wrapper {
      @media screen and (min-width: 64em) {
        justify-content: flex-end;
      }
    }

    .horizontal-scroller {
      &__item {
        flex: 0 0 55%;

        padding-right: 0.9375rem;
        padding-left: 0.9375rem;

        @media screen and (min-width: 35.5em) {
          flex: 0 0 45%;
        }

        @media screen and (min-width: 48em) {
          flex: 0 0 52%;
        }

        @media screen and (min-width: 64em) {
          flex: 0 0 25%;
          min-width: none;
        }
      }

      &__scroll-bar-wrapper {
        @media screen and (min-width: 64em) {
          display: none;
        }
      }
    }

    .swiper-container-horizontal {
      @media screen and (min-width: 64em) {
        cursor: auto !important;
      }
    }
  }
}
