
@import './../_mixins/base-html-elements';
@import '../_definitions/animations';
@import '../_variables/colors';
@import '../_variables/transition';

.channels-filtros {
	background-color: #F6F6F6;
	padding: 0 0 30px 0;
}

.channels__row {
	margin-top: 24px;
	margin-bottom: 14px;

	@media (max-width: 760px){
		margin: 0;
	}

	&__list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		@media (max-width: 760px){
			/*display: none;*/
		}

		@media (max-width: 385px){
			justify-content: space-between;
		}

		&--hidden {
			@media (max-width: 760px){
				display: none;
			}
		}
	}

	&__title {
		font-size: 1.1rem;
		line-height: 1.5rem;
		margin-bottom: 14px;
		font-family: $font-family-primary;
		font-weight: 700;
		border-bottom: 1px solid #BFBFBF;

		@media (max-width: 760px){
			border-bottom: 1px solid #F2F2F2;
			padding: 15px;
			cursor: pointer;
			margin: 0;
			display: flex;
			justify-content: space-between;
		}

		svg {

			display: none;
			pointer-events: none;

			@media (max-width: 760px){
				display: block;
			}

		}
	}
}

.channels__grid {
	display: block;
	column-count: 4;
	column-gap: 32px;
	width: 100%;
	break-inside: avoid;

	.channels__row {
		display: inline-block;

		@media (max-width: 760px){
			display: block;
		}
	}

	@include media(large down) {
		column-count: 3;
	}
	@include media(medium down) {
		column-count: 2;
	}
	@include media(600px down) {
		column-count: 1;
	}
}

.channelsGrid {
	// padding-top: 152px;
	flex-direction: column;
}

.modal--channelsGrid {
	.channels__row__list {
		justify-content: space-between;
	}
}
