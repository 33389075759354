

@import './../_variables/colors';


$landing-title-text-align: center;

.landing-title {

  text-align: $landing-title-text-align;

  padding-left: 16px;
  padding-right: 16px;
  padding-top: 32px;
  margin-bottom: 0;
  max-width: initial;
  width: 100%;

  @include media(medium up) {
      padding-left: 32px;
      padding-right: 32px;
      padding-top: 48px;
      margin-bottom: 0;

      position: relative;
      bottom: -24px;
  }

  &__title {
    font-family: $font-family-primary;
    color: $color-primary;

    font-size: $font-size-28;
    line-height: $line-height-for-font-size-28;
    font-weight: $font-weight-primary-bold;

    @include media(large up) {

      font-size: $font-size-36;
      line-height: $line-height-for-font-size-36;

    }
  }

}