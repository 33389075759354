.c-fibramax {
  position: relative;

  &__hero {
    @include flexbox();
    @include flex-direction(column);
    box-sizing: border-box;
    height: auto;
    position: relative;
    width: 100%;
    padding: 20px 16px;
    background-color: #C22622;

    @include media(medium up) {
      padding: 0 16px;
      height: 400px;
    }

    @include media(xlarge up) {
      height: 510px;
    }

    &__overlay {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
      background: linear-gradient(89.82deg, rgba(0, 0, 0, 0.77) 3.83%, rgba(0, 0, 0, 0) 94.15%);
      display: none;
    }

    &__bg-image {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;

      & img {
        height: 100%;
        object-fit: cover;
        object-position: top;
        width: 100%;
      }
    }

    &__container {
      width: 100%;
      height: 100%;
      @include flexbox();
      @include flex-direction(column);
      @include align-items(center);
      @include justify-content(space-between);
      gap: 20px;

      @include media(medium up) {
        @include flex-direction(row);
      }
    }

    &__logo {
      max-width: 100%;

      & img {
        max-width: 100%;
      }

      @include media(medium up) {
        max-width: 50%;
      }
    }

    &__video {
      height: 80%;
      width: 80%;

      @include media(medium up) {
        width: 50%;
      }

      @include media(xlarge up) {
        margin-right: 40px;
      }
    }
  }

  &__menu {
    position: relative;
    height: 70px;
    border-bottom: 1px solid #E5E5E5;
    background-color: $color-white;

    &__container {
      height: 100%;
      @include flexbox();
      @include align-items(center);
      overflow-x: auto;

      @include media(medium up) {
        @include justify-content(space-between);
      }
    }

    &__item {
      position: relative;
      padding: 0 16px;
      font-family: $font-family-primary;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: $color-text;
      cursor: pointer;
      height: 100%;
      @include flexbox();
      @include align-items(center);
      white-space: nowrap;

      @include media(medium up) {
        font-size: 20px;
        line-height: 28px;
      }

      &:after {
        background-color: $color-secondary;
        bottom: 0;
        content: '';
        height: 2px;
        position: absolute;
        left: 0;
        width: 0;
        transition: width 0.4s $transition-ease-in-out;
      }

      &:hover {
        opacity: 1;

        &:after {
          transition: width $transition-duration-02s $transition-ease-in-out;
          width: 100%;
        }
      }
    }

    & u-wrapper {
      padding-left: 0;
      padding-right: 0;
    }

    &--fixed {
      z-index: 11;
      position: absolute;
      margin-top: var(--mundo-r-header-height);
      top: var(--mundo-r-header-height);
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &__carrusel {
    padding-top: 48px;
    padding-bottom: 48px;

    @include media(medium up) {
      padding-top: 64px;
      padding-bottom: 64px;
    }

    & .c-carousel {
      padding-top: 0;
      margin-top: 40px;
    }

    & .section-description {
      padding-left: 16px;
      padding-right: 16px;
    }

    & .section-description__title {
      font-family: $font-family-primary;
      font-weight: 600;
      font-size: 21px !important;
      line-height: 24px;
      margin-bottom: 8px;

      @include media(medium up) {
        font-size: 28px !important;
        line-height: 34px;
      }
    }

    & .section-description__description {
      font-family: $font-family-secondary;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      max-width: 766px;
      margin: 0 auto;
    }

    &--que-es {
      .swiper-wrapper {
        @include media(medium up) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: 1fr;
          grid-column-gap: 30px;
          grid-row-gap: 30px;
          max-width: 1036px;
          margin: 0 auto;
        }
      }
    }
  }

  &__comparativa {
    padding: 48px 16px;

    @include media(medium up) {
      padding: 64px 16px;
    }

    &__content {
      margin-top: 50px;
    }

    & .section-description {
      padding-left: 16px;
      padding-right: 16px;
    }

    & .section-description__title {
      font-family: $font-family-primary;
      font-weight: 600;
      font-size: 21px !important;
      line-height: 24px;
      margin-bottom: 8px;

      @include media(medium up) {
        font-size: 28px !important;
        line-height: 34px;
      }
    }

    & .section-description__description {
      font-family: $font-family-secondary;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      max-width: 766px;
      margin: 0 auto;
    }

    & .o-table th {
      border: 0;
      font-family: $font-family-primary;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: $color-secondary;
      padding: 20px 20px;
      height: 100px;

      @include media(medium up) {
        font-size: 26px;
        line-height: 32px;
        padding: 24px 40px;
      }
    }

    & .o-table tr {
      border: 1px solid #e5e5e5;
    }

    & .o-table td {
      font-size: 12px;
      line-height: 16px;
      padding: 20px 20px;
      height: 100px;

      @include media(medium up) {
        font-size: 16px;
        line-height: 22px;
        padding: 24px 40px;
      }
    }

    .o-table tr td:first-child {
      font-weight: inherit;
    }

  }

  &__interesa {
    padding: 48px 16px;

    @include media(medium up) {
      padding: 64px 16px;
    }

    & .section-description {
      padding-left: 16px;
      padding-right: 16px;
    }

    & .section-description__title {
      font-family: $font-family-primary;
      font-weight: 600;
      font-size: 21px !important;
      line-height: 24px;
      margin-bottom: 8px;

      @include media(medium up) {
        font-size: 28px !important;
        line-height: 34px;
      }
    }

    & .section-description__description {
      font-family: $font-family-secondary;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      max-width: 766px;
      margin: 0 auto;
    }

    &__content {
      width: 100%;
      max-width: 942px;
      padding: 32px;
      background: $color-white;
      box-shadow: 4px 4px 20px #E5E5E5;
      border-radius: 16px;
      margin: 36px auto 0;
    }

    &__box {
      width: 100%;
      max-width: 744px;
      margin: 0 auto;
      @include flexbox();
      @include flex-direction(column);
      @include align-items(center);
      @include justify-content(center);
    }

    &__title {
      font-family: $font-family-primary;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 24px;
      text-align: center;
    }

    &__cta {
      width: 100%;
      max-width: 475px;
      min-width: 475px;
      margin-bottom: 24px;
    }

    &__copy {
      font-family: $font-family-primary;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      text-align: center;

      & a {
        font-family: $font-family-primary;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: $color-secondary;
      }
    }
  }


  & .section--retrofit-faq {
    background: $color-grey-100;
  }

  &__forms {

    &__content {
      @include flexbox();
      @include justify-content(space-between);
      @include flex-direction(column);
      gap: 20px;

      @include media(medium up) {
        @include flex-direction(row);
      }
    }

    &__item {
      position: relative;
      width: 100%;
      max-width: 100%;
      min-width: 100%;

      @include media(medium up) {
        max-width: 350px;
        min-width: 350px;
      }
    }

    &__input {
      border: 1px solid #999999;
      border-radius: 8px;
      font-family: $font-family-secondary;
      font-size: 16px;
      line-height: 22px;
      outline: none;
      padding: 10px;
      width: 100%;
      height: 44px;
    }

    &__cta {
      width: 100%;
      max-width: 100%;
      min-width: initial;

      @include media(large up) {
        max-width: 332px;
        min-width: 332px !important;
      }

      @include media(xlarge up) {
        max-width: 470px;
        min-width: 470px !important;
      }
    }

    & input.error {
      margin-top: 0;
      margin-bottom: 0;
      border: 1px solid red;
    }

    & label.error {
      position: absolute;
      left: 0;
      bottom: -18px;
      color: $color-secondary;
      font-size: 14px;
      font-weight: 600;
      font-family: $font-family-secondary;
    }
  }

  &__modal {
    &--dni {
      & .c-modal__legal {
        margin-top: 20px;
        font-size: 14px;
        line-height: 21px;

        & a {
          color: $color-secondary;
        }
      }
    }

    &--gracias {
      text-align: center;

      & .c-fibramax__modal__icon {
        & svg {
          width: 92px;
          height: 92px;
          margin-bottom: 38px;

          @include media(large up) {
            width: 128px;
            height: 128px;
          }
        }
      }

      & .c-modal__copy {
        font-family: $font-family-primary;
        font-weight: 700;
        font-size: 21px;
        line-height: 29px;

        @include media(medium up) {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }
}

#modal_fibramax_dni .c-modal__text {
  max-width: 100%;
}
