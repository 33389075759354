@import './../_variables/colors';
@import './../_variables/dimensions';

.c-kit-digital {
  width: 100%;
  .section-description {
    margin-bottom: 16px;

    .section-description__title {
      font-family: $font-family-primary !important;
      font-size: $font-size-18;
      line-height: $line-height-for-font-size-18;
      font-weight: 500;
      color: $color-primary;

      text-align: left;
    }
  }

  .cards-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
    grid-template-rows: 1fr;
    gap: 30px;

    @include media(xlarge up) {
      grid-template-rows: minmax(479px, auto);
      grid-column-gap: 30px;

    }

    .c-kit-digital-cards {
      background-color: $color-white;
      box-shadow: 4px 4px 20px rgba(38, 37, 37, 0.1);
      padding: 24px;
      padding-bottom: 16px;

      height: 100%;
      @include media(medium up){
        min-height: 523px;
      }


      .c-kit-digital__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        height: 100%;

        @include media(medium up) {
          justify-content: space-between;
        }
      }
      .card-title-wrapper {
        display: flex;

        margin-bottom: 8px;

        @include media(medium up) {
          display: block;
          margin-bottom: 16px;
        }

        > img {
          margin-right: 16px;
          width: 24px;
          height: 24px;

          @include media(medium up) {
            width: 40px;
            height: 40px;

            margin-bottom: 16px;
          }
        }

        .c-kit-digitalTitle {
          font-family: $font-family-primary;
          font-size: $font-size-16;
          line-height: $line-height-for-font-size-16;
          font-weight: 500;
          color: $color-primary;

          margin-bottom: 4px;

          @include media(medium up) {
            font-size: $font-size-18;
            line-height: $line-height-for-font-size-18;
          }
        }
        .c-kit-digitalSubTitle {
          // font-family: $font-name-avant-garde;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 5px;
          color:var(--color-grey-600);
        }

        .c-kit-digitalChip {
          display: inline;

          font-family: $font-family-secondary;
          font-size: $font-size-14;
          line-height: $line-height-for-font-size-14;
          font-weight: 700;

          border-radius: 2px;

          padding: 2px 8px;
          &.internet {
            color: #14ccaf;

            background-color: #14ccaf33;
          }

          &.negocio {
            color: #007cb2;

            background-color: #007cb233;
          }

          &.seguridad,
          &.seguridade {
            color: #950db4;

            background-color: #950db433;
          }
        }
      }

      .card-description {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        margin-bottom: 16px;

        @include media(xxlarge) {
          flex-basis: 100%;
        }

        .c-kit-digitalDescription {
          margin-bottom: 8px;

          font-family: $font-family-secondary;
          font-size: $font-size-14;
          line-height: $line-height-for-font-size-14;
        }
      }

      .card-actions {
        width: 100%;
        .c-kit-digitalMaxMoneyText {
          display: block;
          font-family: $font-family-secondary;
          font-size: $font-size-14;
          line-height: $line-height-for-font-size-14;
          font-weight: 700;
          color: #444;
          margin-bottom: 4px;

          @include media(medium up) {
            font-size: $font-size-16;
            line-height: $line-height-for-font-size-16;
          }
        }
        .c-kit-digitalMaxMoney {
          display: block;
          color: #444;
          font-family: $font-family-primary;
          font-size: .75rem;
          line-height: .875rem;
          font-weight: 400;

          @include media(medium up) {
            font-size: .875rem;
            line-height: 1.125rem;
          }

          strong {
            display: block;
            font-family: $font-family-primary;
            font-size: $font-size-18;
            line-height: $line-height-for-font-size-18;
            font-weight: 700;

            @include media(medium up) {
              font-size: $font-size-24;
              line-height: $line-height-for-font-size-24;
            }
          }
        }
        .c-kit-digitalPrice{
          margin: 15px 0 20px;
        }
        .button {
          width: 100%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-weight: 700;
        }
        .link {
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 700;

          width: 100%;
          margin-top: 22px;
          margin-bottom: 14px;
        }
      }
    }
  }

  &.c-kit-digital--kit-digital-section_help {
    .c-kit-digital-cards {
      box-shadow: 0 0 0;
      position: relative;
      min-height:auto;
      &::after {
        @include media(large up) {
          content: '';
          position: relative;
          top: -89%;
          right: 56%;

          display: block;

          width: 92%;
          height: 2px;
          background-color: #f4f4f4;
        }
        @include media(xlarge up) {
          top: -87%;
          width: 97%;
        }
      }
      &:first-child {
        &::after {
          display: none;
        }
      }
    }
    .cards-wrapper {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      gap: 0px;

      margin-top: 24px;

      @include media(large up) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
      }

      .c-kit-digital-cards {
        max-height: 100%;

        .card-title-wrapper {
          margin-bottom: 0;
          position: relative;
          z-index: 10;
        }

        .card-description {
          margin-bottom: 0;
        }
      }
    }

    .c-kit-digital__content {
      width: 100%;
      justify-content: flex-start !important;

      .card-title-wrapper {
        display: block;

        width: 100%;

        text-align: center;

        img {
          width: 80px;
          height: 80px;

          margin-right: 0;
          margin-bottom: 24px;
        }

        .card-title-text {
          font-family: $font-family-primary;
          font-size: $font-size-18;
          line-height: $line-height-for-font-size-18;

          .c-kit-digitalChip {
            display: none;
          }
        }
      }

      .c-kit-digitalTitle {
        margin-bottom: 8px;
      }
      .c-kit-digitalDescription {
        font-family: $font-family-secondary;
        font-size: $font-size-16 !important;
        line-height: $line-height-for-font-size-16 !important;
        text-align: center;
        margin-bottom: 40px;
      }
    }

    .digital-help-actions-wrapper {
      margin-top: 24px;
    }
  }
}
