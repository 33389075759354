:root {
  --mundo-r-menu-btn-height: 80px;
  --mundo-r-menu-switcher-height: 30px;
  --mundo-r-menu-quieres-contratar: 44px;
}

.c-mundo-r-menu {
  position: fixed;
  z-index: 999;
  width: 100%;
  left: 0;
  top: var(--mundo-r-header-height);
  padding: 0;

  height: calc(100vh - calc(100vh - 100%) - var(--mundo-r-header-height));

  overflow-y: initial;
  pointer-events: none;
  transform: scale(0.85);
  opacity: 0;
  transition: all 0.3s $transition-ease-in-out;

  &.show {
    pointer-events: auto;
    transform: scale(1);
    opacity: 1;
    transition: all 0.3s $transition-ease-in-out;
  }

  @include media(large up) {
    flex: 1;
    height: 100%;
    position: static;
    left: auto;
    top: auto;
    overflow-y: initial;
    opacity: 1;
    pointer-events: auto;
    transform: initial;
    z-index: var(--mundo-r-zindex-submenu-trick);
  }

  &__switcher {
    display: flex;
    justify-content: center;

    height: var(--euskaltel-header-switcher-height);
    background: $color-grey-200;
    position: relative;
    z-index: 999999999;

    height: 30px;
    background: $color-grey-200;
    position: relative;
    z-index: 999999999;

    @include media(large up) {
      display: none;
    }

    &__link {
      font-size: 14px;
      line-height: 30px;
      color: #333333;
      text-align: end;
      width: 100%;
      padding: 0 16px;

      @include media(medium) {
        @each $grid-row-config-key, $grid-row-config-value in $grid-row-configs {
          $breakpoint: map-get($grid-row-config-value, breakpoint);
          $row-width: map-get($grid-row-config-value, row-width);
          @include media($breakpoint) {
            width: $row-width;
            padding: 0;
          }
        }
      }
    }
  }


  &__container {
    height: calc(100% - var(--mundo-r-menu-switcher-height) - var(--mundo-r-menu-quieres-contratar));
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
    align-items: center;

    @include media(medium up) {
      height: calc(100% - var(--mundo-r-menu-switcher-height));
    }

    @include media(large up) {
      height: 100%;
      overflow: initial;
      align-items: stretch;
    }

    &--client {
      height: calc(100% - var(--mundo-r-menu-quieres-contratar));
      @include media(medium up) {
        height: 100%;
      }

      @include media(large up) {
        height: 100%;
      }
    }
  }

  &__shop-link {
    color: $color-white;
    padding: 0 16px;
    width: 100%;
    white-space: break-spaces;
    background-color: lighten($color-primary, 10%);
    height: 72px;

    display: flex;
    align-items: center;

    font-family: $font-family-primary;
    font-size: $font-size-14;
    font-weight: 400;
    letter-spacing: 0;
    line-height: $line-height-for-font-size-14;

    @each $grid-row-config-key, $grid-row-config-value in $grid-row-configs {
      $breakpoint: map-get($grid-row-config-value, breakpoint);
      $row-width: map-get($grid-row-config-value, row-width);
      @include media($breakpoint) {
        padding: 16px calc((100% - 736px)/2);
      }
    }
  }

  &__nav {
    display: flex;
    height: initial;
    flex-direction: column;
    justify-content: space-between;
    z-index: 100000;

    @include media(medium down) {
      width: 100%;
      padding: 0 16px;

      @each $grid-row-config-key, $grid-row-config-value in $grid-row-configs {
        $breakpoint: map-get($grid-row-config-value, breakpoint);
        $row-width: map-get($grid-row-config-value, row-width);
        @include media($breakpoint) {
          width: $row-width;
          padding: 0;
        }
      }
    }

    @include media(large up) {
      flex-direction: row;
      height: 100%;
      margin-left: 20px;
      margin-right: 20px;
    }
    @include media(xlarge up) {
      margin-left: 60px;
      margin-right: 60px;
    }
    @include media(xxlarge up) {
      margin-left: 120px;
      margin-right: 120px;
    }
  }
  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $color-white;
    left: 0;
    top: 0;
    z-index: 99999;
    pointer-events: none;

    @include media(large up) {
      background-color: $color-primary;
    }
  }

  &__item {
    align-items: center;
    color: $color-font-primary;
    border-bottom: 1px solid $color-grey-300;
    font-family: $font-family-secondary;
    font-size: $font-size-12;
    font-weight: 400;
    letter-spacing: 0;
    line-height: $line-height-for-font-size-12;
    min-height: 45px;
    height: initial;
    display: inline-flex;
    padding: 6px 0;
    position: relative;
    white-space: inherit;
    z-index: 99999999999999;

    @include media(large up) {
      font-size: $font-size-10;
      border-bottom: 0;
      height: 100%;
      color: $color-white;
      padding: 0;
      display: flex;
      align-items: center;
      margin-right: 20px;

      &:after {
        background-color: $color-chart-7;
        bottom: 0;
        content: '';
        height: 2px;
        position: absolute;
        left: 0;
        width: 0;
        transition: width 0.4s $transition-ease-in-out;
      }
    }

    @include media(xlarge up) {
      font-size: $font-size-12;
      color: $color-white;
    }

    strong {
      display: block;
      font-size: $font-size-14;
      line-height: $line-height-for-font-size-14;
      font-family: $font-family-primary;
      font-weight: bold;

      @include media(large up) {
        font-size: $font-size-12;
      } 

      @include media(xlarge up) {
        font-size: $font-size-14;
      }
    }

    &:first-of-type {
     padding: 6px 0;

      @include media(large up) {
        padding: 0;
      }
    }

    &:last-of-type {
      margin-right: 0;
    }

    &:hover {
      opacity: 1;

      &:after {
        transition: width $transition-duration-02s $transition-ease-in-out;
        width: 100%;
      }
    }

    &.active {
      &:after {
        width: 100%;
      }
    }

    &__arrow {
      width: 16px;
      margin-left: auto;

      @include media(large up) {
        display: none;
      }
    }
  }

  &__submenu-group {
    display: flex;
    background-color: $color-white;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    transform: translateX(115%);
    transition: all $transition-duration-02s $transition-ease-in-out;
    width: 100%;
    z-index: 999999999999;

    @include media(large up) {
      bottom: calc((-1 * var(--mundo-r-header-submenu-height)));
      box-shadow: none;
      height: var(--mundo-r-header-submenu-height);
      top: auto;
      left: 0;
      right: auto;
      transform: translateY(-100%);
      pointer-events: none;
      width: 100%;
      z-index: var(--mundo-r-zindex-submenu);
    }
    &:before {
      @include media(large up) {
        background-color: inherit;
        content: '';
        height: 100%;
        left: -100%;
        position: absolute;
        width: 100%;
      }
    }

    &.show {
      transform: translateX(0%);
      transition: all $transition-duration-02s $transition-ease-in-out;
      pointer-events: auto;

      @include media(large up) {
        box-shadow: 4px 4px 25px rgba(#262525, 0.1);
        transform: translateY(0);

      }
    }
  }

  &__submenu-trick {
    background-color: $color-white;
    display: block;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    height: 100%;
    transition: $transition-opacity;
    z-index: 102;

    @include media(large up) {
      background-color: $color-primary;
      bottom: 0;
      top: auto;
      left: auto;
      right: auto;
      height: 52px;
      opacity: 1;
      z-index: var(--mundo-r-zindex-submenu-trick);
    }

    &.show {
      opacity: 0.6;
      pointer-events: auto;
      transition: $transition-opacity;
    }
  }

  &__submenu-container {
    visibility: hidden;
    height: 100%;
    justify-content: center;
    width: 100%;
    display: none;
    padding: 0 16px;

    @include media(medium up) {
      position: absolute;
      padding: 0;
      display: flex;
    }
    @include media(large up) {
      align-items: center;
    }

    &.show {
      visibility: visible;
      display: flex;
    }
  }

  &__submenu {
    &__item {
      align-items: center;
      border-bottom: 1px solid $color-grey-300;
      color: $color-font-primary;
      font-family: $font-family-primary;
      font-size: $font-size-em-14;
      font-weight: bold;
      line-height: $line-height-for-font-size-16;
      letter-spacing: 0;
      padding: 14px 0;
      transition: $transition-opacity;

      &:hover {
        opacity: 0.75;
        transition: $transition-opacity;
      }

      @include media(large up) {
        border-bottom: 0;
        font-family: $font-family-secondary;
        font-size: $font-size-14;
        font-weight: 300;
        letter-spacing: -0.5px;
        line-height: $line-height-for-font-size-14;
        margin-right: 50px;
        white-space: nowrap;
        display: inline-flex;
        padding: 8px;
        background-color: #fff;
        transition: background-color .2s ease-in-out;
        display: inline-block;
        padding-right: 0;
        padding-left: 0;
        height: 100%;
        border-bottom: 2px solid transparent;

        &::after {
          content: "";
          height: 2px;
          width: 100%;
          background-color: transparent;
          display: block;
          position: relative;
          top: 16px;
          transition: background-color .2s ease-in-out;
        }

        &:hover {
          &::after {
            background-color: $color-primary;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }

      &--back {
        display: inline-flex;

        svg {
          width: 20px;

          * {
            stroke: $color-font-primary;
          }
        }

        @include media(large up) {
          display: none;
        }
      }
    }

    &__main {
      display: flex;
      flex-direction: column;
      width: 100%;

      @include media(medium down) {
        @each $grid-row-config-key, $grid-row-config-value in $grid-row-configs {
          $breakpoint: map-get($grid-row-config-value, breakpoint);
          $row-width: map-get($grid-row-config-value, row-width);
          @include media($breakpoint) {
            width: $row-width;
            padding: 0;
          }
        }
      }
      @include media(medium up) {
        padding: 0;
      }
      @include media(large up) {
        display: block;
        width: initial;
      }
    }

    &__extra {
      display: none;

      @include media(large up) {
        display: block;
      }
    }
  }

  &__links-mobile {
    position: relative;
    display: flex;
    height: initial;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 32px;
    z-index: 999999999;
    margin-bottom: var(--cmn-header-height);
    width: 100%;

    @include media(medium up) {
      margin-bottom: 0;
    }
    @include media(large up) {
      display: none;
    }

    &__item {
      color: $color-white;
      border-bottom: 1px solid $color-grey-500;
      font-family: $font-family-secondary;
      font-size: $font-size-14;
      line-height: $line-height-for-font-size-14;
      padding: 14px 0;
      pointer-events: auto;

      &:last-of-type {
        border-bottom: 0;
      }
    }
  }

  &__language-selector {
    &__arrow {
      margin-left: 10px;
    }

    &.open {
      .c-mundo-r-menu__language-selector__arrow {
        transform: rotate(180deg);
      }
    }

    @include media(large up) {
      display: none;
    }
  }

  &__sections-menu {
    display: none;
    flex-direction: column;
    padding: 16px;
    position: absolute;
    background: $color-grey-600;
    width: 100%;
    left: 0;
    bottom: 48px;
    z-index: 999999999;

    &.show {
      display: flex;
    }

    &__item {
      color: $color-white;
      font-family: $font-family-secondary;
      font-size: $font-size-14;
      line-height: $line-height-for-font-size-14;
      padding: 8px 0;
    }

    @each $grid-row-config-key, $grid-row-config-value in $grid-row-configs {
      $breakpoint: map-get($grid-row-config-value, breakpoint);
      $row-width: map-get($grid-row-config-value, row-width);
      @include media($breakpoint) {
        padding: 16px calc((100% - #{$row-width})/2);
      }
    }
    
    @include media(large up) {
      display: none;
    }
  }

  &__language-menu {
    display: none;
    flex-direction: column;
    align-items: flex-end;
    padding: 16px 30px 16px 16px;
    position: absolute;
    background: $color-grey-600;
    width: 100%;
    right: 0;
    bottom: 48px;
    z-index: 999999999;

    &.show {
      display: flex;
    }

    &__item {
      color: $color-white;
      font-family: $font-family-secondary;
      font-size: $font-size-14;
      line-height: $line-height-for-font-size-14;
      padding: 8px 0;

      &--empresas {
        text-decoration: underline;
        color: $color-white;
        font-family: $font-family-secondary;
        font-size: $font-size-14;
        line-height: $line-height-for-font-size-14;
        padding: 8px 0;
      }
    }

    @each $grid-row-config-key, $grid-row-config-value in $grid-row-configs {
      $breakpoint: map-get($grid-row-config-value, breakpoint);
      $row-width: map-get($grid-row-config-value, row-width);
      @include media($breakpoint) {
        padding: 16px calc((100% - #{$row-width})/2);
      }
    }
    
    @include media(large up) {
      display: none;
    }
  }

  .c-mundo-r-header__call-us {
    display: flex;
    height: 72px;
    position: relative;
    z-index: 999999;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    padding: 0 16px;
    justify-content: flex-start;
    align-items: center;

    .c-mundo-r-header__content {
      font-size: $font-size-18;
      line-height: $line-height-for-font-size-18;
      max-width: 200px;
      flex-direction: row;
      flex-wrap: wrap;

      & br {
        display: none;
      }

      & strong:first-of-type {
        margin-left: 6px;
        display: inline;
      }
    }

    .c-mundo-r-header__call-us__icon {
      margin-right: 18px;
      margin-top: 0;
      width: 29px;
    }

    @include media(large up) {
      display: none;
    }
  }

  .c-mundo-r-header__your-area {
    display: flex;
    height: var(--mundo-r-menu-btn-height);
    justify-content: flex-start;
    position: relative;
    z-index: 999999;
    width: 100%;
    padding: 0 16px;

    .c-mundo-r-header__your-area__content {
      font-size: $font-size-14;
      line-height: $line-height-for-font-size-18;
      margin-left: 16px;
    }

    .c-mundo-r-header__your-area__arrow {
      margin-left: auto;
      width: 16px;
      height: 16px;
    }

    @each $grid-row-config-key, $grid-row-config-value in $grid-row-configs {
      $breakpoint: map-get($grid-row-config-value, breakpoint);
      $row-width: map-get($grid-row-config-value, row-width);
      @include media($breakpoint) {
        padding: 16px calc((100% - #{$row-width})/2);
      }
    }

    @include media(large up) {
      display: none;
    }
  }

  #header_mobile_internet_icon{
    height: 30px;
    display: inline-block;
    margin-right: 12px;
  }

  .c-mundo-r-header__bottom-links {
    position: relative;
    height: 48px;
    background: $color-grey-600;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media(medium down) {
      width: 100%;
      @each $grid-row-config-key, $grid-row-config-value in $grid-row-configs {
        $breakpoint: map-get($grid-row-config-value, breakpoint);
        $row-width: map-get($grid-row-config-value, row-width);
        @include media($breakpoint) {
          padding: 16px calc((100% - #{$row-width})/2);
        }
      }
    }

    &__element {
      cursor: pointer;
      height: inherit;
      display: flex;
      align-items: center;
    }

    &__arrow {
      width: 12px;
      margin-left: 8px;
      transition: all 0.3s $transition-ease-in-out;
      transform: rotate(180deg);

      &.open {
        transition: all 0.3s $transition-ease-in-out;
        transform: rotate(0deg);
      }
    }
  }
}


.mobile-ddi {
  height: 100%;
  margin-right: 55px;

  .ddi-icon {
    display: none;
    width: 26px;
    height: 100%;

    margin-right: 10px;

    @include media(medium up) {
      display: inline-block;
    }

    .icon__glyph--secondary {
      stroke: $color-grey-800;
    }
  }

  .ddi-phone-layout {
    height: 100%;
    display: inline-flex;
    vertical-align: top;
    align-items: center;

    .ddi-phone {
      padding: 12px 16px 10px;
      border-radius: 4px;

      color: $color-white;
      background-color: $color-primary;

      font-size: $font-size-14;
      font-weight: bold;

      @include media(medium up) {
        font-size: $font-size-18;
      }
    }
  }

  @include media(large up) {
    display: none;
  }
}
