@import "../_variables/colors";

$cmn-bg-color: #ef3340;

.custom-hero-slider {
  position: relative;

  &--tv-go {
    margin-bottom: 90px;
  }

  &__menu-content {
    max-width: 100%;
  }

  &__background {
    height: 100%;
    width: 100%;

    &__mask {
      position: absolute;
      top: 0;
      background-color: rgba(#333, 0.4);
      width: 100%;
      height: 100%;

      &--hidden {
        display: none;
      }
    }

    picture img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__container {
    height: 550px;
    display: flex;
    width: 100%;
    flex-direction: row;
    position: relative;
  }

  &__sliders-container {
    /*height: 100%;*/
    position: relative;
    flex: 1 0 100%;

    &--shadowbottom::after {
      bottom: 0;
      content: "";
      display: block;
      height: 100px;
      left: 0;
      pointer-events: none;
      position: absolute;
      width: 100%;
      z-index: 5;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), transparent);
    }
  }

  &__slider {
    //max-width: 1440px;
    width: 100vw;
    margin: 0 auto;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 24px;

    &--flex {
      display: flex;

      @media (max-width: 900px){
        flex-direction: column;
      }
    }

    &__banner-link {
      width: 100%;
      height: 470px;
      z-index: 10;
      display: block;
      position: absolute;
      top: 80px;
      left: 0;

      @media (min-width: 768px) {
        top: 0;
      }
    }

    &__content {
      width: 100%;
      height: 100%;
      max-width: 1440px;
      margin: 0 auto;
      padding-top: 64px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      @media (min-width: 768px) {
        padding-top: 55px;
      }

      &--with-floating-image {
        .custom-hero-slider__slider__tag,
        .custom-hero-slider__slider__title,
        .custom-hero-slider__slider__subtitle {
          margin-right: 140px;

          @include media(medium up) {
            margin-right: 280px;
          }
        }
      }
    }

    &__floating-image {
      max-width: 140px;
      position: absolute;
      top: 60px;
      right: 0;

      @include media(medium up) {
        max-width: 280px;
        top: 20px;
      }

      &--desktop-disabled {
        display: block;

        @include media(medium up) {
          display: none;
        }
      }

      &--mobile-disabled {
        display: none;

        @include media(medium up) {
          display: block;
        }
      }
    }

    &__multiple-boxes {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      &--mobile-disabled {
        display: none;

        @include media(medium up) {
          display: flex;
        }
      }

      &__item {
        width: 232px;
        height: 106px;
        background-color: #fff;
        text-align: center;
        margin-right: 40px;
        margin-bottom: 24px;
      }

      &__title {
        color: #000;
        margin-bottom: 8px;
        margin-top: 8px;
        font-weight: 700;
      }

      &__price {
        color: $color-primary;
        font-size: 56px;
        font-weight: 700;
        font-family: $font-family-primary;
      }

      &__euro {
        font-size: 24px;
      }

      &__text {
        color: #000;
        font-size: 12px;
      }
    }

    &__price-box {
      width: 387px;
      height: 154px;
      background-color: #fff;
      display: flex;
      flex-direction: row;
      max-width: 100%;

      &--mobile-disabled {
        display: none;

        @include media(medium up) {
          display: flex;
        }
      }

      &__feature {
        color: #000;
        font-weight: 700;
        display: inline-flex;
        align-items: center;
      }

      &__features {
        padding: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: calc(100% - 140px);

        .icon {
          max-width: 30px;
          height: 22px;
        }
      }

      &__price {
        background-color: $color-primary;
        height: 100%;
        width: 140px;
        padding: 28px 12px 0 14px;
        color: #fff;
      }

      &__length {
        font-family: $font-family-secondary;
        font-size: 14px;
        font-weight: 700;
      }

      &__no-promo {
        font-family: $font-family-secondary;
        font-size: 14px;
        padding: 4px;
        border-top: 1px solid #fff;
        text-align: center;
      }

      &__amount {
        font-family: $font-family-primary;
        font-size: 56px;
        letter-spacing: 0;
        line-height: 40px;
        font-weight: bold;
      }

      &__promo-price {
        padding-top: 4px;
        padding-bottom: 6px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 60px;
      }

      &__euro {
        font-size: 24px;
        font-family: $font-family-primary;
        font-weight: bold;
        line-height: 30px;
      }

      &--simple {
        height: auto;

        .custom-hero-slider__slider__price-box__price {
          background-color: #fff;
          padding: 18px 31px 17px 14px;
        }

        .custom-hero-slider__slider__price-box__feature {
          margin-bottom: 10px;
        }

        .custom-hero-slider__slider__price-box__promo-price {
          color: $color-primary;
        }

        .custom-hero-slider__slider__price-box__euro {
          padding-left: 5px;
          padding-top: 4px;
        }

        .custom-hero-slider__slider__price-box__month {
          color: #333333;
        }
      }
    }

    &__tag {
      background-color: rgb(152, 122, 217);
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
      text-transform: uppercase;
      display: inline-block;
      padding: 8px;
      font-weight: 700;
      margin-bottom: 24px;

      &--desktop-disabled {
        display: block;

        @include media(medium up) {
          display: none;
        }
      }

      &--mobile-disabled {
        display: none;

        @include media(medium up) {
          display: block;
        }
      }
    }

    &__title {
      font-size: 36px;
      line-height: 42px;
      font-family: $font-family-primary;
      font-weight: 700;
      margin-bottom: 16px;
      color: #fff;

      &--desktop-disabled {
        display: block;

        @include media(medium up) {
          display: none;
        }
      }

      &--mobile-disabled {
        display: none;

        @include media(medium up) {
          display: block;
        }
      }

      &--with-configurator {
        margin-right: 400px;
      }

      @media (min-width: 899px) and (max-width: 1024px) {
          max-width: 200px;
      }
    }

    &__subtitle {
      font-size: 24px;
      line-height: 30px;
      font-family: $font-family-primary;
      margin-bottom: 32px;
      color: #fff;

      &--desktop-disabled {
        display: block;

        @include media(medium up) {
          display: none;
        }
      }

      &--with-configurator {
        margin-right: 400px;
      }

      &--mobile-disabled {
        display: none;

        @include media(medium up) {
          display: block;
        }
      }
    }

    &__actions {
      margin-bottom: auto;
      margin-top: 24px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      @include media(medium up) {
        margin-bottom: 115px;
      }
    }

    &__button {
      width: 210px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-weight: bold;
      margin-bottom: 24px;

      &:nth-child(1) {
        margin-right: 40px;
      }

      &--action {
        background-color: $color-primary;
        color: #fff;

        &--mobile-disabled {
          display: none;

          @include media(medium up) {
            display: inline-flex;
          }
        }
      }

      &--cta {
        background-color: #fff;
        color: $color-primary;
        border: 1px solid $color-primary;

        &--mobile-disabled {
          display: none;

          @include media(medium up) {
            display: inline-flex;
          }
        }
      }
    }
  }

  &__menu-wrapper {
    width: calc(100% - 48px);
    max-width: 1440px;
    margin: 0 auto;
    position: absolute;
    top: 30px;
    bottom: auto;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    display: flex;
    z-index: 10;

    @media (min-width: 768px) {
      top: auto;
      bottom: 15px;
    }
  }

  &__menu {
    display: flex;
    flex-direction: row;
    margin: 0 -16px;
    padding: 0 16px;

    &__marker {
      position: absolute;
      left: 0;
      top: 0px;
      height: 3px;
      width: auto;
      background: $color-primary;
      //background: #EF3340;
      display: none;
      @include media(medium up) {
        display: block;
      }
    }
    :root {
      --hero-menu-color: #000;
    }
    &__item {
      width: 182px;
      // width: 235px;
      border-top: 3px solid #fff;
      color: #fff;
      font-size: 14px;
      line-height: 16px;
      cursor: pointer;
      height: 0;
      padding-top: 0;
      overflow: hidden;
      margin: 0 8px;
      white-space: nowrap;
      color: var(--hero-menu-color);

      @media (min-width: 768px) {
        border-top: 1px solid #fff;
        padding-top: 24px;
        height: auto;
        margin: 0;
      }

      &--active {
        border-top: 3px solid $color-primary;
        font-weight: bold;
        padding-top: 0;

        @media (min-width: 768px) {
          padding-top: 22px;
        }
      }

      &:hover {
        font-weight: bold;
        border-top: 3px solid #fff;
        padding-top: 22px;
      }

    }

  }
}

.custom-hero-slider[data-mode="fade"] .custom-hero-slider__container {
  display: block;

  .custom-hero-slider__sliders-container {
    position: absolute;
    width: 100%;
  }

  .custom-hero-slider__sliders-container:nth-child(1) {
    z-index: 2;
  }
  .custom-hero-slider__sliders-container:nth-child(2) {
    z-index: 1;
  }
  .custom-hero-slider__sliders-container:nth-child(3) {
    z-index: 1;
  }
  .custom-hero-slider__sliders-container:nth-child(4) {
    z-index: 1;
  }
}

/* SMALL */

.custom-hero-slider {
  &__container {
    &--small {
      height: auto;
      max-height: 350px;
      min-height: 280px;
    }

    &--small-at-mobile {
      height: 200px;

      @include media(medium up) {
        height: 550px;
      }
    }
  }
}

.custom-hero-slider__slider--small {
  .custom-hero-slider__slider__subtitle {
    margin-bottom: 15px;
  }

  .custom-hero-slider__slider__actions {
    margin-bottom: 40px;
    margin-top: 0;
  }
}

.hero__cmn {
  font-size: 1rem;
  background-color: $cmn-bg-color;
  border: 0;
  border-radius: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  outline: none;
  padding: 0 30px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  vertical-align: text-bottom;
  text-decoration: none;
}

.custom-hero-slider--tv-go .custom-hero-slider__menu__item{
  width: 235px;
}



.custom-hero-slider__sliders-container--venres-negro {
  &__disclamer {
    color:#fff;
    position: absolute;
    bottom: 30px;
    font-size: 14px;
    text-align: left;


    @media (min-width: 600px){
      bottom: 60px;
      left: 50%;
      transform: translateX(-50%);
      width: 600px;
    }
  }
}


.hero-series-pelis__one-box__disclaimer-image {
  height: 16px;
  width: auto;
}


.banner-cobertura-5g {

  &__image {
    background-image: url('#{$environment-local-url}/images/banners/cobertura5g/banner_cobertura_5g.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    flex: 1 0;
    margin: 0 0 0 -24px;

    @media (max-width: 900px){
      margin: 0 -24px;
    }
  }

  &__claim {
    position: relative;
    top: 208px;
    left: 400px;

    @media (max-width: 900px){

    }

    &__line {
      background-color:#EFE400;
      font-weight: 400;
      font-size: 28.9803px;
      line-height: 36px;
      font-family:'R Gerstner', sans-serif;
      display:inline-block;
      margin: 0 0 3px 0;
      padding: 0 4px;
      color: #000;
    }


    }

    &__featured {
      margin:0  -24px 0 0;
      background-color:#444;
      color:#EFE400;
      padding: 135px 50px 0 50px;

      @media (max-width: 900px){
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 -24px;
        padding: 24px 0;
      }

      img {
        display: block;
        margin: 0 0 20px 0;
      }

    }

}
