.c-card-porques-marca {
  position: relative;
  max-width: 312px;
  width: 100%;
  height: 100%;
  box-shadow: 4px 4px 20px rgba(38, 37, 37, 0.1);
  border-radius: 16px;
  background-color: $color-white;

  @include media(medium up) {
    max-width: 610px;
  }

  &__picture {
    width: 100%;

    > img {
      object-fit: contain;
      width: inherit;
    }
  }

  &__inner {
    height: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;

    @include media(large up) {
      flex-direction: row;
    }
  }

  &__icon {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background-color: #FAD7D9;
    padding: 16px 23px 16px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    @include media(large up) {
      justify-content: center;
      margin-right: 24px;
      width: 75px;
      flex-direction: row;
      border-top-left-radius: 16px;
      border-top-right-radius: 0;
      border-bottom-left-radius: 16px;
      padding: 0;
    }

    > img {
      width: 56px;
      height: 100%;

      @include media(large up) {
        width: 110px;
        height: 110px;
        padding-left: 16px;
      }
    }

    @include media(large up) {
      width: 180px;
      height: 100%;
    }
  }

  &__info {
    padding: 16px 23px 16px;

    @include media(large up) {
      padding: 16px 16px 16px 0;
    }
  }

  &__title {
    font-family: $font-family-secondary;
    font-size: 21px;
    font-weight: bold;
    line-height: 24px;
    color: var(--color-text-v21);
    @include media(medium up) {
      line-height: 31px;
    }
  }

  &__text {
    font-family: $font-family-secondary;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-text-v21);
  }

  &__separator {
    background-color: $color-secondary;
    border: none;
    height: 4px;
    margin: 8px 0;
    width: 32px;
  }
}
