@import '../_variables/colors';

$body-background-color: $color-white;

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
}

html.no-scroll,
html.no-scroll body {
  overflow: hidden !important;
}

html.no-scroll {
  overflow-y: scroll !important;
}

body {
  background-color: $body-background-color;
  font-family: $font-family-secondary;
  font-weight: $font-weight-secondary-regular;
  min-height: 100%;
  overflow-y: auto;
}

strong {
  font-weight: $font-weight-secondary-bold;
}

a {
  color: inherit;
}

// scss-lint:disable VendorPrefix
::-moz-selection,
::selection {
  background: $color-primary;
  color: $color-white;
}
// scss-lint:enable VendorPrefix

// Placeholders' ellipsis if not enough place
input[placeholder],
::-moz-placeholder,
input:-moz-placeholder {
  text-overflow: ellipsis;
}

table:not([class]) {
  box-shadow: 0 5px 30px -10px rgba($color-mine-shaft, .075);
  width: 100%;

  tr {
    border-bottom: 1px solid $color-mercury-lighter;
  }

  thead,
  th {
    background-color: $color-gallery-lighter;
    color: $color-mine-shaft;
    font-family: $font-family-primary;
    font-size: $font-size-16;
    font-weight: $font-weight-primary-semi-bold;
    line-height: $line-height-for-font-size-16;
    text-align: left;
  }
  th,
  td {
    border-left: 1px solid $color-mercury-lighter;
    border-right: 1px solid $color-mercury-lighter;
    display: table-cell;
    max-width: 0;
    padding: 12px 15px;
    text-align: center;
    vertical-align: middle;

    &:last-child {
      border-right: 1px solid $color-mercury-lighter;
    }
  }

  tr:nth-child(odd) {
    td {
      background-color: #f7f7f7;
    }
  }

}
