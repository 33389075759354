@import '../_variables/colors';

.segunda-vivienda-landing {


  .header-background {
    height: 400px;
    justify-content: center;
    @include media(medium up) {
      padding: 40px 100px;
    }
    .c-descriptivoHeader__text {
      font-weight: 500;
      @include media(medium up) {
        max-width: 500px;
      }
    }
  }

  .section-opciones {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    @include media(medium up) {
      margin-top: 50px;
    }

    h2 {
      font-family: $font-family-primary;
      font-size: 24px;
      line-height: 30px;
      font-weight: 700;
      color: $color-tundora;
      margin-bottom: 15px;
      @include media(medium up) {
        font-size: 21px;
        line-height: 27px;
        margin-bottom: 25px;
      }
    }

    &--buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      max-width: 670px;
      width: 100%;

      button {
        width: 50%;
        height: 150px;
        padding: 15px 30px;
        border: 1px solid $color-tundora;
        background-color: $color-white;
        color: $color-tundora;
        cursor: pointer;
        @include media(medium up) {
          height: 100px;
          padding: 10px 20px;
        }
        &:hover,
        &:focus,
        &.active {
          background-color: $color-tundora;
          color: $color-white;
        }
        p.strong {
          font-family: $font-family-primary;
          font-size: 21px;
          line-height: 27px;
          font-weight: 700;
        }
        p {
          font-size: 12px;
          line-height: auto;
          @include media(medium up) {
            font-size: 14px;
            line-height: 21px;
          }
        }
      }
      button:first-child {
        margin-right: 10px;
        @include media(medium up) {
          margin-bottom: 25px;
        }
      }


    }
  }

  .content-100mb {
    display: none;
    &.active {
      display: block;
    }
  }

  .content-4G {
    display: none;
    &.active {
      display: block;
    }
  }


  .c-descriptivoBody--marginTop {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
  }
  .c-descriptivoBody__left  {
    background-color: $color-tundora;
    color: $color-white;
    background-size: cover;
    @include media(medium up) {
      padding: 40px;
    }
      &__content {
        @include media(medium up) {
          max-width: 75%;
        }
      }

      .c-descriptivoBody__rightTitle {
        color: $color-white;
        font-size: 36px;
        line-height: 42px;
        display: flex;
        margin-top: 10px;
      }

      .c-descriptivoBody__left__content-list {
        margin-top: 20px;
      }

      .c-descriptivoBody__item {
        align-items: center;
        margin-bottom: 15px;
        @include media(medium up) {
          margin-bottom: 30px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        .c-descriptivoBody__icon {
          width: 70px;
          flex: 0 0 70px;
          padding: 10px;
          @include media(medium up) {
            padding: 0;
          }
        }
        .c-descriptivoBody__itemLine strong {
          font-family: $font-family-primary;
          font-size: 18px;
          line-height: 24px;
        }
        .c-descriptivoBody__itemLine p {
          font-size: 16px;
          line-height: 24px;
          @include media(medium up) {
            margin-left: 40px;
          }

        }
      }

  }


}