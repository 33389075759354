.section-fibra-movil-centralita{
  padding-bottom:60px;
  padding-top:32px;
  @include media(medium up){
    padding-bottom:108px;
    padding-top:40px;
  }
}
.fibra-movil-centralita-wrapper{
  color: var(--color-text-v21);
  padding-top: 40px;
  margin-bottom: 64px;
  @include media(medium up){
    margin-bottom: 64px;
  }
  .fibra-movil-centralita-title{
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    font-family: $font-family-primary;
    @include media(medium up){
      font-size: 50px;
      line-height: 75px;
    }
  }
  .fibra-movil-centralita-description{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: calc(var(--s-v21)* 4);
    @include media(medium up){
      max-width: 717px;
      font-size: 16px;
      line-height: 22px;
    }
  }
  .fibra-movil-centralita-main-list{
    list-style-type: none;
    @include media(medium up){

    }
    .main-list-item{

      &:not(:last-child){
        margin-bottom: calc(var(--s-v21)*3);
      }
      .main-list-item-title{
        display: flex;
        align-items: flex-start;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        @include media(medium up){
          font-size: 18px;
          line-height: 24px;
        }
        .main-list-item-title-icon{
          margin-right: calc(var(--s-v21)*2);
          @include media(medium up){
            margin-right: calc(var(--s-v21)*3);
          }
        }
        .main-list-item-title-text{

          @include media(medium up){

          }
        }
      }
      .main-list-item-inner-list{
        margin-top: var(--s-v21);
        list-style-type: none;
        // padding-left:36px;
        // @include media(medium up){
        //   padding-left:47px;
        // }
        .inner-list-item{
          display: flex;
          align-items: center;
          padding-left:36px;

          .inner-list-item-text{
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
          }
          .inner-list-item-circle{
            display: block;
            background-color:#ef3340;
            height: 6px;
            width: 6px;
            border-radius: 50%;
            margin-right:calc(var(--s-v21)*2);
          }
        }
      }
    }
  }
}
