.c-carrusel-ofertas {
  padding-top: 32px;
  padding-bottom: 0;

  @include media(medium up) {
    padding-top: 64px;
    padding-bottom: 22px;
  }

  &__info-text {
    font-family: $font-family-secondary;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    max-width: 1036px;
    margin: 0 auto;

    & span {
      color: $color-primary;
      font-weight: bold;
    }
  }

  & .c-carousel {
    padding-top: 0;
  }

  & .section-description {
    padding-left: 16px;
    padding-right: 16px;
  }

  & .section-description__title {
    font-family: $font-family-primary;
    font-weight: 600;
    font-size: 21px !important;
    line-height: 24px;

    @include media(medium up) {
      font-size: 24px !important;
      line-height: 30px;
    }
  }

  & .section-description__description {
    font-family: $font-family-secondary;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  &--landingsofertas {
    .swiper-wrapper {
      display: grid !important;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      margin: 0 auto;

      @include media(medium up) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 30px;
      }

      @include media(large up) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 30px;
      }
    }
  }

  &__card {
    position: relative;
    @include flex();
    @include flex-direction(column);
    align-items: flex-start;
    max-width: 100%;
    width: 100%;
    height: 100%;
    box-shadow: 4px 4px 20px rgba(38, 37, 37, 0.1);
    background-color: $color-white;
    padding: 16px;

    @include media(medium up) {
      max-width: 503px;
    }

    &__content {
      @include flex();
      flex-direction: row;
    }

    &__cardIcon {
      margin-right: 24px;
      height: 56px;
      flex-shrink: 0;
      width: 56px;
      background: rgba($color-primary, 0.1);
      @include flex();
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      & svg {
        height: 32px;
      }
    }

    &__card-picture {
      width: 100%;

      > img {
        object-fit: contain;
        width: inherit;
      }
    }

    &__cardTitle {
      font-size: 18px;
      line-height: 24px;
      font-weight: bold;
      font-family: $font-family-primary;
    }

    &__cardText {
      font-size: 16px;
      line-height: 24px;
      font-family: $font-family-secondary;
      margin-bottom: 24px;
    }

    &__cardSeparator {
      border: none;
      margin: 8px 0;
      width: 32px;
      height: 4px;
      background-color: $color-primary;
    }
  }

  & .c-carousel__btn-nav {
    display: none !important;
  }
}
