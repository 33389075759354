.c-roaming-rates {
  position: relative;
  margin-bottom: 28px;

  @include media(medium up) {
    margin-bottom: 40px;
  }

  &__container {
    padding: 26px 15px;
    background-color: #F6F6F6;

    @include media(medium up) {
      padding: 40px 60px;
    }
  }

  &__title {
    font-family: $font-family-primary;
    font-weight: 700;
    font-size: 28px;
    margin-bottom: 14px;

    @include media(medium up) {
      font-size: 36px;
    }
  }

  &__subtitle {
    margin-bottom: 16px;
    line-height: 24px;
  }

  &__label {
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 8px;
    font-family: $font-family-secondary;
  }

  &__zone-selector {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @include media(medium up) {
      flex-direction: row;
    }

    &__item {
      width: 300px;
      display: inline-flex;
      flex-direction: column;
      margin-right: 30px;
      margin-top: 16px;

      &:last-child {
        margin-top: 16px;
        align-items: flex-start;

        button {
          margin-top: auto;
        }
      }
    }

    &__button {
      background-color: $color-primary;
      color: #fff;
      border-radius:50px;
      padding: 16px 36px;
      width: auto;
      border: 0;
      font-weight: 700;
      font-size: 16px;
      font-family: $font-family-secondary;
      cursor: pointer;

      &:hover {
        opacity: .7;
      }
    }

    &__country-selector{
      height: 48px;
      border-radius: 0;
      border: 1px solid #bfbfbf;
      width: 300px;
      max-width: calc(100vw - 30px);
      padding-left: 16px;
    }
  }

  &__results {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    margin-top: 32px;

    &.u-wrapper {
      margin-right: 15px;
      margin-left: 15px;
      width: calc(100% - 30px);

      @include media(medium up) {
        margin-right: auto;
        margin-left: auto;
      }
    }



    &__title {
      padding: 16px 15px;
      font-family: $font-family-primary;
      font-weight: 700;
      border-bottom: 1px solid #ECECEC;

      @include media(medium up) {
        padding: 24px 60px;
      }

      .icon {
        width: 15px;
        vertical-align: top;
        margin: 0 10px;

        &__glyph {
          stroke: #000;
        }
      }
    }

    &__subtitle {
      font-family: $font-family-secondary;
      font-weight: 700;
      margin-bottom: 16px;
    }

    &__rates {
      padding: 16px 15px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      @include media(medium up) {
        padding: 24px 60px;
      }

      &__column {
        width: 100%;

        @include media(medium up) {
          width: 50%;
        }
      }
    }

    &__list__item {
      list-style: disc;
      margin-left: 18px;
      font-family: $font-family-secondary;
      margin-bottom: 18px;

      .precios__zonas {
        color: $color-primary;
      }
    }

    &__warning {
      background-color: #ECF2F6;
      padding: 16px 15px;

      @include media(medium up) {
        padding: 24px 60px;
      }

      p {
        font-family: $font-family-secondary;
        font-size: 14px;
        line-height: 21px;
      }

    }
  }
}
