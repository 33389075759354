.c-landing-configurador-desktop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 920px;
  z-index: 9999;
  background: $color-font-primary;
  box-shadow: 0 10px 30px rgba(38, 38, 38, 0.15);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  @include transition();

  &.open {
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
    @include transition();
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 12px 16px;
  }

  &__title {
    font-family: $font-family-primary;
    font-weight: bold;
    font-size: 21px;
    line-height: 27px;
    color: $color-white;
    margin-right: 16px;
  }

  &__destacado {
    font-family: $font-family-secondary;
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    color: $color-white;
    background: $color-lilac;
    text-transform: uppercase;
    text-align: center;
    padding: 4px 8px;
  }

  &__price-later {
    font-family: $font-family-secondary;
    font-size: 14px;
    line-height: 25px;
    color: $color-white;

    & span {
      font-family: $font-family-primary;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: $color-white;
    }
  }

  &__ctas {
    position: relative;

    & .button:first-child {
      margin-left: 16px;
      margin-right: 16px;
    }
  }

  &__cta-call {
    color: $color-white !important;
    border-color: $color-white !important;
  }
}
