.c-banners-ofertas {

  &__container {
    position: relative;
    width: 100%;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
      border-radius: 16px;
    }
  }


  &--netflix {
    & .c-banners-ofertas__container {
      height: 313px;
      display: flex;
      align-items: flex-end;

      @include media(medium up) {
        align-items: flex-start;
      }
    }

    & .c-banners-ofertas__content {
      position: relative;
      z-index: 1;
      padding: 16px;
      max-width: 228px;
      margin-bottom: 20px;
      background: rgba(0,0,0,0.2);

      @include media(medium up) {
        max-width: 400px;
        padding: 42px;
        margin-bottom: 0;
        background: none;
      }
    }

    & .c-banners-ofertas__title {
      position: relative;
      color: #ff0000;
      font-size: 18px;
      line-height: 18px;
      font-family: $font-family-primary;

      @include media(medium up) {
        font-size: 32px;
        line-height: 32px;
      }
    }

    & .c-banners-ofertas__description {
      position: relative;
      color: $color-white;
      font-size: 18px;
      line-height: 20px;
      font-family: $font-family-secondary;

      @include media(medium up) {
        font-size: 32px;
        line-height: 32px;
      }
    }

  }

  &--tvgo {
    & .c-banners-ofertas__container {
      height: 426px;
      display: flex;
    }

    & .c-banners-ofertas__content {
      position: relative;
      z-index: 1;
      padding: 32px;
      width: 100%;
      text-align: center;
    }

    & .c-banners-ofertas__title {
      position: relative;
      color: $color-white;
      font-size: 18px;
      line-height: 18px;
      font-family: $font-family-primary;

      @include media(medium up) {
        font-size: 22px;
        line-height: 38px;
      }
    }

    & .c-banners-ofertas__description {
      position: relative;
      color: $color-white;
      font-size: 24px;
      line-height: 28px;
      font-family: $font-family-secondary;
      font-weight: bold;

      @include media(medium up) {
        font-size: 28px;
        line-height: 38px;
      }
    }

  }
}
