:root {
    --card-offer-max-width: 300px;
    --card-offer-min-height: 480px;
    --card-offer-image-height: 134px;
    --card-offer-ribbon-height: 32px;
    --card-offer-ribbon-darken-color: #BC031A;
    --c-cards-icon-with-description-alt-btn-nav-size: 48px;

    @include media(medium down) {
     --card-offer-min-height: 80px;
    }
   }

   .c-porQueR {
     padding-top: 48px;
     padding-bottom: 110px;

     &--altBackground {
       background-color: $alt-background;
     }

     @include media(medium down) {
       padding-top: 29px;
     }

     .section-description {
       margin-bottom: 14px;
     }

     .section-description__title {
       font-size: 21px !important;
     }
     .section-description--centered {
       text-align: center;
     }

     &__content {
       padding: 48px 24px;
     }

     &__card {
       position: relative;
       @include flex();
       @include flex-direction(column);
       align-items: flex-start;
       max-width: var(--card-offer-max-width);
       width: 100%;
       height: 100%;
       box-shadow: 4px 4px 20px rgba(38, 37, 37, 0.1);
       background-color: $color-white;
     }

     &__cardIcon {
       margin-bottom: 16px;

       .icon__glyph {
         stroke: $color-primary;
       }
     }

     &__card-picture {
       width: 100%;

       > img {
         object-fit: contain;
         width: inherit;
       }
     }

     &__cardTitle {
       font-size: 18px;
       line-height: 24px;
       font-weight: bold;
       font-family: $font-family-primary;
     }

     &__cardText {
       font-size: 16px;
       line-height: 24px;
       font-family: $font-family-secondary;

       & a {
         color: $color-secondary;
       }
     }

     &__cardSeparator {
       border: none;
       margin: 16px 0;
       width: 32px;
       height: 4px;
       background-color: $color-secondary;
     }

     &--centered{
      .c-porQueR__card{
        .c-porQueR__content{
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
        }
      }
    }
}
.servicios-avanzados{
  .c-porQueR__content{
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .c-porQueR__cardIcon {
      margin-left: auto;
      margin-right: auto;
      svg {
        max-width: 100%;
      }
  }
  .c-porQueR__cardSeparator{
      margin-left: auto;
      margin-right: auto;
  }
  .c-porQueR__cardText{
      text-align: center;
  }
  /* IE 11 */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .c-carousel .swiper-slide{
      flex-shrink: 1;
      min-width: 270px;
      max-width: 100%;
      .c-porQueR--centered {
        .c-porQueR__card {
          max-width: 100%;
          .c-porQueR__content{
            max-width: 100%;
          }
        }
      }
      .c-porQueR__card {
        max-width: 100%;
        .c-porQueR__content{
          max-width: 100%;
          .c-porQueR__cardText{
            flex-wrap: wrap;
            max-width: 100%;
          }
        }
      }
    }
  }
}
