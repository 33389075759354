@import "./../_mixins/grid";
@import "./../_variables/colors";
@import "./../_variables/dimensions";
@import "./../../../node_modules/lin3s-front-foundation/dist/scss/_mixins/aspect-ratio";

.press-pages.press-home {
    .press--article {
        .press__content {
            @include column(12);
            padding-top: 80px;
        }
    }
}