section {
  &.banner-kit-digital {

    padding-top: calc(8px * 3);

    @include media(medium up) {
      padding-top: calc(8px * 5);
    }
  }
}

.c-banner-kit-digital {
  display: flex;
  justify-content: space-between;
  background-color: #292929;
  flex-direction: column;
  border-radius: calc(8px * 2);
  padding: calc(8px * 3);
  @include media(medium up) {
    flex-direction: row;
    align-items: center;
    min-height: 114px;
    padding: calc(8px * 4) calc(8px * 6);
  }
  .c-banner-kit-digital_logo-container {
    height: 32px;
    margin-bottom: calc(8px * 4);
    img {
      &.icon-img {
        width: 100%;
        height: 100%;
      }
    }
    @include media(medium up) {
      height: 50px;
      margin-bottom: 0;
    }
  }
  .c-banner-kit-digital_text {
    color: #fff;
    margin-bottom: calc(8px * 2);
    p {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      font-family: $font-family-primary;
      @include media(medium up) {
        font-size: 21px;
        line-height: 27px;
      }
    }
    @include media(medium up) {
      margin-bottom: 0;
    }
  }
  // .c-banner-kit-digital_actions-wrapper{
  //   a{
  //     display: block;
  //     min-width: 180px;
  //     background-color: transparent;
  //     border: 1px solid #fff;
  //     border-radius: 8px;
  //     color: #fff;
  //     height: calc(8px*5);
  //     padding: 0 26px;
  //   }
  // }
  .c-pymes-interested-button {
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 8px;
    color: #fff;
  }
}
