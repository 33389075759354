.c-banner-interior {
  position: relative;
  margin: 0 auto 32px;
  padding: 0;
  max-width: 1280px;


  &--nomargin {
    margin-bottom: 0;
  }

  &--full {
    max-width: none;
  }

  &--center {
    .c-banner-interior__top {
      padding-bottom: 0;
    }
  }

  &--bg-primary {
    background-color: $color-primary;
    &:before {
	    content: "";
	    display: block;
	    height: 10px;
	    width: 100%;
	    box-shadow: 4px 4px 20px rgba(37, 37, 37, 0.1);
	    position: absolute;
	    top: -10px;
	    left: 0;
		z-index: 2;
    }
  }

  &--compromiso-internet {
	margin-top: 72px;
	@include media(medium up) {
		margin-top: 100px;
	}
  }

  &__card {
	  width: 360px;
	  position: relative;
	  z-index: 2;
	  display: none;

      @include media(medium up) {
        display: block;
      }

      &--mobile {
	      display: block;
	      margin: 0 auto;
	      @include media(medium up) {
		      display: none;
	      }

	      &-wrapper {
		      display: block;
		      margin: -100px auto 40px auto;
		      @include media(medium up) {
			    display: none;
		      }
	      }
      }
  }

  &__container {
    position: relative;
    width: 100%;
  }

  &__top {
    position: relative;
    @include flex();
    align-items: flex-end;
    height: 200px;
    padding-left: 95px;
    padding-bottom: 32px;
    margin-bottom: 32px;

    > div > .c-banner-interior__subtitle {
      display: none;

      @include media(medium up) {
        display: inline-block;
      }

      &.c-banner-interior__subtitle--compromiso-internet {
	     display: inline-block;
      }
    }

    @include media(medium down) {
      height: 168px;
      padding-left: 16px;
      padding-right: 16px;
      margin-bottom: 24px;
      padding-bottom: 0;
    }

    &--compromiso-internet {
	   	justify-content: space-between;
	    align-items: flex-start;
	    @include media(medium up) {
	    	 align-items: center;
      	}
    }

    &--Height400 {
	  height: 347px !important;

      @include media(medium up) {
        height: 400px !important;
      }
    }
    &--specialHeight {

      @include media(medium up) {
        height: 312px;
        padding-left: 32px;
        padding-bottom: 0;
      }
    }

    &--nomargin {
	    margin-bottom: 0;
    }
  }


  &__content {
    position: relative;
    @include flex();
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    & img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background: #000000;
  }

  &__icon {
    height: 48px;
    margin-top: 8px;
  }

  &__title {
    position: relative;
    z-index: 1;
    font-family: $font-family-secondary;
    font-weight: $font-weight-primary-bold;
    font-size: 24px;
    line-height: 30px;
    color: $color-white;

    @include media(medium up) {
      font-size: 36px;
      line-height: 42px;
    }

    &--compromiso-internet {
	    font-family: $font-family-primary;
	    font-size: 36px;
	    line-height: 42px;
	    margin-bottom: 25px;
	    margin-top: 35px;
	    display: flex;
	    @include media(medium up) {
	    	font-size: 48px;
	    }

	    &:before {
		    content: "";
		    display: block;
		    width: 75px;
		    height: 75px;
		    @include media(medium up) {
		    	width: 88px;
		    	height: 88px;
		    }
		    background-image: url('#{$environment-local-url}/images/compromiso-internet-logo.svg');
		    background-repeat: no-repeat;
		    background-size: cover;
		    margin: 0 32px 0 0;
	    }

	    span{
	    	position: relative;
	    	display: block;

	    	&.c-banner-interior__title__sub {
		    	font-size: 24px;
		    	line-height: 30px;
	    	}
	    }
    }
  }

  &__subtitle {
    position: relative;
    z-index: 1;
    font-family: $font-family-primary;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $color-white;

    @include media(medium up) {
      font-family: $font-family-primary;
      font-size: 18px;
      line-height: 24px;
    }

    &--big {

	    font-size: 16px;
	    line-height: 24px;

	    @include media(medium up) {
		    font-size: 24px !important;
		    line-height: 30px !important;
		    font-weight: 300 !important;

		    strong {
			    font-weight: 300;
		    }
	    }
    }
  }

  &__bottom {
    position: relative;

    > p.c-banner-interior__subtitle {
      @include media(medium up) {
        display: none;
      }
    }

    @include media(large down) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__description-line1 {
    position: relative;
    font-family: $font-family-primary;
    font-weight: $font-weight-primary-bold;
    font-size: 24px;
    line-height: 30px;
    color: $color-black;
    margin-bottom: 18px;
  }

  &__description-line2 {
    margin-bottom: 40px;
  }

  &__description {
    width: 100%;


    & p {
      font-family: $font-family-secondary;
      font-size: 18px;
      line-height: 24px;

      @include media(medium down) {
        font-size: 16px;
      }
    }
  }

  &__cta {
    display: block;
    position: relative;
    font-family: $font-family-secondary;
    font-size: 16px;
    line-height: 24px;
    margin-top: 12px;
    margin-bottom: 12px;

    section {
      margin: 0;

      .legal-text-modal__content {
        padding: 0;
        justify-content: start;

        a {
          color: $color-primary;
          font-size: 18px;
        }
      }
    }
  }

  &--color-dark {
    .c-banner-interior__title,
    .c-banner-interior__subtitle,
    .c-banner-interior__description {
      color: $color-font-primary;
    }
  }
}