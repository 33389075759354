.c-lity-modal__spects {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -5px;
}

.c-lity-modal__spects__spect {
	@include media (medium down) {
		flex: 1;
		min-height: 140px;
	}
	flex: 0 0 calc(50% - 10px);
	min-height: 180px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
	margin: 0 5px 20px 5px;
	padding: 17px 9px 17px 30px;
	position: relative;
}

.c-lity-modal__spects__spect__title {
	font-weight: bold;
	margin: 0 0 15px 0;
}

.c-lity-modal__spects__spect p {
	margin: 0 0 8px 0;
}

.c-lity-modal__spects__spect svg {
	width: 15px;
	height: 15px;
	position: absolute;
	left: 10px;
	top: 17px;
}

#modal_conditions {
	@include media (medium up) {
		width: 500px;
	}
}

.c-banner-full-image__title--wifi-mesh,
.c-banner-full-image__description--wifi-mesh {
	color: #000;
}

.c-banner-full-image__title--wifi-mesh {
	margin: 0 0 16px 0;
}

.c-banner-full-image__description--wifi-mesh {
	margin: 0 0 24px 0;
}

.c-banner-full-image--wifi-mesh {
	.c-banner-full-image__top {
		margin-bottom: 0;
		height: 500px;

		@include media (medium up) {
			margin-bottom: 40px;
			height: 360px;
		}
	}

	.c-banner-full-image__content {
		@include media (medium down) {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			top: 40px;
			left: 0;
			position: absolute;
			width: 100%;
		}

		h1, p {
			@include media (medium down) {
				max-width: 100%;
				margin: 0 15px 15px 15px;
			}
		}

	}

}

.wifi-mesh-recommender {

	display: flex;
	flex-direction: column;

	&__survey__items {

		margin: 0 0 47px 0;

		&__item {

			&:not(:last-of-type){

				margin: 0 0 40px 0;

			}

			&__title {

				font-family: $font-family-primary;
				font-style: normal;
				font-weight: bold;
				font-size: 18px;
				line-height: 24px;
				letter-spacing: -0.5px;
				color: #1D1F2C;
				display: block;
				margin: 0 0 25px 0;

			}

			&__opts {

				&__opt {

					display: flex;

					&:not(:last-of-type) {
						margin: 0 0 25px 0;
					}

					input {
						margin: 0 8px 0 0;
					}

					// label {

					// }
				}
			}
		}
	}

	&__button {
		font-size: 16px;
		line-height: 24px;
		font-family: $font-family-secondary;
		font-weight: 700;
		align-self: center;
		display: flex;
    flex-direction: row;
    justify-content: center;
    width: 90%;
    margin: 0 auto;
	}
}



.wifi-mesh-recommender__results {
	display: flex;
	flex-direction: column;

	&__result {
		display: none;

		&--show {
			display: flex;
			flex-direction: column;
		}
	}

	&__description {
		margin: 0 0 40px 0;
		font-size: 16px;
		line-height: 24px;
		color: #7F7F7F;
	}

	&__picture-wrapper {
		position: relative;
		display: flex;
		justify-content: center;
		padding: 0 7% 7%;

		&__picture {
			align-self: center;
			margin: 0 0 70px 0;
		}

		&__caption {
			position: absolute;
			font-family: $font-family-primary;
			font-style: normal;
			font-weight: bold;
			font-size: 18px;
			line-height: 24px;
			color: #E10A0A;
			left: 255px;
			top: 45px;
		}
	}

	&__title,
	&__title--osoa {
		font-family: $font-family-secondary;
		text-transform: uppercase;
		font-size: 12px;
		line-height: 14px;
		margin: 0 0 8px 0;
	}

	&__title--osoa {
		color: #00A3E0;
	}

	&__price {
		display: flex;

		&__amount {
			font-family: $font-family-primary;
			font-weight: bold;
			font-size: 36px;
			line-height: 42px;
			color: #1D1F2C;
			margin: 0 7px 0 0;
		}

		&__monthly {
			display: flex;
			flex-direction: column;
		}

		&__month {
			font-family: $font-family-primary;
			font-size: 14px;
			line-height: 14px;
			color: #7F7F7F;
		}
	}

	&__small {
		font-family: $font-family-secondary;
		font-size: 12px;
		line-height: 14px;
		color: #1D1F2C;
	}

	&__wrapper {

		flex-direction: column;
		justify-content: flex-start;
		padding: 0;
		div + div {
			&:before {
				content: "";
				display: block;
				height: 1px;
				width: 80%;
				background-color: #F2F2F2;
				margin: 10px 0;
				z-index: 10;
			}
			&:last-child {
				margin-bottom: 30px;
			}
		}

		@include media (medium up) {
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			margin: 0 0 40px 0;
			position: relative;
			div + div {
				&:before {
					content: "";
					display: block;
					height: 100%;
					width: 1px;
					background-color: #F2F2F2;
					position: absolute;
					left: 54%;
					top: 0;
					z-index: 10;
					margin: 0;
				}

			}
		}

		&:last-child {
			@include media (medium down) {
				margin-left: 20px;
			}
		}

	}
}

.wifi-mesh-como-funciona {
	background-color:#FFF;
	padding: 40px 0;

	@include media(medium up) {
		padding: 40px;
	}

	.u-wrapper {
		display: flex;
		align-items: center;

		@include media(medium down) {
			flex-direction: column;
			padding: 0 15px;
		}
	}

	&__description {
		max-width: 100%;
		font-size: 16px;
		line-height: 24px;
		padding-bottom: 40px;

		@include media(large up) {
			padding-bottom: 0;
			max-width: 430px;
		}

	}

	&__img {
		display: block;
		width: 510px;
		height: auto;
	}
}


.wifi-mesh-banner-repetidor {
	background-color:#F6F6F6;
	padding: 40px 0;

	@include media(medium up) {
		padding: 40px;
	}

	.u-wrapper {
		display: flex;
		align-items: center;

		@include media(medium down) {
			flex-direction: column;
			padding: 0 15px;
		}
	}

	&__img {
		display: block;
		width: 510px;
		height: auto;
		// @include media (large up) {
		// 	width: 510px;
		// }
	}

	&__content {
		width: auto;
		margin-left: 0;

		@include media (medium up) {
			width: auto;
		}
		@include media (large up) {
			width: auto;
			margin-left: 70px;
		}
		@include media (xlarge up) {
			width: auto;
			margin-left: 140px;
		}
	}

	&__title {
		max-width: 100%;
		margin-bottom: 5px !important;
		font-family: $font-family-primary;
		font-size: 18px;
		line-height: 24px;
		text-align: center;
		font-weight: 700;
		@include media(medium up) {
			text-align: left;
			font-size: 21px;
   		line-height: 27px;
		}
	}

	&__description {
		max-width: 100%;
		font-family: $font-family-primary;
		font-size: 18px;
		line-height: 24px;
		padding-bottom: 24px;
		color: #7F7F7F;
		text-align: center;
		@include media(medium up) {
			text-align: left;
		}
	}

	.card-offer {
		display: flex;
		flex-direction: column;

		&__result {
			display: none;

			&--show {
				display: flex;
				flex-direction: column;
			}
		}


		&__description {
			margin: 0 0 20px 0;
			font-size: 16px;
			line-height: 24px;
			color: #7F7F7F;
		}

		&__picture-wrapper {
			position: relative;
			display: flex;
			justify-content: center;
			padding: 0 7% 7%;

			&__picture {
				align-self: center;
				margin: 0 0 10px 0;
			}

			&__caption {
				position: absolute;
				font-family: $font-family-primary;
				font-style: normal;
				font-weight: bold;
				font-size: 18px;
				line-height: 24px;
				color: #E10A0A;
				left: 255px;
				top: 45px;
			}
		}

		&__title,
		&__title--osoa {
			font-family: $font-family-secondary;
			text-transform: uppercase;
			font-size: 12px;
			line-height: 14px;
			margin: 0 0 5px 0;
		}

		&__title--osoa {
			color: #00A3E0;
		}

		&__price {
			display: flex;

			&__amount {
				font-family: $font-family-primary;
				font-weight: bold;
				font-size: 36px;
				line-height: 42px;
				color: #1D1F2C;
				margin: 0 7px 0 0;
			}

			&__monthly {
				display: flex;
				flex-direction: column;
			}

			&__month {
				font-family: $font-family-primary;
				font-size: 14px;
				line-height: 14px;
				color: #7F7F7F;
			}
		}

		&__small {
			font-family: $font-family-secondary;
			font-size: 12px;
			line-height: 14px;
			color: #1D1F2C;
			&.bold {
				font-weight: bold;
			}
		}

		&__wrapper {

			flex-direction: column;
			justify-content: flex-start;
			padding: 0;
			div + div {
				&:before {
					content: "";
					display: block;
					height: 1px;
					width: 80%;
					background-color: #F2F2F2;
					margin: 10px 0;
					z-index: 10;
				}
				&:last-child {
					margin-left: 80px;
					@include media (medium up ) {
						margin-left: 40px;
					}
				}
			}

			@include media (small up) {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				margin: 0 0 20px 0;
				position: relative;
				div + div {
					&:before {
						content: "";
						display: block;
						height: 100%;
						width: 1px;
						background-color: #F2F2F2;
						position: absolute;
						left: 53%;
						top: 0;
						z-index: 10;
						margin: 0;
					}

				}
			}

			&:last-child {
				margin-left: 80px;
				@include media (medium up ) {
					margin-left: 40px;
				}
			}

		}

	}


	&__botones {
		display: flex;
		flex-direction: column-reverse;
		@include media(medium up) {
			flex-direction: column;
		}
	}

	&__conditions {
		margin-top: 15px;
		text-align: center;
		@include media(medium up) {
			margin-top: 0;
			text-align: left;
		}
	}

	&__button {
		max-width: 100%;
		margin-top: 15px;
		@include media (medium up) {
			max-width: 300px;
		}
		@include media (large up) {
			max-width: 260px;
		}
	}

}


.wifi-mesh-banner-video {

		height: 400px;
		width: max-content;
		position: relative;

		@include media (medium up) {
			height: auto;
		}

		&__video {
			position: absolute;
			right: -40%;

			.small{
				display: block;
			}

			.medium, .large {
				display: none;
			}

			@include media (medium up) {
				height: auto;
				position: inherit;
				display: block;
				max-width: 130%;
				right: 15%;

				.medium{
					display: block;
				}

				.small, .large {
					display: none;
				}

			}
			@include media (large up) {
				position: inherit;
				display: block;
				max-width: 100%;
				right: 0;

				.small, .medium {
					display: none;
				}

			}
		}

		&__image {
			object-fit: cover;
			height: 100%;
			object-position: right center;

			@media screen and (min-width: 320px) and (max-width: 767px) {
				object-position: 75% center;
			}
		}


	.banner_interior {
		margin: 0 15px;
    top: 40px;
		max-width: 367px;
    position: absolute;
		@include media (medium up) {
			top: 30%;
			margin-left: 60px;
		}
		h1 {
			font-family: $font-family-primary;
			font-weight: 700;
			font-size: 24px;
			line-height: 30px;
			color: #444444;
			width: 70%;
			@include media (medium up) {
				font-size: 36px;
				line-height: 42px;
				width: 100%;
			}
		}
		p {
			font-family: $font-family-secondary;
			font-size: 16px;
			line-height: 24px;
			font-weight: 200;
			color: #444444;
			margin-top: 10px;
			@include media (medium up) {
				font-size: 18px;
			}
		}
	}
}

.c-card-home--wifi-mesh {

	.c-card-home__wrapper {
		align-items: center;
	}


@include media (medium up) {
	.c-card-home__title, .c-card-home__description {
    text-align: center;
	}
}
}

.wifi-mesh-landing {

	#que-es-wifi-mesh {
		.section-description__title {
			text-align: center;
			@include media (large up) {
				text-align: center;
				margin-top: 0;
			}
		}
	}

	.cards-icon-with-description {
		.cards-icon-with-description__container {
			margin-top: 25px;
			@include media (large up) {
				margin-top: 80px;
			}
		}

		.cards-icon-with-description__bottom {
			@include media (medium up) {
				margin-top: 15px;
			}
			@include media (large up) {
				margin-top: 0;
			}
		}
	}

	input[type="radio"] {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
			display: inline-block;
			width: 13px;
		height: 13px;
		padding: 1.4px;
			background-clip: content-box;
			border: 0.75px solid #bbbbbb;
			background-color:#fff;
			border-radius: 50%;
	}
	input[type="radio"]:after,
	input[type="radio"]:checked,
	input[type="radio"]:checked:after {
		background-color: #EF3340;
	}
	input[type="radio"]:focus {
		outline-width: 0;
	}


}

.wifi-mesh-como-instalarlo {
	display: flex;
	flex-direction: column;
	padding: 45px 15px;
	background-color: #f6f6f6;
	@include media (medium up) {
		padding: 60px 0;
	}

	> .section-description {
		display: flex;
		flex-direction: column;
		align-items: start;
		margin: 0 auto;
		width: auto;
		padding-bottom: 30px;
		@include media (medium up) {
			align-items: center;
		}
	}

	> .video-wm {
		margin: 0 auto;
		width: 100%;
		@include media (medium up) {
			width: 660px;
		}
	}

}
