.tvHome {
	width: 100%;

  .section--mas-de-la-tv {
	  max-width: 100%;
	  background-color: #F6F6F6;
	  margin-bottom: 0;
  }

.section--tv-highlight {
	background-color: #F6F6F6;
	padding-top: 32px;
	padding-bottom: 48px;
	max-width: 100%;
}

.section--tv-highlight {
	.section--tv {
		margin-top: 0;
	}
}

  .lity-channels {

	.channels__favourites__title {
		font-weight: bold;
		margin-bottom: 10px;
	}
	&__text{
		line-height: 1.5;
	}
  	&__info {
	  	color: #7F7F7F;
	  	margin: 0 0 0 10px;
	  	font-size: 14px;
	  	margin-bottom: 15px;
	  	display: flex;
	  	align-items: center;

	  	&:before {
		  	content: "";
		  	display: block;
		  	width: 20px;
		  	height: 20px;
			background-image: url('#{$environment-local-url}/images/icons/icon-info.svg');
		  	background-repeat: no-repeat;
		  	background-size: contain;
		  	margin: 0 5px 0 0;
	  	}
  	}

  	.accordion-item__content {
	  	padding: 0;
  	}

  	.list-item {
	  	padding-top: 15px;
	  	padding-bottom: 15px;
	  	font-family: $font-family-secondary;
	  	font-size: 16px;
  	}
  }
}
