.c-prefooter-light {
  background-color: $color-chart-7;
  height: auto;
  min-height: 128px;
  padding: 20px;

  @include media(medium up) {
    height: 128px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;

    @include media(medium up) {
      align-items: center;
      flex-direction: row;
    }
  }

  &__title {
    font-family: $font-family-primary;
    font-weight: $font-weight-primary-bold;
    font-size: 21px;
    line-height: 27px;
    margin-bottom: 10px;
    color: $color-white;

    @include media(medium up) {
      font-size: 24px;
      line-height: 27px;
      margin-bottom: 0;
      margin-right: 16px;
    }

    @include media(large up) {
      font-size: 28px;
      line-height: 38px;
      margin-right: 16px;
    }
  }

  &__subtitle {
    font-family: $font-family-secondary;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 24px;
    color: $color-white;

    @include media(medium up) {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 0;
      margin-right: 0;
    }

    @include media(large up) {
      font-size: 24px;
      line-height: 30px;
      margin-right: 34px;
    }

    &:empty {
      display: none;
    }

    @include media(medium up) {
      &:empty {
        display: initial;
      }
    }
  }

  &__phone {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    flex-wrap: wrap;

    @include media(medium up) {
      flex-wrap: nowrap;
    }

    &--desktop {
      display: none;

      @include media(medium up) {
        display: flex;
      }
    }

    &--mobile {
      display: flex;

      @include media(medium up) {
        display: none;
      }
    }
  }

  &__cta {
    height: 45px;
    display: flex;
    padding: 4px 12px !important;
    margin-right: 16px;
    border-radius: 5px;
    min-width: 154px;
    color: $color-chart-7;
    margin-bottom: 8px;

    @include media(medium up) {
      margin-bottom: 0;
    }

    .ic-phone-number {
      font-size: 16px;
      line-height: 24px;
      color: $color-chart-7;
    }

    .icon {
      margin-right: 8px;
      margin-left: 0;
      width: 16px;
      height: 16px;

      & path {
        stroke: $color-chart-7;
      }
    }
  }

  &__horario {
    font-size: 13px;
    line-height: 15px;
    color: $color-white;
    height: auto;
    padding: 8px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    display: flex;
    align-items: flex-start;
    flex-shrink: 0;
    margin-bottom: 8px;

    @include media(medium up) {
      margin-bottom: 0;
    }

    & svg {
      margin-top: 4px;
      margin-right: 8px;
    }

    &:empty {
      display: none;
    }
  }
}
