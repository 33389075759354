:root {
  --carousel-btn-nav-size: 48px;
}

.c-carousel {
  padding-top: 24px;

  @include media(medium up) {
    padding-top: 29px;
  }

  &--tarifas {
    padding-top: 0;
  }

  .swiper-slide {
    flex-shrink: 0;
    height: auto;

    // Así, evitamos establecer un width para desktop y pisar el width autocalculado de Swiper
    @include media (medium down) {
      width: initial;
    }
  }

  &__wrapper {
    position: relative;
  }

  &__nav-controls {
    position: relative;

    display: none;
    justify-content: center;
    align-content: center;
    align-items: center;

    margin-top: 32px;
    display: flex;

    @include media(medium up) {
      margin-top: 48px;
      display: none;
    }
  }

  &__btn-nav {
    display: flex;
    align-items: center;
    background-color: transparent;
    border-radius: 50%;
    cursor: pointer;
    justify-content: center;
    height: var(--carousel-btn-nav-size);
    position: absolute;
    right: -10px;
    // right: 0;
    top: calc(50% - (var(--carousel-btn-nav-size) / 2));
    transition: background-color .2s ease-in-out;
    width: var(--carousel-btn-nav-size);
    z-index: 10;
    // box-shadow: 4px 4px 25px rgba(38, 37, 37, 0.1);

    &.show {
      display: flex;
    }

    &.disabled {
      > svg > path {
        stroke: $color-grey-600;
      }
    }

    &.prev {
      left: 0;
      right: auto;
      top: calc(50% - (var(--carousel-btn-nav-size) / 2));

      @include media(medium up) {
        left: 90%;
      }
    }

    &.no-border {
      border: none;
    }
  }

  .swiper-container {
    padding: 16px;
    overflow: hidden;
  }

  .swiper-pagination-bullets {
    position: absolute;

    display: flex;
    justify-content: center;

    .swiper-pagination-bullet:not(:first-child) {
      margin-left: 16px;
    }

    .swiper-pagination-bullet {
      background: $color-grey-600;
    }

    .swiper-pagination-bullet-active {
      background: $color-pantone-red;
    }
  }
}
