.c-card-contact {
  box-shadow: 4px 4px 20px rgba(38,37,37,.1);
  background-color: $color-white;
  border: 1px solid $color-white-smoke;
  padding: 24px 21px 35px;

  &__row {
    &__title {
      font-size: $font-size-14;
      color: $color-gray;
      margin-bottom: 15px;
    }

    &__columns {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      &::-webkit-scrollbar {
        display: none;
      }

      &__item {
        position: relative;

        .link.c-card-contact__row__columns__item__link {
          display: flex;
          align-items: center;
          width: max-content;
        }

        &__icon {
          height: 15px;
          width: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 16px;

          & svg > * {
            stroke: $color-pantone-red !important;
          }
        }

        &__text {
          font-size: $font-size-14;
          line-height: $line-height-for-font-size-24;
          color: $color-mine-shaft;
          font-weight: $font-weight-primary-semi-bold;
        }

        &__description {
          position: absolute;
          font-size: $font-size-12;
          line-height: $line-height-for-font-size-16;
          color: $color-gray;
          bottom: -13px;
          left: 0;
          width: max-content;
        }
      }

      &__separator {
        margin-right: 64px;

        &--with-line {
          margin: 0 24px;
          height: 16px;
          border: none;
          border-right: 1px solid $color-gray;
        }
      }
    }

    &__line {
      border: none;
      border-top: 1px solid $color-silver;
      margin: 0;
      margin-bottom: 24px;
    }

    &:last-child {
      .c-card-contact__row__line {
        display: none;
      }

      .c-card-contact__row__columns {
        margin-bottom: 0;
      }
    }
  }
}
