.section-description--fiber-coverage-result {
  margin: 0;

  .section-description__subtitle {
    margin: 0;
  }

}
.section--fiber-coverage-result {
  display: none;
  padding-top: 65px;
  position: relative;
}

.fiber-coverage-result {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #F2F2F2;
  padding-bottom: 50px;
  margin-bottom: 25px;

  &__close {
    position: absolute;
    right: 0;
    top: 0;
  }

  &__label {
    font-weight: 700;
    font-size: 12px;
    line-height: calc(var(--s) * 2);
    background: #C9EFFE;
    border-radius: 4px;
    padding: calc(var(--s) / 2);
    color: #00A3E0;
  }

  &__card {
    overflow: hidden;
    box-shadow: 4px 4px 20px #E5E5E5;
    border-radius: 16px;
    width: 90%;
    max-width: 680px;
    display: flex;
    margin-top: 50px;
    margin-bottom: 30px;

    @media (max-width: 600px){
      flex-direction: column;
    }

    &__inner {
      padding: 20px 30px;
    }

    &__img {
      max-width: 200px;
      object-fit: cover;

      @media (max-width: 600px){
        max-width: none;
      }
    }

    &__items {
      &__item {

        display: flex;
        align-items: center;

        + li {
          margin-top: 18px;
        }

        &:last-of-type {
          margin-top: 25px;
          font-size: 14px;
          line-height: 18px;
          font-weight: 700;
        }

        &__icon {
          display: inline-flex;
          width: 24px;
          height: 24px;
          margin: 0 10px 0 0;
          flex: 0 0 24px;

          svg {
            width: 100%;
            height: 100%;
          }

          &--mobile {

            width: 20px;
            height: 20px;
            margin: 0 8px 0 0;
            flex: 0 0 20px;

            svg {
              path {
                color: #EF3340;
                stroke: #EF3340;
              }
            }
          }
        }
      }
    }
  }

  &__price {
    font-family: "R Gerstner", sans-serif;
    font-weight: 700;
    font-size: 38px;
    line-height: 56px;

    &__decimals {
      font-size: 24px;
      display: inline-flex;
      margin: 0 3px 0 0;
    }

    &__month {
      color: #999;
      font-family: 'Lato';
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__button {
    background-color: #EF3340;
    border-radius: 8px;
    color: #fff;
    text-align: center;
    font-weight: 700;
    padding: 14px 140px;
  }

}
