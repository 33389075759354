.c-modal-seguro-movil {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__subtitle {
    width: 100%;
    text-align: start;
    font-family: var(--font-family-secondary);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  &__description {
    margin-top: 16px;
    font-family: var(--font-family-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }

  &__qr {
    width: 137px;
    height: 138px;
    margin-top: 9px;
  }

  &__cancelar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 22px;
    border: 1px solid var(--color-primary);
    background-color: transparent;
    border-color: var(--color-primary);
    color: var(--color-primary);

    &:hover {
      color: var(--color-white);
    }
  }
}
