.section--tv {
  position: relative;
}

.c-mini-configurador {
  right: 0;
  box-shadow: 4px 4px 25px rgba(38, 37, 37, 0.2);
  background-color: #fff;
  z-index: 10;
  width: 100%;
  position: fixed;
  bottom: 0; 

  @include media(medium up) {
    position: relative;
    top:0;
    margin: 20px auto;
    width: 377px;
  }
  @include media(large up) {
    position: absolute;
    top: 113px;
    margin: 0;
    bottom: auto;
  }


  &__title {
    text-align: center;
    font-family: $font-family-primary;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -.5px;
    display: none;
    padding: 16px 10px;

    @include media(medium up) {
      display: block;
    }
  }

  &__buttonArray {
    display: none;
    flex-direction: row;
    flex-wrap: wrap;
    flex-basis: 1;

    @include media(medium up) {
      display: flex;
    }
  }

  &__button {
    padding: 10px 21px;
    color: $color-primary;
    border: 1px solid $color-primary;
    width: calc(50% - 5px);
    text-align: center;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    &:first-child {
      margin-right: 10px;
    }

    &__title {
      font-weight: bold;
      text-align: center;
      margin-bottom: 8px;
      font-family: $font-family-primary;
      font-size: 18px;
    }

    &__text {
      font-size: 14px;
      line-height: 16px;
      color: #000;
    }

    &--active {
      background-color: $color-primary;
      color: #fff;

      .c-mini-configurador__button__text {
        color: #fff;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    padding: 11px 21px;

    @include media(medium up) {
      padding: 0;
      padding: 0 21px 14px 21px;
    }

    &__button {
      color: #fff;
      background-color: $color-primary;
      border-radius: 50px;
      display: inline-flex;
      padding: 16px 60px;
      font-weight: bold;
      margin-left: auto;

      &:hover {
        cursor: pointer;
      }
    }
    
    &--yc > div {
	    width: 100%;
    }
    
  }

  &__call {
    border-top: 1px solid #ECECEC;
    font-weight: bold;
    color: $color-international-orange;
    padding: 16px;
    display: none;
    align-items: center;
    justify-content: flex-start;

    @include media(medium up) {
      display: flex;
    }

    &:hover {
      cursor: pointer;
    }

    svg {
      width: 22px;
      margin-left: 8px;
    }
  }

  &__price {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 46px;
    
    & ~ div {
	    flex: 0 0 50%;
    }

    &__number {
      font-family: $font-family-primary;
      color: $color-primary;
      font-size: 40px;
      line-height: 48px;
      font-weight: bold;
      height: 100%;
      display: block;
      margin-right: 5px;
    }

    &__euro {
      font-family: $font-family-primary;
      color: $color-primary;
      font-size: 16px;
      font-weight: bold;
      height: 50%;
      display: flex;
      align-items: flex-end;
    }

    &__month {
      font-family: $font-family-primary;
      color: #BFBFBF;
      font-size: 14px;
      line-height: 20px;
      height: 50%;
      display: block;
    }

  }

  &--fixed {
    position: fixed;
    bottom: 0;
    top:auto;
    width: 100%;
    z-index: 100;

    .c-mini-configurador__footer {
      width: 75%;
      display: inline-flex;
      padding: 14px 21px 14px 21px
    }
    .c-mini-configurador__title {
      width: 25%;
      display: inline-block;
      text-align: left;
      padding-left: 16px;
      vertical-align: super;
    }
  }
}

.tvHome {
	.c-mini-configurador {
		bottom: 54px;
		
		  @include media(medium up) {
			  bottom: auto;
		  }
	}
	
	.c-mini-configurador__footer--yc {
		display: none;
		  @include media(medium up) {
			  display: block;
		  }
	}
}