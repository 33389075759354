$link-color: $color-primary;
$link-hover: $color-primary-dark;
.header,
.footer,
.pre-header,
.pre-header-mobile,
.header-menu-mobile,
.header-submenu {
  .link {

    --link-color: #{$link-color};
    --link-hover: #{$link-hover};

    cursor: pointer;
    color: var(--link-color);

    svg {
      * {
        stroke: var(--stroke-color);
      }
    }

    &:active,
    &:focus,
    &:hover:not([disabled]) {
      --link-color: var(--link-hover);
    }

    &[disabled] {
      opacity: 0.25;
      pointer-events: none;
    }

  }

  .link__content {
    display: inline-flex;
    align-items: center;
  }

  .link__text {
    @extend .link-label-style;
  }

  .link.dark-mode {
    --link-color: #{$color-light};
    --link-hover: #{brighten($color-light, 75)};
  }

  .link.dark {
    --link-color: #{$color-dark};
    --link-hover: #{brighten($color-dark, 75)};
  }

  .link.secondary {
    --link-color: #{$color-secondary};
    --link-hover: #{$color-secondary-dark};
  }

  .link.tertiary {
    --link-color: #{$color-tertiary};
    --link-hover: #{$color-tertiary-dark};
  }
}
