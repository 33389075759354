
@import './../_mixins/base-html-elements';
@import '../_definitions/animations';
@import '../_variables/colors';
@import '../_variables/transition';


.channel {
	justify-content: center;
	display: flex;
	flex: 0 0 12.5%;
	padding: 0 10px;
	margin: 10px 0;

	@include media(large up) {
		flex: 0 0 12.5%;
		justify-content: center;
	}

	&__logo {
		position: relative;
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 8px;
		padding: 0;
		min-width: 35px;

		img {
			max-height: 40px;
			object-fit: scale-down;
			flex: 0 0 40px;
			margin-top: 0 !important;
			margin-bottom: 0 !important;
			mix-blend-mode: multiply;
		}

		&__caption {
			font-size: 12px;
			max-width: 85px;
			display: none;

				@include media(large up) {
					display: block;
				}
		}

		&__4k {
			display: flex;
			background-color: #000;
			border-radius: 24px;
			color: #fff;
			position: absolute;
			width: 1.5rem;
			height: 1.5rem;
			font-weight: 700;
			right: 1rem;
			bottom: .5rem;
			justify-content: center;
			align-items: center;
		}
	}

	&__infoModal {
		position: absolute;
		background-color: #fff;
		border-radius: 6px;
		box-shadow: 0px 0px 15px rgba(38, 37, 37, 0.2);
		padding: 24px 32px;
		left: -40px;
		top: 40px;
		z-index: 100;
		display: none;
		width: 225px;

		@include media(large up) {
			width: 275px;
			left: -80px;
		}

		@include media(xxxlarge up) {
			width: 375px;
			left: -120px;
		}

		&:before {
					content: "";
					display: block;
					position: absolute;
					background-color: #fff;
					border-radius: 6px;
					transform: rotate(45deg);
					height: 25px;
					width: 25px;
					top: -6px;
					left: 45px;

					@include media(large up) {
						left: 85px;
					}
					@include media(xxxlarge up) {
						left: 125px;
					}
		}

		&__title {
			font-weight: bold;
			font-size: 1.125rem;
			line-height: 1.5rem;
			font-family: $font-family-primary;
			margin-bottom: 8px;
			display: flex;
			flex-direction: row;
		}

		&__dial {
			font-weight: bold;
			font-size: 1rem;
			color: #7F7F7F;
			line-height: 1.5rem;
			margin-bottom: 8px;
		}

		&__description {
			font-size: 1rem;
			line-height: 1.5rem;
			margin-bottom: 8px;
		}

		&__functionalities {
			font-size: .75rem;
			color: $color-primary;
			line-height: .875rem;

			.icon{
				overflow: visible;
			}
		}

		.channel__functionalities__functionality {
			&:last-child {
				margin-right: 8px;
			}
		}
	}

	&__moreInfo {
		display: none;
		flex-direction: row;
		position: relative;


		&__modality {
			width: 8px;
			height: 8px;
			border-radius: 10px;
			margin: 8px 4px;

			&:last-child {
				margin-right: 8px;
			}

			&--TVOcio {
				background-color: #EF066B;
			}
			&--TVTotal {
				background-color: #FFBF2C;
			}
		}

		&__infoCircle {
			width: 16px;
			height: 16px;
			background-color: #BFBFBF;
			margin: 8px;
			margin-right: auto;
			border-radius: 25px;
			font-weight: 700;
			display: flex;
			justify-content: center;
			align-content: center;
			color: #fff;

			&:hover + .channel__infoModal {
				display: block;
			}
		}
	}

	&__functionalities {
		border-top: 1px solid #F2F2F2;
		display: flex;
		flex-direction: row;

		&__functionality {
			margin: 16px 5px;
			position: relative;
			width: 14px;
			height: auto;
			display: inline-block;

			&:last-child {
				margin-right: 16px;
			}

			&:hover {

				&:after {
					content: attr(data-functionality);
					display: block;
					position: absolute;
					background-color: #3F3F3F;
					width: 200px;
					height: auto;
					left: -24px;
					top: 24px;
					padding: 6px 12px;
					color: #fff;
					border-radius: 6px;
					text-align: center;
				}

				&:before {
					content: "";
					display: block;
					position: absolute;
					background-color: #3F3F3F;
					border-radius: 6px;
					transform: rotate(45deg);
					height: 25px;
					width: 25px;
					top: 21px;
					left: -6px;
				}
			}
		}

		&__dial {
			font-size: 1rem;
			color: #7F7F7F;
			font-weight: 700;
			margin: 16px;
			margin-right: auto;

		}

		.icon {
			width: 14px;
			height: auto;
			overflow: visible;

		}

		& .icon__glyph {
			stroke: $color-primary;
		}
	}
}

.modal--channelsGrid {
	.channel__infoModal {
		left: -58px;
		max-width: 260px;

		@include media(large up) {
			max-width: 375px;
		}

		&:before {
			left: 62px;
		}
	}

	.channel__functionalities__functionality:hover::after {
		left: -84px;
		z-index: 100;
	}
}


.mat-dialog-content {

	.channel {
		height: auto;
		box-shadow: none;
		margin-right: 0 !important;
	}

	.channel__moreInfo {
		display: none;
	}

	.channel__functionalities {
		display: none;
	}

}
