.c-carousel--destacados-tv {

	.swiper-container {
		padding: 16px 0;
	}

	&__btn-nav {
		width: 48px;
		height: 48px;
	}

	&__card {
		border-radius: 6px;
		overflow: hidden;
		position: relative;
		max-width: 240px;
	}
}

.section--tv-highlight .u-wrapper {
	max-width: 1240px;

	.swiper-slide:nth-of-type(2) img {
		left: -15px;
	}
}

.tv-featured-slider-card {

	min-height: 350px;
	cursor: pointer;

	&__image {
		display: block;
		width: 100%;
		min-height: 350px;

		img {
			position: absolute;
			height: 100%;
			max-width: none;
			top: 0;
			// left: -9px;
		}

		&--mask {
			&:before {
				content: "";
				position: absolute;
				width: 100%;
				height: 100%;
				z-index: 2;
				background-color: rgba(0,0,0,0.2);
			}
		}
	}

	&__content {
		position: absolute;
		left: 0;
		top: 0;
		color: #fff;
		padding: 14px;
		z-index: 3;
		height: 100%;
		width: 100%;
	}

	&__tag {
		padding: 4px 8px;
		font-size: 13px;
		background-color: #fff;
		color: #000;
		font-weight: bold;
		text-transform: uppercase;
		margin-bottom: 25px;
		display: inline-block;
	}

	&__title {
		font-size: 24px;
		line-height: 30px;
		font-weight: bold;
		font-family: $font-family-primary
	}

	&__button {
		position: absolute;
		left: 14px;
		bottom: 20px;
		display: flex;
		align-items: center;
		font-weight: bold;
		font-family: $font-family-secondary;
		cursor: pointer;
		white-space: nowrap;

		&__play {
			display: flex;
			align-items: center;

			&:before {
				content: "";
				display: block;
				width: 32px;
				height: 32px;
				background-image: url('#{$environment-local-url}/images/icons/icon-play-trailer.svg');
				background-repeat: no-repeat;
				background-size: cover;
				margin-right: 20px;
			}
		}
	}

}

.custom-hero-slider--tv-go .custom-hero-slider__menu__marker {
	width: 235px !important;
}
