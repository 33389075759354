.pymes-r-new-components {
    --s-v21: 8px;
    $grid-system-config: (
        "xxs": (
            "breakpoint": 0,
            "gutter": 16px,
            "cols": 4,
            "safe-area": 16px,
        ),
        "xs": (
            "breakpoint": 360px,
            "gutter": 16px,
            "cols": 4,
            "safe-area": 16px,
        ),
        "sm": (
            "breakpoint": 480px,
            "gutter": 24px,
            "cols": 6,
            "safe-area": 32px,
        ),
        "md": (
            "breakpoint": 768px,
            "gutter": 40px,
            "cols": 6,
            "safe-area": 48px,
        ),
        "lg": (
            "breakpoint": 1024px,
            "gutter": 24px,
            "cols": 12,
            "safe-area": 58px,
        ),
        "xl": (
            "breakpoint": 1280px,
            "gutter": 24px,
            "cols": 12,
            "safe-area": 80px,
        ),
        "xxl": (
            "breakpoint": 1440px,
            "gutter": 16px,
            "cols": 12,
            "safe-area": 96px,
        ),
        "xxxl": (
            "breakpoint": 1680px,
            "gutter": 16px,
            "cols": 12,
            "safe-area": 48px,
        ),
    );

    $breakpoints: map-keys($grid-system-config);

    .row-v21 {
        display: flex;
        flex-wrap: wrap;
        @each $br in $breakpoints {
            $br-width: map-get($grid-system-config, $br, "breakpoint");
            $br-gutter: map-get($grid-system-config, $br, "gutter");
            @if ($br-width == 0) {
                margin-left: $br-gutter * -0.5;
                margin-right: $br-gutter * -0.5;
            } @else {
                @media screen and (min-width: $br-width) {
                    margin-left: $br-gutter * -0.5;
                    margin-right: $br-gutter * -0.5;
                }
            }
        }
        > .col-v21 {
            @each $br in $breakpoints {
                $br-width: map-get($grid-system-config, $br, "breakpoint");
                $br-gutter: map-get($grid-system-config, $br, "gutter");
                @if ($br-width == 0) {
                    padding-left: $br-gutter * 0.5;
                    padding-right: $br-gutter * 0.5;
                } @else {
                    @media screen and (min-width: $br-width) {
                        padding-left: $br-gutter * 0.5;
                        padding-right: $br-gutter * 0.5;
                    }
                }
            }
        }

        // para composiciones con background
        &--wrapped {
            margin-left: 0;
            margin-right: 0;
        }
    }

    @each $br in $breakpoints {
        $br-cols: map-get($grid-system-config, $br, "cols");
        $br-width: map-get($grid-system-config, $br, "breakpoint");
        .col-v21 {
            @if (map-get($grid-system-config, $br, "breakpoint") == 0) {
                --max-cols: #{$br-cols};
            } @else {
                @media screen and (min-width: $br-width) {
                    --max-cols: #{$br-cols};
                }
            }
        }
        @for $cols from 1 through $br-cols {
            .col-v21[data-#{$br}="#{$cols}"] {
                $br-width: map-get($grid-system-config, $br, "breakpoint");
                @if ($br-width == 0) {
                    flex: 0 0 calc(#{$cols} * 100 / var(--max-cols) * 1%);
                } @else {
                    @media screen and (min-width: $br-width) {
                        flex: 0 0 calc(#{$cols} * 100 / var(--max-cols) * 1%);
                    }
                }
            }
            .col-v21[data-#{$br}o="#{$cols}"],
            .col-v21[data-#{$br}-offset="#{$cols}"] {
                $br-width: map-get($grid-system-config, $br, "breakpoint");
                @if ($br-width == 0) {
                    margin-left: calc(#{$cols} * 100 / var(--max-cols) * 1%);
                } @else {
                    @media screen and (min-width: $br-width) {
                        margin-left: calc(#{$cols} * 100 / var(--max-cols) * 1%);
                    }
                }
            }
        }
    }

    // TODO Uncomment and fix widths
    .u-wrapper {
        width: 100%;
        display: block;
        padding: 14px;
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
        @include media(medium up) {
            padding: 0;
        }

        @each $grid-row-config-key, $grid-row-config-value in $grid-row-configs {
            $breakpoint: map-get($grid-row-config-value, breakpoint);
            $row-width: map-get($grid-row-config-value, row-width);
            @include media($breakpoint) {
                width: $row-width;
            }
        }

        @include media(large up) {
            height: calc(100% - var(--mundo-r-secondary-menu-height));
            justify-content: center;
        }
        // @each $br in $breakpoints {
        //     $br-width : map-get($grid-system-config, $br, "breakpoint");
        //     $br-safe-area : map-get($grid-system-config, $br, "safe-area");
        //     @if ($br-width == 0) {
        //         padding-left: $br-safe-area;
        //         padding-right: $br-safe-area;
        //     }
        //     @else {
        //         @media screen and (min-width: $br-width) {
        //             padding-left: $br-safe-area;
        //             padding-right: $br-safe-area;
        //         }
        //     }
        // }

        &--narrow {
            max-width: 730px;
        }

        &--wide {
            max-width: 1250px;
        }

        &--wider {
            max-width: 1280px;
        }

        &--larger {
            max-width: 1440px;
        }
    }
}

.c-nueva-solucion-pymes{
    margin-top:40px;
    @include media (medium up){
        margin-top:32px;
    }
    .section-por-que-empresas{
        margin-top:58px;
        @include media (medium up){
            margin-top:64px;
        }
    }
}
