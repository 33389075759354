.c-banner-two-columns {

  &__content {
    max-width: 1110px;
    margin: 0 auto;
    display: flex;
    flex-direction: column-reverse;

    @include media(medium up) {
      flex-direction: row;
    }
  }

  &__image { 
    width: 100%;
    text-align: center;

    @include media(medium up) {
      width: 50%;
      text-align: right;
    }

    img{
      height: 100%;
    }
  }

  &__text {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 15px;

    @include media(medium up) {
      width: 50%;
      padding: 0;
    }
  }

  &__title {
    font-family: $font-family-primary;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -.5px;
    margin-bottom: 24px;
    font-weight: bold;

    @include media(medium up) {
      max-width: 450px;
    }
  }

  &__description {
    font-family: $font-family-secondary;
    font-size: 16px;
    line-height: 24px;

    @include media(medium up) {
      max-width: 420px;
    }
  }
  

  /** Variantes del banner twho columns **/

  &--space-between {
    .c-banner-two-columns__image img {
      display: none;
      object-fit: cover;

      @include media(medium up) {
        text-align: left;
        display: block;
      }
    }

    .c-banner-two-columns__text {
      @include media(medium up) {
        padding-left: 15px;
      }
    }
    .c-banner-two-columns__title {
      font-size: 21px;

      @include media(medium up) {
        max-width: none;
      }
    }
    .c-banner-two-columns__description {
      @include media(medium up) {
        max-width: none;
      }
    }
  }

  &--reverse {
    .c-banner-two-columns__content {
      flex-direction: column-reverse;

      @include media(medium up) {
        flex-direction: row-reverse;
      }
    }
  }

  &--backgroundf6f6f6 {
    background-color: #f6f6f6;
  }

  &--list {
    margin-top: 40px;
    
    .c-banner-two-columns__image img {
      object-fit: contain;
      display: none;

      @include media(medium up) {
        display: block;
      }
    }


    li {
      .c-banner-two-columns__title {
        margin-top: 24px;
        margin-bottom: 8px;
      }

      p {
        font-size: 14px;
        padding-left: 20px;
        line-height: 21px;
      }
    }

    .c-banner-two-columns__title {
      font-size: 18px;
      padding-left: 0;

      span {
        color: $color-primary;
      }
    }

    .c-banner-two-columns__description {
      @include media(medium up) {
        max-width: none;
      }
    }
  }
  .c-banner-two-columns__image.mobile{
    display: block;
    @include media(medium up){
      display:none;
    }
    img{
      display: block;
      @include media(medium up){
        display:none;
      }
      max-height: 400px;
      margin-bottom: 1rem;
    }
  }
}
.c-banner-two-columns--backgroundfff {
  background-color: var(--color-white);
  padding-top: 30px;
  padding-bottom: 40px;
  @include media(medium up) {
    padding-top: 60px;
    padding-bottom: 80px;
  }
}