.fmc-card-wrapper {
    width: 100%;
    height: 120px;
    position: fixed;
    bottom: 0;
    background-color: white;
    z-index: 99;

    h3 {
        display: none;
    }

    @media only screen and (min-width: 1300px){
        width: 400px;
        height: fit-content;
        position: absolute;
        top: 155px;
        right: 100px;
        padding: 32px 32px 26px;
        box-shadow: 4px 4px 20px rgb(38 37 37 / 10%);
        border-radius: 16px;
        z-index: 0;

        h3 {
            display: block;
        }
    }

    .fmc-card-title{
      font-family: $font-family-primary;
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 20px;
      line-height: 28px;
      color: #333333;
    }
    .month-offer{
        white-space: nowrap;
        width: 100%;
        padding: 2px 4px;
        background-color: #cce8ff;
        color: #008dff;
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 5px;
        padding: 4px 16px;

        @media only screen and (min-width: 480px){
            padding: 4px 48px 4px 48px;
        }


     @media only screen and (min-width: 1300px) {
        white-space: nowrap;
        width: fit-content;
        height: fit-content;
        border-radius: 4px;
        padding: 2px 4px;
        background-color: #cce8ff;
        color: #008dff;
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 10px;
     }
        }

      .top-container{
        flex-direction: column;
        justify-content: flex-end;
        row-gap: 8px;
        @include media(medium up){
          flex-direction: column;
        }

        .card-buttons-container {
            display: flex;
            flex-direction: column;
            position: fixed;
            right: 16px;
            bottom: 18px;
            @media only screen and (min-width: 480px){
                right: 48px;
            }
            @media only screen and (min-width: 1300px){
                position: relative;
                right: unset;
                bottom: unset;
                text-align: center;
                margin-top: 16px;
            }
            .c-pymes-interested-button{
                &:first-child{
                  margin-bottom: var(--s-v21);
                  background-color: #ef3340;
                  border-radius: 8px;
                }
                &.mobile-no-display{
                  background-color: transparent !important;
                }
            }

            .mobile-no-display {
                display: none;
                @media only screen and (min-width: 1300px){
                    display: block;
                    color: #999
                }
            }

            .button-spacer {
                margin-bottom: 10px;
            }
        }
        // @media only screen and (min-width: 1280px){
        //   margin-right: calc(var(--s-v21)*5);
        // }

        .info-container{
          white-space: nowrap;
          margin-bottom: 12px;
          padding: 0px 16px 12px 16px;
        @media only screen and (min-width: 480px){
            padding: 0px 48px 12px 48px;
        }
        @media only screen and (min-width: 1300px) {
            padding: 0;
            margin-bottom: 0;
        }
          .price-promotion{
            font-family: $font-family-primary;
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            margin-bottom: -6px;
            @media only screen and (min-width: 1300px) {
              font-size: 16px;
              line-height: 24px;
              margin-bottom: 0px;
            }
          }
          .price-inner{
            .price-inner-container{
              display: flex;
              margin-bottom: -10px;
              @media only screen and (min-width: 1300px) {
                margin-bottom: 0;
              }
              .price-inner-left{
                .price-int{
                  font-family: $font-family-primary;
                  font-weight: 700;
                  font-size: 32px;
                  line-height: 48px;
                  @media only screen and (min-width: 1300px) {
                    font-size: 48px;
                  }
                }
              }
              .price-inner-right{
                margin-left: var(--s-v21);
                .price-decimal{
                  font-family: $font-family-primary;
                  font-weight: 700;
                  font-size: 12px;
                  line-height: 24px;
                  @media only screen and (min-width: 1300px) {
                    font-size: 16px;
                  }
                }
                .price-month{
                  font-weight: 700;
                  font-size: 12px;
                  line-height: 24px;
                  @media only screen and (min-width: 1300px) {
                    font-size: 16px;
                  }
                }
                .price-taxes{
                  display: block;
                  font-size: 10px;
                  line-height: 16px;
                  margin-top: -4px;
                  @media only screen and (min-width: 1300px) {
                    font-size: 12px;
                    margin-top: 0;
                  }
                }
              }
            }
            .price-later{
              font-size: 12px;
              line-height: 20px;
              margin-right: var(--s-v21);
              @media only screen and (min-width: 1300px) {
                font-size: 14px;
              }
            }
          }
        }
    }


  .c-hero-pymes{
    position:relative;
    min-height: 436px;

    .c-hero-pymes-background{
      position: absolute;
      width: 100%;
      z-index: -1;
      overflow: hidden;
      @include media(medium up){
        height: 100%;
      }
      img{
        height: 88%;
        @media only screen and (min-width: 1280px){
          height: auto;
          width: 100%;

        }
      }
    }
    .c-hero-pymes-wrapper {
      display:flex;
      padding-top: var(--header-height);
      justify-content: space-between;
      flex-direction: column;
      @media only screen and (min-width: 1280px){
        flex-direction: row;
        padding-bottom: calc(var(--s-v21)*5);
      }
      .c-hero-pymes-text-inner{
        color: var(--color-light-v21);
        margin-bottom: calc(var(--s-v21)*2);
        @media only screen and (min-width: 1280px){
          margin-bottom: 0;
        }
        h1{
          &.c-hero-title{
            margin-bottom: 10px;
            font-family: $font-family-primary;
            font-weight: 600;
            font-size: 24px;
            line-height: 30px;
            @media only screen and (min-width: 1280px){
              margin-bottom: calc(var(--s-v21)*3);
              font-weight: 500;
              font-size: 50px;
              line-height: 64px;
            }
          }
        }
        p{
          &.c-hero-description{
            font-family: $font-family-primary;
            margin: 0;
            font-weight: 200;
            font-size: 16px;
            font-style: normal;
            line-height: 24px;
            @media only screen and (min-width: 1280px){
              font-style: normal;
              font-size: 24px;
              line-height: 32px;
            }
          }
        }
      }
      .c-hero-pymes-card-container{
        transition: ease all .7s;
        display: flex;
        flex-direction: column;
        padding:calc(var(--s-v21)*3);
        background-color: var(--color-light-v21);
        box-shadow: 4px 4px 20px rgba(38, 37, 37, 0.1);
        border-radius: 20px;
        justify-content: center;
        align-items: flex-start;
        @media only screen and (min-width: 480px){
          width: 100%;
          max-width: 343px;
          align-self: center;
        }
        @include media(medium up){
          padding:calc(var(--s-v21)*5);
          max-width: fit-content;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
        @media only screen and (min-width: 1280px){
          align-self: auto;
          max-width: fit-content;
        }
        .c-hero-pymes-card-left-block{
          display: flex;
          flex-direction: column;
          min-width: fit-content;
          border-bottom: 1px solid var(--color-grey-200-v21);
          padding-bottom: calc(var(--s-v21)*2);
          @include media(medium up){
            padding-right:100px;
            padding-bottom: 0;
            border-bottom: none;
            border-right: 1px solid var(--color-grey-200-v21);
          }
          .c-hero-pymes-card-left-block-features{
            display: flex;
            transition: ease all .7s;
            @include media(medium up){
              align-items: center;
            }
            &:not(:last-child){
              margin-bottom: var(--s-v21);
              @include media(medium up){
                margin-bottom: calc(var(--s-v21)*3);
              }
            }
            .features-icon{
              transition: ease all .7s;
              height: calc(var(--s-v21)*2);
              width: calc(var(--s-v21)*2);
              margin-right: calc(var(--s-v21)*3);
              @include media(medium up){
                height: calc(var(--s-v21)*3);
                width: calc(var(--s-v21)*3);
              }
            }
          }
        }


        .c-hero-pymes-card-right-block{
          padding-top: calc(var(--s-v21)*2);
          width: 100%;
          display: flex;
          @include media(medium up){
            height: 100%;
            padding-left: 30px;
            padding-top: 0;
          }
          .c-hero-pymes-card-right-block-inner{
            align-self: end;
            width: 100%;

            .c-hero-pymes-card-actions-wrapper{
              white-space: nowrap;
              display: flex;
              flex-direction: column;
              width: 100%;
              margin-top: calc(var(--s-v21)*3);
              @include media(medium up){
                margin-top: calc(var(--s-v21)*5);
              }
              a {
                  height: 40px;
              }
              .button-spacer {
                margin-bottom: 8px;
            }
            .mobile-display {
              min-width: 160px;
              position: fixed;
              bottom: 28px;
              right: 16px;
              @media only screen and (min-width: 1300px) {
              min-width: 130px;
              position: relative;
              bottom: unset;
              right: unset;
              }
            }
              .c-pymes-interested-button{
                &:first-child{
                  margin-bottom: var(--s-v21);
                }
              }
            }
            .button-spacer {
                margin-bottom: 10px;
            }
          }
        }
        &.low-padding{
          .c-hero-pymes-card-left-block{
            @media only screen and (min-width: 1280px){
              padding-right: 8px;
            }
          }
          .c-hero-pymes-card-right-block-inner{
            .top-container{
              @media only screen and (min-width: 1280px){
                margin-right: calc(var(--s-v21) * 1);
              }
            }
          }
        }
      }
    }
  }
}
