.c-modal {
  position: relative;

  &__container {
    display: flex;
  }

  &__text {
    width: 100%;
    max-width: 510px;
  }

  &__title {
    font-weight: bold;
    font-size: 28px;
    line-height: 24px;
    letter-spacing: -0.5px;
    font-family: $font-family-primary;
    margin-bottom: 16px;

    @include media(medium up) {
      margin-bottom: 28px;
      font-size: 21px;
      line-height: 27px;
    }
  }

  &__copy {
    font-family: $font-family-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 16px;

    @include media(medium up) {
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__ctas {
    display: flex;
    align-items: center;

    & .button {
      margin-right: 44px;
      margin-bottom: 0;
    }
  }

  &__link {
    font-family: $font-family-secondary;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: $color-primary;
  }
}

.c-lity-modal--c-modal-layout .c-lity-modal__wrapper {
  padding: 32px 16px;

  @include media(medium up) {
    padding: 0 0 0 32px;
  }
}

.c-lity-modal--c-modal-layout .c-lity-modal__header {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 9;
}

.c-lity-modal--c-modal-layout .c-lity-modal__content {
  margin-top: 0;
}

.landing--retrofit .c-lity-modal {
  min-width: 820px;
}
