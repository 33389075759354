@import './../_mixins/base-html-elements';
@import '../_definitions/animations';
@import '../_variables/colors';
@import '../_variables/transition';

.c-carousel-firewall {
  .c-carousel__btn-nav.prev {
    @media screen and (min-width: 48em) {
      left: 82%;
    }

    @media screen and (min-width: 60em) {
      left: 90%;
    }
  }
}
