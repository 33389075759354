.footer {
  --footer-bg-color: #292929;
  --footer-color: var(--color-primary);
  --border-bottom: 1px solid var(--color-grey-500-v21);
  --color-light-v21: #fff;
  --color-white-v21: #fff;

  background-color: var(--footer-bg-color);
  color: var(--footer-color);
  --border-bottom: 1px solid #444;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.footer__top {
  padding-top: calc( 4 * var(--s-v21));
  padding-bottom: calc( 3 * var(--s-v21));
  @include media (medium up) {
    // padding-left: calc( 2 * var(--s-v21));
    // padding-right: calc( 2 * var(--s-v21));
    padding-bottom: calc( 4 * var(--s-v21));
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: var(--border-bottom);
  }

  .icon {
    // --stroke-color: var(--color-primary-contrast-v21);
    --stroke-color: var(--color-light-v21);
    --stroke-width: 1px;
    --width: 32px;
    height: 32px;
  }

  .footer__logo {
    .icon {
      --stroke-width: 0;
      // --fill-color: var(--color-primary-contrast-v21);
      --fill-color: var(--color-light-v21);
      --width: 150px;
      --height: 32px;
    }
  }
}

.footer__social-links {
  .icon {
    // --stroke-color: var(--color-primary-contrast-v21);
    color: var(--color-light-v21);
    --stroke-color: var(--color-light-v21);
    --stroke-width: 1px;
    --width: 32px;
    height: 32px;
    transition: all ease-in-out;
    svg{
      path{
        // stroke:var(--color-primary);
        stroke:var(--color-light-v21);
        stroke-width: 0;
      }
      height: 100%;
      width: 100%;
    }
    &:hover{
      opacity: 0.7;
    }
  }

  a + a {
    margin-left: calc( 3 * var(--s-v21) );
  }
}

.footer__middle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include media (medium up) {
    padding-top: calc( 3 * var(--s-v21));
  }

  @include media (large up) {
    padding-bottom: calc( 4 * var(--s-v21));
    border-bottom: var(--border-bottom);
    flex-direction: row;
  }
}

.footer__links-group {
  padding: calc( 3 * var(--s-v21)) var(--s-v21);
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: var(--border-bottom);
  @include media (medium up) {
    padding-top: 0;
    padding-left: 0;
    padding-right: calc( 3 * var(--s-v21));
    padding-bottom: calc( 5 * var(--s-v21));
    border-bottom: none;
  }
  @include media (large up) {
    padding-bottom: 0;
  }

  &__title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $color-white;
    // font-weight: bold;
    font-weight: 900;

    // @include media (medium up) {
    //   color: $color-primary;
    // }

    .icon {
      margin-left: auto;
      width: auto;

      @include media (medium up) {
        display: none;
      }
    }

    &.show {
      & .icon {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }

  a:hover {
    opacity: 0.7;
    transition: $transition-opacity;
  }

  .footer__links-group__links-list {
    display: none;


    @include media (medium up) {
      display: flex;
      flex-direction: column;
    }

    &.show {
      display: flex;
      flex-direction: column;
      padding-bottom: var(--s-v21);
    }

    .link {
      margin-top: calc( 2 * var(--s-v21));
      color: var(--color-light-v21);

      white-space: nowrap;
      &__text {
        color: var(--color-light-v21);
        font-weight: 400;
        font-size: var(paragraph-font-size);
        line-height: 16px;
      }
      &:first-child {
        margin-top: calc( 2 * var(--s-v21));
      }
    }
  }
}

.footer__middle__main-links {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @include media (medium up) {
    flex: 1 1 auto;
    flex-basis: 100%;
    flex-direction: row;
    flex-wrap: wrap;
  }

  @include media (large up) {
    flex-wrap: nowrap;
  }
&.noClient{
  .footer__links-group:first-child{
    @include media (medium up){
      padding-right: 0;
    }
    @include media (large up){
      padding-right: 40px;
    }
  }
}
  .footer__links-group {
    @include media (medium up) {
      padding-right: 40px;
      flex-basis: 50%;
    }

    @include media (large up) {
      flex-basis: 20%;

      &:first-child {
        flex-basis: 40%;
      }
    }
    .footer__links-group__title{
      color:var(--color-primary-v21);
      .icon{
        svg{
          path{
            stroke: var(--color-light-v21);
          }
        }
      }
    }
    &__links-list{
      .link{
        .link__text{
          color:var(--color-white-v21);
        }
      }
    }
  }
}

.footer__bottom {
  // padding-bottom: calc( 3 * var(--s-v21));
  display: flex;
  flex-direction: column;
  width: 100%;
  @include media (medium up) {
    padding-top: var(--s-v21);
  }

  @include media (large up) {
    padding-top: calc( 3 * var(--s-v21));
    flex-flow: row-reverse;
    justify-content: space-between;

    &__legal,
    &__about-us {
      width: 64%;
    }
  }
}

.footer__bottom__about-us {
  flex: row wrap;
  width: auto;
  padding-top: calc( 3 * var(--s-v21));
  padding-bottom: calc( 4 * var(--s-v21));
  @include media (medium up) {
    border-top: var(--border-bottom);
    border-bottom: var(--border-bottom);
    padding-bottom: calc( 3 * var(--s-v21));
  }
  @include media (large up) {
    border: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
  }

  .link {
    width: 50%;
    margin-top: var(--s-v21);
    @include media (medium up) {
      width: auto;
      margin-top: 0;
      &:not(:first-child) {
        margin-left: calc( 3 * var(--s-v21));
      }
    }
    .link__content{
      .link__text{
        color: var(--color-light-v21);
        font-weight: 900;
        color: var(--color-light-v21);
      }
    }
  }
}

.footer__bottom__legal {
  margin-top: calc( 3 * var(--s-v21));
  line-height: calc( 3 * var(--s-v21));
  font-size: var(--medium-font-size);
  font-weight: 400;
  // color: var(--color-grey-500-v21);
  color: var(--color-light-v21);
  @include media (large up) {
    margin-top: 0;
  }

  .link__text {
    font-weight: 400;
    // color: var(--color-primary);
    color: var(--color-light-v21);
  }
}

.footer__bottom__copyright {
  font-size: var(--medium-font-size);
  line-height: 16px;
  color: var(--color-white-v21);
}
.footer__bottom__links {
  padding-bottom: calc(3 * var(--s-v21));
  @include media(large up){
    max-width: 70%;
  }
  .link__text {
    font-size: var(--medium-font-size);
    line-height: 16px;
    color: var(--color-white-v21);
  }
}
.footer__bottom__copyright {
  margin-bottom: 20px;
}
.footer__links-separator {
  display: inline-flex;
  margin-right: calc(var(--s-v21)*2);
  margin-left: calc(var(--s-v21)*2);
  border: var(--border-bottom);
  height: 10px;

  &--thinner {
    border-left-width: 0.5px;
    border-right-width: 0;
    border-left-color: var(--color-primary);
  }
}
.hide-mobile {
  display: none;
  @include media (medium up) {
    display: flex;
  }
}
.hide-tablet {
  display: flex;
  @include media (medium up) {
    display: none;
  }
}