@import './../_mixins/base-html-elements';

.legal {
    @extend %base-html-elements;

    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
    padding-top: 64px;
    padding-bottom: 128px;
    position: relative;
    z-index: 10;

    @media print, screen and (max-width: 64em) {
        padding-top: 32px;
        padding-bottom: 32px;
    }

    &__header {
        margin-bottom: 32px;
    }

    &__title {
        color: #333;
        font-family: $font-family-primary;
        font-size: 1.375rem;
        font-weight: 700;
        line-height: 1.625rem;
        margin-bottom: 10px;
    }

    &__content {
        color: #444444;

        h2 {
            margin-top: 32px;
            margin-bottom: 16px;
        }

        h3 {
            margin-top: 32px;
            margin-bottom: 16px;
            font-family: $font-family-primary;
            font-size: 1.125rem;
            font-weight: 700;
            line-height: 1.5rem;
        }

        a {
            color: #ef3340;
            font-family: $font-family-secondary;
        }
    }

    .card a {
        text-decoration: none;
    }
}
