.c-landing-configurador-mobile {
  position: fixed;
  bottom: 44px;
  left: 0;
  width: 100%;
  z-index: 999;

  @include media(medium up) {
    display: none;
  }

  & .c-landing-configurador-mobile__bar-icon.up {
    display: block;
  }

  & .c-landing-configurador-mobile__bar-icon.down {
    display: none;
  }

  & .c-landing-configurador-mobile__content {
    display: none;
  }

  &.open {
    & .c-landing-configurador-mobile__bar-icon.up {
      display: none;
    }

    & .c-landing-configurador-mobile__bar-icon.down {
      display: block;
    }

    & .c-landing-configurador-mobile__content {
      display: flex;
    }
  }

  &__bar {
    background: $color-lilac;
    color: $color-white;
    padding: 0 18px;
    display: flex;
    justify-content: space-between;
    height: 22px;
    align-items: center;
    cursor: pointer;
  }

  &__bar-title {
    font-size: 14px;
    line-height: 21px;
  }

  &__bar-resume {
    display: flex;
  }

  &__bar-resume-title {
    font-size: 12px;
    line-height: 14px;
  }

  &__bar-icon {
    margin-left: 8px;

    & svg {
      height: 11px;
      width: auto;

      & .icon__glyph {
        stroke: $color-white;
      }
    }
  }

  &__destacado {
    font-family: $font-family-secondary;
    font-size: 16px;
    line-height: 19px;
    color: $color-grey-600;
  }

  &__price {
    font-family: $font-family-primary;
    font-weight: bold;
    font-size: 21px;
    line-height: 27px;
  }

  &__price-later {
    font-family: $font-family-secondary;
    font-size: 14px;
    line-height: 25px;

    & span {
      margin-left: 8px;
      font-family: $font-family-primary;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__cta {
    & .button {
      min-width: 140px;
    }
  }

  &__content {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    background: $color-white;
    padding: 12px 16px;
  }
}
