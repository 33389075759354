.lity-channels {

    .channels__row {
        margin-bottom: 0;

        &--favourite {
            margin-bottom: 5px;

            .channel__logo {
                border: 1px solid #ddd;
            }
        }

        &__list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            position: relative;
            padding: 5px 0;

            &:not(.channels__row__list--favourite){
                background-color: rgba(236,242,246,0.5);
                width: calc(100% + 32px);
                left: -24px;
                padding-left: 24px;
            }

            &__footer {
                width: 100%;
                text-align: center;
                padding-top: 5px;
                font-size: 14px;
            }
        }

        &__title {
            font-size: .875rem;
            line-height: 1.5rem;
            padding-bottom: 16px;
            font-weight: bold;
            position: relative;

            &:after {
                content: "";
                display: block;
                height: 1px;
                bottom: -1px;
                left: 0;
                width: 100%;
                background-color: #fff;
                position: absolute;
                z-index: 2;
            }
        }
    }

    .modal--channelsGrid {
        .channels__row__list {
            justify-content: space-between;
        }
    }

    .mat-dialog-channels-header {
        text-align: center;
    }

    .channel {
        @include media(large up) {
            flex: 0 0 12.5%;
            justify-content: center;
        }

        &__logo {
            width: 100%;
            padding: 2px 0;
            min-width: 40px;

            @include media(medium up) {
                padding: 10px 0;
                min-width: 80px;
            }


            img {
                max-height: 56px;
                max-height: 36px;
                object-fit: scale-down;
                flex: 0 0 56px;
                flex: 0 0 36px;
                margin-top: 0 !important;
                margin-bottom: 0 !important;
                mix-blend-mode: multiply;

                @include media(medium up) {
                    flex: 0 0 56px;
                    max-height: 56px;
                }
            }


            &__caption {
                font-size: 12px;
                max-width: 85px;
                display: none;

                @include media(large up) {
                    display: block;
                }
            }
        }

        &__moreInfo {
            display: none;
        }
    }

    .channels__favourites__title {
        font-weight: bold;
        margin-bottom: 10px;
    }

    .channels__favourites__info {
        color: #7F7F7F;
        margin: 0 0 0 10px;
        font-size: 14px;
        margin-bottom: 15px;
        display: flex;
        align-items: center;

        &:before {
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            background-image: url('#{$environment-local-url}/images/icons/icon-info.svg');
            background-repeat: no-repeat;
            background-size: contain;
            margin: 0 5px 0 0;
        }
    }

    .accordion-item__content {
        padding: 0;
    }

    .list-item {
        padding-top: 15px;
        padding-bottom: 15px;
        font-family: $font-family-secondary;
        font-size: 16px;
    }
}
