.c-retrofit-banner-full-image {
  height: auto;
  width: 100%;
  position: relative;

  @include media(medium up) {
    height: 496px;
  }

  &__container {
    height: 100%;
    position: relative;
    @include flex();
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 32px 16px;

    @include media(medium up) {
      align-items: center;
      flex-direction: row;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }

    &.parallax {
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &__title {
    position: relative;
    color: $color-white;
    font-size: 30px;
    line-height: 30px;
    font-weight: 600;
    font-family: $font-family-primary;
    margin-bottom: 24px;

    @include media(medium up) {
      max-width: 540px;
      font-size: 34px;
      line-height: 40px;
    }

    @include media(xlarge up) {
      max-width: 540px;
      font-size: 32px;
      line-height: 46px;
    }
  }

  &__description {
    position: relative;
    color: $color-white;
    font-size: 16px;
    line-height: 24px;
    font-family: $font-family-primary;
    margin-top: 8px;
    max-width: 228px;

    @include media(medium up) {
      max-width: 350px;
    }
  }

  &__list {
    margin-bottom: 0;

    @include media(medium up) {
      max-width: 400px;
    }

    &__item {
      font-family: $font-family-secondary;
      font-weight: 300;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 16px;
      color: $color-white;
      @include flex();
      align-items: center;

      &::before {
        content: '';
        width: 8px;
        height: 8px;
        background: $color-white;
        border-radius: 50%;
        margin-right: 8px;
        flex-shrink: 0;
      }
    }
  }

  &__content-image {
    position: relative;
    margin-left: 0;
    max-width: 310px;

    @include media(medium up) {
      margin-left: 20px;
      max-width: 580px;
    }

    @include media(large up) {
      margin-left: 40px;
    }

    @include media(xlarge up) {
      margin-left: 80px;
    }

    &__image {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
      max-width: 100%;
    }
  }
}

