@import '../_variables/_colors';

$pymes-section-header-font-size: $font-size-14;
$pymes-section-header-line-height: $line-height-for-font-size-13;

$pymes-section-description-font-size: $font-size-16;
$pymes-section-description-line-height: $line-height-for-font-size-18;

$pymes-first-description-font-size: $font-size-24;
$pymes-first-description-line-height: $line-height-for-font-size-24;

$pymes-list-description-font-size: $font-size-21;
$pymes-list-description-line-height: $line-height-for-font-size-23;

$list-with-icon-font-size: $font-size-16;
$list-with-icon-line-height: $line-height-for-font-size-18;

$banner-title-font-size: $font-size-24;
$banner-title-line-height: $line-height-for-font-size-26;

$banner-content-font-size: $font-size-16;
$banner-content-line-height: $line-height-for-font-size-18;

$advantages-title-font-size: $font-size-21;
$advantages-title-line-height: $line-height-for-font-size-23;

$advantages-description-font-size: $font-size-16;
$advantages-description-line-height: $line-height-for-font-size-18;

$advantages-cards-font-size: $font-size-18;
$advantages-cards-line-height: $line-height-for-font-size-18;

$advanced-first-title-font-size: $font-size-21;
$advanced-first-title-line-height: $line-height-for-font-size-23;

$advanced-first-description-font-size: $font-size-16;
$advanced-first-description-line-height: $line-height-for-font-size-18;

$card-price-font-size: 84px;
$card-price-height: 72px;

$card-price-euro-font-size: $font-size-42;
$card-price-euro-line-height: $line-height-for-font-size-46;

$card-price-monthly-font-size: $font-size-24;
$card-price-monthly-height: 13px;

$card-price-price-taxes-font-size: $font-size-18;
$card-price-price-taxes-line-height: $line-height-for-font-size-20;

$card-price-feature-font-size: $font-size-14;
$card-price-feature-line-height: $line-height-for-font-size-13;

$login-title-font-size: $font-size-18;
$login-title-line-height: $line-height-for-font-size-18;

.cybersecurity {
  .links-with-icon {
    margin-top: 24px;
    margin-bottom: 0;
    margin-left: -25px;
    margin-right: -25px;

    &__link-group {
      padding-bottom: 50px;
    }
    .link-with-icon {
      font-size: $list-with-icon-font-size;
      line-height: $list-with-icon-line-height;
    }

    @media screen and (min-width: 48em) {
      margin-left: 0;
      margin-right: 0;
    }

    .section-description {
      margin: 0;
    }

    .link-with-icon:hover {
      .icon__glyph {
        stroke: $color-pantone-red;
      }
    }
  }

  .card {
    flex: 0 0 100%;
    margin-top: 30px;

    @media screen and (min-width: 64em) {
      flex: 0 0 33%;
      margin-right: 8.5%;
    }

    &-product__content-wrapper {
      &__content {
        padding: 12px 16px 16px;
      }

      &__price {
        display: flex;
        justify-content: flex-start;
        margin-left: 25%;

        .price-monthly-fee {
          .price-with-subject__integer-amount {
            font-size: $card-price-font-size;
            height: $card-price-height;
          }

          .price-with-subject {
            &__right {
              justify-content: space-around;

              .price-with-subject {
                &__decimal-amount {
                  font-size: $card-price-euro-font-size;
                  line-height: $card-price-euro-line-height;
                }

                &__subject {
                  font-size: $card-price-monthly-font-size;
                  height: 13px;
                }
              }
            }
          }

          .price-taxes {
            color: $color-tundora;
          }
        }
      }

      &__actions .button--primary {
        border-radius: 5px;
        min-height: 48px;
      }
    }
  }

  &__hero {
    a[href='#modal_cybersecurity_login_hero'] {
      margin-top: 16px;
      background-color: $color-pantone-red;
      height: 48px;
      padding: 16px 30px;
    }
  }

  &__pymes {
    flex-wrap: wrap;
    margin-top: 54px;
    margin-bottom: 44px;

    .section-header {
      font-size: $pymes-section-header-font-size !important;
      line-height: $pymes-section-header-line-height;
    }

    .section-header::after {
      background-color: $color-pantone-red;
    }

    .section-description {
      &--first-description {
        .section-description__title {
          font-size: $pymes-first-description-font-size !important;
          line-height: $pymes-first-description-line-height;
          font-weight: 700;
        }

        .section-description__description {
          margin-top: 18px;

          font-size: $pymes-section-description-font-size;
          line-height: $pymes-section-description-line-height;
        }
      }

      &--list-description {
        margin-top: 54px;

        .section-description__title {
          font-size: $pymes-list-description-font-size !important;
          line-height: $pymes-list-description-line-height;
          font-weight: 700;
          color: $color-tundora;
        }
      }
    }
  }

  &__banner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    section {
      width: 100%;

      .section__column-content {
        .two-cols-banner {
          &__content-wrapper {
            .section-description {
              &__title {
                color: $color-tundora;
                font-size: $banner-title-font-size !important;
                line-height: $banner-title-line-height;
              }
              .two-cols-banner__content {
                margin-top: 20px;
                font-size: $banner-content-font-size;
                line-height: $banner-content-line-height;
                color: $color-tundora;
                a.link--underlined-black {
                  color: $color-tundora;
                  border-bottom: 1px solid black;
                  font-weight: 500;
                  &:hover {
                    opacity: 0.7;
                  }
                }
              }
            }
          }

          &__ctas {
            button {
              margin-top: 48px;
            }
          }
        }
      }

      .section__column-content
        .two-cols-banner__content-wrapper:hover
        section
        > .user-login-form {
        background-color: red;
      }
    }

    .cybersecurity-login-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 36px 25px;

      .button--login {
        position: relative;
        z-index: 10;

        max-width: 275px;
        border-radius: 5px;
        padding-left: 30px;
        padding-right: 30px;

        @media screen and (min-width: 48em) {
          right: 130px;
          bottom: 40px;
          left: unset;
        }
      }

      .button--login:hover ~ .user-login-form {
        opacity: 1;
        z-index: 10;
        pointer-events: auto;
      }

      .user-login-form {
        pointer-events: none;

        position: absolute;
        z-index: 10;
        top: 30%;

        opacity: 0;
        padding: 30px 16px;
        background-color: white;
        border-radius: 5px;
        box-shadow: 4px 4px 25px rgba(38, 37, 37, 0.1);

        @media screen and (min-width: 48em) {
          bottom: -370px;
          top: 100%;
          left: unset;
        }
      }
      .user-login-form:hover {
        opacity: 1;
        z-index: 10;
        pointer-events: auto;
      }
    }
  }

  &__advantages,
  &__firewall {
    margin-top: 60px;

    display: block;

    .section-description {
      &__title {
        font-size: $advantages-title-font-size;
        line-height: $advantages-title-line-height;
      }
      &__description {
        margin-top: 26px;

        font-size: $advantages-description-font-size;
        line-height: $advantages-description-line-height;
      }
    }

    .c-porQueR--advantages,
    .c-porQueR--firewall {
      padding-bottom: 0;
      padding-top: 0;

      .c-carousel {
        padding-top: 10px;
        .swiper-slide {
          width: max-content !important;
        }
        .swiper-container {
          padding-left: 0;
        }
      }

      .c-porQueR__card {
        max-width: 255px;
        width: 100%;
      }

      .c-porQueR__content {
        padding: 48px 16px;
      }

      .c-porQueR__cardTitle {
        font-family: $font-family-primary;
        font-size: $advantages-cards-font-size;
        line-height: $advantages-cards-line-height;
        font-weight: 700;
        color: $color-tundora;
      }

      .c-porQueR__cardText {
        color: $color-tundora;
      }
    }
  }

  &__advanced {
    background-color: $color-white-smoke;
    margin-top: 64px;

    &.section--full {
      max-width: 100%;
      width: 100%;
    }

    &__first {
      display: flex;
      flex-flow: row wrap;

      padding-top: 84px;

      .section-description {
        @media screen and (min-width: 64em) {
          flex: 0 0 40%;
          margin-right: 18.5%;
        }

        &__title {
          font-size: $advanced-first-title-font-size !important;
          line-height: $advanced-first-title-line-height;
          font-weight: bold;
          color: $color-tundora;
        }

        &__description {
          margin-top: 16px;

          font-size: $advanced-first-description-font-size;
          line-height: $advanced-first-description-line-height;
          color: $color-tundora;
        }
      }
    }

    &__second {
      margin-top: 64px;

      .section-description {
        @media screen and (min-width: 64em) {
          flex: 0 0 50%;
        }

        &__title {
          flex: 0 0 75%;

          font-size: rem(18) !important;
          line-height: rem(24);
          font-weight: 700;
          color: $color-tundora;
        }
      }

      .list-container {
        margin-top: 14px;

        @media screen and (min-width: 48em) {
          display: flex;
          justify-content: space-between;
        }

        .first-list {
          @media screen and (min-width: 48em) {
            flex: 0 0 40%;
          }
        }
        .second-list {
          @media screen and (min-width: 48em) {
            flex: 0 0 45%;
          }
        }

        .section-description {
          &__title {
            flex: 0 0 75%;

            font-size: rem(18) !important;
            line-height: rem(24);
            color: $color-mine-shaft;
          }

          &__description {
            margin-top: 26px;
            line-height: rem(24);
          }
        }
      }
    }
  }

  &__backup {
    margin-top: 84px;
    margin-bottom: 84px;

    .section-description {
      flex: 0 0 100%;
      @media screen and (min-width: 64em) {
        flex: 0 0 40%;
        margin-right: 18.5%;
      }
    }
  }

  &__firewall {
    &__container {
      @media (min-width: 64em) {
        display: flex;

        > :first-child {
          flex: 0 0 28%;
        }

        > :not(:first-child) {
          width: 64%;
          margin-left: 8%;
        }
      }
    }

    .u-wrapper {
      .c-carousel {
        .swiper-slide {
          width: max-content;
        }
        &__nav-controls {
          display: flex !important;
        }
      }
    }

    .button--firewall {
      margin: 50px 0 0 0;

      @media screen and (min-width: 64em) {
        margin: 0;
      }
    }
  }
  #modal_cybersecurity_login_hero,
  #modal_cybersecurity_login {
    .c-lity-modal__close {
      top: 1%;
      right: 1%;
      @include media(medium up) {
        top: 2%;

        right: 2%;
      }

      @include media(large up) {
        top: 3%;
      }
    }
  }

  #modal_cybersecurity_banner_video {
    .c-lity-modal__content {
      //margin-top: 36px;
      @include media(medium up) {
        min-width: 700px;
      }
    }
  }
}
