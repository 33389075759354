$height-landings-coverage-banner: 64px;

:root {
  --height-landings-coverage-banner: #{$height-landings-coverage-banner};
}

.c-landings-coverage-banner {
  background-color: $color-grey-200;
  width: 100%;
  height: var(--height-landings-coverage-banner);

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 1440px;
    color: $color-white;
    height: var(--height-landings-coverage-banner);
    padding-left: 16px;
    padding-right: 16px;

    @include media(medium up) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__icon {
    & svg {
      height: 50px;
      flex-shrink: 0;
    }
  }

  &__text {
    font-family: $font-family-primary;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: $color-primary;
    margin-left: 16px;
    margin-right: 16px;

    @include media(medium up) {
      font-size: 18px;
      line-height: 24px;
      margin-left: 32px;
      margin-right: 32px;
    }
  }

  &__button {
    font-size: 16px;
    line-height: 40px;
    height: 40px;
    min-width: 120px;

    @include media(medium up) {
      min-width: 174px;
    }

    & .button__content {
      height: 100%;
    }
  }

  &__button-modal {
    font-size: 16px;
    line-height: 40px;
    height: 40px;
    min-width: 120px;

    @include media(medium up) {
      min-width: 174px;
    }

    & .button__content {
      height: 100%;
    }
  }
}
