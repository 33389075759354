.section-description__title {
  margin-bottom: 5px !important;

  font-size: 21px !important;
  font-family: $font-family-primary;
  line-height: 27px;
}

.section-description__subtitle {
  font-size: 18px !important;
  font-family: $font-family-primary;
  line-height: 24px;
}

.section-description--centered {
  text-align: center;
}

.c-smartphones {
  width: 100%;

  padding: 83px 0 45px 0;

  &__mas-buscados {
    display: flex;
    flex-direction: column;
    justify-content: center;

    font-family: $font-family-secondary;
    font-weight: $font-weight-primary-bold;

    padding: 44px 16px 0 16px;

    @include media(medium up) {
      flex-direction: row;

      padding: 16px 16px 0 16px;
    }
  }

  &__buscado {
    color: $color-gray;
  }

  &__tienda {
    color: $color-tertiary;
  }

  &__phone {
    color: $color-pantone-red;
  }
}