:root {
  --mundo-r-header-height: 72px;
  --mundo-r-header-mobile-height: 126px;
  --mundo-r-header-submenu-height: 50px;
  --mundo-r-zindex-normal: 100;
  --mundo-r-zindex-submenu-trick: 99;
  --mundo-r-zindex-submenu: 98;
  --mundo-r-secondary-menu-height: 32px;
  @include media(large up) {
    --mundo-r-header-height: 72px;
  }
}

.c-mundo-r-header {
  background-color: $color-primary;
  height: var(--mundo-r-header-height);
  padding: 0 14px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: var(--mundo-r-zindex-normal);

  // &--fixed {
  //   position: fixed;
  // }

  @include media(large up) {
    height: calc(var(--mundo-r-header-height) + var(--mundo-r-secondary-menu-height));
  }

  &--light {
    background-color: $color-white;

    &:after {
      display: none;
    }
  }

  &.menu-open {
    position: fixed;
  }

  &:after {
    content: ' ';
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-color: $color-primary;
  }

  @include media(large up) {
    top: 0;
  }

   &__menuCliente {
    background-color: #F7F7F7;
    position: relative;
    z-index: 999999;
    padding: 0 16px;
    width: 100%;

    @include media(large up) {
      display: none;
    }
  }

  &__menuClienteColumn {
    flex: 0;
    .c-mundo-r-header__menuClienteLink {
      margin-top: 6px;
      display: block;
    }
  }

  &__menuClienteItem {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
    color: $color-font-primary;
    border-bottom: 1px solid #BFBFBF;
    padding: 16px 0;
    &:last-of-type {
      border-bottom: none;
    }

    &--dudas {
      @each $grid-row-config-key, $grid-row-config-value in $grid-row-configs {
        $breakpoint: map-get($grid-row-config-value, breakpoint);
        $row-width: map-get($grid-row-config-value, row-width);
        @include media($breakpoint) {
          padding: 16px calc((100% - #{$row-width})/2);
        }
      }
    }
  }

  &__menuClienteTitle {
    font-family: $font-family-secondary;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
    color: #333333;
  }

  &__menuClienteLink {
    font-family: $font-family-secondary;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
    color: $color-chart-8;
  }

  &__shop-link {
    color: $color-white;
    padding: 0 16px;
    height: 100%;
    width: min-content;
    white-space: break-spaces;
    background-color: $color-secondary;

    z-index: 99;

    display: none;
    align-items: center;

    font-family: $font-family-primary;
    font-size: $font-size-12;
    font-weight: 400;
    letter-spacing: 0;
    line-height: $line-height-for-font-size-14;

    @include media(large up) {
      display: flex;
      font-size: $font-size-12;
    }

    @include media(xlarge up) {
      font-size: $font-size-14;
    }

    @include media(xxxlarge up) {
      width: unset;
    }

    &:hover {
      background-color: lighten($color-primary, 10%);
      transition: background-color 0.2s ease-in-out;
    }
  }


  &__container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 100%;
    margin-left: auto;
    margin-right: auto;

    @each $grid-row-config-key, $grid-row-config-value in $grid-row-configs {
      $breakpoint: map-get($grid-row-config-value, breakpoint);
      $row-width: map-get($grid-row-config-value, row-width);
      @include media($breakpoint) {
        width: $row-width;
      }
    }

    @include media(large up) {
      height: calc(100% - var(--mundo-r-secondary-menu-height));
      justify-content: center;
    }
  }

  &__logo-link {
    display: block;
    flex-shrink: 0;
    z-index: var(--mundo-r-zindex-normal);
    margin-bottom: 0;
  }

  &__logo {
    height: 36px;
    margin-left: 0;
    flex-shrink: 0;

    img {
      height: 50px;
      @include media(large up) {
        height: 42px;
      }
    }
  }

  &__back-link {
    display: none;
    align-items: center;
    z-index: 1;
    color: $color-white;

    font-size: $font-size-16;
    line-height: $line-height-for-font-size-18;

    font-family: $font-family-primary;
    font-weight: bold;

    &__arrow {
      transform: rotateZ(90deg);
      width: 16px;
      height: 16px;
      margin-right: 16px;
    }
  }

  &__call-us {
    background-color: $color-chart-8;
    color: $white;
    display: none;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 16px 0;
    transition: $transition-background-color;
    white-space: normal;
    z-index: var(--mundo-r-zindex-normal);
    width: 184px;

    margin-left: auto;
    margin-right: 16px;

    @include media(medium up) {
      display: flex;
      flex-shrink: 0;
    }
    @include media(large up) {
      margin: 0;
    }

    &:hover {
      background-color: $color-secondary;
      transition: $transition-background-color;
    }

    &--sticky {
      position: fixed;
      bottom: -50%;
      height: auto;
      width: 100%;
      z-index: 999999;
      max-width: 100%;
      padding: 14px 25px;
      justify-content: flex-start;
      transition: all .3s;

      &.visible {
        display: flex;
        bottom: 0;
         @include media(large up) {
          display: none !important;
         }
      }

      strong {
        display: initial !important;
      }

      .c-mundo-r-header__call-us__icon {
        width: 29px;
        margin-right: 17px;
      }
      .c-mundo-r-header__call-us__content {
        font-size: 18px;
      }
    }

    &--sticky--cliente {
      align-items: center;
      .c-mundo-r-header__call-us__content {
        font-size: 16px;
        max-width: unset;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }

    &__icon {
      margin-right: 8px;
      width: 20px;
      flex-shrink: 0;

      > svg * {
        stroke: $color-white;
      }
    }

    &__content {
      font-family: $font-family-primary;
      font-size: $font-size-14;
      line-height: 1.4;
      max-width: 90px;

      @include media(xlarge up) {
        max-width: max-content;
      }

      strong:first-of-type {
        display: block;

        @include media(xlarge up) {
          display: inline;
        }
      }

      .ic-phone-number {
        display: block;
      }
    }

    &__phone {
        white-space: nowrap;
    }
  }

  &__your-area {
    align-items: center;
    display: none;
    justify-content: center;
    flex-direction: row;
    height: 100%;
    padding: 0 15px;
    transition: $transition-background-color;
    z-index: var(--mundo-r-zindex-normal);
    color: $color-font-primary;

    @include media(large up) {
      display: flex;
      flex-direction: column;
      color: $color-white;
      &:hover {
        background-color: $color-secondary;
        transition: $transition-background-color;
      }
    }

    @include media(xlarge up) {
      padding: 20px 16px;
    }

    &__icon {
      width: 33px;
    }

    &__content {
      font-family: $font-family-primary;
      font-size: $font-size-14;
      font-weight: bold;
      letter-spacing: -0.5px;
      line-height: $line-height-for-font-size-14;
      margin-top: 6px;
      white-space: nowrap;
    }
  }

  &__language-selector {
    border-left: 1px solid $color-grey-500;
    display: none;
    position: relative;
    justify-content: flex-end;
    flex-direction: column;
    height: 100%;
    z-index: var(--mundo-r-zindex-normal);
    font-family: $font-family-secondary;
    font-size: $font-size-14;
    line-height: $line-height-for-font-size-14;
    padding: 16px;
    transition: $transition-background-color;
    cursor: pointer;

    .icon {
      width: 12px;
    }

    &:hover {
      background-color: darken($color-white, 5%);
      transition: $transition-background-color;
    }

    @include media(large up) {
      display: flex;

      &.show {
        img {
          transform: rotate(180deg);
        }
      }
    }

    &__menu {
      background-color: $color-white;
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateY(100%);
      display: none;
      flex-direction: column;
      box-shadow: 4px 4px 25px rgba(#262525, 0.1);

      &.show {
        display: flex;
      }

      &__item {
        border-bottom: 1px solid $color-grey-500;
        color: $color-font-primary;
        padding: 8px 16px;
        min-width: 140px;
        transition: $transition-color;

        &:hover {
          color: rgba($color-font-primary, 0.5);
          transition: $transition-color;
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }

    &__current {
      white-space: nowrap;
    }
  }

  &__phone-mobile {
    background: $color-white;
    height: 32px;
    padding: 5px 8px;
    z-index: 1;
    margin-right: 16px;
    margin-left: auto;

    &__number {
      font-family: $font-family-secondary;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: $color-primary;
    }

    @include media(medium up) {
      display: none;
    }
  }

  &--scrolling {
    position: fixed;
    top: calc(-1 * var(--mundo-r-header-height));
    left: 0;
    right: 0;
    z-index: 999;
  }

  // Cuando se está haciendo scroll down se esconde
  &--nav-down {
    transform: translateY(-100%);
    transition: $transition-transform;
  }

  // Cuando se está haciendo scroll up se muestra
  &--nav-up {
    top: 0;
    transform: translateY(0);
    transition: $transition-transform;
  }

  & .header-desktop__secondary .section__column-content {
    margin-left: auto;
    margin-right: auto;
  }



}
