.web-component {
  &__simple {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .c-euskaltel-header {
      &__container {
        justify-content: center;
      }
    }

    main {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      /* min-height: calc(100vh - 72px);

      @media (min-width: 1024px) {
        min-height: calc(100vh - 100px);
      } */
    }
  }

  &__wrapper {
    display: flex;
    flex-grow: 1;
  }
}
