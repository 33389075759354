.landings-cobertura{
  .c-krt-coverage__content-bottom{
    max-width: 824px ;
    margin: 0 auto;
  }
  
  .c-krt-coverage__response-message{
    max-width: none;
  }
  

}

.c-krt-coverage {
  position: relative;

  &__response-message {
    display: none;
    width: 100%;
    // max-width: 824px;
    margin: 0 auto;
    padding-left: 16px;
    padding-right: 16px;
    flex-direction: column;

    @include media(large up) {
      padding-left: 0;
      padding-right: 0;
    }

    &#response-cmn-ok-schedule,
    &#response-cmn-error-services {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  &__content{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__icon {
    margin-bottom: 24px;
    width: 56px;

    @include media(medium up) {
      width: 96px;
      margin-bottom: 40px;
    }
  }

  &__icon-2 {
    margin-bottom: 24px;
  }

  &__title {
    margin-bottom: 8px;
    font-family: $font-family-secondary;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: $color-tundora;
    text-align: center;

    @include media(medium up) {
      font-size: 21px;
      line-height: 27px;
    }
  }

  &__copy {
    margin-bottom: 24px;
    font-family: $font-family-secondary;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: $color-tundora;
    text-align: center;

    @include media(medium up) {
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 32px;
    }
  }

  &__phone {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    font-weight: bold;
    font-size: 21px;
    background-color: rgba(29, 31, 44, 0.1);
    height: 48px;
    color: $color-primary;
    border-radius: 53px;
    text-align: center;
    line-height: 20px;

    &:hover {
      color: $color-white;
    }
  }

  &__scheduler {
    display: flex;
    flex-direction: column;
    width: 100%;

    > .form-select {
      width: 100%;

      @include media(medium up) {
        width: 288px;
      }
    }

    > button {
      margin-top: 24px;
      font-weight: $font-weight-secondary-bold;
      font-size: 16px;
      line-height: 24px;

      @include media(medium up) {
        margin-top: 0 !important;
        margin-left: 24px;
      }
    }

    @include media(medium up) {
      flex-direction: row;
      justify-content: center;
    }
  }

  &__separator {
    width: 100%;
    max-width: 610px;
    height: 1px;
    background-color: #F2F2F2;
    margin: 40px auto 44px;
  }

  &__content-bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    &--centered {
      width: 100%;

      .c-krt-coverage__back-to-start,
      button {
        width: 100%;
        margin-top: 0 !important;
        font-weight: bold;
        font-family: $font-family-secondary;
        min-width: 266px;
        font-size: 16px;
        line-height: 24px;

        @include media(medium up) {
          width: auto;
        }
      }

      @include media(medium up) {
        width: auto;
        justify-content: center;
      }
    }

    @include media(medium up) {
      flex-direction: row;
      align-items: flex-end;
    }
  }

  &__your-address {
    margin-right: 12px;
    width: 100%;
    max-width: 400px;

    &__title {
      font-family: $font-family-secondary;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: $color-tundora;
    }

    &__address {
      font-family: $font-family-secondary;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: $color-tundora;
    }
  }

  &__check-again {
    position: relative;

    & button {
      min-width: 266px;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
