.c-pymes-interested-button{
  min-width: fit-content;
}
.section--solucion-pymes-faq{
  background-color: #ffffff;
}

.c-pymes-interested-button__submit-button {
  &:hover {
    cursor: pointer;
  }
}
.c-hero-pymes {
  position: relative;
  margin-bottom: 40px;

  .c-hero-pymes-background {
    position: absolute;
    width: 100%;
    z-index: -1;
    height: 375px;
    overflow: hidden;
    @include media(medium up) {
      height: 100%;
    }
    picture {
      height: 100%;
      display: block;
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }
  .u-wrapper.c-hero-pymes-wrapper {
    display: flex;
    padding-top: 40px;
    padding-bottom: 40px;
    justify-content: space-between;
    flex-direction: column;
    @include media(xlarge up) {
      flex-direction: row;
    }
    .c-hero-pymes-text-inner {
      color: #fff;
      margin-bottom: calc(8px * 2);
      @media screen and (min-width: 1280px) {
        margin-bottom: 0;
        max-width: 414px;
      }
      h1 {
        &.c-hero-title {
          margin-bottom: 10px;
          font-family: $font-family-primary;
          font-weight: 600;
          font-size: 24px;
          line-height: 30px;
          @media screen and (min-width: 1280px) {
            margin-bottom: calc(8px * 3);
            font-size: 51px;
            line-height: 76px;
            font-weight: 700;
          }
        }
      }
      p {
        &.c-hero-description {
          font-family: $font-family-secondary;
          margin: 0;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          @media screen and (min-width: 1280px) {
            font-size: 18px;
            line-height: 24px;
          }
        }
      }
    }
    .c-hero-pymes-card-container {
      transition: ease all 0.7s;
      display: flex;
      flex-direction: column;
      padding: calc(8px * 3);
      background-color: #fff;
      box-shadow: 4px 4px 20px rgba(38, 37, 37, 0.1);
      border-radius: 20px;
      justify-content: center;
      align-items: flex-start;
      @include media(medium down) {
        width: 100%;
        align-self: center;
      }
      @include media(medium up) {
        padding: calc(8px * 5);
        max-width: fit-content;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
      @media screen and (min-width: 1280px) {
        align-self: auto;
        max-width: fit-content;
      }
      .c-hero-pymes-card-left-block {
        display: flex;
        flex-direction: column;
        min-width: fit-content;
        border-bottom: 1px solid var(--color-grey-200);
        padding-bottom: calc(8px * 2);
        @include media(large up) {
          padding-right: 100px;
          padding-bottom: 0;
          border-bottom: none;
          border-right: 1px solid var(--color-grey-200);
        }
        .c-hero-pymes-card-left-block-features {
          display: flex;
          transition: ease all 0.7s;
          @include media(medium up) {
            align-items: center;
          }
          &:not(:last-child) {
            margin-bottom: 8px;
            @include media(medium up) {
              margin-bottom: calc(8px * 3);
            }
          }
          .features-text {
            font-family: $font-family-primary;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: var(--color-dark);
          }
          .features-icon {
            transition: ease all 0.7s;
            height: calc(8px * 2);
            width: calc(8px * 2);
            margin-right: calc(8px * 3);
            @include media(medium up) {
              height: calc(8px * 3);
              width: calc(8px * 3);
            }
          }
        }
      }
      .c-hero-pymes-card-right-block {
        padding-top: calc(8px * 2);
        width: 100%;
        display: flex;
        @include media(medium up) {
          height: 100%;
          padding-left: 30px;
          padding-top: 0;
        }
        .c-hero-pymes-card-right-block-inner {
          align-self: end;
          width: 100%;
          .top-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            @include media(medium up) {
              flex-direction: column;
            }
            @media screen and (min-width: 1280px) {
              margin-right: calc(8px * 5);
            }
            .month-offer {
              white-space: nowrap;
              width: fit-content;
              height: fit-content;
              border-radius: 4px;
              padding: 2px 4px;
              background-color: #c9effe;
              color: #00a3e0;
              font-weight: 700;
              font-size: 12px;
              line-height: 20px;
              margin-bottom: 10px;
            }
            .info-container {
              white-space: nowrap;
              color: #444444;
              .price-promotion {
                font-family: $font-family-secondary;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                @include media(medium up) {
                  font-size: 16px;
                  line-height: 24px;
                }
              }
              .price-inner {
                .price-inner-container {
                  display: flex;
                  .price-inner-left {
                    .price-int {
                      font-family: $font-family-primary;
                      font-weight: 700;
                      font-size: 48px;
                      line-height: 48px;
                    }
                  }
                  .price-inner-right {
                    margin-left: 8px;
                    .price-decimal {
                      font-family: $font-family-primary;
                      font-weight: 700;
                      font-size: 16px;
                      line-height: 24px;
                    }
                    .price-month {
                      font-weight: 700;
                      font-size: 16px;
                      line-height: 24px;
                    }
                    .price-taxes {
                      display: block;
                      font-size: 12px;
                      line-height: 16px;
                    }
                  }
                }
                .price-later {
                  font-size: 14px;
                  line-height: 20px;
                  margin-right: 8px;
                }
              }
            }
          }
          .c-hero-pymes-card-actions-wrapper {
            white-space: nowrap;
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: calc(8px * 3);
            @include media(medium up) {
              margin-top: calc(8px * 5);
            }
            .c-pymes-interested-button {
              &:first-child {
                margin-bottom: 8px;
                background: #ef3340;
                border-radius: 8px;
                border: none;
              }
            }
            .c-pymes-hero-button {
              color: #444444;
              border-color: #444444;
              border-radius: 8px;
            }
          }
        }
      }
      &.low-padding {
        .c-hero-pymes-card-left-block {
          @media screen and (min-width: 1280px) {
            padding-right: 8px;
          }
        }
        .c-hero-pymes-card-right-block-inner {
          .top-container {
            @media screen and (min-width: 1280px) {
              margin-right: calc(8px * 1);
            }
          }
        }
      }
    }
  }
}
