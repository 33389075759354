$color-primary-dark:#9c181e;
$color-light: #{$color-white};
$button-color: #{$color-white};
$button-bg-color: #{$color-primary};
$button-border-color: 1px solid var(--button-color);
$button-border-radius: var(--s-v21);
$button-min-width: 130px;
$button-hover: #{$color-primary-dark};
$color-secondary-dark: brighten($color-secondary, 75);
$color-tertiary-dark: brighten($color-tertiary, 75);

.header,
.footer,
.pre-header,
.pre-header-mobile,
.header-menu-mobile,
.header-submenu {
  .button {

    --button-color: #{$button-color};
    --button-bg-color: #{$button-bg-color};
    --button-border-color: #{$button-border-color};
    --button-hover: #{$button-hover};

    justify-content: center;
    align-items: center;
    padding: 12px calc(var(--s-v21) * 2);
    border-radius: $button-border-radius;
    position: relative;

    &:not(.button--icon) {
      min-width: $button-min-width;
    }

    color: var(--button-color);
    background-color: var(--button-bg-color);
    border: 0;
    border-radius: $button-border-radius;

    .icon svg {
      stroke: var(--button-color);

      * {
        stroke: inherit;
      }
    }

    &:active,
    &:focus,
    &:hover:not([disabled]) {
      --button-bg-color: #{$color-primary-dark};
    }

    
    &[disabled] {
      opacity: 0.25;
      pointer-events: none;

      &:hover {
        cursor: initial;
      }
    }

    &.dark-mode {
      --button-color: #{$color-primary};
      --button-bg-color: #{$color-light};

      &:active,
      &:focus,
      &:hover:not([disabled]) {
        --button-color: #{$color-primary-dark};
        --button-bg-color: #{$color-light};
      }

      &.secondary {
        --button-color: #{$color-secondary};
        --button-bg-color: #{$color-light};

        &:active,
        &:focus,
        &:hover:not([disabled]) {
          --button-color: #{$color-secondary-dark};
          --button-bg-color: #{$color-light};
        }
      }

      &.tertiary {
        --button-color: #{$color-tertiary};
        --button-bg-color: #{$color-light};

        &:active,
        &:focus,
        &:hover:not([disabled]) {
          --button-color: #{$color-tertiary-dark};
          --button-bg-color: #{$color-light};
        }
      }

    }

    &.secondary {
      --button-color: #{$color-light};
      --button-bg-color: #{$color-secondary};

      &:active,
      &:focus,
      &:hover:not([disabled]) {
        --button-color: #{$color-light};
        --button-bg-color: #{$color-secondary-dark};
      }
    }

    &.tertiary {
      --button-color: #{$color-light};
      --button-bg-color: #{$color-tertiary};

      &:active,
      &:focus,
      &:hover:not([disabled]) {
        --button-color: #{$color-light};
        --button-bg-color: #{$color-tertiary-dark};
      }
    }

    &--large {
      max-width: 285px;
      width: 100%;
    }

  }


  .button__text {
    @extend .link-label-style;
  }

  .button--secondary {
    --button-color: #{$color-primary};
    --button-bg-color: #{$color-light};

    border: #{$button-border-color};

    &:active,
    &:focus,
    &:hover:not([disabled]) {
      --button-color: #{$color-primary-dark};
      --button-bg-color: #{$color-light};
    }

    &.light {
      --button-color: #{$color-light};
      --button-bg-color: transparent;

      &:active,
      &:focus,
      &:hover:not([disabled]) {
        --button-color: #{brighten($color-light, 75)};
        --button-bg-color: transparent;
      }
    }

    &.secondary {
      --button-color: #{$color-secondary};
      --button-bg-color: #{$color-light};

      &:active,
      &:focus,
      &:hover:not([disabled]) {
        --button-color: #{$color-secondary-dark};
        --button-bg-color: #{$color-light};
      }
    }

    &.tertiary {
      --button-color: #{$color-tertiary};
      --button-bg-color: #{$color-light};

      &:active,
      &:focus,
      &:hover:not([disabled]) {
        --button-color: #{$color-tertiary-dark};
        --button-bg-color: #{$color-light};
      }
    }

  }

  .button--no-border {
    --button-color: #{$color-primary};
    --button-bg-color: transparent;

    &:active,
    &:focus,
    &:hover:not([disabled]) {
      --button-color: #{$color-primary-dark};
      --button-bg-color: transparent;
    }

    &.secondary {
      --button-color: #{$color-secondary};
      --button-bg-color: transparent;

      &:active,
      &:focus,
      &:hover:not([disabled]) {
        --button-color: #{$color-secondary-dark};
        --button-bg-color: transparent;
      }
    }

    &.tertiary {
      --button-color: #{$color-tertiary};
      --button-bg-color: transparent;

      &:active,
      &:focus,
      &:hover:not([disabled]) {
        --button-color: #{$color-tertiary-dark};
        --button-bg-color: transparent;
      }
    }

    &.dark-mode {
      --button-color: #{$color-light};
      --button-bg-color: transparent;

      &:active,
      &:focus,
      &:hover:not([disabled]) {
        --button-color: #{$color-grey-200};
        --button-bg-color: transparent;
      }

      &.primary {
        --button-color: #{$color-primary};
        --button-bg-color: transparent;

        &:active,
        &:focus,
        &:hover:not([disabled]) {
          --button-color: #{$color-primary-dark};
          --button-bg-color: transparent;
        }
      }

      &.secondary {
        --button-color: #{$color-secondary};
        --button-bg-color: transparent;

        &:active,
        &:focus,
        &:hover:not([disabled]) {
          --button-color: #{$color-secondary-dark};
          --button-bg-color: transparent;
        }
      }

      &.tertiary {
        --button-color: #{$color-tertiary};
        --button-bg-color: transparent;

        &:active,
        &:focus,
        &:hover:not([disabled]) {
          --button-color: #{$color-tertiary-dark};
          --button-bg-color: transparent;
        }
      }

    }
  }


  .button--icon {
    --button-width: calc(var(--s-v21) * 7);
    --button-height: calc(var(--s-v21) * 7);
    --button-border-radius: calc(var(--s-v21) * 7);

    box-sizing: border-box;
    flex: 0 0 calc(var(--s-v21) * 7);
    cursor: pointer;
    border-radius: var(--button-border-radius);
    height: var(--button-width);
    width: var(--button-height);
    display: inline-flex;

    .icon {
      --stroke-width: calc(var(--s-v21) / 3);
      display: inline-flex;
      width: 12.5px;
      height: 12.5px;
    }

    &[disabled],
    &.swiper-button-disabled {
      cursor: not-allowed;
      opacity: 0.25;
      pointer-events: none;

      &:hover {
        cursor: initial;
      }
    }

  }

  .button--prev,
  .button--next {
    margin: 0 var(--s-v21);
    background-color: transparent;
  }
}
