.landings-kit-digital {
    --s: 8px;

    section {
        flex-direction: column;
    }
    .landings-kit-digital-sponsors {
        padding-top: calc(var(--s) * 8);
        padding-left: calc(var(--s) * 3);
        padding-right: calc(var(--s) * 3);
        display: flex;
        flex-direction: column;
        align-items: center;
        @include media(large up) {
            padding-left: none;
            padding-right: none;
        }
        p {
            text-align: center;
            max-width: 639px;
            font-size: 0.6rem;
            color: var(--color-grey-600);
            @include media(large up) {
                padding-left: none;
                padding-right: none;
                font-size: 1rem;
            }
        }
        .landings-kit-digital-sponsors-separator {
            margin-top: var(--s);
            margin-bottom: calc(var(--s) * 2);
            height: 1px;
            border-bottom: 1px solid var(--color-grey-600);
            width: 100px;
            @include media(large up) {
                width: 170px;
                margin-top: calc(var(--s) * 2);
                margin-bottom: calc(var(--s) * 4);
            }
        }
        .landings-kit-digital-sponsors-image-container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            img {
                max-height: 34px;
                margin-bottom: calc(var(--s) * 5);
                &:not(:last-child) {
                    margin-right: calc(var(--s) * 6);
                }
                @include media(large up) {
                    max-height: 80px;
                    &:not(:last-child) {
                        margin-right: calc(var(--s) * 8);
                    }
                }
                @include media(xlarge up) {
                    margin-bottom: 0;
                }
            }
        }
    }
    .landings-kit-digital {
        &.u-wrapper {
            max-width: 1280px !important;
        }
    }

    .landings-kit-digital-features {
        padding-top: calc(var(--s) * 8);
        padding-bottom: calc(var(--s) * 11);

        .landings-kit-digital-features-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            @include media(large up) {
                flex-direction: row;
                align-items: flex-start;
                justify-content: flex-start;
            }
        }

        .landings-kit-digital-features-includes {
            padding-top: calc(var(--s) * 3);
            padding-bottom: calc(var(--s) * 3);
            font-family: $font-family-secondary;
            font-size: 16px;
            line-height: 24px;
            font-weight: bold;
            @include media(large up) {
                padding-bottom: calc(var(--s) * 5);
                padding-top: calc(var(--s) * 5);
            }
        }

        .landings-kit-digital-features-head-container {
            h2 {
                font-family: $font-family-primary;
                font-size: 24px;
                line-height: 30px;
                font-weight: bold;
            }

            p {
                margin-top: calc(var(--s) * 2);
                line-height: 24px;
            }
        }
        .landings-kit-digital-features-list-container {
            border: 1px solid var(--color-grey-400);
            border-radius: 10px;
            width: 100%;
            min-width: 290px;
            height: fit-content;
            padding-bottom: calc(var(--s) * 3);
            &:not(:first-child) {
                margin-top: calc(var(--gutter) * 2);
            }
            @include media(large up) {
                max-width: 370px;
                border-radius: 10px;
                margin-top: 0;
                min-width: none;
                &:not(:first-child) {
                    margin-top: 0;
                }
                &:not(:last-child) {
                    margin-right: calc(var(--gutter) * 2);
                }
            }
            .actions-wrapper {
                display: flex;
                margin-top: calc(var(--s) * 3);
                padding-top: calc(var(--s) * 3);
                border-top: 1px solid var(--color-grey-400);
                justify-content: center;
                align-items: center;
                @include media(large up) {
                    display: none;
                }
                &:hover {
                    cursor: pointer;
                }
                button {
                    font-family: $font-family-secondary;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: bold;
                    color: var(--color-primary);
                    background: none;
                    border: none;

                    &:hover {
                        cursor: pointer;
                        color: var(--color-grey-600);
                    }

                    svg {
                        margin-left: var(--s);
                        &.rotate {
                            transform: rotate(180deg);
                        }
                    }
                }

            }
            .landings-kit-digital-features-list-head-container {
                padding-right: calc(var(--s) * 3);
                padding-left: calc(var(--s) * 3);
                padding-top: calc(var(--s) * 3);
                @include media(large up) {
                    padding-right: calc(var(--s) * 5);
                    padding-left: calc(var(--s) * 5);
                    padding-top: calc(var(--s) * 5);
                }
                h3 {
                    font-family: $font-family-primary;
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: bold;
                }
                p {
                    margin-top: 5px;
                    font-family: $font-family-secondary;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--color-grey-600);
                }
            }
            .landings-kit-digital-features-list-inner-container {
                height: unset;
                overflow: hidden;
                &.hidden {
                    -moz-transition: height 1s ease;
                    -webkit-transition: height 1s ease;
                    -o-transition: height 1s ease;
                    transition: height 1s ease;
                    height: 0;
                    overflow: hidden;
                    border: none;
                    @include media(large up) {
                        height: unset;
                        overflow: hidden;
                        border-top: none;
                    }
                }
                @include media(large up) {
                    margin-bottom: none;
                    border-top: none;
                }
                .list-amount-spec {
                    @include media(large up) {
                        display: block;
                    }
                    &.hidden {
                        display: none;
                        @include media(large up) {
                            display: block;
                        }
                    }
                    p {
                        padding-left: calc(var(--s) * 3);
                        padding-right: calc(var(--s) * 3);
                        @include media(large up) {
                            padding-left: calc(var(--s) * 5);
                            padding-right: calc(var(--s) * 5);
                        }
                    }
                    .list-amount {
                        margin-top: calc(var(--s) * 3);
                        font-family: $font-family-primary;
                        font-size: 24px;
                        line-height: 30px;
                        font-weight: bold;
                    }
                    .list-amount-description {
                        margin-top: 0;
                        padding-bottom: calc(var(--s) * 3);
                        font-family: $font-family-secondary;
                        font-size: 14px;
                        line-height: 24px;
                    }
                }
                .list-extra-title {
                    margin-top: calc(var(--s) * 4);
                    font-family: $font-family-primary;
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: bold;
                    padding-left: calc(var(--s) * 3);
                    padding-right: calc(var(--s) * 3);
                    @include media(large up) {
                        padding-left: calc(var(--s) * 5);
                        padding-right: calc(var(--s) * 5);
                    }
                }
                .landings-kit-digital-features-list {
                    padding-left: calc(var(--s) * 3);
                    padding-top: calc(var(--s) * 5);
                    padding-right: calc(var(--s) * 3);
                    padding-top: calc(var(--s) * 5);
                    &.first {
                        border-top: 1px solid var(--color-grey-400);
                    }
                    @include media(large up) {
                        padding-left: calc(var(--s) * 5);
                        padding-top: calc(var(--s) * 5);
                        padding-right: calc(var(--s) * 5);
                    }
                    .landings-kit-digital-features-list-item-container {
                        display: flex;
                        align-items: flex-start;
                        &:not(:last-child) {
                            margin-bottom: calc(var(--s) * 2);
                        }
                        svg {
                            flex-shrink: 0;
                        }
                        .landings-kit-digital-features-list-item {
                            font-family: $font-family-secondary;
                            font-size: 14px;
                            line-height: 20px;
                            margin-left: 20px;
                        }
                    }
                }
                .landings-kit-digital-features-list-footPrint {
                    padding-left: calc(var(--s) * 3);
                    padding-right: calc(var(--s) * 3);
                    margin-top: calc(var(--s) * 2);
                    font-size: 0.75rem;
                }
            }
        }
    }

    .landings-kit-digital-compromiso {
        flex-direction: column;

        padding-top: calc(var(--s) * 8);
        padding-bottom: calc(var(--s) * 8);
        h2 {
            font-family: $font-family-primary;
            font-size: 21px;
            line-height: 27px;
            font-weight: bold;
            margin-bottom: calc(var(--s) * 8);
            text-align: center;
            @include media(large up) {
                margin-bottom: calc(var(--s) * 10);
            }
        }
        .landings-kit-digital-compromiso-inner-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
        .landings-kit-digital-compromiso-inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            &:not(:last-child) {
                margin-bottom: 72px;
            }
            .landings-kit-digital-compromiso-img {
                margin-bottom: calc(var(--s) * 5);
                height: 80px;
                width: 80px;
            }
            .landings-kit-digital-compromiso-text {
                max-width: 295px;
                text-align: center;
                padding-top: calc(var(--s) * 3);
                line-height: 24px;
                @include media(large up) {
                    max-width: 340px;
                }
            }
        }
    }
}
