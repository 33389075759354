.c-hero-landing-list {
  box-sizing: border-box;
  height: auto;
  padding: 0;
  position: relative;
  width: 100%;

  &__banner-interior {
    & .c-banner-interior__title {
      font-size: 24px;
      line-height: 30px;

      & br {
        display: none;
      }

      @include media(medium up) {
        font-size: 36px;
        line-height: 42px;

        & br {
          display: block;
        }
      }
    }
  }


  &__bg-image {
    height: auto;
    left: auto;
    right: 0;
    position: absolute;
    top: 0;
    width: auto;
    z-index: 1;
    display: none;

    & img {
      height: 100%;
      width: auto;
    }

    @include media(large up){
      display: initial;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 0 24px;

    @include media(medium up) {
      padding: 10px 0 40px;
    }
  }

  &__title {
    font-family: $font-family-primary;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.5px;
    color: $color-white;
    margin-bottom: 36px;

    @include media(medium up) {
      font-size: 36px;
      line-height: 42px;
      margin-bottom: 40px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include media(medium up) {
      flex-direction: row;
    }
  }

  &__content-ofertas {
    background: #F9F9F9;
    position: relative;
    margin-right: 0;
    width: 100%;
    min-height: 467px;

    @include media(medium up) {
      margin-right: 32px;
      width: calc(100% - 287px);
    }
  }

  &__ofertas {
    position: relative;
    z-index: 2;
    padding: 20px;

    & .cards-icon-with-description--landings {
      padding: 0;
    }

    @include media(large up) {
      padding: 24px 32px 62px;
    }

    &__content-title {
      padding-left: 0;
      margin-bottom: 24px;

      @include media(large up) {
        padding-left: 10px;
      }
    }

    &__title {
      font-family: $font-family-primary;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.5px;
      color: $color-black;
      margin-bottom: 10px;

      @include media(medium up) {
        font-size: 21px;
        line-height: 27px;
        margin-bottom: 10px;
      }
    }

    &__subtitle {
      font-family: $font-family-secondary;
      font-size: 14px;
      line-height: 21px;
      color: $color-black;

      @include media(medium up) {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }

  &__legal {
    font-family: $font-family-secondary;
    font-size: 12px;
    line-height: 14px;
    color: $color-black;
    margin-top: 24px;
    margin-bottom: 16px;
  }
}
