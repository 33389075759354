// Añade la propiedad object-fit a una imagen
@mixin object-fit {
  @supports (object-fit: cover) {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
  }

  @supports (object-fit: cover) and (-ms-ime-align:auto) {
    // fix issue on Edge - object-fit not working for video elements
    height: auto;
    width: auto;
  }
}

@mixin disable-user-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}


// This following mixin is modified from the original Bourbon triangle add-on (https://github.com/thoughtbot/bourbon/blob/master/app/assets/stylesheets/addons/_triangle.scss) in order to customise triangle shape and foreground / background colours, which can also create CSS ribbon incredibly easy.

// @include triangle($width $height, $foreground-color $background-color, $direction)
// $height and $background-color can be omitted, if so, $height will equal to $width and $background-color will be transparent.
// $direction contains the following arguments: up, down, left, right, up-right, up-left, down-right, down-left, inset-up, inset-down, inset-left, inset-right.

@mixin triangle ($size, $color, $direction) {
  height: 0;
  width: 0;

  $width: nth($size, 1);
  $height: nth($size, length($size));

  $foreground-color: nth($color, 1);
  $background-color: transparent !default;
  @if (length($color) == 2) {
    $background-color: nth($color, 2);
  }

  @if ($direction == up) or ($direction == down) or ($direction == right) or ($direction == left) {

    $width: $width * 0.5;

    @if $direction == up {
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
      border-bottom: $height solid $foreground-color;

    } @else if $direction == right {
      border-top: $width solid $background-color;
      border-bottom: $width solid $background-color;
      border-left: $height solid $foreground-color;

    } @else if $direction == down {
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
      border-top: $height solid $foreground-color;

    } @else if $direction == left {
      border-top: $width solid $background-color;
      border-bottom: $width solid $background-color;
      border-right: $height solid $foreground-color;
    }
  }

  @else if ($direction == up-right) or ($direction == up-left) {
    border-top: $height solid $foreground-color;

    @if $direction == up-right {
      border-left:  $width solid $background-color;

    } @else if $direction == up-left {
      border-right: $width solid $background-color;
    }
  }

  @else if ($direction == down-right) or ($direction == down-left) {
    border-bottom: $height solid $foreground-color;

    @if $direction == down-right {
      border-left:  $width solid $background-color;

    } @else if $direction == down-left {
      border-right: $width solid $background-color;
    }
  }

  @else if ($direction == inset-up) {
    border-width: $height $width;
    border-style: solid;
    border-color: $background-color $background-color $foreground-color;
  }

  @else if ($direction == inset-down) {
    border-width: $height $width;
    border-style: solid;
    border-color: $foreground-color $background-color $background-color;
  }

  @else if ($direction == inset-right) {
    border-width: $width $height;
    border-style: solid;
    border-color: $background-color $background-color $background-color $foreground-color;
  }

  @else if ($direction == inset-left) {
    border-width: $width $height;
    border-style: solid;
    border-color: $background-color $foreground-color $background-color $background-color;
  }
}

/* Añade una transición */
@mixin transition($time: 0.3s, $function: ease-in-out, $property: all) {
  -webkit-transition: $property $time $function;
  -moz-transition: $property $time $function;
  -o-transition: $property $time $function;
  transition: $property $time $function;
}