.tv-featured {
	margin-bottom: 40px;	

	&__item {
		border-radius: 6px;
		overflow: hidden;
		margin: 0;
		scroll-snap-align: start;
	}
}

.tv-featured--large {
	display: none;
	    @include media(medium up) {
			display: block;
	    }

	.tv-featured__list--large {
		list-style-type: none;
		position: relative;
		display: grid;
		grid-gap: 15px;
		grid-template-columns: 1fr 1fr 2fr 1fr;
		grid-template-rows: 150px 150px;
	}
}

.tv-featured--small {
    @include media(medium up) {
		display: none;
    }
	.tv-featured__list--small {
		list-style-type: none;
		cursor: pointer;
		position: relative;
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		width: 100%;
		overflow-x: scroll;
		overflow-y: hidden;
		-ms-scroll-snap-type: x mandatory;
		scroll-snap-type: x mandatory;
		-webkit-overflow-scrolling: touch;
		
		.tv-featured__item {
			flex: 0 0 250px;
			height: 150px;
			margin: 0 20px 20px 0;
		}
	}
}

.tv-featured__item--big {
	grid-row-end: span 2
}

.tv-featured__item:not(:last-child) {
}

.tv-featured__item img {
	max-width: none;
	height: 100%;
	width: 100%;
	object-fit: cover;
}