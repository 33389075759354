.banner-cobertura-5g {

  &__claim {
    display: inline-block;

    @media (max-width: 2100px){
      left: 650px;
    }

    @media (max-width: 1900px){
      left: 600px;
    }

    @media (max-width: 1700px){
      left: 550px;
    }

    @media (max-width: 1500px){
      left: 420px;
    }

    @media (max-width: 1200px){
      left: 330px;
      top: 380px;
    }


    @media (max-width: 900px){
      left: 420px;
      top: 150px;
    }

    @media (max-width: 800px){
      left: 290px;
    }

    @media (max-width: 700px){
      left: 270px;
    }

    @media (max-width: 600px){
      left: 260px;
    }

    @media (max-width: 550px){
      left: 200px;
      top: 150px;
    }

    @media (max-width: 480px){
      left: 110px;
      top: 150px;
    }

    &__line {

      @media (max-width: 1400px){
        font-size: 24px;
        line-height: 32px;
      }

      @media (max-width: 700px){
          font-size: 24px;
          line-height: 32px;
      }

      @media (max-width: 600px){
        font-size: 20px;
        line-height: 30px;
      }

      @media (max-width: 480px){
        font-size: 18px;
        line-height: 30px;
      }

    }

    &--secondary {

      * {

        @media (max-width: 900px){
          font-size: 22px;
        }

        @media (max-width: 700px){
          font-size: 20px;
        }

        @media (max-width: 660px){
          font-size: 18px;
        }

      }

    }

  }

  &__featured {

    img {

      @media (max-width: 900px){
        max-width: 300px;
        margin: 0 15px 0;
      }

      @media (max-width: 800px){
        max-width: 270px;
        margin: 0 15px 0;
      }

      @media (max-width: 660px){
        max-width: 250px;
        margin: 0 15px 0;
      }

      @media (max-width: 600px){
        max-width: 220px;
        margin: 0 15px 0;
      }

      @media (max-width: 550px){
        margin: 0 0 15px 0;
      }

    }

    @media (max-width: 550px){
      flex-direction: column;
      padding: 25px;
      align-items: flex-start;
    }

  }

}

@media (min-width: 900px) and (max-width: 1130px){
  .banner-cobertura-5g__claim {
    display: none;
  }
}

.cobertura-5g {

  &__cards {

    margin-top: 56px;

    &__wrapper {
      display: flex;
      flex-wrap: wrap;
    }

  }

  &.card {

      flex: 1 0 calc(50% - 16px);
      margin: 0 8px 35px 8px;

      @media (max-width: 1023px){
        flex: 1 0 calc(100% - 16px);
      }

      .card-icon-with-description {

        padding: 0;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        text-align: left;
        height: 100%;;

        @media (max-width: 1023px){
          flex-direction: column;
        }

        &__icon {
          width: 96px;
          flex: 0 0 96px;
          padding: 40px;
          box-sizing: content-box;
          background-color: #FAD7D9;
          margin: 0 24px 0 0;
          height: calc(100% - 80px);
          display: flex;
          align-items: center;

          svg {
            width: 100%;
            height: 100%;

            @media (max-width: 1023px){
              width: 56px;
              height: 56px;
            }
          }

          @media (max-width: 1023px){
            width: 100%;
            padding: 20px;
            box-sizing: border-box;
            margin: 0;
          }

          + div {
            padding-top: 24px;

            @media (max-width: 1023px){
              padding: 24px;
            }
          }

        }

        &:hover {
          &:after {
            transform: scaleX(1);
          }
        }

        &__title {
          margin-top: 0;

          &:after {
            width: 34px;
            height: 4px;
            transform: scaleX(1);
            background-color: #EF3340;
          }


        }

        &__description {
          font-size: 16px;
          line-height: 22px;
          padding-right: 24px;
        }

      }

  }

}


.c-carousel--5g {
  padding-top: 0;
}
