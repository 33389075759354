$breakpoint-mobile: 600px;
$breakpoint-tablet: 1200px;
$breakpoint-desktop: 1201px;

.c-table {
    
    width: 100%;
    box-shadow: inset 0 0 0 1px #ECECEC;
    border-radius: 4px;
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 20px;

    &__thead {
        &__th {
            padding: 30px 0;
            border-bottom: 1px solid #ECECEC;
            text-align: center;
            font-weight: bold;
            display: none;

            &__content {
                display: inline-flex;
            }

            @media (max-width: $breakpoint-mobile){
                &[data-mobile = true]{
                    display: table-cell;
                }
            }

            @media (max-width: $breakpoint-tablet){
                &[data-mobile = true]{
                    display: table-cell;
                }
            }

            @media (min-width: $breakpoint-desktop){
                &[data-desktop = true]{
                    display: table-cell;
                }
            }
        }
    }

    &__tbody {

        &__td {
            padding: 30px 0;
            border-bottom: 1px solid #ECECEC;
            text-align: center;
            display: none;

            @media (max-width: $breakpoint-mobile){
                &[data-mobile = true]{
                    display: table-cell;
                }
            }

            @media (max-width: $breakpoint-tablet){
                &[data-mobile = true]{
                    display: table-cell;
                }
            }
    
            @media (min-width: $breakpoint-desktop){
                &[data-desktop = true]{
                    display: table-cell;
                }
            }

        }

        tr:last-child {
            td {
                border: 0;
            }    
        }

        a {
            color: #E10A0A;
            font-weight: bold;
        }
    }

    .icon {
        display: inline-flex;
        max-width: 16px;
        max-height: 16px;
        stroke-width: 1;
        margin: 0 10px 0 0;
    }
}