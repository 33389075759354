.c-krt-coverage-response {
  display: none;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 4px 4px 20px rgba(38, 37, 37, 0.1);

  &#response-cmn-off {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  &__address,
  &__offers {
    padding: 25px 40px;
  }

  &__address,
  &__status,
  &__offers {
    @include flex();
  }

  &__address {
    @include flex-direction(column);
    border-bottom: 1px solid #F2F2F2;

    @include media(medium up) {
      max-width: 285px;
      border-right: 1px solid #F2F2F2;
    }
  }

  &__address-intro,
  &__address-address {
    font-family: $font-family-secondary;
    font-size: $font-size-16;
    line-height: $line-height-for-font-size-16;
  }

  &__address-address {
    margin-top: 5px;
    font-weight: $font-weight-secondary-bold;
  }

  &__check-again {
    margin-top: 5px;
    text-decoration: underline;
    color: $color-primary;
  }

  &__status {
    align-items: center;
    padding: 54px 30px;

    & .coverage-ok {
      width: 40px;
      flex-shrink: 0;

      & svg {
        width: 100%;
        height: auto;
      }
    }

    & .coverage-ko {
      width: 40px;
      flex-shrink: 0;

      & svg {
        width: 100%;
        height: auto;
      }
    }
  }

  &__status-title {
    margin-left: 30px;
    font-family: $font-family-primary;
    font-size: $font-size-18;
    line-height: $line-height-for-font-size-18;
    font-weight: $font-weight-primary-bold;
    color: $color-primary;

    &--not-coverage {
      color: $color-black;
    }

    @include media(medium up) {
      font-size: $font-size-24;
      line-height: $line-height-for-font-size-24;
    }
  }

  &__offers {
    @include flex-direction(column);
    align-items: center;
    background-color: $color-primary;

    @include media(medium up) {
      max-width: 350px;
    }
  }

  &__offers-title {
    font-family: $font-family-primary;
    font-size: $font-size-18;
    line-height: $line-height-for-font-size-18;
    font-weight: $font-weight-primary-bold;
    color: $color-white;
    text-align: center;
  }

  &__offers-button {
    > button {
      font-family: $font-family-secondary;
      font-size: $font-size-16;
      line-height: $line-height-for-font-size-16;
      font-weight: $font-weight-primary-bold;
      color: $color-primary;
    }
  }

  @include media(medium up) {
    flex-direction: row;
  }
}
