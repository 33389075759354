:root {
  --footer-background: #{$color-mine-shaft};
  --footer-content-spacing-left: 19px;
}

.c-footer {
  background-color: var(--footer-background);
  padding: 0 24px 32px;
  color: $color-white;

  @include media(medium up) {
    padding: 0 24px;
  }

  &__container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    &--simple {
      flex-direction: column;
      justify-content: space-between;
      padding: 25px 0;
      align-items: flex-start;

      @include media(medium up) {
        align-items: center;
        flex-direction: row;
      }

      & .c-footer__top {
        border: 0;
        padding: 0;
        width: auto;
        margin-right: 32px;
        margin-bottom: 24px;

        @include media(medium up) {
          margin-bottom: 0;
        }
      }

      & .c-footer__bottom {
        padding: 0;
        align-items: center;
      }
    }
  }

  &__top {
    padding: 32px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    order: 4;

    @include media(medium up) {
      padding: 29px 0;
      flex-direction: row;
      order: 1;
      border-bottom: 1px solid rgba(255,255,255,0.3);
    }
  }

  &__top-mobile {
    padding-top: 38px;

    @include media (medium up) {
      display: none;
    }
  }

  &__logo {
    display: none;

    @include media(medium up) {
      display: initial;
      padding-left: var(--footer-content-spacing-left);
    }
  }

  &__social-links {
    & a {
      margin: 0 12px;
      opacity: 1;
      transition: $transition-opacity;

      img {
        max-width: 22px;
      }

      &:hover {
        opacity: 0.7;
        transition: $transition-opacity;
      }
    }

    & a:last-child {
      margin-right: 12px;
    }
  }

  &__middle {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    order: 2;

    @include media(medium up) {
      padding: 24px 0 32px 0;
      flex-direction: row;
      border-bottom: 1px solid rgba(255,255,255,0.3);
    }

    &__left-links {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      @include media(medium up) {
        flex-basis: 100%;
        max-width: 750px;
        padding-left: var(--footer-content-spacing-left);
      }
    }

    &__right-links {
      display: flex;
      align-items: flex-start;
      flex: 1 1 auto;

      @include media(medium up) {
        max-width: 275px;
      }
    }
  }

  &__links-group {
    border-bottom: 1px solid rgba(255,255,255,0.3);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 0;
    line-height: 24px;
    padding: 24px 0;
    width: 100%;

    @include media(medium up) {
      flex-direction: row;
      align-items: center;
      border-bottom: none;
      padding: 0;
      width: inherit;
    }

    &:first-child {
      padding-top: 47px;
      @include media(medium up) {
        padding-top: initial;
      }
    }

    &:not(:first-child) {
      @include media(medium up) {
        padding-top: 24px;
      }
    }

    &__title {
      position: relative;
      display: flex;
      align-items: center;
      font-family: $font-family-secondary;
      color: $color-white;
      font-size: 16px;
      font-weight: $font-weight-secondary-bold;
      letter-spacing: -0.5px;
      width: 100%;
      cursor: pointer;

      @include media(medium up) {
        cursor: default;
        border-bottom: 0;
        margin-bottom: 0;
        width: initial;
        color: $color-pantone-red;
      }

      &__arrow {
        position: absolute;
        right: 0;

        @include media(medium up) {
          display: none;
        }
      }

      &.show {
        & .c-footer__links-group__title__arrow {
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
    }

    &__links {
      padding-top: 6px;
    }

    &__links-list {
      margin-top: 12px;
      display: none;

      @include media(medium up) {
        margin-top: 0;
        margin-left: 14px;
        display: block;
      }

      &.show {
        display: flex;
        flex-direction: column;

        @include media(medium up) {
          flex-direction: row;
        }
      }

      & a:not(:first-child) {
        @include media(medium up) {
          margin-left: 14px;
        }
      }
    }

    &__link {
      color: $color-white;
      font-family: $font-family-secondary;
      font-size: 14px;
      opacity: 1;
      transition: $transition-opacity;

      &:hover {
        color: #ef3340;
        /* opacity: 0.7; */
        transition: $transition-opacity;
      }

      &--featured {
        font-weight: $font-weight-secondary-bold;
      }
    }

    &--featured {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      line-height: 24px;
      padding-top: 32px;
      width: 100%;

      &:first-child {
        padding-top: 24px;
        @include media(medium up) {
          padding-top: initial;
        }
      }

      a {
        line-height: 14px;
      }

      > div {
        &:not(:first-child) {
          padding-top: 24px;
        }

        &:nth-child(3) {
          padding-left: 27px;
        }

        &:first-child,
        &:last-child {
          flex-basis: 100%;
          flex-grow: 1;
        }
      }

      .c-footer__links-group__title {
        color: $color-pantone-red;
        cursor: default;
      }

      @include media(medium up) {
        padding-top: 2px;

        &__link {
          width: auto;
        }
      }
    }
  }

  &__bottom {
    padding: 0 0 32px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 12px;
    flex-direction: column;
    order: 5;

    @include media(medium up) {
      padding: 32px var(--footer-content-spacing-left);
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__copyright {
      margin-bottom: 10px;
    }

    &__legal {
      font-size: 12px;
      line-height: 12px;
      color: $color-grey-400;

      @include media(medium up) {
        font-size: 14px;
        line-height: 16px;
      }
    }

    &__links {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      & a:hover {
        color: $color-secondary;
        /*opacity: 0.7;*/
        transition: $transition-opacity;
      }
    }

    &__about-us {
      display: none;
      font-family: $font-family-secondary;
      font-weight: $font-weight-secondary-bold;
      font-size: 16px;
      line-height: 24px;
      padding-top: 24px;

      &--mobile {
        display: flex;
        flex-wrap: wrap;
        order: 3;

        @include media(medium up) {
          display: none !important;
        }
      }

      > a {
        flex-basis: 50%;

        &:hover {
          color: #EF3340;
          /* opacity: 0.7; */
          transition: $transition-opacity;
        }

        &:not(:last-child) {
          @include media(medium up) {
            padding-right: 24px;
          }
        }

        &:nth-child(n+3) {
          margin-top: 8px;

          @include media(medium up) {
            margin-top: 0;
          }
        }

        @include media(medium up) {
          flex-basis: initial;
        }
      }

      @include media(medium up) {
        display: flex;
        flex-wrap: wrap;
        order: 3;
        line-height: 36px;
        padding-top: 0;
      }
    }
  }

  &__links-separator {
    margin-right: 8px;
    margin-left: 8px;

    border: 1px solid $color-grey-400;

    &--thinner {
      border-left-width: 0.5px;
      border-right-width: 0;
      height: 10px;
    }
  }
}
