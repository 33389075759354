#pymes_interested_form{
  .c-lity-modal__header{
    margin-bottom: 8px;
  }
}

.pymes-modal-general-styles{
  padding: 40px;
  --s-v21: 8px;
  .c-lity-modal__header{
    position: static;
  }
}

.pymes_interested-form-subtitle{
  margin-top:0;
  margin-bottom: 20px;
}
.pymes_interested-form-input-wrapper{
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  @include media (medium up){
    flex-direction: row;
  }
  .pymes_interested-input{
    width: 100%;
    &:not(:last-child){
      margin-bottom: 20px;
      @include media (medium up){
        margin-bottom: 0px;
        margin-right: 20px;
      }
    }
    .form-group-input__label{
      padding-left: 0;
      margin-bottom: var(--s-v21);
      .form-label{
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        @include media (medium up){
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
    .form-input{
      width: 100%;
      min-height: 48px;
      margin-top: 0 !important;
      margin-bottom: 0!important;
      &.error{
        margin-top: 0 !important;
        margin-bottom: 0!important;
        border:1px solid $color-danger;
        background: #FFE6E6;
      }
    }
    input{
      padding-left: calc(var(--s-v21)*2);
    }
    .contact-form__empty_field{
      display: none;
      color: $color-danger;
      padding-top: var(--s-v21);
      &.show{
        display: block;
      }
    }
    .contact-form__wrong_field{
      display:none;
      color: $color-danger;
      padding-top: var(--s-v21);
      &.show{
        display: block;
      }
    }
  }
}
.pymes_interested-form-check-box{
  margin-bottom: calc(var(--s-v21)*3);
  display: flex;
  align-items: flex-start;
  .pymes_interested-form-checkbox-text{
    .privacy-policy{
      text-decoration: underline;
      margin-bottom: 12px;
      &:hover{
        cursor:pointer;
      }
    }
  }
  .checkboxLabel{
    height: 16px;
    width: 16px;
  }
  .checkBoxCheckmark{
    border: 1px solid grey;
    &::after{
      left: 3px;
      top: 3px;
    }
  }
  .contact-form__terms-not-accepted{
    display: none;
    color: $color-danger;
    padding-top: var(--s-v21);
    &.show{
      display: block;
    }
  }
}
.pymes_interested-form-actions-wrapper{

  .c-pymes-interested-button__submit-button{
    background: #EF3340;
    border-radius: 8px;
  }

  .button-response-error{
    display: none;
    margin-top: calc(var(--s-v21)*3);
    background: rgba(255, 0, 0, 0.1);
    border: 1px solid #FF0000;
    border-radius: 8px;
    text-align: center;
    padding: var(--s-v21);
    &.show{
      display: block;
    }
  }
  .button-response-succes{
    display: none;
    margin-top: calc(var(--s-v21)*3);
    background: rgba(0, 212, 31, 0.1);
    border: 1px solid #00D41F;
    border-radius: 8px;
    text-align: center;
    padding: var(--s-v21);
    &.show{
      display: block;
    }
  }
}

