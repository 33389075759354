$font-family-primary: var(--font-family-primary);
$font-family-secondary: var(--font-family-secondary);

$title1-font-size: var(--title1-font-size);
$title1-line-height: var(--title1-line-height);

$title2-font-size: var(--title2-font-size);
$title2-line-height: var(--title2-line-height);

$title3-font-size: var(--title3-font-size);
$title3-line-height: var(--title3-line-height);

$title4-font-size: var(--title4-font-size);
$title4-line-height: var(--title4-line-height);

$paragraph-font-size: var(--paragraph-font-size);
$paragraph-line-height: var(--paragraph-line-height);

$big-label-font-size: var(--big-label-font-size);
$big-label-line-height: var(--big-label-line-height);

$link-font-size: var(--link-font-size);
$link-line-height: var(--link-line-height);

$little-title-font-size: var(--little-title-font-size);
$little-title-line-height: var(--little-title-line-height);

$breadcrumb-font-size: var(--breadcrumb-font-size);
$breadcrumb-line-height: var(--breadcrumb-line-height);

$medium-font-size: var(--medium-font-size);
$medium-line-height: var(--medium-line-height);

$small-font-size: var(--small-font-size);
$small-line-height: var(--small-line-height);

$base-font-size: var(--base-font-size);
$base-line-height: var(--base-line-height);

:root {
  --title1-font-size: #{rem(28px)};
  --title1-line-height: #{rem(42px)};
  --title2-font-size: #{rem(24px)};
  --title2-line-height: #{rem(31px)};
  --title3-font-size: #{rem(18px)};
  --title3-line-height: #{rem(27px)};
  --title4-font-size: #{rem(16px)};
  --title4-line-height: #{rem(24px)};

  --paragraph-font-size: #{rem(16px)};
  --paragraph-line-height: #{rem(24px)};

  --big-label-font-size: #{rem(21px)};
  --big-label-line-height: #{rem(24px)};

  --link-font-size: #{rem(16px)};
  --link-line-height: #{rem(20px)};

  --little-title-font-size: #{rem(14px)};
  --little-title-line-height: #{rem(20px)};

  --breadcrumb-font-size: #{rem(14px)};
  --breadcrumb-line-height: #{rem(24px)};

  --medium-font-size: #{rem(14px)};
  --medium-line-height: #{rem(20px)};

  --small-font-size: #{rem(14px)};
  --small-line-height: #{rem(18px)};

  --base-font-size: #{rem(16px)};
  --base-line-heigth: #{rem(20px)};

  @media screen and (min-width: 768px) {
    --title1-font-size: #{rem(50px)};
    --title1-line-height: #{rem(75px)};
    --title2-font-size: #{rem(37px)};
    --title2-line-height: #{rem(55px)};
    --title3-font-size: #{rem(28px)};
    --title3-line-height: #{rem(42px)};
    --title4-font-size: #{rem(21px)};
    --title4-line-height: #{rem(31px)};

    --paragraph-font-size: #{rem(16px)};
    --paragraph-line-height: #{rem(24px)};
  }
}


// Estilos Generales

h1,
.h1 {
  font-size: var(--title1-font-size);
  line-height: var(--title1-line-height);
  font-family: var(--font-family-primary);
  font-weight: 600;
}

h2,
.h2 {
  font-size: var(--title2-font-size);
  line-height: var(--title2-line-height);
  font-family: var(--font-family-primary);
  font-weight: 600;
}

h3,
.h3 {
  font-size: var(--title3-font-size);
  line-height: var(--title3-line-height);
  font-family: var(--font-family-primary);
  font-weight: 600;
}

h4,
.h4 {
  font-size: var(--title4-font-size);
  line-height: var(--title4-line-height);
  font-family: var(--font-family-primary);
  font-weight: 600;
}

h5,
.h5 {
  font-size: var(--title4-font-size);
  line-height: var(--title4-line-height);
  font-family: var(--font-family-primary);
  font-weight: 600;
}

p,
.p {
  font-size: var(--paragraph-font-size);
  line-height: var(--paragraph-line-height);
  font-family: var(--font-family-secondary);
  font-weight: normal;
}

button,
a {
  font-family: var(--font-family-secondary);
  font-size: var(--link-font-size);
  line-height: var(--link-line-height);
  font-weight: 500;
}


// Estilos Casos Concretos

.big-label-style {
  font-family: var(--font-family-secondary);
  font-size: var(--big-label-font-size);
  line-height: var(--big-label-line-height);
  font-weight: bold;
}

.link-label-style {
  font-family: var(--font-family-secondary);
  font-size: var(--link-font-size);
  line-height: var(--link-line-height);
  font-weight: 500;
}

.little-title-style {
  font-family: var(--font-family-secondary);
  font-size: var(--little-title-font-size);
  line-height: var(--little-title-line-height);
  font-weight: 500;
}

.breadcrumb-text-style {
  font-family: var(--font-family-secondary);
  font-size: var(--breadcrumb-font-size);
  line-height: var(--breadcrumb-line-height);
  font-weight: normal;
}

.medium-text-style {
  font-family: var(--font-family-secondary);
  font-size: var(--medium-font-size);
  line-height: var(--medium-line-height);
  font-weight: normal;
}

.small-text-style {
  font-family: var(--font-family-secondary);
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  font-weight: normal;
}
