.c-countdown {
  position: relative;
  background-color: $color-white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  min-width: 160px;
  padding: 12px;

  &__close {
    position: absolute;
    top: 8px;
    right: 16px;
    cursor: pointer;

    & svg {
      width: 20px;
      height: 20px;
    }

    @include media(medium up) {
      top: 24px;
      right: 34px;
    }
  }

  &__content {
    @include flex();
    @include flex-direction(column);
    align-items: center;
  }

  &__content-counter {
    @include flex();
    align-items: center;
  }

  &__item {
    @include flex();
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 4px;

    &__text {
      font-family: $font-family-secondary;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
    }

    &__number {
      color: $color-secondary;
      font-family: $font-family-primary;
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;

      @include media(medium up) {
        font-size: 36px;
        line-height: 42px;
      }
    }
  }

  &__separator {
    font-family: $font-family-primary;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: $color-secondary;
    margin: -18px 4px 0;

    @include media(medium up) {
      font-size: 36px;
      line-height: 42px;
      margin: -18px 18px 0;
    }
  }

  &__text-bottom {
    margin-top: 10px;
    font-family: $font-family-secondary;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;

    @include media(medium up) {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
