.section-description__title {
  margin-bottom: 5px !important;

  font-family: $font-family-primary;
  font-size: 18px !important;
  line-height: 24px;

  @include media(medium up) {
    font-size: 21px !important;
    line-height: 27px;
  }
}

.section-description__subtitle {
  font-family: $font-family-secondary;
  font-size: 14px !important;
  line-height: 14px;

  @include media(medium up) {
    font-size: 16px !important;
    line-height: 24px;
  }
}

.section-description--centered {
  text-align: center;
}

.c-tarifas {
  width: 100%;

  padding: 32px 0;

  &__destacado {
    padding: 12px 34px;
    color: $color-white;
    background-color: $color-azure-radiance;
    width: auto;
    margin: 15px auto 15px auto;
    display: flex;
    align-items: center;

    @include media(medium) {
      text-align: center;
      width: max-content;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      height: 32px;
      width: 32px;
      min-width: 32px;
      margin-left: -22px;
      margin-right: 8px;
      background: rgba($color-white, 0.2);

      & > svg {
        height: 15px;
        & > * {
          stroke: $color-white;
        }
      }
    }
  }

  &__lineas-adicionales {
    display: flex;
    justify-content: center;
    text-align: center;

    padding: 32px 14px;

    font-family: $font-family-secondary;
    font-weight: $font-weight-secondary-bold;
    font-size: $font-size-14;
    line-height: 14px;
    color: $color-grey-600;

    @include media (medium up) {
      font-size: $font-size-16;
      line-height: 24px;
    }
  }
}