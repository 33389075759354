.c-hero-new-pymes{
    position:relative;
    min-height: 436px;
  
  .c-hero-pymes-background{
    position: absolute;
    width: 100%;
    height: 40%;
    z-index: -1;
    overflow: hidden;
    @include media (xxlarge up){
      height: 100%;
    }
    img{
      height: 88%;
      object-fit: cover;
      @include media (xlarge up){
        object-fit: cover;
        height: auto;
        width: 100%;
      }
      &.responsive{
        // transform: translateX(-33%);
        filter: brightness(0.6);
        @include media (medium up){
          // transform: none;
        }
      }
    }
  }
  .c-hero-pymes-wrapper {
    display:flex;
    flex-direction: column;
    padding-top: var(--header-height);
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 100px;
    padding-top: 32px;
    @include media (medium up){
      padding-top: 48px;
    }

    .c-hero-pymes-text-container{
      .c-hero-pymes-text-inner{
        .c-hero-title{
          height: 50px;
          margin-bottom: var(--s-v21);
          @include media (medium up){
            width: fit-content;
            height: 70px;
          }
          img{
            height: 100%;
            width: 100%;
          }
        }
        .c-hero-description{
          font-size: 14px;
          line-height: 18px;
          text-align: center;
          color:  #fff;
          @include media (medium up){
            text-align: left;
            font-size: 18px;
            font-size: var(--subtitle-m-line-height);
          }
        }
      }
    }
    .c-hero-pymes-main-card-container{
      &.fixed{
        position: fixed;
        bottom:0px;
        width: 100%;
        left: 0;
        z-index: 2000;
        height: fit-content;
        @include media (xxlarge up){
          top:102px;
          bottom:72px;
        }
        .c-hero-pymes-main-card-inner{
          background: #fff;
          box-shadow: 4px 4px 25px rgb(38 37 37 / 10%);
          margin: 0 auto;
        }
        .c-hero-pymes-main-card{
          position: relative;
          width: 100%;
          border-radius: 0;
          bottom: unset;
          box-shadow: none;
          padding-bottom: calc(var(--s-v21)*2);
          padding-left: calc(var(--s-v21)*2);
          padding-right: calc(var(--s-v21)*2);
          @media (min-width: 360px){
            padding-left: 32px;
            padding-right: 32px;
          }
          @include media (medium up){
            padding-left: 48px;
            padding-right: 48px;
          }
          @include media (large up){
            padding-left: 58px;
            padding-right: 58px;
          }
          @include media (xxlarge up){
            max-width: 1330px;
            padding-left: inherit;
            padding-right: inherit;
            width: fit-content;
            gap: 40px;
          }
          .main-card-left-block{
            display: none;
            align-items: center;
            align-self: flex-start;
            gap: calc(var(--s-v21)*7);
            @include media (xxlarge up) {
              display: flex;
            }
            .left-block-inner-text{
              .left-block-inner-title{
                display: none;
              }
              .left-block-inner-logo{
                display: block;
                img{
                  height: 50px;
                  width: 122px;
                }
              }
            }
            .left-block-inner-featured{
              .featured-item{
                border:none;
                padding: 0;
                height: fit-content;
                width: fit-content;
                .featured-icon{
                  width: 18px;
                  height: 18px;
                }
                .featured-text{
                  font-size: 16px;
                  line-height: 22px;
                  font-weight: normal;
                  max-width: 120px;
                }
              }
            }
          }
          .main-card-separator{
            display:none;
          }
          .price-block{
            display: flex;
            width: unset;
            gap: calc(var(--s-v21)*6);
            align-items: center;
            @include media (xxlarge up){
              align-items: flex-start;
            }
            .price-container {
              .month-offer{
                width: 100%;
                position: absolute;
                top:0;
                left:0;
                padding-left: 16px;
                border-radius: 0; 
                @media (min-width: 360px){
                  padding-left: 32px;
                }
                @include media (medium up){
                  padding-left: 48px;
                }
                @include media (large up){
                  padding-left: 58px;
                }
                @include media (xxlarge up){
                  position: static;
                  width: fit-content;
                  padding-left: 4px;
                  border-radius: var(--s-v21);
                }
              }
            }
            .c-hero-pymes-card-actions-wrapper{
              margin-top:0;
              a{
                &:last-child{
                  display: none;
                }
              }
            }
          }
        }
      }
      .c-hero-pymes-main-card{
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
        background-color:  #fff;
        padding: calc(var(--s-v21)*4);
        display: flex;
        flex-direction: column;
        box-shadow: 4px 4px 25px rgba(38, 37, 37, 0.1);
        border-radius: 16px;
        position: relative;
        bottom: -56px;
        @include media (medium up) {
          width: fit-content;
        }
        @include media (xxlarge up){
          width: 100%;
          flex-direction: row;
          justify-content: space-between;
        }
        &.fixed{
          margin-top:10px;
        }
        .main-card-left-block{
          margin-bottom: calc(var(--s-v21)*4);
          @include media (xxlarge up){
            margin-bottom: 0;
          }
          .left-block-inner-text{
            .left-block-inner-title{
              font-weight: bold;
              font-family: $font-family-primary;
              font-size: 21px;
              line-height: 29px;
              margin-bottom: calc(var(--s-v21)*2);
              color: var(--color-primary);
            }
            .left-block-inner-logo{
              display: none;
            }
          }
          .left-block-inner-featured{
            display:grid;
            grid-template-columns: 1fr 1fr;
            gap: calc(var(--s-v21)*2);
            @include media (xxlarge up){
              display:flex;
              justify-content: space-between;
              gap: unset;
            }
            .featured-item{
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: var(--s-v21);
              width: 145px;
              width: 100%;
              height: 114px;
              border: 1px solid #F5F5F5;
              padding: calc(var(--s-v21)*4) calc(var(--s-v21)*2) calc(var(--s-v21)*6);
              border-radius: 4px;
              &:not(:last-child){
                @include media (medium up){
                  margin-right: calc(var(--s-v21)*2);
                }
              }
              @include media (large up){
                padding: calc(var(--s-v21)*4) calc(var(--s-v21)*2) calc(var(--s-v21)*6);
                height: 175px;
              }
              @include media (xxlarge up) {
                width: 164px;
              }
              .featured-icon{
                width: 24px;
                height: 24px;
                @include media (medium up){
                  width: 40px;
                  height: 40px;                    
                }
                img{
                  width: 100%;
                  height: 100%;
                }
              }
              .featured-text{
                text-align: center;
                font-family: $font-family-primary;
                font-weight: bold;
                font-size: 16px;
                line-height: 22px;
                max-width: 100px;
                color: var(--color-primary);
                @include media (medium up){
                  font-size: 21px;
                  line-height: 29px;
                  max-width: none;
                }
                img {
                  vertical-align: bottom;
                }
              }
            }
          }
          &.fixed{
            .left-block-inner-logo{
              display: block;
            }
            .left-block-inner-title{
              display:none;
            }
          }
        }
        .main-card-separator{
          width: 1px;
          border-left: 1px solid #F5F5F5;
        }
        .price-block{
          @include media (xlarge up){
            width: 216px;
          }
          .c-hero-pymes-card-actions-wrapper{
            margin-top: calc(var(--s-v21)*2);
            a{
              &:first-child{
                margin-bottom: calc(var(--s-v21)*2);
                background-color: #ef3340;
                border-radius: 8px;
              }
              width: 100%;
              &.c-pymes-hero-button-condition{
                background-color: transparent;
              }
              &.button--secondary{
                color:#ef3340;
                border-radius: var(--s-v21);
              }
              &[href*="condiciones"] {
                border: transparent;
                background-color: none;
                color: #999999;

                &.button {
                  background-color: none;
                }
              }
            }
          }
          .price-container{
            .month-offer{
              white-space: nowrap;
              width: fit-content;
              height: fit-content;
              border-radius: 4px;
              padding:2px 4px;
              background-color: #C9EFFE;
              color: $color-chart-8;
              font-weight: 700;
              font-size: 12px;
              line-height: 20px;
              margin-bottom: 10px;
            }
            .info-container{
              white-space: nowrap;
              .price-promotion{
                font-family: $font-family-primary;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: var(--color-primary);
                @include media (medium up){
                  font-size: 16px;
                  line-height: 24px;
                }
              }
              .price-inner{
                .price-inner-container{
                  display: flex;
                  .price-inner-left{
                    .price-int{
                      font-family: $font-family-primary;
                      font-weight: 700;
                      font-size: 48px;
                      line-height: 48px;
                      color: var(--color-primary);
                    }
                  }
                  .price-inner-right{
                    margin-left: var(--s-v21);
                    .price-decimal{
                      font-family: $font-family-primary;
                      font-weight: 700;
                      font-size: 16px;
                      line-height: 24px;
                      color: var(--color-primary);
                    }
                    .price-month{
                      font-family: $font-family-primary;
                      font-weight: 700;
                      font-size: 16px;
                      line-height: 24px;
                      color: var(--color-primary);
                    }
                    .price-taxes{
                      display: block;
                      font-size: 12px;
                      line-height: 16px;
                      color: var(--color-primary);
                    }
                  }
                }
                .price-later{
                  font-size: 14px;
                  line-height: 20px;
                  margin-right: var(--s-v21);
                  color: var(--color-primary);
                }
              }
            }

          }
        }
      }
    }
  }
}
