.c-card-info {
  background-color: $color-white;
  padding: 13px 16px;
  display: flex;
  align-items: center;
  font-size: $font-size-14;
  line-height: $line-height-for-font-size-18;

  @include media(mobilexlarge down) {
    background: none;
    flex-wrap: wrap;
  }

  &__content {
    display: flex;

    @include media(mobilexlarge down) {
      overflow-y: hidden;
      overflow-x: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
      margin-right: auto;
      white-space: nowrap;

      &::-webkit-scrollbar {
        height: 2px;
        width: 2px;
        background: var(--color-primary);
      }
    }

    &__title {
      font-weight: $font-weight-secondary-bold;
      margin-right: 16px;
    }

    &__items {
      display: flex;
      align-items: center;

      .link.c-card-info__content__items__link-item {
        display: flex;
        align-items: center;
        margin-right: 18px;
      }

      &__item {
        display: flex;
        align-items: center;
        margin-right: 18px;

        &__icon {
          height: 15px;
          width: 15px;
          display: flex;
          align-items: center;
          margin-right: 5px;

          & svg > * {
            stroke: $color-pantone-red !important;
          }
        }

        &__text {
          font-size: $font-size-14;
          line-height: $line-height-for-font-size-18;
        }

        &__line {
          height: 16px;
          border: 1px solid $color-silver;
        }

        &:last-child {
          margin-right: 0;

          .c-card-info__content__items__item__line {
            display: none;
          }
        }
      }
    }
  }

  .link.link--primary.c-card-info__link {
    font-weight: $font-weight-secondary-bold;
    font-size: $font-size-14;
    line-height: $line-height-for-font-size-18;
    margin-left: auto;

    @include media(mobilexlarge down) {
      display: none;
    }

    &--mobile {
      display: none;

      @include media(mobilexlarge down) {
        display: block;
        margin-left: 0;
        margin-top: 21px;
        width: 100%;
        text-align: center;
      }
    }
  }
}
