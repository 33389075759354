.c-table-seguros {
  position: relative;
  margin: 64px auto 0;

  &__cross {
    @include media(medium up) {
      > * {
        display: none !important;
      }
    }
  }

  &__table {
    & .icon {
      height: 12px;

      & .icon__glyph--secondary {
        stroke: #00D41F;
      }
    }

    & .o-table thead {
      background-color: #F6F6F6;
      width: 100px;

      @include media(mobilexlarge up) {
        width: 100px;
      }
    }

    & .o-table tr td{
      font-size: 12px;
      line-height: 16px;
    }

    & .o-table tr td:first-child {
      font-size: 14px;
      line-height: 16px;
    }

    & .o-table td, .o-table th {
      font-family: $font-family-secondary;
      padding: 10px !important;
      border-bottom: 1px solid #e5e5e5;

      @include media(mobilexlarge up) {
        padding: 12px 18px;
      }

      @include media(medium up) {
        padding: 20px 25px;
      }
    }

    & .no-border {
      border: none !important
    }

    & .text-center {
      text-align: center;
    }

    & .bordertop {
      border-top: 1px solid #e5e5e5;
    }

    & .clean {
      padding: 5px 0;
    }

    & .tb-hide-mobile {
      display: none;

      @include media(mobilexlarge up) {
        display: revert;
      }
    }

    &__title-th-first {
      font-weight: bold;
      font-size: 14px !important;
      line-height: 16px !important;
    }

    &__title-th {
      font-weight: bold;
      font-size: 14px !important;
      line-height: 20px !important;
      color: $color-primary;

      @include media(mobilexlarge up) {
        font-size: 16px !important;
        line-height: 24px !important;
      }
    }

    &__title {
      text-transform: uppercase;
      font-size: 12px !important;
      line-height: 16px !important;
      color: #7F7F7F;
    }

    &__legal {
      margin-top: 40px;
      font-family: $font-family-secondary;
      font-size: 12px;
      line-height: 16px;
    }


    &[data-seguro = "movil"] {
      & table thead tr th:nth-child(1) {
        border-bottom: 1px solid $color-primary;

        @include media(mobilexlarge up) {
          border-bottom: 1px solid #e5e5e5;
          border-right: 1px solid $color-primary;
        }
      }

      & table tr td:nth-child(1) {
        border-bottom: 1px solid $color-primary;

        @include media(mobilexlarge up) {
          border-bottom: 1px solid #e5e5e5;
          border-right: 1px solid $color-primary;
        }
      }

      & table tr .no-border:nth-child(1) {
        border-bottom: 1px solid $color-primary !important;

        @include media(mobilexlarge up) {
          border-bottom: 1px solid #e5e5e5 !important;
          border-right: 1px solid $color-primary !important;
        }
      }

      & table thead tr th:nth-child(2) {
        border-top: 0;
        border-bottom: 1px solid $color-primary;
        border-left: 1px solid $color-primary;

        @include media(mobilexlarge up) {
          border-top: 1px solid $color-primary;
          border-right: 1px solid $color-primary;
          border-left: 1px solid #e5e5e5;
          border-bottom: 1px solid #e5e5e5;
        }
      }

      & table tr td:nth-child(2) {
        border-bottom: 1px solid $color-primary;

        @include media(mobilexlarge up) {
          border-bottom: 1px solid #e5e5e5;
          border-right: 1px solid $color-primary;
        }
      }

      & table tr .no-border:nth-child(2) {
        border-bottom: 1px solid $color-primary !important;

        @include media(mobilexlarge up) {
          border-bottom: 1px solid #e5e5e5 !important;
          border-right: 1px solid $color-primary !important;
        }
      }

      & table tr:last-child td:nth-child(2) {
        border-right: 1px solid $color-primary;

        @include media(mobilexlarge up) {
          border-bottom: 1px solid $color-primary;
        }
      }
    }

    &[data-seguro = "movil-plus"] {
      & table thead tr th:nth-child(2) {
        border-bottom: 1px solid $color-primary;

        @include media(mobilexlarge up) {
          border-bottom: 1px solid #e5e5e5;
          border-right: 1px solid $color-primary;
        }
      }

      & table tr td:nth-child(2) {
        border-bottom: 1px solid $color-primary;

        @include media(mobilexlarge up) {
          border-bottom: 1px solid #e5e5e5;
          border-right: 1px solid $color-primary;
        }
      }

      & table tr .no-border:nth-child(2) {
        border-bottom: 1px solid $color-primary !important;

        @include media(mobilexlarge up) {
          border-bottom: 1px solid #e5e5e5 !important;
          border-right: 1px solid $color-primary !important;
        }
      }

      & table thead tr th:nth-child(3) {
        border-top: 0;
        border-bottom: 1px solid $color-primary;
        border-left: 1px solid $color-primary;

        @include media(mobilexlarge up) {
          border-top: 1px solid $color-primary;
          border-right: 1px solid $color-primary;
          border-left: 1px solid #e5e5e5;
          border-bottom: 1px solid #e5e5e5;
        }
      }

      & table tr td:nth-child(3) {
        border-bottom: 1px solid $color-primary;

        @include media(mobilexlarge up) {
          border-bottom: 1px solid #e5e5e5;
          border-right: 1px solid $color-primary;
        }
      }

      & table tr .no-border:nth-child(3) {
        border-bottom: 1px solid $color-primary !important;

        @include media(mobilexlarge up) {
          border-bottom: 1px solid #e5e5e5 !important;
          border-right: 1px solid $color-primary !important;
        }
      }

      & table tr:last-child td:nth-child(3) {
        border-right: 1px solid $color-primary;

        @include media(mobilexlarge up) {
          border-bottom: 1px solid $color-primary;
        }
      }
    }

    &[data-seguro = "rotura-pantalla"] {
      & table thead tr th:nth-child(3) {
        border-bottom: 1px solid $color-primary;

        @include media(mobilexlarge up) {
          border-bottom: 1px solid #e5e5e5;
          border-right: 1px solid $color-primary;
        }
      }

      & table tr td:nth-child(3) {
        border-bottom: 1px solid $color-primary;

        @include media(mobilexlarge up) {
          border-bottom: 1px solid #e5e5e5;
          border-right: 1px solid $color-primary;
        }
      }

      & table tr .no-border:nth-child(3) {
        border-bottom: 1px solid $color-primary !important;

        @include media(mobilexlarge up) {
          border-bottom: 1px solid #e5e5e5 !important;
          border-right: 1px solid $color-primary !important;
        }
      }

      & table thead tr th:nth-child(4) {
        border-top: 1px solid $color-primary;
        border-bottom: 1px solid $color-primary;
        border-left: 1px solid $color-primary;

        @include media(mobilexlarge up) {
          border-top: 1px solid $color-primary;
          border-right: 1px solid $color-primary;
          border-left: 1px solid #e5e5e5;
          border-bottom: 1px solid #e5e5e5;
        }
      }

      & table tr td:nth-child(4) {
        border-bottom: 1px solid $color-primary;

        @include media(mobilexlarge up) {
          border-bottom: 1px solid #e5e5e5;
          border-right: 1px solid $color-primary;
        }
      }

      & table tr .no-border:nth-child(4) {
        border-bottom: 1px solid $color-primary !important;

        @include media(mobilexlarge up) {
          border-bottom: 1px solid #e5e5e5 !important;
          border-right: 1px solid $color-primary !important;
        }
      }

      & table tr:last-child td:nth-child(4) {
        border-right: 1px solid $color-primary;

        @include media(mobilexlarge up) {
          border-bottom: 1px solid $color-primary;
        }
      }
    }
  }
}

#preguntas-frecuentes-sobre-seguros-para-tu-movil h3,
#preguntas-frecuentes-sobre-cuidamos-tu-movil h3,
#preguntas-frecuentes-sobre-cuidamos-tu-movil-plus h3,
#preguntas-frecuentes-sobre-cuidamos-tu-pantalla h3  {
  text-align: left;
}
