.section-banner-equipamiento {
  max-width: 1024px;
  margin: 0 auto;
  // margin-top:;
  // margin-bottom:40px;
  @include media(medium up) {
    // margin-top:;
    // margin-bottom:96px;
  }
  .banner-equipamiento {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #292929;
    border-radius: 16px;
    box-shadow: 4px 4px 20px #e5e5e5;
    position: relative;
    overflow: hidden;
    margin-top: 32px;
    min-height: 120px;
    @include media(medium up) {
      overflow: visible;
      justify-content: flex-end;
      min-height: fit-content;
    }
    @media screen and (min-width: 867px) {
      justify-content: center;
    }
    .banner-equipamiento-img-vector {
      position: absolute;
      left: -60px;
      top: 0;
      height: 100%;
      @include media(medium up) {
        left: 0;
      }
      img {
        height: 100%;
      }
    }
    .banner-equipamiento-img-destacado {
      position: absolute;
      left: 0;
      bottom: -4px;
      @include media(medium up) {
        bottom: 0;
      }
      img {
        height: 129px;
        @include media(medium up) {
          height: 166px;
        }
      }
      &.big {
        img {
          height: 129px;
          @include media(medium up) {
            height: 185px;
          }
        }
      }
    }
    .banner-equipamiento-text-block {
      position: relative;
      padding: calc(8px * 2);
      margin-left: 123px;
      max-width: 500px;
      font-family: $font-family-primary;
      @include media(medium up) {
        padding: calc(8px * 4);
      }
      @media screen and (min-width: 1280px) {
        margin-left: 0;
      }
      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #fff;
        @include media(medium up) {
          font-size: 20px;
          line-height: 28px;
        }
      }
      .description {
        color: #ef3340;
        font-weight: 900;
        font-size: 14px;
        line-height: 18px;
        font-family: $font-family-secondary;
        @include media(medium up) {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 8px;
        }
      }
      .equipment-link {
        color: #fff;
        font-size: 16px;
        line-height: 20px;
        font-weight: 900;
        display: flex;
        align-items: center;
        .icon {
          height: 14px;
          --stroke-width: 3px;
          transform: translateY(3px);
          svg {
            height: 100%;
            path {
              stroke: #fff;
            }
          }
        }
      }
      .banner-eqipment-modal-button{
        display: flex;
        align-items: center;
        .equipment-link{
          background-color: transparent;
          justify-content: flex-start;
          min-width: fit-content;
          padding: 0;
        }
      }
    }
  }
}
