@import './../_mixins/grid';
@import './../_variables/colors';
@import './../_variables/transition';

.input-switch{
	color: $color-white;
	width: 30px;
	height: 16px;
	position: relative;
	display: inline-block;
	text-align: left;
	margin: 2px 0 0 0;
	cursor: pointer;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	-ms-border-radius: 30px;
	-o-border-radius: 30px;
	border-radius: 30px;
	padding: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.input-switch input{
	position: absolute;
	opacity: 0;
	filter: alpha(opacity=0);
}
.input-switch input:checked+i:before {
	background-color: $color-primary;
}
.input-switch i:before {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: $color-silver;
	border-radius: 30px;
	content: "";
	-webkit-transition: all .2s;
	transition: all .2s;
}
.input-switch input:checked+i:after {
	margin-left: 14px;
}
.input-switch i:after {
	position: absolute;
	top: 2px;
	left: 2px;
	bottom: 2px;
	width: 12px;
	background-color: $color-white;
	border-radius: 50%;
	content: "";
	-webkit-box-shadow: 1px 1px 3px rgba(0,0,0,0.25);
	box-shadow: 1px 1px 3px rgba(0,0,0,0.25);
	-webkit-transition: margin-left .3s;
	transition: margin-left .3s;
}