:root {
    .c-comoMontoRouter4g {
        padding: 50px 16px;

        @include media(medium up) {
            padding: 50px 0;
        }
        &__content {
            display: flex;
            flex-direction: column;
            margin: 50px 0;
            @include media(medium up) {
                flex-direction: row-reverse;
            }
        }

        &__picture {
            max-width: calc(100% - 32px);

            @include media(medium up) {
                flex-shrink: 0;
                width: 50%;
                margin-right: 20%;
            }
        }

        &__steps {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        &__step {
            margin-bottom: 25px;
            border-left: 2px solid $color-secondary;
            padding: 0 25px;

            &__title {
                font-family: $font-family-primary;
                font-size: $font-size-18;
                line-height: $line-height-for-font-size-24;
                font-weight: 700;
            }
        }

        &__images {
            padding-top: 20px;
            display: flex;
            justify-content: space-around;
            align-items: flex-end;
        }

    }
}