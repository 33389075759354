.landing-dazn {
  background-color: $color-white;

  &__bg {
    height: 350px;
    left: 0;
    position: absolute;
    top: var(--mundo-r-header-height);
    width: 100%;
    right: 0;
    margin: 0 auto;

    @include media(large up) {
      height: 690px;
      top: calc(var(--mundo-r-header-height) + var(--mundo-r-secondary-menu-height));
    }

    & img {
      height: 100%;
      object-fit: cover;
      object-position: top center;
      width: 100%;
    }
  }

  &__content-header {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    @include media(medium up) {
      flex-direction: row;
    }
  }

  &__content-titles {
    width: 100%;
    max-width: 550px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    margin-right: 0;

    @include media(medium up) {
      margin-right: 20px;
    }
  }

  &__header {
    position: relative;
    width: 100%;
    padding: 36px 16px 0;
    height: auto;

    @include media(medium up) {
      padding: 0 20px;
      height: 690px;
    }

    @include media(large up) {
      padding: 0 80px 0 40px;
    }

    &__title {
      font-family: var(--font-primary);
      font-weight: 700;
      font-size: 21px;
      line-height: 27px;
      color: $color-white;
      margin-bottom: 20px;
      width: 100%;

      @include media(medium up) {
        font-size: 24px;
        line-height: 30px;
      }
    }

    &__pretitle {
      font-family: var(--font-primary);
      font-weight: 700;
      text-transform: uppercase;
      font-size: 18px;
      line-height: 24px;
      color: $color-secondary;
      margin-bottom: 8px;
      display: flex;
      align-items: center;

      & img {
        margin-left: 10px;
      }
    }

    &__copy {
      font-family: var(--font-secondary);
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: $color-white;
    }

    &__right {
      position: relative;
      margin-top: 16px;

      @include media(medium up) {
        margin-top: 0px;
      }
    }

    &__condiciones {
      font-family: var(--font-secondary);
      font-size: 14px;
      line-height: 16px;
      color: $color-white;
      text-align: center;
      margin-top: 8px;
      display: block;
    }
  }

  &__content {
    width: 100%;
  }

  &__offer {
    width: 312px;
    height: 168px;
    background-color: $color-white;
    box-shadow: 4px 4px 25px rgba(38, 37, 37, 0.2);

    @include media(large up) {
      width: 350px;
    }

    &__ribbon {
      background-color: $color-secondary;
      line-height: 32px;
      padding: 0 var(--card--oferta-side-padding);
      padding-left: 20px;
      color: $color-white;
      margin: 0 0 0 -10px;
      right: 0;
      box-shadow: 4px 4px 20px rgba(38, 37, 37, 0.1);
      position: relative;
      font-size: 14px;
      font-family: var(--font-secondary);
      font-weight: 400;

      &:before {
        content: ' ';
        width: 10px;
        height: 32px;
        background-color: $color-secondary-variant-2;
        position: absolute;
        left: 0;
        z-index: -1;
        transform: skewY(45deg);
        transform-origin: 0 0;
      }
    }

    &__content {
      margin-top: 18px;
      padding: 0 16px;
    }

    &__title {
      font-family: var(--font-primary);
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 10px;
      text-align: center;
      width: 100%;
    }

    &__columns {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__cta {
      min-width: 155px;

      @include media(large up) {
        min-width: 180px;
      }
    }

    &__price-def{
      font-family: var(--font-secondary);
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 12px;
    }

    &__pricePerMonth {
      display: flex;
      font-family: var(--font-primary);
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }

    &__priceEntero {
      font-size: 40px;
      line-height: 48px;
      font-family: var(--font-primary);
      font-weight: 700;
    }

    &__priceDecimas {
      font-size: 16px;
      line-height: 18px;
      font-family: var(--font-secondary);
      font-weight: 700;

      span {
        font-size: 14px;
        line-height: 20px;
        color: $color-grey-400;
      }
    }
  }

  &__contratar {
    padding: 40px 0 12px;
    background-color: #F5F5F5;

    @include media(medium up) {
      padding: 68px 0;
    }

    & .c-ventajas-tarifas__ventaja__title {
      margin-bottom: 10px;
    }

    & .c-ventajas-tarifas__ventaja__icon img {
      height: 30px;
    }
  }

  &__donde-puedes {
    padding: 48px 16px 110px;
    /*background-color: $alt-background;*/

    @include media(medium down) {
      padding: 30px 16px 30px;
    }

    & .section-description__title {
      margin-bottom: 20px;
    }

    &__cards {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;

      @include media(medium up) {
        flex-wrap: nowrap;
      }
    }

    &__card {
      width: 156px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: $color-white;
      margin: 8px 4px;

      @include media(medium up) {
        width: 160px;
        height: 160px;
        margin: 16px;
      }
    }

    &__cardIcon {
      height: 32px;
      margin-bottom: 8px;

      @include media(medium up) {
        height: 50px;
      }

      & svg > * {
        stroke: $color-primary;
      }
    }

    &__cardTitle {
      font-size: 16px;
      line-height: 24px;
      font-family: var(--font-secondary);
    }
  }

  &__facturas {
    padding: 28px 16px;
    /*background-color: $alt-background;*/

    @include media(medium up) {
      padding: 60px 16px;
    }

    &__content {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: column;

      @include media(medium up) {
        flex-direction: row;
      }
    }

    &__image {
      width: 100%;
      max-width: 174px;
      margin-right: 0;

      @include media(medium up) {
        max-width: 245px;
        margin-right: 30px;
      }
    }

    &__texts {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      max-width: 350px;
      margin-top: 38px;

      @include media(medium up) {
        margin-top: 0;
      }
    }

    &__title {
      font-family: var(--font-primary);
      font-weight: bold;
      font-size: 21px;
      line-height: 27px;
      margin-bottom: 16px;
    }

    &__copy {
      font-family: var(--font-secondary);
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 22px;
    }

    &__cta {
      position: relative;
      width: 100%;

      @include media(mobilexlarge up) {
        width: auto
      }
    }
  }

  &__canales {
    padding: 48px 16px 20px;

    @include media(medium up) {
      padding: 48px 16px;
    }

    &__card {
      position: relative;
      margin: 0;
      width: 186px;
      height: 260px;

      @include media(medium up) {
        margin: 8px;
        width: 260px;
        height: 360px;
      }

      &__image {
        position: absolute;
        height: 100%;
        width: 100%;

        & img {
          border-radius: 8px;
          height: 100%;
          object-fit: cover;
          object-position: top center;
          width: 100%;
        }
      }

      &__content {
        padding: 16px 16px 24px;
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
      }

      &__title {
        color: $color-white;
        font-size: 18px;
        line-height: 24px;
        font-family: var(--font-primary);
        font-weight: 700;
        margin-bottom: 4px;

        @include media(medium up) {
          font-size: 24px;
          line-height: 30px;
        }
      }

      &__description {
        color: $color-white;
        font-size: 14px;
        line-height: 16px;
        font-family: var(--font-secondary);

        @include media(medium up) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    & .swiper-wrapper {
      flex-wrap: nowrap;

      @include media(medium up) {
        flex-wrap: wrap;
        justify-content: center;
      }
    }

    & .c-carousel .swiper-slide {
      width: fit-content;
    }

    & .c-carousel .swiper-container {
      padding: 0 0 40px;

      @include media(medium up) {
        padding: 0;
      }
    }

    & .c-carousel__nav-controls{
      display: block;

      @include media(medium up) {
        display: none;
      }
    }

    & .c-carousel__btn-nav {
      display: none;
    }

    & .c-carousel .swiper-pagination-bullets {
      left: 0;
      right: 0;
      margin: 0 auto;
    }

    & .section-description--centered {
      margin-bottom: 0;
    }
  }

  &__ligas {
    padding: 30px 16px 60px;

    &__logo {
      background: #F2F2F2;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.075);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 97px;
      height: 97px;
    }

    & .c-carousel .swiper-slide {
      width: fit-content;
    }

    & .c-carousel__nav-controls{
      display: block;
    }

    & .c-carousel .swiper-pagination-bullets {
      left: 0;
      right: 0;
      margin: 0 auto;
    }

    .c-carousel .swiper-container {
      padding: 16px 0;
    }
  }

  & .section--faq {
    background-color: $color-white;
  }

  & .c-faq__header {
    text-align: left !important;
  }
/* -- */

&__section {

    background-color: #F5F5F5;

  .section-description__title {
    font-family: var(var(--font-primary));
    font-weight: 600;
    font-size: 24px !important;
    color: var(--color-dark);
    line-height: 0.925em;

    @include media(medium up) {
      font-size: 28px !important;
    }
  }

}

  &__cards-section {
    padding: 40px 0 70px 0;
  }

  &__elige-plan {

    padding: 40px 0 70px 0;
    max-width: 100%;
    background-color: #F5F5F5;
  
      &__inner {
        display: flex;
        justify-content: center;
        align-items: center;
        align-items: flex-end;
        margin: 0 -33px;
        flex-wrap: wrap;
  
        @include media(medium up) {
          flex-direction: row;
          align-items: initial;
        }
      }
  
      &__footer {
        display: flex;
        justify-content: center;
  
        .icon {
          display: inline-block;
          width: 20px;
          height: 20px;
          margin: 0 5px 0 0;
  
            svg {
              width: 100%;
              height: 100%;
            }
  
            path, circle {
              stroke: var(--color-primary);
            }
        }
  
        a {
          color: var(--color-primary);
          display: inline-flex;
          align-items: center;
          white-space: nowrap;
        }
      }
  
      &__card {
        &--shadow {
          box-shadow: 4px 4px 20px #ddd;
        }
  
      }
    }
}

.dazn-elige-plan-card {

  width: 100%;
  max-width: 310px;
  padding: 24px 30px;
  background-color: #fff;
  border-radius: 8px;
  margin: 0 33px 33px 33px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  
  &--shadow {
    box-shadow: 4px 4px 20px #ddd;
  }

  a:not(.button) {
    color: var(--color-primary);
  }

  &__header {

    border-bottom: 1px solid #C4C4C4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 18px;
    align-self: normal;

    &__icon {
      display: block;
      width: 58px;
      margin: 0 0 10px 0
    }

  }

  &__icons {
    margin: 16px 0 10px 0;
    padding: 0;
    list-style-type: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 13px;
    grid-row-gap: 25px;

    &__item {
    }

    .laliga-santander {
      grid-column: 1/4;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;

    p {
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
    }

    &__icon {
      display: block;
      width: 44px;
      margin: 0 0 5px 0
    }
  }

  &__footer {
    padding: 16px 0 0 0;
    align-self: normal;

    &.dazn-elige-plan-card--total {
      border-top: 1px solid #C4C4C4;
      margin-top: 16px;
      p{
        font-size:14px;
        margin-bottom: 1em;
      }
    }
  }

  &__price {
    // margin-bottom: 20px;
    display: flex;
    font-family: var(--font-secondary);
    justify-content: center;

    &__left {
      font-weight: 600;
      font-size: 50px;
      line-height: 40px;
    }

    &__right {

      display: flex;
      flex-direction: column;

      &:first-child {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: left;
      }
      &:last-child {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: left;
      }
    }
  }

}

.button--dazn {
  border-radius: 8px;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
}


.landing-dazn_cards-section {

  &__items {
    display: flex;
    @media (min-width: 768px) {
      margin-left: auto;
      margin-right: auto;
      max-width: 1110px;
      width: 100%;
    }

    .swiper-wrapper {
      align-items: stretch;
    }

  }

  &__item {
    box-shadow: 4px 4px 20px #ddd;
    border-radius: 16px;
    padding: 20px;
    overflow: hidden;
    display: flex;
    flex: 1 0 200px;
    height: 290px;

    @media (min-width: 768px){
      flex: 1 0;
      height: auto;
    }

    &--double {
      @media (min-width: 768px){
        flex: 2 0;
      }
    }

    &__wrapper {
      position: relative;
      border-radius: 16px;
      overflow: hidden;
      flex: 1 0;

      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(2.08deg, rgba(0, 0, 0, 0.2) 1.7%, rgba(0, 0, 0, 0) 98.25%);
      }
    }

    &__caption {
      font-family: var(--font-secondary);
      font-size: 18px;
      line-height: 24px;
      position: absolute;
      bottom: 70px;
      left: 15px;
      color: #fff;
      font-weight: 700;
      @media (min-width: 768px){
        bottom: 90px;
      }
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  &__logos {
    position: absolute;
    left: 10px;
    bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px;
  }
  
  &__logo {
    width: 50px;
    height: 50px;
    object-fit: contain;
    border-radius: 10px;
    @media (min-width: 768px){
      width: 70px;
      height: 70px;
    }
  }

}




