@import './../_mixins/base-html-elements';
@import './../_mixins/grid';
@import './../_variables/dimensions';

.kit-digital {
  color: $color-primary;

  .background-grey {
    background-color: $color-grey-300;
  }

  .background-primary {
    background-color: $color-primary;
  }

  .page-header--kit-digital-hero {
    @include media(xlarge up) {
      min-height: 447px;
    }

    .page-header {
      &__content {
        @include media(xlarge up) {
          flex-direction: row;
          position: relative;
        }
      }
      &__pre-title{
        align-self: flex-start;
        font-size: 16px;
        line-height: 24px;
      }
      &__details {
        @media screen and (min-width: 1280px) {
          max-width: 570px;
        }

        @include media(xlarge up) {
          max-width: 610px;
        }
      }

      &__desktop-space {
        display: none;

        @include media(xlarge up) {
          display: block;

          position: absolute;
          top: 46px;
          right: 0;

          max-width: 500px;
        }

        .kit-digital-form__form-col-help {
          max-width: 100%;
        }
      }

      &__title_tag_text {
        font-family: $font-family-secondary;
        font-size: $font-size-14;
        line-height: $line-height-for-font-size-14;
        color: $color-white;
        text-transform: uppercase;
        text-align: left;
        align-self: flex-start;

        margin-bottom: 8px;

        @include media(medium up) {
          font-size: $font-size-16;
          line-height: $line-height-for-font-size-16;
        }
      }

      &__title {
        font-family: $font-family-primary;
        font-size: $font-size-24 !important;
        line-height: $line-height-for-font-size-24;
        font-weight: 700;
        align-self: flex-start;
        text-align: left;

        @include media(medium up) {
          font-size: $font-size-36 !important;
          line-height: $line-height-for-font-size-36;
        }
      }

      &__description {
        font-family: $font-family-secondary;
        font-size: $font-size-16;
        line-height: $line-height-for-font-size-16;
        align-self: flex-start;
        text-align: left;

        @include media(medium up) {
          font-size: $font-size-18;
          line-height: $line-height-for-font-size-18;
        }
      }
    }
  }

  .logos-wrapper-title{
    line-height: 0.85rem;
  }

  .logos-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    margin: 146px 16px 32px;

    @media screen and (min-width: 375px) {
      margin: 146px 40px 32px;
    }

    @media screen and (min-width: 515px) {
      margin: 136px 40px 32px;
    }

    @media screen and (min-width: 600px) {
      flex-wrap: nowrap;
    }

    @include media(medium up) {
      margin-top: 121px;
    }

    @include media(large up) {
      margin-top: 101px;
    }

    @include media(xlarge up) {
      margin: 40px auto;
    }

    h2{
      display:none;
      max-width: 620px;
      font-size: 0.85rem;
      color: var(--color-grey-600);
      @include media (large up){
        display:block;
      }
    }
    .logos-image-container{
      display: flex;
      flex-wrap: wrap;
      max-width: 660px;
      justify-content: center;
      @include media (large up){
        justify-content: flex-start;
        gap: 20px;
      }
      .logos-image{
        max-height: 27px;
        margin-bottom:calc(var(--s)*5);
        &:not(:last-child){
          margin-right: calc(var(--s) * 2 );
        }
        @include media(large up){
            max-height: 80px;
            &:not(:last-child){
                margin-right: calc(var(--s)*10);
            }
        }
      }
    }

    .logos-separator{
      display: none;
      margin-top: var(--s);
      margin-bottom: calc(var(--s)*2);
      height: 1px;
      border-bottom:1px solid var(--color-grey-600);
      width: 100px;
      @include media(large up){
          display:block;
          width: 170px;
          margin-top: calc(var(--s)*2);
          margin-bottom: calc(var(--s)*4);
      }
    }

    .first-logos-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 100%;

      margin-bottom: 16px;

      @media screen and (min-width: 600px) {
        margin-bottom: 0;
      }

      @include media(medium up) {
        justify-content: flex-start;

        flex-wrap: nowrap;
        width: fit-content;
      }

      img:first-child {
        margin-right: 30px;

        @include media(medium up) {
          margin-right: 0;
        }
      }
      img + img {
        @include media(medium up) {
          margin-left: 30px;
        }
      }
    }

    .second-logos-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      width: 100%;

      @include media(medium up) {
        justify-content: flex-start;
        flex-wrap: nowrap;
        width: fit-content;
      }

      img + img {
        @include media(medium up) {
          margin-left: 30px;
        }
      }
    }

    .first-logos-wrapper + .second-logos-wrapper {
      @media screen and (min-width: 600px) {
        margin-left: 30px;
      }
    }
  }

  .are-you-interested {
    position: relative;
    .mobile-wrapper {
      position: absolute;
      top: -215px;

      z-index: 3;

      padding: 24px;
      margin: 0 16px;

      background-color: $color-white;
      box-shadow: 4px 4px 20px rgba(38, 37, 37, 0.1);

      @media screen and (min-width: 515px) {
        top: -134px;
        left: 50%;

        transform: translate(-50%, -50%);

        margin: 0;
        width: 90%;
      }

      @include media(medium up) {
        top: -119px;
        width: 95%;
      }

      @include media(large up) {
        top: -119px;
      }

      @include media(large up) {
        top: -127px;
      }

      @include media(xlarge up) {
        display: none;
      }
    }

    &__title {
      font-family: $font-family-primary;
      font-size: $font-size-18;
      line-height: $line-height-for-font-size-18;
      font-weight: 700;

      margin-bottom: 8px;
    }

    &__description {
      font-family: $font-family-secondary;
      font-size: $font-size-16;
      line-height: $line-height-for-font-size-16;

      margin-bottom: 24px;
    }

    .button--kit-digital {
      display: flex;
      justify-content: center;
      align-items: center;

      font-weight: 700;
    }
  }

  .what-is-digital-kit {
    padding: 40px 16px;

    @include media(medium up) {
      padding: 0 0 155px;
    }

    .section-description--first-description {
      margin-bottom: 16px;

      @include media(medium up) {
        margin-top: 115px;
        margin-bottom: 0;
      }

      .section-description__title {
        font-family: $font-family-primary;
        font-size: $font-size-21 !important;
        line-height: $line-height-for-font-size-22;

        color: $color-primary;
        @include media(medium up) {
          font-size: $font-size-24 !important;
          line-height: $line-height-for-font-size-24;
        }
      }
    }

    .c-kit-digital--kit-digital-section01 {
      margin-top: 16px;

      @include media(medium up) {
        margin-top: 40px;
      }
    }

    .c-kit-digital--kit-digital-section02,
    .c-kit-digital--kit-digital-section03 {
      margin-top: 32px;

      @include media(medium up) {
        margin-top: 70px;
      }
    }

    .section-description__title {
      font-family: $font-family-primary;
      font-size: $font-size-21 !important;
      line-height: $line-height-for-font-size-22;

      color: $color-primary;

      @include media(medium up) {
        font-size: $font-size-24 !important;
        line-height: $line-height-for-font-size-24;
      }
    }

    .section-description__description {
      font-family: $font-family-secondary;
      font-size: $font-size-16;
      line-height: $line-height-for-font-size-16;

      color: $color-primary;

      p:first-child {
        margin-top: 3px !important;

        @include media(medium up) {
          margin-top: 20px;
        }
      }
    }
  }

  .advice-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .digital-solution-row {
      padding: 24px 16px;
      width: 100%;
      margin-bottom: 64px;

      @include media(xlarge up) {
        order: -1;

        display: flex;
        justify-content: space-between;

        padding: 32px 0;
        margin-bottom: 64px;
      }

      .inner-wrapper {
        justify-content: center;

        @include media(xlarge up) {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }

        .digital-solution-title {
          font-family: $font-family-primary;
          font-size: $font-size-21;
          line-height: $line-height-for-font-size-22;
          font-weight: 700;
          color: $color-white;

          text-align: center;
          margin-bottom: 8px;

          @include media(medium up) {
            text-align: left;
          }
        }

        .digital-solution-description {
          font-family: $font-family-secondary;
          font-size: $font-size-16;
          line-height: $line-height-for-font-size-16;
          font-weight: 400;
          color: $color-white;

          text-align: center;
          margin-bottom: 16px;

          @include media(xlarge up) {
            margin-bottom: 0;
            text-align: left;
          }
        }

        .digital-solution-actions-wrapper {
          width: 100%;

          margin-top: 0;

          @include media(xlarge up) {
            width: auto;
          }

          .button {
            display: flex;
            justify-content: center;
            align-items: center;

            text-align: center;

            max-width: 250px;
            margin: 0 auto;
            width: 100%;

            font-weight: 700;

            background-color: $color-secondary;

            @include media(medium up) {
              width: auto;
            }
          }
        }
      }
    }

    .kit-digital-help {
      margin-bottom: 40px;
      margin-left: -16px;
      margin-right: -16px;
      flex-direction:column-reverse;

      @include media(medium up) {
        margin-bottom: 0px;
        flex-direction:column;
        max-width: 100%;
        width: 100%;
        margin-top: 64px;
        margin-left: 0;
        margin-right: 0;
      }

      @include media(xlarge up) {
        margin-top: 0;
      }



      .kit-digital-help-cards-wrapper {
        width: 100%;
        margin-bottom: 40px;
        padding: 0 16px;

        justify-content: center;

        @include media(medium up) {
          margin-bottom: 64px;
        }
      }

      .section-description__description {
        max-width: 670px;
        margin: 0 auto;
        font-family: $font-family-secondary;
        font-size: $font-size-16;
        line-height: $line-height-for-font-size-16;
        font-weight: 400;
      }

      .section-description--centered {
        margin-bottom: 0;
      }

      .section-description__title {
        width: 100%;
        font-size: $font-size-21 !important;
        line-height: $line-height-for-font-size-22;

        @include media(medium up) {
          font-size: $font-size-24 !important;
          line-height: $line-height-for-font-size-24;
        }
      }
      .button--kit-digital-help {
        display: flex;
        justify-content: center;
        align-items: center;

        text-align: center;

        flex-basis: 250px;

        font-weight: 700;
      }
    }
  }
  .section--faq-kit-digital {
    background-color: $color-white;

    .u-wrapper {
      max-width: 1280px;

      &.c-faq {
        padding-top: 0;
      }

      .c-faq__header,
      .c-faq__content {
        padding-right: 0;
        padding-left: 0;
          // KIT DIGITAL MODIFIERS
        .accordion--faq-kit-digital{
          .accordion-item:first-child{
            .accordion-item__header{
              background-color: var(--color-primary);
              color:var(--color-white);
              .list-item{
                div{
                  color:var(--color-white);
                }
                svg{
                  path{
                    stroke:#fff;
                  }
                }
              }
            }
          }
          & .kit-digital-faq-featured{
            border: 1px solid var(--color-grey-400);
            padding:16px;
            border-radius: 8px;
            &:not(:last-child){
              margin-bottom: 8px;
            }
            .black{
              font-weight: bold;
              color:var(--color-secondary);
            }
            & .kit-digital-faq-featured-title{
              display: flex;
              justify-content: space-between;
              align-items: center;
              &:hover{
                cursor: pointer !important;
              }
              p{
                margin:0;
                &.close{
                  display: none;
                  transform: translateY(-8px);
                }
              }
            }
            .kit-digital-faq-featured-inner{
              display: block;
              margin-top: 16px;
              @include media (medium up){
                padding:16px;
              }
              &.close{
                display: none;
              }
              .kit-digital-faq-featured-inner-link{
                display: block;
                color:var(--color-tertiary);
                margin-bottom: 16px;
                font-weight: bold;
              }
              .footer-warning{
                background-color:#FFEEE5;
                padding: 16px;
                border-radius: 8px;
                .warning-colored{
                  color: var(--color-primary);
                  font-weight: bold;
                }
              }
              .kit-digital-faq-featured-inner-list-item{
                p{
                  span{
                    display: block;
                  }
                }
              }
            }
          }
          .kit-digital-faq-featured-button{
            display: block;
            background-color: var(--color-primary);
            color: var(--color-white);
            padding: 20px 30px;
            font-weight: bold;
            margin-top: 24px;
            text-decoration: none;
            max-width: fit-content;
          }
        }
      }

      .section-description__title,
      .section-description__description {
        text-align: left;
        max-width: 820px;
      }
    }
  }
  .section--faq-kit-digital {
    background-color: $color-white;

    .u-wrapper {
      max-width: 1280px;

      &.c-faq {
        padding-top: 0;
      }

      .c-faq__header,
      .c-faq__content {
        padding-right: 0;
        padding-left: 0;
      }

      .section-description__title,
      .section-description__description {
        text-align: left;
        max-width: 820px;
      }
    }
  }

  .benefits {
    padding-top: 40px;
    padding-bottom: 40px;

    @include media(medium up) {
      padding-top: 64px;
      padding-bottom: 64px;
    }

    .section-description--kit_digital_benefits_description {
      margin-bottom: 24px;

      max-width: 820px;

      .section-description__title {
        font-family: $font-family-primary;
        font-size: $font-size-21 !important;
        line-height: $line-height-for-font-size-22;

        color: $color-primary;

        @include media(medium up) {
          font-size: $font-size-24 !important;
          line-height: $line-height-for-font-size-24;
        }
      }
    }

    .benefits-wrapper {
      background-color: $color-white;

      padding-bottom: 16px;

      font-family: $font-family-primary;
      font-size: $font-size-21 !important;
      line-height: $line-height-for-font-size-22;

      color: $color-primary;

      @include media(medium up) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-bottom: 48px;

        font-size: $font-size-24 !important;
        line-height: $line-height-for-font-size-24;
      }

      .links-with-icon__column {
        padding: 0;

        @include media(xlarge up) {
          padding: 0 48px;
        }
      }

      .links-with-icon__link-group {
        max-width: 100%;
        padding-bottom: 0;

        @include media(medium up) {
          padding: 0;
        }

        @include media(xxxlarge up) {
          column-gap: 160px;
        }
      }

      .links-with-icon {
        margin: 24px 0;

        @include media(medium up) {
          flex-basis: 48%;
          display: block;
          margin: 40px 0 32px;
          padding-left: 16px;
        }

        .section-description--centered {
          margin-bottom: 0;
        }
      }

      .links-with-icon + .links-with-icon {
        margin-top: -15px;

        @include media(medium up) {
          margin: 40px 0 32px;
          padding-right: 16px;
        }
      }

      .link-with-icon .icon__glyph {
        stroke: #00d41f;
      }

      .link-with-icon + .link-with-icon {
        margin-top: 16px;
      }

      .outer-wrapper {
        margin: 0 16px;

        @include media(medium up) {
          flex-basis: 100%;
        }

        @include media(xlarge up) {
          margin: 0 48px;
        }
        .advice-orange-card {
          background: #ef33401a;

          .inner-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            width: 100%;

            padding: 12px 16px;

            @include media(medium up) {
              max-width: 1010px;
              margin: 0 auto;
              padding: 15px;
              width: fit-content;
            }

            img {
              margin-right: 18px;
            }
          }

          .advice-description {
            font-family: $font-family-secondary;
            font-size: $font-size-16;
            line-height: $line-height-for-font-size-16;
            color: $color-secondary;
          }
        }
      }
    }
  }

  .kit-digital-contact {
    margin-top: 24px;
    margin-bottom: 80px;

    @include media(xlarge up) {
      display: flex;
      flex-flow: initial;
      justify-content: space-between;

      margin-top: 64px;
      margin-bottom: 128px;
    }

    .kit-digital-text {
      margin-top: 16px;
      @include media(xlarge up) {
        margin-top: 0;
        flex-basis: 55%;
        max-width: 610px;
        font-size: 16px;
        line-height: 24px;
      }

      .section-description__title {
        font-family: $font-family-primary;
        font-size: $font-size-21 !important;
        line-height: $line-height-for-font-size-22;

        color: $color-primary;

        @include media(medium up) {
          font-size: $font-size-24 !important;
          line-height: $line-height-for-font-size-24;
        }
      }
    }

    .kit-digital-form {
      box-shadow: 0 0 0;
      padding: 16px 0 0;

      @include media(xlarge up) {
        flex-basis: 70%;
        margin: 0 0 0 30px;
        padding: 0;
      }

      &:hover {
        .legal-text {
          .ellipsis {
            all: unset;
          }
        }
      }

      .kit-digital-form__form-col {
        @include media(medium up) {
          flex-basis: 48%;
          max-width: 48%;
        }
      }

      .legal-text {
        .ellipsis {
          width: 100%;
          text-overflow: unset;
          white-space: normal;
        }
      }

      .kit-digital-form__query-form-col {
        align-items: flex-start;
      }

      .kit-digital-form__submit {
        max-width: 160px;

        display: flex;
        flex-direction: column;

        .section-description__title {
          font-family: $font-family-primary;
          font-size: $font-size-24 !important;
          line-height: $line-height-for-font-size-24;

          color: $color-primary;
        }
      }
    }
  }
  .footer {
    margin-top: 0;
  }
}
