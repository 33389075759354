:root {
  --card--oferta-side-padding: 16px;

  /* temporal, para igual el alto de los botones cmn de byside (original 48px) */
  --card--oferta-button-height: 58px;

  --card-oferta-max-width: 300px;
}

.c-ofertaCard {
  position: relative;
  width: 100%;
  max-width: var(--card-oferta-max-width);
  background-color: #F9F9F9;
  box-shadow: 0px 10px 30px rgba(38, 38, 38, 0.15);
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  height: 100%;
  min-height: 500px;
  transition: background-color 0.2s ease,
  box-shadow 0.2s ease;

  &:hover {
    background-color: $color-grey-200;
    box-shadow: 0px 10px 30px rgba(38, 38, 38, .30);

    & .c-ofertaCard__promo-inferior {
      background-color: $color-white;
      transition: background-color 0.2s ease;
    }
  }

  &--configurar {
    background-color: $color-grey-800;

    &:hover {
      background-color: $color-grey-800;
    }
  }

  &--withRibbon {
    margin-top: 0;
  }

  &__ribbon {
    background-color: #14CCAF;
    font-size: 14px;
    line-height: 24px;
    width: 100%;
    padding: 0 var(--card--oferta-side-padding);
    color: $color-white;
    font-family: $font-family-secondary;
    font-weight: bold;

    .icon {
      height: auto;
      width: auto;
      margin-right: 8px;

      & > * {
        stroke: $color-white;
        fill: $color-white;
      }
    }
  }

  &__header {
    padding: var(--card--oferta-side-padding);
    font-size: 21px;
    font-family: $font-family-primary;
    line-height: 27px;
    font-weight: bold;
  }

  &__superior {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1 1 auto;
  }

  &__body {
    // padding: 0 var(--card--oferta-side-padding);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1 1 auto;
  }

  &__items {
    padding: 0 var(--card--oferta-side-padding);
    flex: 1 1 auto;
  }

  &__conditions {
    padding: var(--card--oferta-side-padding) var(--card--oferta-side-padding) 0;
  }

  &__inferior {
    padding: 10px var(--card--oferta-side-padding) var(--card--oferta-side-padding);
    // padding: var(--card--oferta-side-padding);
    // padding-bottom: var(--card--oferta-side-padding);
  }

  &__promo-inferior {
    background: $color-grey-200;
    padding: 8px 16px;
    min-height: 82px;
    display: flex;
    justify-content: space-between;
    transition: background-color 0.2s ease;

    &__text {
      width: 100%;
      max-width: 170px;
      margin-right: 8px;
    }

    &__pretitle {
      font-family: $font-family-secondary;
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 10px;
    }

    &__title {
      font-family: $font-family-secondary;
      font-size: 14px;
      line-height: 16px;
    }

    &__icon {
      margin-top: 8px;

      & img, & svg {
        max-width: 70px;
        height: auto;
      }
    }
  }

  &__button {
    /* temporal (no exisita), para igualar el alto de los botones cmn de byside */
    min-height: var(--card--oferta-button-height);

    padding: 0;
    line-height: var(--card--oferta-button-height);
    width: 100%;

    .button__content {
      line-height: var(--card--oferta-button-height);
    }
  }

  &__links {
    & a {
      min-height: var(--card--oferta-button-height);
      line-height: var(--card--oferta-button-height);
    }
  }

  &__destacada {
    background-color: $color-azure-radiance;
    line-height: 32px;
    padding: 0 var(--card--oferta-side-padding);
    padding-left: 30px;
    color: $color-white;
    margin: 0 -16px;
    margin-left: -24px;
    margin-bottom: 20px;
    right: 0;
    box-shadow: 4px 4px 20px rgba(38, 37, 37, 0.1);
    position: relative;
    font-size: 14px;
    font-family: $font-family-secondary;
    font-weight: $font-weight-secondary-regular;

    &:before {
      content: ' ';
      width: 8px;
      height: 32px;
      background-color: #0067BB;
      position: absolute;
      left: 0px;
      z-index: -1;
      transform: skewY(45deg);
      transform-origin: 0 0;
    }

    &--image {
      padding-left: 30px !important;
      padding-right: 120px;
      line-height: 1.5em;
    }
  }


  &__destacadaImage {
    position: absolute;
    right: var(--card--oferta-side-padding);
    bottom: 0;
  }

  &__discountText {
    font-size: 14px;
    line-height: 14px;
    font-family: $font-family-secondary;
    font-weight: $font-weight-secondary-regular;
    color: $color-gray;
    margin-left: 4px;
  }

  &__itemDestacado {
    background-color: $color-azure-radiance;
    // line-height: 24px !important;
    color: $color-white;
    font-size: 12px !important;
    font-family: $font-family-secondary;
    font-weight: $font-weight-secondary-regular;
    padding: 5px;

    strong {
      font-weight: $font-weight-secondary-bold;
    }
  }

  &__mas_info {
    color: $color-secondary;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    display: block;
    width: 100%;
    margin-top: 8px;
    line-height: 18px;
  }

  &__item {
    display: flex;
    margin-bottom: 17px;
    align-items: flex-start;

    &--destacada {
      display: block;

      .c-ofertaCard__destacada {
        padding-left: 54px;
      }
    }

    p {
      font-family: $font-family-secondary;
      font-size: 14px;
      line-height: 16px;
      margin-left: 8px;

      span {
        color: $color-azure-radiance;

        &.green {
          color: #14CCAF;
        }
      }
    }
  }

  &__itemLine {
    margin-top: 3px;

    p:nth-child(1n+2).c-ofertaCard__itemDestacado {
      margin-top: 5px;
    }

    .icon {
      width: auto;
      height: auto;
      margin-right: 2px;
    }
  }

  &__icon {
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 24px;
  }

  &__prices {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 8px;
    max-width: 266px;
    margin-top: 8px;

    &--column {
      flex-direction: column;
      margin-top: 32px;
      align-items: flex-start;
    }
  }

  &__pricePerMonth {
    display: flex;
    font-family: $font-family-primary;
    font-weight: $font-weight-primary-bold;
    font-size: 16px;
    line-height: 24px;

    &--withDiscount {
      font-family: $font-family-primary;
      font-weight: $font-weight-primary-bold;

      p {
        color: $color-gray;
        font-family: $font-family-secondary;
        font-weight: $font-weight-secondary-regular;
        font-size: 12px;
        line-height: 21px;
      }

      span {
        &.decimals {
          font-size: 14px;
        }

        &.text {
          font-size: 14px;
          font-family: $font-family-secondary;
          font-weight: $font-weight-secondary-regular;
          color: $color-gray;
        }
      }
    }
  }

  &__priceEntero {
    font-size: 48px;
    line-height: 48px;
    font-family: $font-family-primary;
    font-weight: $font-weight-primary-bold;
  }

  &__priceDecimas {
    font-size: 24px;
    line-height: 18px;
    font-family: $font-family-secondary;
    font-weight: $font-weight-secondary-bold;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    span {
      font-size: 14px;
      line-height: normal;
      color: $color-gray;
      margin-top: 4px;
    }

    &--compromiso-internet {
      font-family: $font-family-primary;
      font-size: 38px;
      line-height: 46px;
      display: flex;
      flex-direction: column;

      span {
        font-size: 24px;
        line-height: 28px;
        font-weight: normal;
        font-family: $font-family-secondary;
      }
    }
  }

  &__taxesIncluded {

  }

  &__configuradorBody {
    padding: 42px 21px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .webcareslot1 {
      a {
        background-color: rgba($color-white, 0);
        border: 1px solid $color-white;
        color: $color-white;
        border-radius: 100px;

        &:hover {
          background-color: $color-white;
          color: $color-grey-600;
        }
      }
    }
  }

  &__configuradorImage {
    position: relative;

    &:after {
      background: linear-gradient(180deg, rgba(51, 51, 51, 0) 0%, #333333 100%);
      content: ' ';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    img {
      width: 100%;
    }
  }

  &__configuradorTitle {
    font-size: 18px;
    line-height: 24px;
    font-family: $font-family-primary;
    font-weight: $font-weight-primary-bold;
    margin-bottom: 12px;
    color: $color-white;
  }

  &__configuradorText {
    font-size: 14px;
    line-height: 17px;
    font-family: $font-family-secondary;
    font-weight: $font-weight-secondary-regular;
    color: $color-white;
  }

  &--small {
    min-height: auto;
    height: auto;

    &__inferior {
      padding-top: 0;
    }

    &__destacada {
      font-size: 16px;
      font-weight: bold;
    }

    &__mas_info {
      text-align: left;
      font-size: 16px;
    }

    &__prices {
      margin-top: 32px;
    }

    &__priceEntero {
      font-size: 72px;
      line-height: 86px;
      margin-right: 10px;
    }

  }

  &__links {
    & a {
      border-radius: 0;
    }
  }

  & .underline {
    border-bottom: 1px solid $color-azure-radiance;
  }

  &--blackfriday {
    background: linear-gradient(180deg, #545454 0%, #000000 100%);
    background-color: transparent;
    color: #fff;

    .c-ofertaCard__ribbon {
      background: $color-black;
    }

    .button {
      background-color: $color-secondary;
    }

    .c-ofertaCard__item p span {
      color: $color-secondary;
    }
  }
}
