.c-app {
  background-color: $color-pantone-red;
  position: relative;

  @include media(large up) {
    &::after {
      background-color: $color-white;
      content: "";
      width: 29%;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
    }
  }

  &__content {
    padding: 50px 16px 115px;

    @include media(xxxlarge up) {
      padding-right: 170px;
    }

    &.u-wrapper {
      max-width: 640px;
    }

    @include media(large up) {
      padding: 63px 20px 54px;
      padding-right: 270px;
    }

    @include media(large up) {
      &.u-wrapper {
        max-width: 850px;
      }
    }

    @include media(xxxlarge up) {
      &.u-wrapper {
        max-width: 1110px;
      }
    }

    &__text {
      .section-description.section-description--color-white {
        .section-description__title {
          font-size: $font-size-18 !important;
          line-height: 24px;
          margin-bottom: 22px !important;

          @include media(large up) {
            font-size: $font-size-21 !important;
            line-height: 27px;
            margin-bottom: 19px !important;
          }
        }

        .section-description__description {
          max-width: 90%;
          font-size: $font-size-14;
          line-height: 16px;

          @include media(large up) {
            max-width: 100%;
            font-size: $font-size-16 !important;
            line-height: 24px;
          }
        }
      }

      &__list {
        max-width: 65%;
        margin-top: 21px;

        @include media(large up) {
          max-width: 100%;
          margin-top: 29px;
        }

        .list-item.list-item--white.list-item--reverse {
          font-size: $font-size-14;
          line-height: 24px;
          font-weight: $font-weight-secondary-regular;
          font-family: $font-family-secondary;
          padding: 0;
          display: flex;
          margin-bottom: 8px;

          &:last-child {
            margin-bottom: 0;
          }

          @include media(large up) {
            font-size: $font-size-16;
            line-height: 32px;
            margin-bottom: 0;
          }

          .icon {
            min-width: 16px;
            min-height: 16px;
            align-self: start;
            margin-top: 3px;

            @include media(large up) {
              margin-top: 7px;
              min-width: 17px;
              min-height: 17px;
            }
          }
        }
      }
    }

    &__stores {
      margin-top: 48px;
      margin-left: 27px;
      display: flex;
      flex-direction: column;

      @include media(medium up) {
        flex-direction: row;
        margin-left: 0;
        margin-top: 35px;
      }

      & > a {
        width: max-content;
        margin-top: 22px;

        @include media(medium up) {
          margin-top: 0;
          margin-left: 11px;

          &:first-child {
            margin-left: 0;
          }
        }

        &:first-child {
          margin-top: 0;
        }

        img {
          width: max-content;
          max-width: max-content;
        }
      }
    }

    &__phone {
      position: absolute;
      bottom: 0;
      right: -63px;
      height: 440px;
      width: 440px;
      z-index: 10;
      max-width: max-content;

      @include media(medium up) {
        height: 374px;
        width: 374px;
      }

      @include media(large up) {
        height: 420px;
        width: 420px;
        right: 19%;
      }

      @include media(xlarge up) {
        right: 21%;
      }

      @include media(xxlarge up) {
        right: 22%;
      }

      @include media(xxxlarge up) {
        right: 23%;
      }
    }
  }
}
