.landing-mambo-axina {

  &.c-lity-modal {
    .c-lity-modal__content {
      * + * {
        margin: 10px 0 0 0;
      }

      ul, ol {
        list-style: disc;
        list-style-position: inside;
      }
    }
  }
  &__hero {
    position: relative;
    height: 465px;
    overflow: hidden;
    display: flex;

    @media (max-width: 900px){
      height: 300px;
    }

    &__boxes {
      display: flex;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      @media (max-width: 900px){
        transform: scale(.7);
        flex-direction: column;
        margin-top: -180px;
      }

    }

    &__box1 {
      width: 294px;
      height: 182px;
    }

    /*
    &__box2 {
      width: 289px;
      height: 184px;
    }
    */
  }

  &__bg {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    right: 0;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__header {
    position: relative;
    width: 100%;
    padding: 0 80px 0 40px;
    height: 100%;
    /*height: 340px;*/

    &__title {
      font-family: "R Gerstner", sans-serif;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      color: #0E0E0E;
      margin-bottom: 20px;
      width: 100%;
      max-width: 255px;
    }

    &__copy {
      font-family: "Lato", sans-serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #0E0E0E;
    }
  }

  &__content {

    &-header {
      position: relative;
      z-index: 2;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }

    &-titles {
      width: 100%;
      max-width: 472px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      margin-right: 0;
    }

  }

  &__section-title {

    .section-description__title {
      font-family: 'R Gerstner';
      font-style: normal;
      font-weight: 700;
      font-size: 29px !important;
      line-height: 35px  !important;
      color: #333;

      @media (max-width: 767px){
        font-size: 21px !important;
        line-height: 29px  !important;
      }
    }

    &__icon {
      margin: 0 25px 0 0;
      width: 56px;
      height: 56px;

      @media (max-width: 767px){
        width: 32px;
        height: 32px;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &__inner {
      display: flex;
      justify-content: center;
    }

    &__content {

    }

  }

}

.mambo-axina {
  &__cards {
    display: flex;
    justify-content: space-between;
    margin: 0 15px;

    @media (max-width: 767px){
      flex-direction: column;
    }

     &__card {
      box-shadow: 4px 4px 20px #E5E5E5;
      border-radius: 8px;
      padding: 16px 12px;
      flex: 1 0;
      margin: 0 7.5px;

      @media (max-width: 767px){
         margin: 0 7.5px 20px 7.5px;
      }

      &__icon {
        display: inline-flex;
        width: 32px;
        height: 32px;
        margin: 0 12px 8px 0;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      &__title {
        font-weight: 900;
        font-size: 16px;
        line-height: 20px;
        color:var(--color-dark);
        display: inline-flex;
        align-items: center;

      }
     }
  }
}

/*.offer-block {

  background-color:#F5F5F5;
  border-radius: 16px;
  padding: 24px 32px;
  filter: drop-shadow(4px 4px 20px #E5E5E5);
  margin: 0 0 40px 0;

  @media (max-width: 900px){
    padding: 10px 10px;
  }

  &__inner {
    display: flex;
    padding:  0 0 10px 0;

    @media (max-width: 600px){
      flex-direction: column;
    }

  }

  &__description {
    flex: 1 0;
    margin: 0 40px 0 0;

    @media (max-width: 900px){
      margin: 0 15px 0 0;
    }

    &__title {
      font-family: 'R Gerstner';
      font-style: normal;
      font-weight: 700;
      font-size: 21px;
      line-height: 29px;
      margin: 0 0 35px 0;

    }


    &__cards {
      display: flex;
      margin: 0 -8px;

      @media (max-width: 600px){
        flex-direction: column;
      }

      &__card {
        margin: 0 8px;
        flex: 1 0;
      }
    }

  }

  &__actions {
    display: flex;
    flex-direction: column;
    padding: 0 0 0 40px;
    justify-content: center;
    border-left: 2px solid #E5E5E5;

    @media (max-width: 900px){
      padding: 0 0 0 15px;
    }

    @media (max-width: 600px){
      border: 0;
    }

    .button {
      + .button {
        margin-top: 16px;
      }
    }
  }

  &__price {
    font-family: 'R Gerstner';
    font-style: normal;
    font-weight: 700;
    font-size: 51px;
    line-height: 76px;

    &__decimals {
      font-size: 24px;
      line-height: 40px;
    }

    &__month {
      color: #999;
      font-size: 18px;
      line-height: 24px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
    }
  }
}*/

/*.offer-block__card {

  display: flex;
  flex-direction: column;
  align-items: center;

  + .offer-block__card {
    @media (max-width: 600px){
      margin-top: 15px;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    border-radius: 4px;
    padding: 30px 30px 20px 30px;
    width: 100%;
    height: 100%;

    @media (max-width: 900px){
      padding: 30px 15px 20px 15px;
    }

  }

  &__icon {
    display: block;
    margin: 0 0 10px 0;
    width: 40px;
    height: 40px;

    svg {
      width: 100%;
      height: 100%;
    }

    path {
      stroke: #000;
    }
  }

  &__title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    margin: 0 0 15px 0;

    @media (max-width: 900px){
      font-size: 14px;
    }

  }

  input[type="checkbox"]{
    accent-color: #EF3340;
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.24);
    margin: 0 10px 0 0;
    cursor: pointer;
  }

  &--footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 16px 0 0 0;

    &:first-of-type {
      @media (max-width: 600px){
        display: none;
      }
    }

    @media (max-width: 600px){
      justify-content: flex-start;
    }

    &__cta {
      font-family: 'Lato';
      font-weight: 900;
      font-size: 14px;
      line-height: 18px;
      color: #EF3340;
    }

    &__icon {
      display: inline-flex;
      margin: 4.5px;
    }
  }

}*/
/*
.button--cta-mambo-axina {
  display: inline-block;
  background-color: #EF3340;
  color: #fff;
  border-radius: 8px;
  padding: 12px 48px;
  font-family: 'Lato';
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  min-width: 215px;

  @media (max-width: 900px){
    min-width: auto;
    padding: 12px 40px;
  }
}

.button--cmn-mambo-axina {
  display: inline-block;
  background: #FFFFFF;
  border: 1px solid #EF3340;
  border-radius: 8px;
  min-width: 215px;
  padding: 12px 48px;
  font-family: 'Lato';
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  color: #EF3340;

  @media (max-width: 900px){
    min-width: auto;
    padding: 12px 40px;
  }
}
*/
.button--secondary-mambo-axina {
  display: inline-block;
  background: #FFFFFF;
  border: 1px solid #EF3340;
  border-radius: 8px;
  padding: 12px 48px;
  font-family: 'Lato';
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  color: #EF3340;

  @media (max-width: 600px){
    min-width: auto;
    padding: 12px 30px;
  }

}

.section--mambo-axina {
  padding: 35px 0 35px 0;
  max-width: none;

  @media (max-width: 767px){
    padding: 35px 15px 35px 15px;
  }
}

.section--mambo-axina-secondary {
  background-color: #F5F5F5;
  padding: 60px 0 120px 0;

  @media (max-width: 767px){
      padding: 60px 15px 120px 15px;
  }
}

.section--mambo-axina-contratalo-ya {
  padding-top: 50px;
  padding-bottom: 135px;
  max-width: none;

  &__footer {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #EF3340;
    display: none;
  }

}

.banner--mambo-axina {
  background-color: #fff;
  border-radius: 16px;
  filter: drop-shadow(4px 4px 20px #E5E5E5);
  padding: 32px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row-reverse;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    object-fit: cover;

    @media (max-width: 700px){
      width: 40%;
    }

    @media (max-width: 600px){
      position: relative;
      max-width: none;
      margin: -32px -32px 24px -32px;
      width: calc(100% + 64px);
      max-width: none;
    }
  }

  @media (max-width: 600px){
    flex-direction: column;
  }

  &__content {
    flex: 0 0 50%;

    @media (max-width: 700px){
      flex: 0 0 60%;
    }

  }

  &__items {

    padding: 0 0 0 40px;

    &__item {

      display: flex;
      align-items: center;

      + .banner--mambo-axina__items__item {

            margin-top: 32px;

      }

    }

  }

  &__item {
    &__icon {
      display: inline-flex;
      margin: 0 8px 0 0;
    }
  }

}

.section--mambo-axina--downloads {
  &__cta {
    margin: 35px 0 0 0;
  }
}

.landing-mambo-axina {
  &__offers-cards-wrapper {
    display: flex;
    justify-content: center;

    @media (max-width: 1100px){
      flex-direction: column;
      align-items: center;
    }
  }
}

.offer-card {
  --s : 8px;
  box-shadow: 4px 4px 20px #DDD;
  border-radius: calc(var(--s) * 2);
  padding: calc(var(--s) * 2);
  width: 33.33%;
  min-width: 370px;
  margin: 0 15px 30px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__label {
    font-weight: 700;
    font-size: 12px;
    line-height: calc(var(--s) * 2);
    background: #C9EFFE;
    border-radius: 4px;
    padding: calc(var(--s) / 2);
    color: #00A3E0;
  }

  &__title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    margin-top: 12px;
    margin-bottom: 30px;
  }

  &__body {
    height: 100%;
  }

  &__items {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0 0 28px 0;
      max-width: 240px;
      margin: auto;
  }

  &__item {
    display: flex;
    flex-direction: column;
    flex: 0 0 90px;
    max-width: 90px;
    align-items: center;
    text-align: center;
    margin: 0 15px 15px 15px;
  }

  &__icon {
    display: inline-flex;
    width: 32px;
    height: 32px;
    align-items: flex-start;
    margin: 0 0 10px 0;

    svg {
      display: block;
      width: 100%;
      height: 100%;

      path {
        stroke: #000;
      }
    }
  }

  &__icon-label {
    font-family: 'Lato';
    font-weight: 900;
    font-size: 12px;
    line-height: 16px;
  }

  &__extra {
    font-size: 12px;
    line-height: 16px;
    color: #EF3340;
    font-weight: 700;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    margin: 0 0 24px 0;
    width: 100%;

    &__button {
      cursor: pointer;
      display: inline-flex;
      align-items: center;
    }

    svg {
      display: inline-flex;
      margin: 0 4px 0 0;
    }
  }

  &__footer {
    border-top: 1px solid #f5f5f5;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__price {
    font-family: 'R Gerstner';
    font-weight: 700;
    font-size: 33px;
    line-height: 26px;
    display: block;
    margin: 0 0 4px 0;
  }

  &__price-note {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    &--secondary {
      color: #999;
    }
  }

  &__cta {
    font-family: 'Lato';
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    background: #EF3340;
    color: #fff;
    border-radius: 8px;
    min-width: auto;
    min-height: auto;
    padding: 14px 22px;
  }
}
