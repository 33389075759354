.descarga-app-red {
  background-color: var(--color-white);
  font-family: var(--font-primary);

  &--bg {
    background-color: #f5f5f5;
  } 

  &__wrapper {
    margin-left: calc(8px * 2);
    margin-right: calc(8px * 2);
  }

  &__inner-container {
    padding: 99px 0;
    text-align: center;
  }

  &__img-container {
    width: calc(8px * 12);
    height: calc(8px * 12);
    margin: 0 auto 19px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-primary);
    border-radius: calc(8px * 2);

    .icon {
      display: block;
      width: 60px;
      height: 60px;
      object-fit: contain;
      object-position: center center;
    }
  }

  &__text-container {
    /*max-width: 370px;*/
    margin: 0 auto 43px auto;
  }

  &__text {
    margin-bottom: 13px;
  }

  &__title {
    font-size: 21px;
    line-height: 29px;

    @include media(medium up) {
      font-size: 29px;
      line-height: 35px;
    }
  }
}





