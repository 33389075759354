.pymes-footer {
  --footer-bg-color: #{$color-dark};
  --footer-color: #{$color-light};
  --border-bottom: 1px solid var(--color-grey-500-v21);

  background-color: var(--footer-bg-color);
  color: var(--footer-color);

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    &.u-wrapper {
      width: 100%;
      display: block;
      padding: 14px;
      max-width: 1680px;
      margin-left: auto;
      margin-right: auto;
      @include media(medium up) {
          padding-left: 48px !important;
          padding-right: 48px !important;
      }

      // @each $grid-row-config-key, $grid-row-config-value in $grid-row-configs {
      //     $breakpoint: map-get($grid-row-config-value, breakpoint);
      //     $row-width: map-get($grid-row-config-value, row-width);
      //     @include media($breakpoint) {
      //         width: $row-width;
      //     }
      // }

      @include media(large up) {
          height: calc(100% - var(--esukaltel-secondary-menu-height));
          justify-content: center;
      }

      &--narrow {
          max-width: 730px;
      }

      &--wide {
          max-width: 1250px;
      }

      &--wider {
          max-width: 1280px;
      }

      &--larger {
          max-width: 1440px;
      }
    }
  }
  &.card-display{
    margin-bottom: 100px;
    @media only screen and (min-width: 1300px){
      margin-bottom: 0;
    }
  }
}

.pymes-footer__top {
  padding-top: calc( 4 * var(--s-v21));
  padding-bottom: calc( 3 * var(--s-v21));
  @include media(medium up) {
    padding-left: calc( 2 * var(--s-v21));
    padding-right: calc( 2 * var(--s-v21));
    padding-bottom: calc( 4 * var(--s-v21));
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: var(--border-bottom);
  }

  .icon {
    --stroke-color: var(--color-primary-contrast-v21);
    --stroke-width: 1px;
    --width: 32px;
    height: 32px;
  }

  .pymes-footer__logo {
    .icon {
      --stroke-width: 0;
      --fill-color: var(--color-primary-contrast-v21);
      --width: 150px;
      --height: 32px;
    }
  }
}

.pymes-footer__social-links {
  .link {
    margin-right: 0;
    padding-bottom: 0;
  }

  .icon {
    --stroke-color: var(--color-primary-contrast-v21);
    --stroke-width: 1px;
    width: 32px;
    height: 32px;
    display: block;

    svg {
      width: 100%;
      height: auto;

      path {
        stroke: var(--color-white-v21);
        stroke-width: 0;
        fill: none;
      }
    }
  }

  a + a {
    margin-left: calc(3 * var(--s-v21));
  }
}

.pymes-footer__middle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include media(medium up) {
    padding-top: calc( 3 * var(--s-v21));
  }

  @include media(medium up) {
    padding-bottom: calc( 4 * var(--s-v21));
    border-bottom: var(--border-bottom);
    flex-direction: row;
  }
}

.pymes-footer__links-group {
  padding: calc( 3 * var(--s-v21)) var(--s-v21);
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: var(--border-bottom);
  @include media(medium up) {
    padding-top: 0;
    padding-left: 0;
    padding-right: calc( 3 * var(--s-v21));
    padding-bottom: calc( 5 * var(--s-v21));
    border-bottom: none;
  }
  @include media(medium up) {
    padding-bottom: 0;
  }

  &__title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $color-white;
    font-weight: bold;

    @include media(medium up) {
      color: $color-primary;
    }

    .icon {
      margin-left: auto;
      width: auto;

      @include media(medium up) {
        display: none;
      }

      & svg path {
        stroke: $color-primary;
      }
    }

    &.show {
      & .icon {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }

  a:hover {
    opacity: 0.7;
    transition: $transition-opacity;
  }

  .pymes-footer__links-group__links-list {
    display: none;


    @include media(medium up) {
      display: flex;
      flex-direction: column;
    }

    &.show {
      display: flex;
      flex-direction: column;
      padding-bottom: var(--s-v21);
    }

    .link {
      margin-top: calc( 2 * var(--s-v21));
      color: var(--color-grey-500-v21);

      white-space: nowrap;
      strong {
        color: var(--color-white-v21);
      }
      &__text {
        font-weight: 400;
        font-size: var(--medium-font-size);
        line-height: 16px;
      }
      &:first-child {
        margin-top: calc( 2 * var(--s-v21));
      }
    }
  }
}

.pymes-footer__middle__main-links {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @include media(medium up) {
    flex: 1 1 auto;
    flex-basis: 100%;
    flex-direction: row;
    flex-wrap: wrap;
  }

  @include media(medium up) {
    flex-wrap: nowrap;
  }

  .pymes-footer__links-group {
    @include media(medium up) {
      flex-basis: 50%;
    }
     &:not(:last-child){
      @include media(medium up) {
        padding-right: 40px;
      }
     }

     &:last-child{
      @include media(medium up) {
        padding-right: 0;
      }
     }

    @include media(medium up) {
      flex-basis: 25%;
    }
  }
}

.pymes-footer__bottom {
  padding-bottom: calc( 3 * var(--s-v21));
  display: flex;
  flex-direction: column;
  width: 100%;

  @include media(medium up) {
    padding-top: var(--s-v21);
  }

  @include media(medium up) {
    padding-top: calc( 3 * var(--s-v21));
    flex-flow: row-reverse;
    justify-content: space-between;

    &__legal,
    &__about-us {
      width: 50%;
    }
  }
}

.pymes-footer__bottom__about-us {
  flex: row wrap;
  width: auto;
  padding-top: calc( 3 * var(--s-v21));
  padding-bottom: calc( 4 * var(--s-v21));
  @include media(medium up) {
    border-top: var(--border-bottom);
    border-bottom: var(--border-bottom);
    padding-bottom: calc( 3 * var(--s-v21));
  }
  @include media(medium up) {
    border: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
  }

  .link {
    width: 50%;
    margin-top: var(--s-v21);
    @include media(medium up) {
      width: auto;
      margin-top: 0;
      &:not(:first-child) {
        margin-left: calc( 3 * var(--s-v21));
      }
    }
  }
}

.pymes-footer__bottom__legal {
  margin-top: calc( 3 * var(--s-v21));
  line-height: calc( 3 * var(--s-v21));
  font-size: var(--medium-font-size);
  color: var(--color-grey-500-v21);
  @include media(medium up) {
    margin-top: 0;
  }

  .link__text {
    font-weight: 400;
    color: var(--color-grey-500-v21);
  }
}

.pymes-footer__bottom__copyright {
  font-size: var(--medium-font-size);
  line-height: 16px;
}
.pymes-footer__bottom__links {
  .link__text {
    font-size: var(--medium-font-size);
    line-height: 16px;
  }
}
.pymes-footer__bottom__copyright {
  margin-bottom: 20px;
}
.pymes-footer__links-separator {
  display: inline-flex;
  margin-right: var(--s-v21);
  margin-left: var(--s-v21);
  border: var(--border-bottom);
  height: 10px;

  &--thinner {
    border-left-width: 0.5px;
    border-right-width: 0;
  }
}
