:root {
  --card-height: 276px;
}

.pymes-c-nuevas-tarifas-moviles-section {
  padding-top: calc(8px * 7); //56
  padding-bottom: calc(8px * 7);
}

.pymes-swiper-solo-movil {
  flex-direction: column;
  align-items: center;

  @media (min-width: 1102px) {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 20px;
    gap: 30px;
  }
}

.pymes-swiper-cards-solo-movil {
  margin-top: 24px;
  @media (min-width: 1280px) {
    padding-bottom: 0;
    margin-top: 42px;
  }
}

.pymes-swiper-cards-solo-movil-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;

  &__title {
    font-size: 29px;
    line-height: 35px;
    color: var(--color-dark);
    font-family: $font-family-primary;
    font-weight: 700;
    margin-bottom: 8px;
    @include media(medium up) {
      font-size: 38px;
      line-height: 56px;
      text-align: center;
    }
  }

  &__subtitle {
    font-family: var(--font-family-secondary);
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: var(--color-dark);
    @include media(medium up) {
      text-align: center;
    }
  }
}

.cards-container {
  display: flex;
  gap: 30px;
}

.pymes-c-card-solo-movil-desktop {
  color: var(--color-text);
  width: 100%;
  border-radius: 8px;
  display: none;
  flex-direction: column;
  min-height: 276px;
  position: relative;
  box-shadow: 4px 4px 20px rgba(38, 37, 37, 0.1);
  overflow: hidden;
  border-radius: 4px;
  @media (min-width: 1102px) {
    display: flex;
  }
  &.hasBanner {
    overflow: visible;
    border-radius: 0px;
    // Las cards sin promocion tienen bordes redondeados
    // esta solo tiene los inferiores redondeados
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  & .month-offer {
    background-color: #14ccaf;
    border-radius: 8px 8px 0px 0px;
    font-family: var(--font-family-secondary);
    color: var(--color-white);
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-weight: 600;
    padding: 8px;
  }

  & .pymes-c-card-solo-movil-header {
    padding: 12px;
    background-color: #fad7d9;
    color: #ef3340;
    display: flex;
    justify-content: center;
    align-items: end;
    font-family: $font-family-primary;
    font-weight: 700;
    .title {
      display: flex;
      align-items: center;
      font-size: 21px;
      line-height: 31px;

      img {
        height: 26px;
        transform: translateY(-4px);
        margin-right: 8px;
      }
    }
  }
  & .pymes-c-card-solo-movil-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;
    background-color: var(--color-white);
    min-height: 235px;
  }

  & .pymes-c-card-solo-movil-content {
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 30px;

    & .pymes-c-card-solo-movil-spec {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
      border-radius: 4px;
      text-align: center;
      p {
        margin: 0;
        line-height: 27px;
        font-size: 16px;

        strong {
          font-weight: 900;
        }
      }

      &.normalHeight {
        height: 52px;
      }
    }
  }

  & .pymes-c-card-solo-movil-bannerDiscount {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    color: #008dff;
    font-weight: 600;
    align-self: center;
    margin-bottom: 20px;
    img {
      margin-right: calc(8px * 2);
    }
  }
  & .pymes-c-card-solo-movil-footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .price {
      font-weight: 700;
      margin: 0;
      font-family: $font-family-primary;

      .price-integer {
        font-size: 32px;
        line-height: 50px;
      }

      .price-decimals {
        font-size: 20px;
        line-height: 25px;
      }
      .price-month {
        color: var(--color-dark);
        font-weight: 400;
      }
    }

    .price-later {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      margin: 0;
      .taxes {
        color: #b2b2b2;
      }
    }
    & .mini-offer {
      font-size: 12px;
      line-height: 20px;
      background-color: #14ccaf;
      display: flex;
      justify-content: center;
      color: var(--color-white);
      padding: 2px 4px;
      font-weight: bold;
      margin-bottom: 10px;
      border-radius: 4px;
    }

    .footer-button {
      align-self: flex-end;
      a {
        color: white;
        background: #ef3340;
        border-radius: 8px;
      }
    }
  }
}

.swiper-cards-solo-movil-nav {
  display: none;
  align-self: flex-end;
  position: absolute;
  bottom: -60px;
  z-index: 0;
  .__solo-movil-footer__counter__total {
    color: #b2b2b2;
  }
  @media (max-width: 1083px) {
    display: block;
  }
}

.swiper-cards-solo-movil {
  overflow: visible;
  margin-top: 20px;

  @media (min-width: 1280px) {
    /*padding-bottom: 30px;*/
    overflow: hidden;
  }
}
/*
.swiper-cards-solo-movil-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;

  h2 {
      font-size: 28px;
      line-height: 38px;
      color: var(--color-dark);
  }

  h4 {
      margin-top: 16px;
      font-size: 20px;
      font-family: var(--font-family-secondary);
      line-height: 24px;
      font-weight: 300;
      color: var(--color-dark);
  }

}
*/
.cards-container {
  display: flex;
  gap: 30px;
}

// --------------------- MOBILE --------------------------******

.pymes-c-card-solo-movil-mobile {
  display: flex;
  justify-content: space-between;
  background-color: var(--color-white);
  border-radius: 8px;
  min-height: 180px;
  box-shadow: 4px 4px 20px #e5e5e5;
  width: 100%;
  margin-bottom: 16px;
  max-width: fit-content;
  @media (min-width: 1102px) {
    display: none;
  }

  &.short-display {
    min-height: fit-content;
    .pymes-c-card-solo-movil__right-block {
      justify-content: normal;
    }
  }
  .pymes-c-card-solo-movil__left-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 10px 20px 14px;
    min-width: 170px;
    color: var(--color-text);
    &.noDiscount {
      justify-content: flex-start;
      .left-block__spec {
        margin-top: 24px;
      }
    }
    .heading {
      display: flex;
      align-items: center;
      margin: 0;
      .icon--worko {
        --stroke-color: var(--color-text);
      }
      .title {
        text-align: left;
        margin-left: 10px;
        font-size: 16px;
        line-height: 14px;
      }
    }
    .left-block__spec {
      font-size: 16px;
      line-height: 20px;
      text-align: left;
    }
    .left-block__spec-list-item {
      text-align: left;
      margin-top: calc(8px * 2);
      font-size: 16px;
      line-height: 20px;
      text-align: left;
    }
    .left-block__extraLines {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      .text {
        margin-left: 4px;
        color: var(--color-tertiary);
      }
    }
  }
  .pymes-c-card-solo-movil__right-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 13px;
    border-left: 1px solid lightgray;
    font-family: $font-family-primary !important;
    color: var(--color-text);
    &.noOffer {
      justify-content: flex-end;
      font-family: $font-family-primary !important;
    }
    .right-block__month-offer {
      border-radius: 4px;
      padding: 4px;
      color: var(--color-tertiary);
      background-color: #cce8ff;
      font-family: $font-family-primary;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      white-space: nowrap;
    }
    .right-block__price {
      text-align: left;
      font-family: $font-family-primary;
      font-weight: 700;
      margin-bottom: 16px;
      @include media(xlarge up) {
        margin-bottom: 0;
      }
      .price-later {
        white-space: nowrap;
        margin-top: 0;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        .taxes {
          color: var(--color-grey-600);
        }
      }
      .price__init {
        white-space: nowrap;
        font-size: 32px;
        line-height: 48px;
        font-family: $font-family-primary !important;
        .price-integer {
          font-family: $font-family-primary;
          font-weight: 700;
        }
        .price-decimals {
          font-family: $font-family-primary;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
        }
        .price-currency {
          font-family: $font-family-primary;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          margin-left: 4px;
        }
        .price-month {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
    .right-block__button {
      white-space: nowrap;
      .c-pymes-interested-button {
        color: white;
        background: #ef3340;
        border-radius: 8px;
        max-width: 136px;
      }
    }
  }
  .pymes-c-card-solo-movil-allClick {
    position: absolute;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
}
