@import './../_mixins/base-html-elements';
@import '../_definitions/animations';
@import '../_variables/colors';
@import '../_variables/transition';

body.validation {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;

  .validation-header {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    height: 80px;
    margin-bottom: 40px;
    background: $color-tundora;

    .wrapper-centered {
      .languange-menu {
        margin: 0 20px 0 0;
      }

      .c-mundo-r-header__call-us {
        margin-left: auto;
        white-space: nowrap;

        @media screen and (min-width: 64em) {
          margin: 0 16px 0 auto;
        }

        @media screen and (min-width: 80em) {
          white-space: normal;
        }

        .c-mundo-r-header__call-us__icon {
          @media screen and (min-width: 48em) {
            display: flex;
            margin-right: 16px;
          }
        }
      }

      .c-mundo-r-header__phone-mobile {
        margin-left: auto;
      }
    }
    section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
    }
  }

  .lang-switcher {
    display: flex;
    align-items: center;
    position: relative;

    /*
    select{
      -webkit-appearance: none;
      border: none;
      background: none;
      padding-right: 30px;
      position: relative;
      z-index: 1;
    }
    .select-button{
      position: absolute;
      right: 0;
      top: 50%;
      z-index: 0;
      transform: translateX(-50%);
    }
    */
    .select {
      position: relative;
      font-weight: 700;
      cursor: pointer;
      font-size: 14px;
      line-height: 16px;
      > ul {
        display: none;
        position: absolute;
        z-index: 9;
        top: 30px;
        left: -30px;
        background-color: $color-white;
        padding: 30px;
        font-weight: 400;
        box-shadow: var(--box-shadow-ev);

        &.open {
          display: block;
        }

        li {
          margin-bottom: 30px;
          &:last-child {
            margin-bottom: 0;
          }
          &:hover {
            color: $color-primary;
          }
        }
      }
    }
    .select-button {
      margin-left: 15px;
    }
  }

  #logo {
    display: block;
    width: 54px;
  }

  .c-footer {
    .c-footer__container {
      @media screen and (min-width: 1024px) {
        flex-direction: row;
        max-width: 960px;
      }

      @media screen and (min-width: 1440px) {
        max-width: 1140px;
      }

      .c-footer__top-mobile {
        margin-bottom: 40px;
      }

      .c-footer__top {
        display: none;

        @media screen and (min-width: 768px) {
          display: block;
        }

        @media screen and (min-width: 1024px) {
          width: fit-content;
        }

        .c-footer__bottom {
          @media screen and (min-width: 1024px) {
            flex-basis: 100%;
          }
        }
      }
    }
  }
}
