.c-tv-ademas {


	&__container {
	  position: relative;
  }

  & .section-description__title {
	  position: relative;
	  font-family: $font-family-primary;
	  font-weight: $font-weight-primary-bold;
	  font-size: 20px;
	  line-height: 30px;
	  letter-spacing: -0.5px;
	  color: $color-black;
	  margin-bottom: 25px;
  
	  @include media(medium down) {
		font-size: 20px;
		line-height: 26px;
		text-align: center;
	  }
  }
	   
  &__list {
	  display: flex;
	  justify-content: space-between;
	  flex-wrap: wrap;
  }

  &__item {
	  flex: 1 auto;
	  display: flex;
	  flex-direction: column;
	  position: relative;
	  margin-top: 40px;
	  
	  @include media(medium up) {
		  flex: 0 0 calc(25% - 15px); 
	  }

	  &--promo {
		  
	  }
	  
	  &__inner {
		  background-color: #fff;
		  box-shadow: 4px 4px 25px rgba(38, 37, 37, 0.1);
		  margin: -15px;
		  height: calc(100% + 30px);
		  padding: 15px 15px 0 15px;
		  display: flex;
		  flex-direction: column;
		  justify-content: space-between;
	  }

	  &__wrapper {
		  display: block;
		  height: 100%;
		  padding: 15px;
	  }
	  
	  &__icon {
		@include flex();
		margin-right: 16px;
		display: flex;
		align-items: baseline;
		margin-bottom: 8px;
	  }
	  &__block {
		width: 100%;
		max-width: 100%;
  
		@include media(medium up) {
		  max-width: 250px;
		}
	  }

	  &__title {
		font-weight: $font-weight-primary-bold;
		font-family: $font-family-primary;
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 8px;
		letter-spacing: -0.5px;
	  }
  
	  &__text {
		font-family: $font-family-secondary;
		font-size: 14px;
		line-height: 16px;
	  }

	  &__promotion {
		  background-color: #14CCAF;
		  color: #fff;
		  font-size: 14px;
		  line-height: 21px;
		  padding: 2px 0 2px 15px;
		  position: absolute;
		  width: 100%;
		  top: -25px;
	  }

	  &__more-info {
		  margin: 15px -15px 0 -15px;
		  padding: 15px 0;
		  text-align: center;
		  border-top: 1px solid #ECECEC;
		  color: #EF3340;
		  font-weight: bold;
	  }

  }

  

}