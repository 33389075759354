
@import './../_mixins/base-html-elements';
@import '../_definitions/animations';
@import '../_variables/colors';
@import '../_variables/transition';

.channels-filtros {
	&__title {
		font-family: $font-family-primary;
		font-weight: bold;
		font-size: 24px;
		line-height: 30px;
		margin-top: 32px;

		@media (max-width: 760px){
			display: none;
		}
	}

	&__subtitle {
		margin-top: 32px;
		margin-bottom: 16px;
		font-family: $font-family-primary;
		font-weight: bold;
		font-size: 18px;
		line-height: 24px;
	}

	&__filters {
		display: flex;
		flex-direction: row;
		align-items: center;

		@media (max-width: 760px){
			align-items: flex-start;
		}

		@media (max-width: 500px){
			flex-direction: column;
		}

		&__group {
			display: flex;
			flex: 1 0;

			@media (max-width: 500px){
				width: 100%;
			}

			&:not(:last-child){
				justify-content: space-between;
				margin: 0 30px 0 0;
			}

			@media (max-width: 760px){
				flex-direction: column;
			}

			&__inner {
				position: relative;
				flex: 1 0 auto;
				max-width: calc(50% - 15px);

				@media (max-width: 760px){
					margin: 0 0 15px 0;
					max-width: none;
				}
			}
		}

		&__icon {
			display: inline-block;
			width: 16px;
			position: absolute;
			right: 15px;
			top: 50%;
			transform: translateY(-50%);
			flex: 0 0 16px;
			pointer-events: none;

			svg {
				pointer-events: none;
			}
		}

		@media (max-width: 760px){

		}
	}

	&__filter {
	}

	&__button {
		color: $color-primary;
		margin-left: 28px;
		cursor: pointer;
		white-space: nowrap;
		padding: 15px 0;

		&__icon {
			display: inline-block;
			width: 16px;
			margin-left: 10px;

			&--rotation {
				transform: rotate(90deg);
			}

			&__list {
				display: inline-block;
			}

			&__grid {
				display: none;
			}
		}
	}
}
