@import './../_mixins/grid';
@import './../_theme/r-colors';


.field-container{
  display: flex;
  flex-direction: column-reverse;
}

.cv-form__form-col{
flex: 0 0 100%;
max-width: 100%;
padding-right: 0.9375rem;
padding-left: 0.9375rem;
position: relative;
margin-top:30px;
@include media (medium up){
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
}
@include media (large up){
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
}
&.add{
  button{
    margin: 0;
    @include media (medium up){
      transform: translateY(6px);
    }
  }
  @include media (medium up){
      padding-top:26px;
  }
}
&.remove{
    display:none;
}
}


.c-work-with-us {
padding-top: 48px;
padding-bottom: 110px;

&__section {
  padding-top: calc(var(--s-v21) * 8);
  padding-bottom: calc(var(--s-v21) * 8);
  max-width: 1431px;
  margin: 0 auto;
}

em {
    color: $color-primary;
    font-style: normal;
}

a {
    color: $color-primary;
    text-decoration: underline;

    &:hover {
        color: var(--color-secondary);
    }
}
h3 {
    font-size: 21px;
    font-family: var(--font-family-secondary);
    font-weight: bolder;
    margin-top: 60px;
    // margin-bottom: 30px;
}

&__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    line-height: 1.5rem;
    justify-content: center;
    font-size: 1rem;
    list-style-type: none;

    li {
        margin-bottom: 10px;
    }

    .li--em {
        font-weight: bold;
    }

    &--center {
        align-items: center;
    }

    &--em-first::first-letter {
        color: var(--color-primary);
    }

    &--nested {
        margin-left: 30px;
    }
}

// &--altBackground {
//     background-color: $alt-background;
// }

@include media (medium up) {
    padding-top: 29px;
}

.section-description {
    margin-bottom: 14px;
}

.section-description__title {
    font-size: 21px !important;
}
.section-description--centered {
    text-align: center;
    
}

&__content {
    padding: 48px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

&__card {
    position: relative;
    @include flex();
    @include flex-direction(column);
    align-items: flex-start;
    max-width: var(--card-offer-max-width);
    width: 100%;
    height: 100%;
    box-shadow: 4px 4px 20px rgba(38, 37, 37, 0.1);
    background-color: var(--color-light-v21);
}

&__cardIcon {
    margin-bottom: 16px;
    height: 64px;

    .icon__glyph {
    stroke: var(--color-primary);
    fill:none;
    }
}

&__card-picture {
    width: 100%;

    > img {
    object-fit: contain;
    width: inherit;
    }
}

&__cardTitle {
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    font-family: var(--font-family-primary);
}

&__cardText {
    font-size: 16px;
    line-height: 24px;
    font-family: var(--font-family-secondary);
}

&__cardSeparator {
    border: none;
    margin: 16px 0;
    width: 32px;
    height: 4px;
    background-color: var(--color-primary);
}
}

.cv-form-submit-message-cv{
  margin-left:20px;
}

.button--remove-button{
background-color: transparent;
color:var(--color-text);
&:hover{
  color:var(--color-primary);
  background-color: transparent;
}
}
.cv-pdf-loader-container{
display: flex;
align-items: flex-start;
flex-direction: column;
gap:20px;

.loader-content{
    display: inline-block;
}
}


.button--add-button{
  &.disabled{
      background: gray;
      cursor: default;
  }
}

.cv-pdf-loader-button{
font-family: var(--font-family-secondary);
font-size: var(--link-font-size);
line-height: 24px;
font-weight: bold;
&:hover{
    cursor:pointer;
}
}


.cv-pdf-loader-input{
  display: none !important;
  opacity: 0;
  position: absolute;
}
.cv-form__legal-terms {
  margin-top: 10px;
}

.cv-form__form-col.cv-form__query-form-col {
flex: 0 0 100%;
max-width: 100%;
padding-right: 0.9375rem;
padding-left: 0.9375rem;
@include media (large up){
    flex: 0 0 100%;
    max-width: 100%;  
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
}
@include media (medium up){
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
    margin-top: 30px;
}
}