.section--compartir-internet-movil {
  padding: 60px 0;

  .section-description__subtitle {
    font-family: var(--font-family-secondary);
    font-weight: 400;
    font-size: 16px;
  }

}

.u-wrapper--compartir-internet-dialog {
  max-width: 1240px;
}

.compartir-internet-movil {

    img {

      user-drag: none;
      user-select: none;
    }

    &__tabs {

      list-style-type: none;
      max-width: 1240px;
      margin: 55px auto 80px auto;
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

      &__tab {
        font-family: var(--font-secondary);
        display: inline-flex;
        border: 1px solid #BFBFBF;
        border-radius: 8px;
        color: var(--color-primary);
        padding: 16px;
        text-align: center;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 16px;
        line-height: 24px;

        @media (min-width: 600px){
          font-size: 21px;
          line-height: 31px;
        }

        &:hover,
        &.active {

          background-color: var(--color-primary);
          color: var(--color-white);
          border-color: var(--color-primary);

        }
      }

    }
}

.swiper-compartir-internet-title {
  &::before {
    content: attr(data-index);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    border-radius: 100%;
    box-shadow: 4px 4px 25px rgba(38, 37, 37, 0.1);
    color: var(--color-primary);
    font-size: 21px;
    line-height: 24px;
    margin: 0 20px 0 0;
  }
}

.swiper-compartir-internet {


  display: none;
  overflow: hidden;

  &-wrapper {
    &::before {
      content: "";
    }
  }

  &.show {
    display: block;
  }

  &-title {
    display: none;
    font-size: 21px;
    line-height: 31px;

    &.show {
      display: block;
    }

  }

  .swiper-wrapper {
    left: calc(50% - 145.5px);
    margin: 0 0 40px 0;
  }

  .swiper-slide {
    opacity: 0;
    pointer-events: none;
  }

  .swiper-slide.swiper-slide-active,
  .swiper-slide.swiper-slide-prev,
  .swiper-slide.swiper-slide-next {
    opacity: 1;
  }

  .swiper-slide.swiper-slide-active {
    pointer-events: initial;
  }

  .swiper-slide-active {

    display: flex;
    justify-content: center;

      &::before {
        content: "";
        display: block;
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        transform: translateX(-100%);
        background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0.8519608527004552) 100%);
        pointer-events: none;
        user-select: none;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        transform: translateX(100%);
        background: linear-gradient(90deg, rgba(255,255,255,0.8547619731486344) 0%, rgba(255,255,255,1) 50%);
        pointer-events: none;
        user-drag: none;
        user-select: none;
      }

  }


  &__slide-dialog {


    &--last {
      background-color: var(--color-primary);
      color: var(--color-white);
    }
  }

  &__footer {
    margin: 0 auto 32px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 291px;
    transform: translateX(-50%);
    font-size: 14px;
    line-height: 16px;
    color: #BFBFBF;

    &__counter {
      &__from {
        color: #333;
      }
    }

      &__nav {
        margin: 0 0 0 40px;
      }

  }

}

.swiper-compartir-internet-dialog {
  padding: 10px;
  width: 100%;
  background-color: #F2F2F2;
  font-size: 18px;
  line-height: 22px;
  margin: 0 0 24px 0;
  padding: 30px;
}
