.pymes-cards-variants{
    max-width: 1440px;
    margin-top: 119px;
    margin-bottom: calc(var(--s-v21)*6);
    @include media (medium up){
        margin-top: 119px;
        margin-bottom: calc(var(--s-v21)*11);
    }
    .swiper{
        overflow: visible;
    }
    .pymes-cards-variants-title{
        text-align: center;
        margin-bottom: calc(var(--s-v21)*4);
        font-family: $font-family-primary;
        color: var(--color-primary);
        font-size: 29px;
        line-height: 35px;
        font-weight: $font-weight-primary-bold;
    }
    .cards-variants-pagination-container{
        padding-top: calc(var(--s-v21)*3);
        .swiper-pagination-bullet{
            &:not(:last-child){
                margin-right: var(--s-v21);
            }
        }
        @include media (large up){
            display: none;
        }
        .swiper-pagination-cards-variants{
            text-align: center;
        }
    }
    .pymes-cards-variants-card-container{
        @include media (large up){
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 30px;
        }
        .pymes-cards-variants-card{
            display: flex;
            flex-direction: column;
            background-color: var(--color-light-v21);
            padding: calc(var(--s-v21)*3);
            box-shadow: 4px 4px 25px rgba(38, 37, 37, 0.1);
            border-radius: 16px;
            min-height: 393px;
            width: 100%;
            justify-self: center; 
            @include media (large up){
                min-height: 235px;
                max-width: 608px;
                flex-direction: row;
            }
            &.swiper-slide{
                height: initial;
            }
            img{
                height: 88px;
                width: 88px;
                margin-bottom: calc(var(--s-v21)*3);
                @include media (large up){
                    margin-right: calc(var(--s-v21)*3);
                }
                path {
                    color: red;
                    fill: red;
                    stroke: orange;
                }
            }
            .card-inner-title{
                font-weight: bold;
                font-family: $font-family-primary;
                color: var(--color-primary);
                font-size: 27px;
                line-height: 33px;
                margin-bottom: var(--s-v21);
                @include media (medium up){
                    font-size: 29px;
                    line-height: 35px;
                }

                .card-inner-title__logo {
                    height: 25px;
                    width: 56px;
                    margin-bottom: 0;
                    margin-right: 0;
                    @include media (medium up) {
                        height: 32px;
                        width: 56px;
                    }
                }
            }
            .card-inner-description{
                max-width: 413px;
                font-size: 18px;
                line-height: 24px;
                color: var(--color-primary);
            }
        }
    }
}