@import './../_mixins/grid';
@import './../_variables/colors';
@import './../_variables/transition';

.configurator__item{
	width: 100%;

	.configurator__item__title{
		margin-bottom: 40px;
	}

	.internet-options-result{
		text-align: center;
		margin: 50px auto 0 auto;
	}
	.internet-options-result__item{
		display: none;
		font-family: $font-family-primary;
		font-size: 2.75rem;
		font-weight: 300;
		color: $color-grey-600;
	}
		.internet-options-result__item strong,
		.configurator__box__speed strong{
			font-family: $font-family-primary;
			font-weight: 700;
			font-size: 4.0625rem;
			color: $color-international-orange;
		}

		.internet-options-result__item.active{
			display: block;
		}
	.internet-selector{
		text-align: center;
		margin: 30px auto;
	}
		.internet-selector__item{
			display: inline-block;
			width: 40%;
			min-width: 95px;
			border: 1px solid $color-silver;
			border-radius: 20px;
			color: $color-silver;
			margin: 5px;
			cursor: pointer;
		}
			.internet-selector__item.disabled{
				background-color: #F2F2F2;
				border-color: $color-silver;
				color: $color-silver;
				cursor: not-allowed;
			}
			.internet-selector__item span{
				display: block;
				padding: 6px;
				border: 1px solid transparent;
				border-radius: 20px;
			}
			.internet-selector__item:hover,
			.internet-selector__item:hover span,
			.internet-selector__item.active,
			.internet-selector__item.active span{
				border-color: $color-international-orange;
				color: $color-international-orange;
				font-weight: bold;
			}
				.internet-selector__item.disabled:hover,
				.internet-selector__item.disabled:hover span,
				.internet-selector__item.disabled.active,
				.internet-selector__item.disabled.active span{
					border-color: transparent;
					color: $color-silver;
					font-weight: normal;
				}
					.internet-selector__item.disabled:hover,
					.internet-selector__item.disabled.active{ border-color: $color-silver; }
	.configurator__symbol{
		display: table;
		width: 100%;
		height: 100%;
		text-align: center;
	}
	.configurator__symbol__item,
	.configurator__box__symbol__item{
		display: table-cell;
		font-family: $font-family-primary;
		font-size: 2.25rem;
		font-weight: 700;
		color: $color-silver;
		vertical-align: middle;
	}
		.configurator__box__symbol__item{
			font-size: 1.3125rem;
		}
	.configurator__box{
		position: relative;
		margin-bottom: 15px;
	}
		.configurator__box__options{ display: none; }
			.configurator__box__options .configurator__box__table{
				border-bottom: 1px solid $color-silver;
				cursor: pointer;
			}
				.configurator__box__options .configurator__box__table:last-child{
					border-bottom: none;
				}
		.configurator__box--selectable .configurator__box__options{
			position: absolute;
			display: block;
			width: 100%;
			top: 0;
			z-index: 2;
			background-color: $color-white;
			border: 1px solid $color-silver;
			border-radius: 7px;
			box-shadow: 4px 4px 20px rgba(38, 37, 37, 0.1);
			left: 0;
			top: -80px;
		}
		.configurator__box--big{ margin-bottom: 50px; }
		.configurator__box__table{
			display: table;
			width: 100%;
		}
		.configurator__box--border{
			border: 1px solid $color-silver;
			border-radius: 7px;

			&.configurator__box--selectable{ border: none; }
		}
		.configurator__box__cell{
			display: table-cell;
			vertical-align: middle;
			padding: 15px 10px;
		}
			.configurator__box__switch{
				text-align: right;
			}
		.configurator__box__icon{
			width: 24px;
		}
			.configurator__box__icon__img{
				display:block;
				width: 24px;
				height: 24px;
				background-color: $color-international-orange;
			}
		.configurator__box__text{
			font-family: $font-family-primary;
			font-size: 1.125rem;
			font-weight: 700;
		}
		.configurator__box.disabled .configurator__box__icon__img{
			background-color: $color-silver;
		}
		.configurator__box.disabled .configurator__box__text{
			color: $color-grey-600;
		}
		.configurator__box__symbol{
			width: 16px;
			padding-left: 0;
			padding-right: 0;
		}
		.configurator__box__price{
			font-family: $font-family-primary;
			font-size: 1.125rem;
			line-height: 1.5rem;
			font-weight: 700;
			color: $color-grey-600;
			width: 120px;
		}
		.configurator__box__speed{
			text-align: right;
			font-family: $font-family-primary;
			font-size: 1.75rem;
			font-weight: 300;
			color: $color-grey-600;
		}
			.configurator__box__speed strong{
				font-size: 3rem;
			}
		.configurator__box__arrow{
			width: 40px;
			text-align: center;
			cursor: pointer;
			padding-left: 0;
		}
			.configurator__box__arrow__icon{
				position: relative;
				display: block;
				width: 16px;
				height: 16px;
			}
				.configurator__box__options .configurator__box__arrow__icon{ opacity: 0; }
				.configurator__box__options .configurator__box__table.active{
					position: relative;
					z-index: 1;
				}
				.configurator__box__options .configurator__box__table:hover{
					background-color: #F2F2F2;
				}
					.configurator__box__options .configurator__box__table:first-child.active,
					.configurator__box__options .configurator__box__table:last-child.active{
						outline: none;
					}
					.configurator__box__options .configurator__box__table.active:before{
						content: "";
						position: absolute;
						bottom: -1px;
						left: -1px;
						display: block;
						width: calc(100% + 2px);
						height: calc(100% + 2px);
						background-color: $color-white;
						border: 1px $color-international-orange solid;
						z-index: -1;
					}
						.configurator__box__options .configurator__box__table:last-child.active:before,
						.configurator__box__options .configurator__box__table:last-child:hover{
							-webkit-border-bottom-right-radius: 7px;
							-webkit-border-bottom-left-radius: 7px;
							-moz-border-radius-bottomright: 7px;
							-moz-border-radius-bottomleft: 7px;
							border-bottom-right-radius: 7px;
							border-bottom-left-radius: 7px;
						}
						.configurator__box__options .configurator__box__table:first-child.active:before,
						.configurator__box__options .configurator__box__table:first-child:hover{
							-webkit-border-top-left-radius: 7px;
							-webkit-border-top-right-radius: 7px;
							-moz-border-radius-topleft: 7px;
							-moz-border-radius-topright: 7px;
							border-top-left-radius: 7px;
							border-top-right-radius: 7px;
						}
				.configurator__box__options .configurator__box__table.active .configurator__box__arrow__icon{ opacity: 1; }
				.configurator__box__arrow__icon:before,
				.configurator__box__arrow__icon:after{
					content: "";
					display: block;
					background-color: $color-international-orange;
					position: absolute;
					top: 7px;
					height: 2px;
					width: 12px;
				}
					.configurator__box__arrow__icon:before{
						right: -2px;
						-ms-transform: rotate(45deg);
						-webkit-transform: rotate(45deg);
						transform: rotate(45deg);
					}
						.configurator__box__options .configurator__box__arrow__icon:before{
							-ms-transform: rotate(-45deg);
							-webkit-transform: rotate(-45deg);
							transform: rotate(-45deg);
						}
					.configurator__box__arrow__icon:after{
						left: 13px;
						-ms-transform: rotate(-45deg);
						-webkit-transform: rotate(-45deg);
						transform: rotate(-45deg);
					}
						.configurator__box__options .configurator__box__arrow__icon:after{
							-ms-transform: rotate(45deg);
							-webkit-transform: rotate(45deg);
							transform: rotate(45deg);
						}
		.configurator__box__close{
			position: absolute;
			top: -10px;
			right: -10px;
			width: 24px;
			height: 24px;
			border-radius: 50%;
			background-color: $color-mine-shaft;
			cursor: pointer;
			z-index: 2;
		}
			.configurator__box--selectable .configurator__box__close{ display: none; }
			.configurator__box__close:before,
			.configurator__box__close:after{
				content: "";
				display: block;
				background-color: #F2F2F2;
				position: absolute;
				top: 11px;
				left: 5px;
				height: 2px;
				width: 14px;
				border-radius: 2px;
			}
				.configurator__box__close:before{
					-ms-transform: rotate(45deg);
					-webkit-transform: rotate(45deg);
					transform: rotate(45deg);
				}
				.configurator__box__close:after{
					-ms-transform: rotate(-45deg);
					-webkit-transform: rotate(-45deg);
					transform: rotate(-45deg);
				}
			.configurator__box--dashed{
				border: 2px dashed $color-silver;
				background-color: #F5F5F5;
				border-radius: 7px;
			}
			.configurator__box--add{
				text-align: center;
				color: $color-silver;
				font-size: 0.875rem;
				font-weight: bold;
				cursor: copy;
			}
			.configurator__box--add__icon{
				position: relative;
				display: block;
				margin: 0 auto 10px auto;
				width: 20px;
				height: 20px;
				background-color: $color-silver;
				border-radius: 50%;
			}
				.configurator__box--add__icon:before,
				.configurator__box--add__icon:after{
					content: "";
					display: block;
					background-color: $color-white;
					position: absolute;
					top: 9px;
					left: 4px;
					height: 2px;
					width: 13px;
				}
					.configurator__box--add__icon:after{
						-ms-transform: rotate(90deg);
						-webkit-transform: rotate(90deg);
						transform: rotate(90deg);
					}

		.your-election{
			margin-bottom: 40px;

			.your-election__subtitle{
				margin: 20px 0;
				background-color: $color-international-orange;
				color: $color-white;
				display: inline-block;
				padding: 10px;
				font-size: 0.875rem;
				font-weight: bold;
			}
			.your-election__disclaimer{
				color: $color-grey-600;
				font-size: 0.875rem;
				font-weight: bold;
				max-width: 200px;
			}
		}
}
