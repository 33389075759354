.c-toggle-switch {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .text {
        font-family: $font-family-secondary;
        font-size: $font-size-16;
        line-height: $line-height-for-font-size-24;
        font-weight: bold;
        margin-right: 20px;
        color: rgba(51, 51, 51, 1);
    }
    .switch {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 20px;
        flex-shrink: 0;

        input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: .4s;
            transition: .4s;

            min-height: auto;
            &:before {
                position: absolute;
                content: "";
                height: 16px;
                width: 16px;
                left: 2px;
                bottom: 2px;
                background-color: white;
                -webkit-transition: .4s;
                transition: .4s;
            }
        }
        input:checked + .slider {
            background-color: $color-tertiary;
        }

        input:focus + .slider {
            box-shadow: 0 0 1px $color-tertiary;
        }

        input:checked + .slider:before {
            -webkit-transform: translateX(19px);
            -ms-transform: translateX(19px);
            transform: translateX(19px);
        }

            /* Rounded.sliders */
        .slider.round {
            border-radius: 20px;
        }

        .slider.round:before {
            border-radius: 50%;
        }
    }
}
