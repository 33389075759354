.c-prefooter {
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;

  .button__content {
    font-size: 14px;
  }

  @include media(large up) {
    flex-direction: row;
  }

  &__horario {
    font-size: 14px;
    line-height: 15px;
    color: #000;
    background-color: rgba($color-primary, 0.3);
    height: auto;
    padding: 8px;
    margin-top: 0;
    width: 100%;
    max-width: 200px;

    &:empty {
      display: none;
    }

    @include media(medium up) {
      text-align: left;
    }

    @include media(xlarge up) {
      font-size: 11px;
      width: auto;
    }
  }

  &__subtext {
    padding-top: 16px;
    font-size: 13px;
    line-height: 16px;
    color: #fff;
    width: 100%;
  }

  &__left-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 40px 24px;
    border-bottom: 2px solid $color-grey-400;

    @include media(large up) {
      width: 40%;
      border-right: 2px solid $color-grey-400;
      border-bottom: none;

      & > div {
        min-width: 440px;
      }

      .c-prefooter__content {
        grid-template-columns: auto auto;
      }
    }

    @include media(xlarge up) {
      justify-content: flex-end;
      padding: 16px 24px 41px;
      text-align: left;
      width: 40%;

      .c-prefooter__content {
        grid-column-gap: 28px;
      }

      & > div {
        min-width: 470px;
      }
    }

    @include media(xxlarge up) {
      & > div {
        min-width: 447px;
      }
    }

    @include media(xxxlarge up) {
      & > div {
        min-width: 415px;
      }
    }

    .c-prefooter__cta {
      &:hover {
        background-color: $color-gray !important;
        color: #fff !important;

        .icon__glyph {
          stroke: #fff;
        }
      }
    }

    .c-prefooter__title,
    .c-prefooter__subtitle,
    .c-prefooter__text {
      .icon {
        max-width: 20px;
        margin-right: 4px;
      }

      .icon__glyph {
        stroke: #fff;
      }
    }

    .c-prefooter__text {
      font-size: 18px; 
      font-weight:bold;
      font-family: $font-family-primary;
      padding-left: 8px;
    }

    .c-prefooter__subtitle {
      display: inline-block;

      @include media(xlarge up) {
        display: block;
      }
    }

    .c-prefooter__title {
      width: 100%;
    }

    .button--bordered-to-filled.button--secondary {
      border-color: rgba(255,255,255,.5);
      color: #fff;
    
      &:hover {
        background-color: #fff;
        color: #000;
      }
    }

    .c-prefooter__text {
      margin-top: 8px;
      display: flex;
      align-items: baseline;
    }
  }

  &__container {
    display: flex;
    justify-content: center;
    padding: 40px 24px;
    flex-direction: column;

    @include media(xlarge up) {
      text-align: left;
    }

    @include media(xlarge up) {
      padding: 16px 24px 41px;
    }

    &.u-wrapper {
      margin: 0;
      max-width: none;
    }

    .c-prefooter__cta {
      border-color: rgba(#1D1F2C,.2);
    }

    .c-prefooter__content {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, 1fr);
      grid-column-gap: 0;
      grid-row-gap: 20px;

      @include media(medium up) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
      }

      @include media(large up) {
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 30px;
      }

      @include media(xlarge up) {
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 40px;
      }

      @include media(xxlarge up) {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(1, 1fr);
        grid-column-gap: 40px;
      }

      .c-prefooter__column {
        &:nth-child(1) {
          grid-area: auto;

          @include media(medium up) {
            grid-area: 1 / 1 / 2 / 2;
          }

          @include media(xxlarge up) {
            grid-area: 1 / 1 / 2 / 2;
          }
        }

        &:nth-child(2) {
          grid-area: auto;
          white-space: nowrap;

          @include media(medium up) {
            grid-area: 2 / 1 / 3 / 2;
          }

          @include media(xxlarge up) {
            grid-area: 1 / 2 / 2 / 3;
          }
        }

        &:nth-child(3) {
          grid-area: auto;

          @include media(medium up) {
            grid-area: 1 / 2 / 3 / 3;
          }

          @include media(xxlarge up) {
            grid-area: 1 / 3 / 2 / 4;
          }
        }

        &:nth-child(4) {
          grid-area: auto;

          @include media(medium up) {
            grid-area: 1 / 3 / 3 / 4;
          }

          @include media(xxlarge up) {
            grid-area: 1 / 4 / 2 / 5;
          }
        }
      }
    }
  }

  &__content {
    display: grid;
    grid-template-rows: auto;
    justify-content: flex-start;
    
    @include media(medium up) {
      grid-template-columns: auto auto auto;
      grid-template-rows: 1fr;
      grid-column-gap: 10px;
    }

    @include media(xlarge up) {
      max-width: max-content;
    }

    @include media(xxlarge up) {
      grid-column-gap: 71px;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 8px;
    max-width: 100%;

    & > * {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 20px;
      }
    }

    @include media(medium up) {
      padding-bottom: 0;
      max-width: 170px;
    }

    @include media(xlarge up) {
      text-align: left;
    }
  }

  &__title {
    font-family: $font-family-primary;
    font-weight: $font-weight-primary-bold;
    font-size: 21px;
    line-height: 27px;
    letter-spacing: -0.5px;
    margin-bottom: 10px;
    color: $color-font-primary;

    @include media(medium up) {
      margin-bottom: 16px;
      font-size: 18px;
      line-height: 30px;
    }
  }

  &__subtitle {
    font-family: $font-family-secondary;
    font-weight: $font-weight-secondary-bold;
    font-size: 16px;
    line-height: $line-height-for-font-size-16;
    letter-spacing: -0.5px;
    margin-bottom: 12px;
    color: $color-font-primary;
    min-height: 20px;

    &:empty {
      display: none;
    }

    @include media(medium up) {
      &:empty {
        display: initial;
      }
    }
  }

  &__text {
    font-family: $font-family-secondary;
    font-size: $font-size-em-14;
    line-height: $line-height-for-font-size-14;
    color: $color-font-primary;
    margin-bottom: 10px;

    @include media(medium up) {
      margin-bottom: 16px;
    }

    a {
      color: $color-primary;
      transition: $transition-color;

      &:hover {
        color: lighten($color-primary, 30%);
        transition: $transition-color;
      }
    }

    &--size-lg {
      font-size: $font-size-em-16;
      line-height: 1.5rem;
    }
  }

  &__cta {
    height: 45px;
    display: flex;
    padding: 4px 12px !important;

    .icon {
      margin-right: 8px;
      margin-left: 0;
      width: 16px;
      height: 16px;
    }
  }
}
