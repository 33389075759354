.section-centralita-virtual {
  margin: calc(8px * 8) auto;
}
.centralita-virtual-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border: 5px solid var(--color-grey-200);
  border-radius: 32px;
  align-items: center;
  padding: calc(8px * 3);
  @include media(medium up) {
    margin-left: auto;
    margin-right: auto;
  }
  @include media(large up) {
    flex-direction: row;
    padding: calc(8px * 8);
  }
  .centralita-virtual-image-block {
    max-width: 80%;
    align-self: center;
    @include media(medium up) {

      max-width: 50%;
    }
    img {
      width: 100%;
    }
  }
  .centralita-virtual-text-block {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    width: 100%;
    @include media(medium up) {
    }
    @include media(large up) {
      align-self: auto;
      width: fit-content;
      padding-left: calc(8px * 3);
    }
    .text-block-featured-list {
      list-style-type: none;
      margin-top: calc(8px * 3);
      margin-bottom: calc(8px * 4);
      @include media(medium up) {
      }
      .text-block-featured-list-item {
        display: flex;
        align-items: center;
        &:not(:last-child) {
          margin-bottom: calc(8px * 2);
        }
        .featured-item-text {
          margin-left: 8px;
          font-size: 14px;
          line-height: 18px;
        }
        .icon {
          height: 14px;
          width: 14px;
          svg {
            height: 14px;
            width: 14px;
            path {
              stroke: var(--color-primary);
            }
          }
        }
      }
    }
    .text-block-title {
      font-weight: 600;
      font-size: 26px;
      line-height: 32px;
      color: #ef3340;
      font-family: $font-family-primary;
      @include media(medium up) {
        font-size: 36px;
        line-height: 42px;
      }
      &.secondary {
        color: var(--color-text);
      }
    }
    .text-block-subtitle {
      margin-top: 8px;
      font-weight: bolder;
      font-size: 14px;
      line-height: 18px;
      @include media(medium up) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .text-block-actions-wrapper {
      margin-top: calc(8px * 3);
      @include media(medium up) {
        margin-top: calc(8px * 4);
      }
      a {
        color: white;
        background: #ef3340;
        border-radius: 8px;
      }
    }
  }
}
