.netflix-advanced-products{
    .netflix-info{
      display: flex;
      width: 100%;
      flex-direction: column;
      flex-wrap: wrap;
      @include media(medium up) {
        margin-right: -10px;
        margin-left: -10px;
        flex-direction: row;
        margin-top: 16px;
      }
      article{
        display: flex;
        width: 100%;
        flex-direction: column;
        margin-bottom: 20px;
        @include media(medium up) {
          padding-right: 10px;
          padding-left: 10px;
          width: 33.33333%;
          min-width: 250px;
        }
        header{
          background-color: $color-secondary;
          padding: 12px 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          h3{
            color: $color-primary-contrast;
            font-family: $font-family-primary;
            font-weight: bold;
            padding-right: 16px;
            line-height: 1.2;
            span {
              font-weight: 400;
              font-size: 0.87rem;
              font-family: $font-family-secondary;
            }
          }
          p {
            color: $color-primary-contrast;
            font-weight: bold;
            margin: 0;
          }
        }
        >div{
          padding: 16px;
          display: flex;
          align-items: center;
          background-color: $color-grey-300;
          flex-wrap: wrap;
        }
        footer {
          background-color: $color-grey-300;
          border-top: 1px solid #bfbfbf;
          padding: 16px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          > div{
            display: flex;
            flex-direction: column;
            margin-right: 16px;
            align-items: flex-start;
          }
          span{
            font-size: 12px;
            margin: 0;
          }
        }
        .netflix-logo{
          max-width: 82px;
          margin-right: 16px;
        }
        .netflix-type{
          height: 24px;
        }
        .netflix-pantallas{
          margin-bottom: 16px;
        }
        .netflix-promo{
          font-weight: bold !important;
        }
      }

    }
    .smaller{
      font-size: 12px;
      line-height: 14px;
      display: block;
      color: $color-grey-600;
      a{
        font-weight: 400 !important;
        color: $color-primary;
      }
    }
  }
  .dialog-bigger{
    @include media(medium up){
      width: 100% !important;
      max-width: 900px !important;
    }
  }
