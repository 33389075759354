.c-hero-seguros {
  box-sizing: border-box;
  height: 100%;
  min-height: 535px;
  padding: 0;
  position: relative;
  width: 100%;

  @include media(large up) {
    min-height: 480px;
  }

  &--movil {
    background-color: #FADC8F;
  }

  &--movil-plus {
    background-color: #ADF2E7;
  }

  &--rotura-pantalla {
    background-color: #E9CAF8;
  }

  &__container {
    z-index: 2;
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 24px 12px 24px;

    @include media(medium up) {
      justify-content: space-between;
      padding: 48px 20px 48px;
    }

    @include media(xlarge up) {
      padding: 48px 0;
    }
  }

  &__container-text {
    @include media(medium up) {
      margin-right: 40px;
    }
  }

  &__pretitle {
    font-family: $font-family-primary;
    font-size: 14px;
    line-height: 16px;
    color: $color-black;
    margin-bottom: 16px;

    @include media(medium up) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__title {
    font-family: $font-family-primary;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: $color-black;
    margin-bottom: 8px;

    @include media(medium up) {
      font-size: 36px;
      line-height: 42px;
    }
  }

  &__subtitle {
    font-family: $font-family-primary;
    font-size: 18px;
    line-height: 24px;
    color: $color-black;
  }

  &__offer {
    margin-top: 24px;
    background-color: #ffffff;
    width: 275px;
    min-height: 200px;
    padding: 20px 30px;

    @include media(medium up) {
      min-height: 240px;
    }

    &__title {
      font-family: $font-family-primary;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      color: $color-font-primary;

      @include media(large up) {
        font-size: 18px;
        line-height: 24px;
      }
    }

    &__price {
      font-family: $font-family-primary;
      font-weight: 600;
      font-size: 48px;
      line-height: 48px;
      color: $color-font-primary;

      @include media(large up) {
        font-size: 76px;
        line-height: 70px;
      }
    }

    &__euros {
      font-family: $font-family-primary;
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      color: $color-font-primary;

      @include media(large up) {
        font-size: 36px;
        line-height: 36px;
      }
    }

    &__months {
      font-family: $font-family-primary;
      font-size: 14px;
      line-height: 14px;
      color: $color-font-primary;

      @include media(large up) {
        font-size: 22px;
        line-height: 22px;
      }
    }

    &__content-price {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      @include media(mobilelarge up) {
        margin-bottom: 24px;
      }
    }

    &__content-extra {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__ribbon {
      background-color: #008dff;
      font-size: 14px;
      line-height: 24px;
      padding: 0 8px;
      color: #fff;
      font-family: $font-family-secondary;
      font-weight: 700;
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      width: fit-content;
    }

    &__cta {
      max-width: 100%;
      line-height: 40px;
      width: inherit;
      height: inherit;
      color: #fff;
      text-decoration: none;
      font-family: $font-family-secondary;
      font-weight: 600;
      text-align: center;
      display: none;

      @include media(medium up) {
        display: block;
      }

      &--mobile {
        display: block;

        @include media(medium up) {
          display: none;
        }
      }
    }
  }

  &__image {
    display: none;

    @include media(medium up) {
      display: block;
    }
  }

  &__image-mobile {
    display: block;
    height: 150px;

    @include media(medium up) {
      display: none;
    }
  }
}
