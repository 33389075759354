@import './../_variables/colors';

:root {
  --lity-modal-max-width: 920px;
  --lity-modal-min-height: 200px;
  --lity-modal-max-height: 750px;
  --lity-modal-outer-spacing: 16px;

  @include media(medium up) {
    --lity-modal-outer-spacing: 40px;
  }
}

.lity-container {
  overflow: auto;
}

.lity-wrap--full-mobile {
  &::before {
    margin-right: 0 !important;
  }
}

.lity-container--vertical {
  position: absolute !important;
  left: 0 !important;

  width: 100% !important;
  max-width: 100% !important;

  @include media(mobilexlarge up) {
    width: initial !important;
    max-width: 80% !important;
  }
}

.lity-container--full-mobile {
  width: 100% !important;
  max-width: 100% !important;

  @include media(medium up) {
    width: initial !important;
    max-width: 80% !important;
  }
}

.lity-close {
  display: none !important;
}

.lity-opened {
  background-color: rgba(29, 31, 44, 0.7) !important;
}

.c-lity-modal {
  overflow: auto;

  min-height: var(--lity-modal-height);

  background-color: $color-white;

  @include media(medium up) {
    max-width: var(--lity-modal-max-width);
  }

  &__wrapper {
    position: relative;

    display: flex;
    flex-direction: column;

    padding: var(--lity-modal-outer-spacing);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: start;
    color: #333;
    margin-bottom: 32px;

    @include media(medium up) {
		margin-bottom: 16px;
        align-items: center;
	}

    > div > button {
      cursor: pointer;
      background-color: transparent;
      border: none;

      > svg {
        pointer-events: none;
      }
    }
  }

  &__close {
	position: absolute;
	right: 12px;
    top: 16px;
  }

  &__content {
    font-size: 0.9rem;
    line-height: 1.1rem;
    color: #444444;

    & a.lity-link {
      color: $color-secondary;
    }
  }

  &__footer {
    padding-left: var(--lity-modal-outer-spacing);
    padding-bottom: var(--lity-modal-outer-spacing);

    > button {
      font-weight: $font-weight-secondary-bold;
    }
  }

  &__title {
    margin-bottom: 0;
    margin-top: 32px;

    @include media(medium up) {
        margin-top: 0px;
    }

    font-family: $font-family-primary;
    font-weight: $font-weight-primary-bold;
    font-size: $font-size-24;
    line-height: 30px;
  }


  &--vertical {
    & {
      min-height: 100vh;
      // Sobreescribe estilo en línea de Lity
      max-height: 100% !important;
    }
  }

  &--full-mobile {
    & {
      min-height: 100vh;
      // Sobreescribe estilo en línea de Lity
      max-height: 100% !important;

      @include media(medium up) {
        height: initial;
        min-height: initial;
        max-height: initial;
      }
    }
  }

  &--fixed-height {
    & {
      max-height: var(--lity-modal-max-height) !important;
    }
  }
}
