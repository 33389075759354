:root {
  --mundo-r-header-light-height: 84px;

  @include media(large up) {
    --mundo-r-header-light-height: 100px;
  }
}

.c-mundo-r-header-light {
  background-color: $color-primary;
  height: var(--mundo-r-header-light-height);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;

  padding-top: 5px;

  z-index: var(--mundo-r-zindex-normal);

  @include media(medium up) {
    height: var(--mundo-r-header-light-height);
  }

  &__submenu {
    background-color: #fff;
    border-top: #ECECEC 1px solid;
    box-shadow: 4px 15px 15px rgba(38, 37, 37, 0.1);
    position: relative;
    top: -50px;
    opacity: 0;
    display: none;
    height: 100%;

    @include media(medium up) {
      height: 51px;
    }

    &--shown{
      opacity: 1;
      top: 38px;
      transition: top .2s ease-in-out,opacity 0s ease-in-out .2s;
      display: block;

      @include media(medium up) {
        top: 0;
      }
    }

    &__container {
      flex-direction: row;
      margin-left: auto;
      margin-right: auto;
      max-width: 1110px;
      display: none;
      padding:0 12px;
      position: absolute;
      top: 0;
      left: 0;

      &--shown {
        display: flex;
      }
    }

    &__item {
      font-size: 14px;
      line-height: 24px;
      margin-right: 20px;
      padding: 12px 0;
      display: block;
      border-bottom: 2px solid #fff;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      @include media(medium up) {
        margin-right: 50px;
      }

      &:hover,
      &--active {
        border-bottom-color: $color-primary;
      }
    }
  }

  &__nav {
    background-color: $color-primary;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 0;
    order: 2;
    overflow-x: auto;

    @include media(large up) {
      order: 0;
      height: 100%;
      justify-content: space-around;
    }

    &__item {
      background-color: $color-primary;
      font-size: 14px;
      font-weight: bold;
      line-height: 24px;
      padding: 6px 20px;
      border-bottom: 2px solid $color-primary;
      white-space: nowrap;
      color: #fff;
      cursor: pointer;

      @media(min-width: 360px) {
        font-size: 14px;
      }

      @include media(large up) {
        padding: 12px;
      }

      &--active {
        border-bottom-color: #FFF;
        color: #FFF;
      }

      &:hover {
        border-bottom-color: #FFF;
      }
    }
  }

  &__container {
    position: relative;
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1110px;
    flex-wrap: wrap;

    @include media(large up) {
      flex-wrap: nowrap;
      align-items: flex-end;
    }
  }

  &__logo-link {
    margin-left: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    z-index: var(--mundo-r-zindex-normal);

    @include media(large up) {
      align-items: flex-end;
    }
  }

  &__logo {
    height: 42px;
    flex-shrink: 0;
    margin-bottom: 0;
    @include transition();

    @include media(medium up) {
      height: 42px;
      margin-bottom: 18px;
    }

    @include media(large up) {
      height: 42px;
      margin-bottom: 18px;
    }

    img {
      width: auto;
      height: 42px;
      @include transition();

      @include media(large up) {
        width: auto;
        height: 42px;
      }
    }
  }

  &__call-us {
    margin-right: 10px;
    background-color: $color-font-primary;
    color: $color-white;
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 16px;
    transition: $transition-background-color;
    white-space: nowrap;
    z-index: var(--mundo-r-zindex-normal);
    border-radius: 100px;
    @include transition();
    margin-bottom: 8px;

    @include media(medium up) {
      margin-bottom: 8px;
    }

    @include media(large up) {
      padding: 0 25px;
      height: 64px;
      margin-bottom: 8px;
    }

    &:hover {
      background-color: darken($color-primary, 5%);
      transition: $transition-background-color;
    }

    &__icon {
      margin-right: 15px;
      width: 24px;

      > svg * {
        stroke: $color-white;
      }

      @include media(xlarge up) {
        width: 30px;
      }
    }

    &__content {

      &__text {
        font-size: $font-size-14;
        line-height: $line-height-for-font-size-14;
        font-family: $font-family-secondary;

        @include media(xlarge up) {
          font-size: $font-size-16;
          line-height: $line-height-for-font-size-16;
        }
      }

      &__phone {
        display: block;
        font-size: 1rem;
        line-height: 1rem;
        font-family: $font-family-primary;

        @include media(xlarge up) {
          font-size: 30px;
          line-height: $line-height-for-font-size-30;
          letter-spacing: -0.5px;
        }
      }
    }

    &--desktop {
      display: none;

      @include media(medium up) {
        display: flex;
      }
    }

    &--mobile {
      display: flex;

      @include media(medium up) {
        display: none;
      }
    }
  }

  &--fixed {
    position: fixed;
    width: 100%;
    height: 90px;
    @include transition(0.6s, ease-in-out);

    & .c-mundo-r-header-light__logo {
      height: 32px;
      @include transition();

      img {
        height: 32px;
        @include transition();
      }
    }

    & .c-mundo-r-header-light__call-us {
      height: 56px;
      @include transition();
    }

    & .c-mundo-r-header-light__nav {
      background-color: $color-primary;
      margin-top: 0;
    }
  }

  & .header-desktop__secondary-content-menu {
    position: absolute;
    top: 4px;
    right: 14px;
    left: auto;

    @include media(medium up) {
      right: 30px;
      top: 2px;
    }

    & .links--primary .link--primary-alt:hover {
      color: $color-secondary;
    }
  }
}
