.c-contact-client {
  background-color: $color-white-smoke;

  &__content {
    margin-top: 48px;
    margin-bottom: 99px;
    color: $color-mine-shaft;
    display: flex;

    @include media(xlarge down) {
      justify-content: center;
    }

    @include media(medium down) {
      flex-flow: column;
    }

    &__faq {
      min-width: 647px;
      max-width: 730px;
      width: 100%;

      @include media(xlarge down) {
        width: auto;
        padding-left: 20px;
      }

      @include media(medium down) {
        padding: 0;
        margin: 0 auto;
      }

      @include media(mobilexlarge down) {
        max-width: 100%;
        min-width: auto;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
      }

      &__accordion {
        .section.section--full.section--wrapper.section--faq.section--faq {
          width: 100%;
          margin: 0 auto;

          .c-faq.u-wrapper.icon--arrow-down {
            padding-top: 0;
            padding-bottom: 40px;

            .c-faq__header, .c-faq__content {
              padding-right: 0;
              padding-left: 0;

              @include media(mobilexlarge down) {
                padding-right: 20px;
                padding-left: 20px;
              }
            }

            .c-faq__header {
              .section-description {
                .section-description__title {
                  color: $color-mine-shaft;
                  text-align: left;
                  font-size: $font-size-21 !important;
                  line-height: $line-height-for-font-size-27;

                  @include media(medium down) {
                    text-align: center;
                  }

                  @include media(mobilexlarge down) {
                    font-size: $font-size-18 !important;
                    line-height: $line-height-for-font-size-24;
                  }
                }
              }
            }

            .c-faq__content {
              .accordion-item.accordion-item--faq,
              .accordion-item.accordion-item--faq.accordion-item--opened {
                @include media(xlarge down) {
                  max-width: 627px;
                }

                @include media(medium down) {
                  max-width: 100%;
                }
              }
            }
          }
        }
      }
    }

    &__contact {
      width: 100%;
      max-width: 350px;
      margin-left: 30px;

      @include media(xlarge down) {
        padding-right: 20px;
        margin-left: 25px;
      }

      @include media(medium down) {
        padding-top: 36px;
        padding-right: 0;
        margin: 0 auto;
      }

      @include media(mobilelarge down) {
        margin: 0;
        max-width: 100%;
        padding-right: 20px;
        padding-left: 20px;
      }

      &__title {
        font-size: $font-size-21;
        line-height: $line-height-for-font-size-27;
        font-weight: $font-weight-primary-bold;
        margin-bottom: 32px;

        @include media(medium down) {
          text-align: center;
        }

        @include media(mobilexlarge down) {
          font-size: $font-size-18;
          line-height: $line-height-for-font-size-24;
        }
      }

      &__link {
        display: flex;

        & > .link.link--primary {
          font-weight: $font-weight-secondary-bold;
          font-size: $font-size-14;
          line-height: $line-height-for-font-size-16;
          margin-left: auto;
          margin-top: 16px;
        }
      }
    }
  }
}
