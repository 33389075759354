@import '../_theme/fonts';
@import './../_functions/em';
@import './../_functions/rem';

$font-family-primary: 'R Gerstner', sans-serif;
$font-weight-primary-bold: 700;
$font-weight-primary-semi-bold: 700;
$font-weight-primary-regular: 400;
$font-weight-primary-light: 400;

$font-family-secondary: 'Lato', sans-serif;
$font-weight-secondary-bold: 700;
$font-weight-secondary-semi-bold: 600;
$font-weight-secondary-regular: 400;
$font-weight-secondary-light: 300;

$font-size-86: rem(86);
$font-size-62: rem(62);
$font-size-52: rem(52);
$font-size-50: rem(50);
$font-size-48: rem(48);
$font-size-46: rem(46);
$font-size-42: rem(42);
$font-size-36: rem(36);
$font-size-32: rem(32);
$font-size-28: rem(28);
$font-size-24: rem(24);
$font-size-22: rem(22);
$font-size-21: rem(21);
$font-size-20: rem(20);
$font-size-18: rem(18);
$font-size-16: rem(16);
$font-size-15: rem(15);
$font-size-14: rem(14);
$font-size-13: rem(13);
$font-size-12: rem(12);
$font-size-11: rem(11);
$font-size-10: rem(10);
$font-size-9: rem(9);
$line-height-for-font-size-52: rem(64);
$line-height-for-font-size-50: rem(62);
$line-height-for-font-size-48: rem(58);
$line-height-for-font-size-46: rem(54);
$line-height-for-font-size-42: rem(48);
$line-height-for-font-size-36: rem(42);
$line-height-for-font-size-32: rem(38);
$line-height-for-font-size-30: rem(34);
$line-height-for-font-size-28: rem(32);
$line-height-for-font-size-27: rem(31);
$line-height-for-font-size-26: rem(30);
$line-height-for-font-size-24: rem(28);
$line-height-for-font-size-23: rem(27);
$line-height-for-font-size-22: rem(26);
$line-height-for-font-size-21: rem(24);
$line-height-for-font-size-20: rem(24);
$line-height-for-font-size-18: rem(24);
$line-height-for-font-size-16: rem(20);
$line-height-for-font-size-15: rem(18);
$line-height-for-font-size-14: rem(18);
$line-height-for-font-size-13: rem(16);
$line-height-for-font-size-12: rem(14);
$line-height-for-font-size-11: rem(12);
$line-height-for-font-size-9: rem(10);
$font-size-em-86: em(86);
$font-size-em-48: em(48);
$font-size-em-42: em(42);
$font-size-em-36: em(36);
$font-size-em-24: em(24);
$font-size-em-22: em(22);
$font-size-em-18: em(18);
$font-size-em-16: em(16);
$font-size-em-14: em(14);
$font-size-base: $font-size-16;
