.pymes-menu{
.header__menu__item{
  .link.pymes-menu{
    @include media(large up){
      padding-right: 4px;
      padding-left: 4px;
    }
    @media only screen and (min-width: 1060px) {
      padding-right: var(--s-v21);
      padding-left: var(--s-v21);
    }
  }
  .link__content{
    .icon--tienda-equipamiento-icon-small{
      width: 12px;
      svg{
        path{
          stroke-width: 1px;
        }
      }
    }
    .link__text{
      font-size: 14px;
      line-height: 18px;
    }
  }
}
&.fit-header{
  .header__menu__item{
    @include media(medium up){
      margin-right: 24px;
      max-width: fit-content;
    }
    @media only screen and (min-width: 1260px) {
      margin-right: 0px;
      max-width: none;
    }
    @media only screen and (min-width: 1400px) {
      margin-right: 8px;
    }
    .link__content{
      .link__text{
        @include media(medium up){
          width: fit-content;
          max-width: 125px;
        }
        @media only screen and (min-width: 1260px) {
          max-width: none;
        }
      }
    }
  }
}
}
.pymes-header{
  .header__logo{
    @include media(large up){
      padding-right: 8px;
    }
    @media only screen and (min-width: 1060px) {
      padding-right: 0;
    }
  }
  .header__buttons{
    .header__buttons__actions{
      .header__buttons__cmn{
        @include media(large up){
          padding: 10px !important;
          width: fit-content;
          min-width: fit-content;
        }
        @media only screen and (min-width: 1060px) {
          min-width: 130px;
          padding: 12px calc(var(--s-v21) * 2);
        }
        .icon.icon--mr{
          @include media(large up){
            height: 20px;
          }
          @media only screen and (min-width: 1060px) {
            height: 24px;
          }
        }
        .button__text.drp-phone{
          @include media(large up){
            font-size: 10px;
          }
          @media only screen and (min-width: 1060px) {
            font-size:var(--link-font-size);
          }
        }
      }
    }
  }
}
