.custom-hero-slider__slider__block--raw-tv-title {
    max-width: 400px !important;
    line-height: 24px;
    padding-top: 10px;
}

@media (max-width: 768px){
    .custom-hero-slider__slider__block--raw-tv-title {
        padding-top: 200px;
    }
}

@media (min-width: 768px) and (max-width: 899px) {
    .custom-hero-slider__slider__block--raw-tv-title {
        padding-top: 60px;
    }
}

@media (min-width: 899px) and (max-width: 1024px) {
    .custom-hero-slider__slider__block--raw-tv-title {
        padding-top: -200px;
    }
}

.hero__tv__title {
    font-family: $font-family-primary;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    margin: 0 0 20px 0;
    color: #000;
}

.hero__tv__text {
    max-width: 375px;
    font-size: 16px;
    color: #000;
    font-family: $font-family-secondary;
    font-weight: normal;
    line-height: 24px;
}

.hero__tv__grid {
    display: flex;
    flex-wrap: wrap;
    width: 653px;
    position: absolute;
    top: 85px;
    right: 360px;
}

@media (max-width: 1350px){
    .hero__tv__grid {
        right: 280px;
    }
}

@media (max-width: 1300px){
    .hero__tv__grid {
        right: 250px;
    }
}

@media (max-width: 1250px){
    .hero__tv__grid {
        right: 200px;
    }
}

@media (max-width: 1200px){
    .hero__tv__grid {
        right: 150px;
    }
}

@media (max-width: 1150px){
    .hero__tv__grid {
        width: 589px;
        right: 130px;
    }
}

@media (max-width: 1050px){
    .hero__tv__grid {
        right: 100px;
        top: 165px;
    }
}

@media (max-width: 1049px){
    .hero__tv__grid {
        right: -50px;
        top: -28px;
    }
}

.hero__tv__grid > * {
    margin: 8px;
}

.hero__tv__grid  > *:nth-child(1){
    width: 197px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

@media (max-width: 1150px){
    .hero__tv__grid  > *:nth-child(1) img{
        width: 100px;
    }
}

.hero__tv__grid  > *:nth-child(2){
    width: 424px;
}

@media (max-width: 1150px){
    .hero__tv__grid  > *:nth-child(2){
        width: 360px;
    }
}

.hero__tv__grid  > *:nth-child(3){
    width: 197px;
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 1150px){
    .hero__tv__grid  > *:nth-child(3) img{
        width: 150px;
    }
}

.hero__tv__grid  > *:nth-child(4){
    width: 75px;
}


/*
    Banner: TVGO
    Fecha: 14/04/2021
*/

.hero__tvgo__title {
    font-family: $font-family-primary;
    font-size: 50px;
    font-weight: bold;
    margin: 0 0 15px 0;

    span {
        color: #e10a0a;
    }
}

.hero__tvgo__subtitle {
    font-family: $font-family-primary;
    font-size: 28px;
    font-weight: bold;
    margin: 0 0 100px 0;
}


.hero-tv-go__img {
    /*transform: scaleX(-1);*/
    position: absolute;
    right: 0;
    top: 40px;

    @media (max-width: 1000px){
        transform: none;
        width: 640px;
    }

    @media (max-width: 900px){
        width: 520px;
    }

    @media (max-width: 800px){
        position: absolute;
        width: 440px;
        top: -220px;
        right: auto;
    }
}

.hero-tv-go__title-line--1 {
    font-size: 28px;
    line-height: 42px;
    font-weight: bold;
    font-family: $font-family-primary;
    color: #000;
}

.hero-tv-go__title-line--2 {
    font-size: 40px;
    line-height: 42px;
    font-weight: bold;
    font-family: $font-family-primary;
    color: #ef3340;
    margin: 0 0 15px 0;

    br {
        @media (max-width: 800px){
            display: none;
        }
    }
}

.hero-tv-go__title-line--3 {
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    font-family: $font-family-primary;
    margin-bottom: 20px;
    color: #000;

    br {
        @media (max-width: 800px){
            display: none;
        }
    }
}

.hero-tv-go__cta {
    background-color: #ef3340 !important;
    border: 1px solid #ef3340;
    color: #fff !important
}

.tv-go-bg {
    opacity: 0.1;
    position: absolute;
    bottom: 0;
    left: calc(50% - 160px);
    transform: translateX(-50%);
    width: 470px;

    @media (max-width: 800px){
        transform: none;
        left: auto;
        right: 10px;
    }
}
