$color-primary: $color-primary;
$color-primary-faded: $color-primary;
$color-secondary: $color-secondary;

$two-cols-banner-content-color: $color-white;
$two-cols-banner-content-background: linear-gradient(90deg, $color-grey-200, $color-grey-200);
$two-cols-banner-button-color: $color-white;
$two-cols-banner-button-background: $color-primary;
$section-description-text-color-alt: $color-white;

$input-border-radius: 5px;
$checkbox-border-radius: 2px;
$checkbox-inset-border-radius: 1px;

$button-text-color-white: $color-primary-faded;
$button-text-color-primary: $color-white;
$button-border-radius: 0;

$header-links-text-color: $color-white;
$header-main-menu-background: linear-gradient(90deg, $color-primary, $color-primary-faded);
$header-secondary-menu-background: $color-mine-shaft;
$header-links-text-color-hover: $color-white;
$header-buttons-icon-color: $color-white;
$header-buttons-icon-color-active: $color-primary;
$header-buttons-icon-color-hover: $color-primary;
$header-buttons-background-color-active: $color-white;
$header-buttons-background-color-hover: rgba($color-white, .95);
$header-buttons-background-color-first: rgba($color-primary, .2);
$header-buttons-background-color-second: rgba($color-primary, .45);
$header-buttons-background-color-third: rgba($color-primary, .7);
$header-links-arrow-fill-color: $color-white;

$section-header-color-title: rgba($color-primary, 0.25);

$promotion-flap-background-color: $color-tertiary;
$promotion-flap-text-color: $color-white;
$promotion-flap-flap-color: $color-tertiary;

$icon-color: $color-tertiary;

$card-icon-icon-color: $icon-color;
$card-pack-feature-icon-color: $icon-color;
$card-background-active-color: $color-primary;
$card-text-active-color: $color-tertiary;
$card-border-radius: 5px;
$price-with-subject-text-color: $color-secondary;
$price-with-subject-amount-line-height: .75em;

$shadow-color: $color-mine-shaft;

$hero-slider-nav-indicator-color: $color-secondary;

$accordion-item-icon-color: $color-secondary; //$color-tertiary

$horizontal-scroller-bar-color: $color-tertiary;
$pagination-bullets-color: $horizontal-scroller-bar-color;
$pagination-item-color: $horizontal-scroller-bar-color;

$promotion-ribbon-background: $color-cerulean;
