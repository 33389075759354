.error404 {
  font-family: "R Gerstner", sans-serif;
  color: #fff;
  background-image: url('#{$environment-local-url}/images/404/error-404.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom right;
  aspect-ratio: 1440/960;
  padding-top: 15px;

  &__wrapper {
    position: relative;
  }

  &__star {
    display: block;
    width: 170px;
    height: 146px;
    background-image: url('#{$environment-local-url}/images/404/star.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: absolute;
    top: -150px;
    right: 0;
    z-index: 10;
    animation-name: star;
    animation-duration: 4s;
    animation-delay: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease-out;
  }

  &__title {
    font-weight: 700;
    font-size: max(40px, min(8.3333vw, 120px));
    text-shadow: 4.97851px 4.97851px 24.8925px rgba(0, 0, 0, 0.25);
  }

  &__subtitle {
    font-weight: 700;
    font-size: max(120px, min(29.4118vw, 360px));
    line-height: .85;
    text-shadow: 4.97851px 4.97851px 24.8925px rgba(0, 0, 0, 0.25);
    margin: 0 0 0.111112em 0;
  }

  .button {
    font-family: 'Lato';
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    background-color: #EF3340;
    color: #fff;
    border-radius: 8px;
  }

  .error__content {
    display: flex;
    flex-direction: column;
    width: min-content;
    text-align: center;

    &__text {
      margin: 0 0 30px 0;
    }
  }

  &__line1 {
    font-size: max(24px, min(2.777775vw, 40px));
    font-weight: 700;
    margin: 0 0 15px 0;
    text-shadow: 4.97851px 4.97851px 24.8925px rgba(0, 0, 0, 0.25);
  }

  &__line2 {
    font-size: max(12px, min(1.527775vw, 22px));
    font-weight: 700;
    text-shadow: 4.97851px 4.97851px 24.8925px rgba(0, 0, 0, 0.25);
  }

  &__footer {
    margin: 0 0 120px 0;
  }
}


@keyframes star {
  from { transform: translate(0,0) rotate(0deg); }
  to { transform: translateX(-90vw) translateY(38vw) rotate(90deg); }
}
