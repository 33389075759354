.u-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1110px;
  width: 100%;

  &--narrow {
    max-width: 730px;
  }

  &--wide {
    max-width: 1250px;
  }

  &--wider {
    max-width: 1280px;
  }

  &--larger {
    max-width: 1440px;
  }
}

.u-example__section {
  padding: 32px 16px;
}

.u-example__title {
  font-size: 30px;
}

.u-hidden {
  display: none !important;
}

.u-height-100 {
  height: 100%;
}

.u-relative {
  position: relative;
}
