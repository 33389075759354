:root {
  --s-v21: 8px;
  --header-height: 72px;
  --header-secondary-height: 36px;
  --header-secondary-mobile-height: 39px;
  --header-logo-width: 114px;
  --header-logo-height: 24px;
  --header-button-height: 40px;
  --header-separator-margin: calc(var(--s-v21) * 2);
  --header-separator-width: 2px;
  --header-secondary-separator-height: 12px;
  --header-secondary-separator-width: 1px;
  --header-button-call-us-line-height: #{rem(14px)};
  --header-button-cmn-mobile-height: 34px;
  --header-submenu-height: 40px;
  --header-menu-underline-height: 4px;
  --header-menu-mobile-link-height: 56px;
  --header-mobile-call-us-font-size: 18px;
  --header-mobile-call-us-icon-size: 32px;

  --stroke-color: #340609;
  --button-text-color: #fff;
  --color-dark-v21: #b4b4b4;
  --color-white: $color-white;
  --color-text-v21: var(--color-text);
  --color-primary-v21: #ef3340;
  --link-color: var(--color-primary-v21);
  --color-grey-200-v21: #444;
  --color-grey-600-v21: #363636;
  --color-primary-900-v21: #340609;
  --color-primary-700-v21: #9C181E;;

  --font-family-primary: "R Gerstner",sans-serif;
  --font-family-secondary: "Lato",sans-serif;
}

.header-space {
  width: 100%;
  height: var(--header-height);

  @include media (large up) {
    height: calc(var(--header-height) + var(--header-secondary-height));
  }

}

.we-are {
  .header-space {
    height: 0px;
  }
}

.header {
  position: fixed;
  top: 0;
  z-index: 1004;

  width: 100%;
  height: var(--header-height);

  background-color: var(--color-text-v21);
  color: var(--color-white);

  @include media (large up) {
    top: var(--header-secondary-height);
  }

  >.u-wrapper{
    max-width: 1380px;
  }

  .header__wrapper {
    width: 100%;
    height: 100%;
    display: inline-flex;
    justify-content: start;
    align-items: center;

    .link--pymes {
      .icon {
        width: 15px;
      }
    }
  }

  .header__left-block {
    display: flex;
    align-items: center;
  }

  .header__logo {
    display: flex;
    flex-shrink: 0;
    cursor: pointer;

    transition: opacity 0.2s ease-in-out;
    -webkit-transition: opacity 0.2s ease-in-out;
    @include media (small up) {
      display: flex;
      opacity: 1;
    }

    .icon {
      --stroke-color: var(--color-white);
      --stroke-width: 0;
      --fill-color: var(--color-white);
      --width: fit-content;
      height: 35px;
    }

    &--mobile {

      opacity: 1;
      transition: opacity 0.2s ease-in-out;
      -webkit-transition: opacity 0.2s ease-in-out;
      -moz-transition: opacity 0.2s ease-in-out;
      -o-transition: opacity 0.2s ease-in-out;
      @include media (small up) {
        display: none;
      }

      .icon {
        --stroke-color: var(--color-white);
        --stroke-width: 0;
        --fill-color: var(--color-white);
        --width: 24px;
        --height: 35px;
      }
    }
  }

  .header__hamburger {
    &:not(.header__hamburger__display){
      display: block;
      transition: opacity 0.2s ease-in-out;
      -webkit-transition: opacity 0.2s ease-in-out;
      -moz-transition: opacity 0.2s ease-in-out;
      -o-transition: opacity 0.2s ease-in-out;
      &:hover{
        cursor:pointer;
      }

      span {
        display: block;
        background-color: var(--color-white);
        width: 21px;
        height: 1px;
        margin-bottom: 6px;
        @include media (large up){
          background-color:transparent;
          pointer-events: none;
          &:hover{
            cursor: default;
          }
        }
      }

      :last-child {
        margin-bottom: 0;
      }

      @include media (large up) {
        display: none !important;
      }
    }

  }

  .header__menu {
    display: none;
    flex-shrink: 0;
    margin-left: calc(var(--s-v21)*3);

    &__item {
      display: inline-block;
      flex-direction: row;

      padding-top: var(--header-menu-underline-height);
      height: var(--header-height);

      color: var(--color-white);

      &:after {
        display: block;
        content: '';
        bottom: 0;
        width: 0;
        left: 0;
        height: 4px;
        transition: width 250ms ease-in-out;
        background-color: var(--color-white);
      }

      &:hover:after {
        width: 100%;
      }

      &:last-child {
        margin-right: 0;
      }

      @include media (large up) {
        margin-right: var(--s-v21);
      }
      @include media (xlarge up) {
        margin-right: calc(var(--s-v21) * 4);
      }

      .link {
        display: flex;
        height: calc(100% - 4px);

        padding-left: var(--s-v21);
        padding-right: var(--s-v21);

        --link-hover: var(--color-white);

        &__text {
          font-weight: 700 !important;
          font-size: 16px !important;
          color:var(--color-white);
          font-family: var(--font-family-primary) !important;
          @include media (xlarge up) {
            font-size: var(--base-font-size);
          }
        }
        & .link__content {
          align-items: center;
          color: var(--link-color);
          display: inline-flex;
        }
      }

      .icon {
        height: calc(var(--s-v21) * 2.5);
        width: 20px;
        margin-right: var(--s-v21);

        --stroke-color: var(--color-white);
        svg{
          path{
            stroke:var(--color-white);
          }
        }

        &:hover {
          color: var(--color-white);
        }

        @include media (xlarge up) {
          height: calc(var(--s-v21) * 3);
          width: 24px;
        }
      }

    }

    @include media (large up) {
      display: block;
    }

    ul {
      display: inline-flex;
      list-style: none;
    }
  }

  .header__buttons {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    height: var(--header-height);
    margin-left: auto;

    transition: opacity 0.2s ease-in-out;
    -webkit-transition: opacity 0.2s ease-in-out;

    &__actions {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      height: var(--header-height);

      transition: opacity 0.2s ease-in-out;
      -webkit-transition: opacity 0.2s ease-in-out;

      .icon{
        display: inline-flex;
        height: fit-content;
        margin: 0px 24px 0 0;
        svg{
          stroke: none;
        }
      }

      .button-customer-area{
        margin-right: 20px;
        display: flex;
        background-color: var(--color-primary-v21);
        align-items: center;
        transition: all 0.3s ease-in-out;
        &:hover{
          background-color: var(--color-primary-700-v21);
        }
        .button__text{
          font-weight: 900;
        }
      }

      .ic-phone-link{
        display: flex;
        color: var(--color-light-v21);
        padding: 10px 22px;
        align-items: center;
        background-color: var(--color-primary-v21);
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
        margin-right: calc(var(--s-v21)*2);
        transition: all 0.3s ease-in-out;
        @include media (large up) {
          display: none;
        }
        &:hover{
          background-color: var(--color-primary-700-v21);
        }
        .header__button-primary{
          &:not(.ic-phone-link){
            border: 1px solid;
            border-radius: 0px 8px 8px 8px;
          }
        }
        .icon{
          width: fit-content;
          margin-right: var(--s-v21);
        }
      }
      .desktop{
        display: none;
        @include media (large up){
          display: inherit;
        }
        &:hover{
          opacity: 0.8;
        }
      }

      .button .icon.icon--mr {
        margin-right: 8px;
        --button-color: inherit;
        & svg {
          width: auto;
          @include media (medium up) {
            width: 100%;
          }
        }
      }
    }

    &__cmn {
      display: none;
      height: var(--header-button-height);

      @include transition();

      @include media (large up) {
        display: inline-flex;
      }

      .icon svg {
        @include transition();
      }

      &:hover{
        background-color: #9c181e;
      }
      &--mobile {

        height: var(--header-button-cmn-mobile-height);
        border-radius: 12px;
        margin-right: 20px;
        display: flex;
        flex-direction: row;
        gap: 10px;

        @include media (large up) {
          display: none;
        }

        .icon {
          width: calc(var(--s-v21) * 2);
          stroke: none;
          --fill-color: var(--color-primary-v21);
        }
        .button__text {
          font-size: var(--medium-font-size);
          font-weight: 900;
        }
      }
      .button__text {
        font-size: var(--medium-font-size);
        font-weight: 900;
      }
    }

    &__call-us {
      display: none;
      height: var(--header-button-height);

      @include media (large up) {
        display: inline-flex;
      }

      .button__text {
        font-size: var(--small-font-size);
        font-weight: 400;

        line-height: var(--header-button-call-us-line-height);

        @include transition();

        em {
          font-size: var(--base-font-size);
          font-weight: bold;
        }
      }
    }

    &__customer-area {
      display: none;
      min-width: calc(var(--s-v21) * 3) !important;

      border-radius: 0 !important;
      margin-left: calc(-1 * var(--header-separator-width));
      padding: 0;
      height: var(--header-height);

      @include transition();

      @include media(large up) {
        display: flex;
        padding: 0 calc(var(--s-v21) * 2);
      }

      @include media(xlarge up) {
        padding: 0 calc(var(--s-v21) * 3);
      }

      .button__text {
        display: none;

        font-weight: bold;
        color: var(--button-text-color);
        font-size: var(--medium-font-size);
        font-weight: 900;

        &:hover {
          color: var(--color-primary-v21);
        }

        @include media(large up) {
          display: none;
        }

        @include media(xlarge up) {
          display: block;
          color: var(--button-text-color);
          font-size: var(--base-font-size);
          font-weight: 900;
        }

      }

      .icon {
        display: none;
        border-radius: 50%;
        background-color: var(--color-white);
        --stroke-width: 2px;
        margin-left: calc(var(--s-v21) * 2);
        justify-content: center;
        align-items: center;

        @include media(small up) {
          display: flex;
        }

        svg {
          stroke: var(--color-primary-v21);
          height: 15px;
          width: 14px;

          & path {
            stroke: var(--color-primary-v21) !important;
          }
        }

        @include media(large up) {
          margin-left: 0;
          margin-right: var(--s-v21);
        }

      }

      @include media(large up) {
        &:hover {
          border-radius: 0;
          background-color: var(--color-white);

          .button__text {
            color: var(--color-primary-v21);
          }

          .icon {
            background-color: var(--color-primary-v21);

            svg {
              stroke: var(--color-white);
            }

            svg path {
              stroke: var(--color-white);
            }
          }
        }
      }
      &:hover {
        svg {

          & path {
            stroke: var(--color-white) !important;
          }
        }
      }
    }

    &__separator {
      display: none;

      width: var(--header-separator-width);
      height: var(--header-button-height);
      margin-left: var(--header-separator-margin);
      background-color: var(--color-white);
      border-radius: 1px;

      @include media (large up) {
        display: block;
        margin-left: calc(var(--s-v21) * 2);
      }

      @include media (xlarge up) {
        margin-left: calc(var(--s-v21) * 3);
      }
    }

    &__close {
      display: none;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      -webkit-transition: opacity 0.2s ease-in-out;
      &:hover{
        cursor: pointer;
      }

      .icon {
        --stroke-color: var(--color-white);
        svg{
          path{
            stroke:var(--color-white);
          }
        }
      }
    }
  }
}

.pre-header {
  position: fixed;
  top: 0;
  z-index: 1004;

  display: none;
  width: 100%;

  background-color: #DADADA;
  color: var(--color-dark-v21);

  height: var(--header-secondary-height);
  align-items: center;

  @include media (large up) {
    display: flex;
  }
  .u-wrapper{
    max-width: 1380px;
  }

  &__wrapper {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;

    ul {
      display: inline-flex;
      list-style: none;
      align-items: center;
    }
  }

  &__item {
    .link__text {
      font-size: var(--medium-font-size);
      font-weight: 400;
      color: var(--color-primary);
      @include transition();
    }

    &.active {
      .link__text {
        color: var(--color-primary-v21);
        font-weight: bold;
      }
    }

    &:hover {
      .link__text {
        color: var(--color-primary-v21);
      }
    }

  }

  &__separator {
    width: var(--header-secondary-separator-width);
    height: var(--header-secondary-separator-height);
    margin-right: var(--s-v21);
    margin-left: var(--s-v21);
    background-color: var(--color-text);
  }

  &__other-links {
    margin-right: calc(var(--s-v21) * 3);
  }

  &__switch-language {
    margin-right: var(--s-v21);
    .pre-header__item{
      // text-transform: uppercase;
    }
  }

  &__other-links, &__switch-language {
    justify-self: end;

    .link__text {
      color: var(--color-grey-600-v21);
    }

    .pre-header__item {
      &.active {
        .link__text {
          color: var(--color-primary-v21);
          font-weight: bold;
        }
      }

      &:hover {
        .link__text {
          color: var(--color-grey-700-v21);
        }
      }

    }
  }

  .pre-header-buttons-wrapper{
    display: flex;
    gap:calc(var(--s-v21)*2);
    .pre-header__cmn{
      display: flex;
      align-items: center;
      gap: calc(var(--s-v21)*2);
      color: var(--color-primary);
      .pre-header__cmn-button.ic-phone-link{
        display: flex;
        color: var(--button-text-color);
        padding: calc(var(--s-v21) / 2) calc(var(--s-v21) * 2);
        align-items: center;
        background-color: var(--color-primary-v21);
        transition: all 0.3s ease-in-out;
        &:hover{
          background-color: var(--color-primary-700-v21);
        }
        .icon{
          width: fit-content;
        }
      }
    }
  }

}

.header-submenu {
  display: none;
  position: fixed;
  top: calc(var(--header-secondary-height) + var(--header-height) - var(--header-submenu-height));

  z-index: 1003;

  height: var(--header-submenu-height);
  width: 100%;
  transition: top 250ms ease-in-out;
  background-color: var(--color-white);
  box-shadow: 4px 4px 20px rgba(38, 37, 37, 0.1);

  @include media (large up) {
    display: block;
  }

  &__wrapper {
    position: absolute;
    display: none;
    list-style: none;
    height: var(--header-submenu-height);

    &:last-child {
      margin-right: 0;
    }
  }

  &__item {
    display: inline-block;

    height: calc(100% - var(--header-menu-underline-height) * 2);

    margin-top: var(--header-menu-underline-height);
    margin-right: var(--s-v21);
    padding: 0 var(--s-v21);

    &:after {
      display: block;
      content: '';
      bottom: 0;
      width: 0;
      left: 0;
      height: 2px;
      transition: width 250ms ease-in-out;
      background-color: var(--color-primary-v21);
      transform: translateY(2px);
    }

    &:hover:after {
      width: 100%;
    }

    .link {
      display: flex;
      height: 100%;
      .link__content{
        color: var(--color-primary) !important;
      }
      &__text {
        font-weight: normal;
        font-size: var(--medium-font-size);
        @include media (xlarge up) {
          font-size: var(--base-font-size);
        }
      }
    }

    &.item-agile-ref {
      position: relative;
    }

    & .agile-ref .link__text {
      font-size: 12px;
      line-height: 24px;
      color: var(--color-grey-700-v21);
      @include flexbox();
      @include align-items(center);

      & img {
        margin-left: 6px;
        height: 12px;
      }
    }
  }
}

.header-menu-mobile {
  display: none;
  justify-content: space-between;
  flex-direction: column;
  position: fixed;
  z-index: 1002;
  height: calc(100% - 111px);
  width: 100%;
  background-color: var(--color-white);
  overflow-y: scroll;
  justify-content: space-between;

  @include media (large up) {
    display: none;
  }

  ul {
    list-style: none;
  }

  &__menu {
    padding: var(--s-v21) 0;

    .link {
      height: var(--header-menu-mobile-link-height);
      border-bottom: 1px solid var(--color-grey-200-v21);
      width: 100%;

      &__content {
        width: 100%;
        justify-content: space-between;
        color: var(--color-primary-v21);
      }

      &__text {
        display: flex;
        flex-direction: column;
        padding: var(--s-v21) 0;
        font-weight: bold !important;

        small {
          color: var(--color-grey-600-v21);
          font-size: var(--medium-font-size);
          font-weight: 400;
        }
      }

      .icon {
        --stroke-color: var(--color-primary-v21);
        width: auto;
      }
    }
  }

  &__buttons {
    // position: fixed;
    z-index: 1084;
    bottom: var(--header-secondary-mobile-height);
    width: 100%;

    .link {
      border-bottom: none;
    }
  }

  &__customer-area, &__call-us {
    .link {
      width: 100%;
      &.customer{
        .link__content{
          .icon{
            background-color: var(--color-primary-v21);
            svg{
              path{
                stroke:var(--color-white);
              }
            }
          }
        }
      }
      &.notCustomer{
        .link__content{
          .icon{
            background-color: transparent;
            border-radius: 50%;
            border: 2px solid var(--color-primary-v21);
            svg{
              path{
                stroke:var(--color-primary-v21);
                stroke-width: 2px;
              }
            }
          }
        }
      }

      &__content {
        height: var(--header-height);

        .icon {
          --stroke-color: var(--color-white);
          height: var(--header-mobile-call-us-icon-size);
          width: var(--header-mobile-call-us-icon-size);
          margin-right: calc(var(--s-v21) * 2);
        }
      }

    }
  }

  &__customer-area {
    .link {
      &__content {
        .icon {
          background-color: var(--color-primary-v21);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            height: 18px;
            width: 20px;
          }
        }
      }

      &__text {
        font-weight: bold;
        font-size: var(--base-font-size);
        color:var(--color-text);
      }
    }
  }

  &__call-us {
    background-color: var(--color-primary);

    .link {
      &__text {
        font-size: var(--header-mobile-call-us-font-size);
        line-height: var(--paragraph-line-height);

        em {
          text-transform: uppercase;
          font-weight: bold;
        }
      }
      .link__content{
        color:var(--color-white);
        .icon{
          svg{
            path{
              stroke:var(--color-white);
            }
          }
        }
      }
    }
  }

  &__item {
    &--help {
      .link {
        display: flex;
      }
    }

    & .no-events {
      pointer-events: none;

      &:hover {
        cursor: pointer;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

}

.pre-header-mobile {
  display: none;


  position: fixed;
  bottom: 0;

  z-index: 1004;

  justify-content: space-between;
  align-items: center;

  background-color: var(--color-dark-v21);
  color: var(--color-primary-v21);

  height: var(--header-secondary-mobile-height);
  padding-right: 16px;
  padding-left: 16px;

  ul {
    list-style: none;

    .link {
      color: var(--color-primary-v21);
    }
  }

  &__customer-type {

    &__selected {
      .link {
        height: var(--header-secondary-mobile-height);
        display: flex;
        align-items: center;

        &__text {
          font-weight: 400;
          font-size: var(--medium-font-size);
          line-height: var(--small-line-height);
          color: var(--color-primary-v21);
        }
      }
      .icon--s16 {
        width: calc(var(--s-v21)*2);
        svg {
          width: 100%;
          margin-left: 7px;
        }
      }
    }

    a.link{
      &.dark{
        .link__text{
          font-weight: 400 !important;
        }
      }
    }

    &__list {
      display: none;

      position: fixed;
      bottom: var(--header-secondary-mobile-height);
      left: 0;
      width: 100%;

      background-color: var(--color-dark-v21);
      .link {
        height: var(--header-secondary-mobile-height);
        display: flex;
        align-items: center;

        &__text {
          font-weight: 400;
          font-size: var(--medium-font-size);
          line-height: var(--small-line-height);
        }
      }
    }

    &__item {
      border-bottom: 1px solid var(--cancel-button-color);
      padding-right: 16px;
      padding-left: 16px;
    }
  }

  &__switch-language {
    display: inline-flex;
    align-items: center;

    .link {

      &.active {
        .link__text {
          font-weight: bold;
        }
      }

      &__text {
        font-weight: 400;
        font-size: var(--medium-font-size);
        line-height: var(--small-line-height);
      }
    }
  }

  &__separator {
    width: var(--header-secondary-separator-width);
    height: var(--header-secondary-separator-height);
    margin-right: var(--s-v21);
    margin-left: var(--s-v21);
    background-color: var(--color-text);
  }
}

.header-submenu-mobile {
  display: flex;
  position: fixed;
  top: var(--header-height);
  left: 100%;
  z-index: 1003;
  background-color: var(--color-white);
  width: 100%;
  height: calc(100% - 111px);
  overflow-y: scroll;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;

  @include media (large up) {
    display: none;
  }

  ul {
    list-style: none;
  }

  &__category {
    &:hover {
      cursor: pointer;
    }
  }

  .no-events {
    pointer-events: none;

    &:hover {
      cursor: pointer;
    }
  }

  .link {
    display: flex;
    align-items: center;

    height: var(--header-menu-mobile-link-height);
    border-bottom: 1px solid var(--color-grey-200-v21);
    width: 100%;

    &__content {
      width: 100%;
      padding-right: 16px;
      padding-left: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      color: var(--color-primary-v21);
    }

    &__text {
      display: block;
      flex-direction: column;
      padding: var(--s-v21) 0;
      font-weight: bold;

      small {
        color: var(--color-grey-600-v21);
        font-size: var(--medium-font-size);
        font-weight: 400;
      }
    }

    .icon {
      --stroke-color: var(--color-primary-v21);
      width: auto;
    }
  }

  &__menu {
    padding: var(--s-v21) 0;
  }

  &__item {
    &.item-agile-ref {
      position: relative;
    }

    & .agile-ref .link__text {
      width: 100%;
      font-size: 12px;
      line-height: 24px;
      color: var(--color-grey-700-v21);
      @include flexbox();
      @include align-items(center);
      @include flex-direction(row);
      @include justify-content(flex-start);

      & img {
        margin-left: 6px;
        height: 12px;
      }
    }
  }

 &__buttons {
    // position: fixed;
    z-index: 1084;
    bottom: var(--header-secondary-mobile-height);
    width: 100%;

    .link {
      border-bottom: none;
    }
  }

  &__customer-area, &__call-us {
    .link {
      width: 100%;
      height: var(--header-height);
      &.customer{
        .link__content{
          .icon{
            background-color: var(--color-primary-v21);
            svg{
              path{
                stroke:var(--color-white);
              }
            }
          }
        }
      }
      &.notCustomer{
        .link__content{
          .icon{
            background-color: transparent;
            border-radius: 50%;
            border: 2px solid var(--color-primary-v21);
            svg{
              path{
                stroke:var(--color-primary-v21);
                stroke-width: 2px;
              }
            }
          }
        }
      }

      &__content {
        height: var(--header-height);

        .icon {
          --stroke-color: var(--color-white);
          height: var(--header-mobile-call-us-icon-size);
          width: var(--header-mobile-call-us-icon-size);
          margin-right: calc(var(--s-v21) * 2);
        }
      }

    }
  }

  &__customer-area {
    .link {
      &__content {

        .icon {
          background-color: var(--color-primary-v21);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            height: 18px;
            width: 20px;
          }
        }
      }

      &__text {
        font-weight: bold;
        font-size: var(--base-font-size);
        color:var(--color-text);
      }
    }
  }

  &__call-us {
    background-color: var(--color-primary-900-v21);
    overflow: hidden;

    .link {
      .icon{
        svg{
          path{
            stroke:var(--color-white);
          }
        }
      }
      &__text {
        font-size: var(--header-mobile-call-us-font-size);
        line-height: var(--paragraph-line-height);
        color: var(--color-white);

        em {
          text-transform: uppercase;
          font-weight: bold;
        }
      }
    }
  }

  &__item {
    &--help {
      .link {
        display: flex;
      }
    }

    & .no-events {
      pointer-events: none;

      &:hover {
        cursor: pointer;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
}
.header__menu__item--active::after {
  width: 100% !important;
}

.header,
.footer,
.pre-header,
.header-menu-mobile,
.header-submenu {
  & .u-wrapper {
    max-width: 1680px;
    padding-left: 16px;
    padding-right: 16px;

    @include media(mobile up) {
      padding-left: 16px;
      padding-right: 16px
    }

    @include media(mobilexlarge up) {
      padding-left: 32px;
      padding-right: 32px
    }

    @include media(medium up) {
      padding-left: 48px;
      padding-right: 48px
    }

    @include media(large up) {
      padding-left: 58px;
      padding-right: 58px
    }

    @include media(xlarge up) {
      padding-left: 80px;
      padding-right: 80px
    }

    @include media(xxlarge up) {
      padding-left: 96px;
      padding-right: 96px
    }

    @include media(xxxlarge up) {
      padding-left: 48px;
      padding-right: 48px
    }
  }
}
.hide-from-lg {
  display: inherit;

  @include media (large up) {
    display: none !important;
  }
}
