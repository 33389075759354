.c-tvgo {
    width: calc(100% + 64px);
    margin: 0 -32px;
    color: #1D1F2C;
    font-family: $font-family-primary;
    background-color: rgba(236, 236, 236, 0.2);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 100%;
    padding: 50px 0 55px 0;

    &__inner {
        position: relative;

        @media (max-width: 1100px){
            padding: 0 30px;
        }
    }

    &__pretitle {
        font-weight: bold;
        font-size: 21px;
        line-height: 27px;
        letter-spacing: -0.5px;
    }

    &__title {
        font-family: "R Gerstner", sans-serif;
        font-weight: bold;
        font-size: 60px;
        line-height: 72px;
        letter-spacing: -0.5px;
        color: #ef3340;
        margin: 0 0 10px 0;
    }

    &__text {
        font-family: $font-family-secondary;
        font-size: 16px;
        line-height: 24px;
        max-width: 365px;
        margin: 0 0 15px 0;

        @media (max-width: 700px){
            max-width: none;
        }
    }

    &__cta {
        font-family: $font-family-secondary;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 30px 0;

    }

    &__app-title {
        font-family: $font-family-secondary;
        font-size: 14px;
        line-height: 20px;
        margin: 0 0 20px 0;
    }

    &__app-btns {
        display: flex;
    }

    &__img {
        position: absolute;
        top: -25px;
        right: 0;
        width: 678px;
        height: 410px;

    @media (max-width: 1100px){
        width: 575px;
        height: 348px;
    }

    @media (max-width: 900px){
        width: 420px;
        height: 254px;
    }

    @media (max-width: 800px){
        width: 330px;
        height: 200px;
        right: 30px;
    }

    @media (max-width: 700px){
        position: relative;
        top: 0;
        right: 0;
        width: auto;
        height: auto;
        padding-top: 30px;
    }

        &__background {
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;

            @media (max-width: 700px){
                position: relative;
                max-width: 80%;
                display: block;
                margin: auto;
            }
        }
    }

}

.c-tvgo__app-btns__cta {
    background-color: #000;
    border-radius: 100px;
    display: block;
    transition: all 0.3s ease-in-out;
    border: 1px solid #1D1F2C;
    min-width: 150px;
    height: 35px;
    margin-right: 16px;
    margin-bottom: 0;
    position: relative;
    overflow: hidden;

    svg {
        height: 42px;
        width: auto;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}

.tvgo-disclaimer {
    background-color: $color-grey-300;
    padding: $dimension-section-vertical-padding 0;
    p {
        font-size: $font-size-11;
        color: $color-grey-600;
        padding: 0 1.5rem;
    }
}